import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance';
import transformArrayToMap from 'utils/transformArrayToMap';

const urlPrefix = '/v1';

const productService = {
  getProducts(params) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/product/search`,
      params
    ).then(({ result: list }) => transformArrayToMap(list));
  },

  createProduct(data) {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/product`, data);
  },

  toggleLike(productId, isLiked) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/product/${productId}/like`,
      { isLiked }
    );
  },
};

export default productService;
