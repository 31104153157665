import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import myImagesClasses from 'modules/dashboard/myImages/MyImages.module.scss';
import LookBoardPreview from 'components/lookBoardThumbnails/LookBoard/LookBoardPreview';
import FilterInput from 'modules/dashboard/components/FilterInput/FilterInput';
import {
  lookBoardStatusRadioOptions,
  myLookBoardsSortOptions,
  myLookBoardsTypeOptions,
} from 'modules/dashboard/constants';
import { approvalStatusKeys } from 'modules/inspirationImage/constants';
import { lookBoardStatusKeys } from 'constants/lookBoardSearchParams';
import CustomRadioGroup from 'modules/curateTheLook/createLookBoard/components/CustomRadioGroup';
import OverlayLabel from 'components/imageThumbnails/OverlayLabel/OverlayLabel';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import { toggleUnderConstructionModalAction } from 'modules/layout/store/actions';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import LookBoard from 'components/lookBoardThumbnails/LookBoard/LookBoard';
import classes from './MyLookBoards.module.scss';

const MyLookBoardsComponent = ({
  loading,
  hasMore,
  loadMore,
  lookBoardList,
  currentType,
  currentSort,
  currentStatus,
  onChangeSearchParams,
  onDeleteLookBoard,
  colors,
  toggleUnderConstructionModal,
}) => {
  const [selectedLookBoard, setSelectedLookBoard] = useState(null);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);

  const statusValue = useMemo(
    () =>
      currentStatus
        ? lookBoardStatusKeys.drafts
        : lookBoardStatusKeys.published,
    [currentStatus]
  );

  const handleChangeType = useCallback(
    (type) => {
      onChangeSearchParams({ type });
    },
    [onChangeSearchParams]
  );

  const handleChangeSort = useCallback(
    (sort) => {
      onChangeSearchParams({ sort });
    },
    [onChangeSearchParams]
  );

  const handleChangeStatus = useCallback(
    ({ target: { value } }) => {
      onChangeSearchParams({
        draft: value === lookBoardStatusKeys.drafts ? 1 : 0,
      });
    },
    [onChangeSearchParams]
  );

  const handleLoadMore = useCallback(() => {
    if (!hasMore || loading) {
      return;
    }
    loadMore();
  }, [loading, hasMore, loadMore]);

  const handlePreviewModalOpen = useCallback(
    ({ currentTarget }) => {
      const lookBoardId = currentTarget.getAttribute('data-id');
      const currentLookBoard = lookBoardList.find(
        ({ id }) => id === Number.parseInt(lookBoardId, 10)
      );

      setSelectedLookBoard(currentLookBoard);
      setPreviewModalOpen(true);
    },
    [lookBoardList]
  );

  const handlePreviewModalClose = useCallback(() => {
    setPreviewModalOpen(false);
  }, []);

  const handleUnderConstructionModalOpen = useCallback(() => {
    toggleUnderConstructionModal(true);
  }, [toggleUnderConstructionModal]);

  return (
    <>
      <div className={myImagesClasses.section}>
        <div className="d-flex justify-content-between mb-3">
          <FilterInput
            className={classes.typeSelect}
            options={myLookBoardsTypeOptions}
            currentValue={currentType}
            onChange={handleChangeType}
          />
          <FilterInput
            additionalLabel="Sort by:"
            className={classes.sortSelect}
            options={myLookBoardsSortOptions}
            currentValue={currentSort}
            onChange={handleChangeSort}
          />
        </div>
        <CustomRadioGroup
          controls={lookBoardStatusRadioOptions}
          value={statusValue}
          onChange={handleChangeStatus}
        />
        {lookBoardList.length > 0 ? (
          <InfiniteScroll
            hasMore={hasMore}
            loadMore={handleLoadMore}
            initialLoad={false}
          >
            <div className="row">
              {lookBoardList.map(
                ({ id, title, colorId, isLiked, products, approval }) => (
                  <div key={id} className="col-3 mb-2">
                    <LookBoardPreview
                      id={id}
                      title={title}
                      colorId={colorId}
                      isLiked={isLiked}
                      products={products}
                      showStaticOverlay={
                        approval === approvalStatusKeys.pending
                      }
                      staticOverlay={
                        <div className="h-100 flex-center">
                          <OverlayLabel text="under review" />
                        </div>
                      }
                      hoverOverlay={
                        <div className={classes.hoverOverlay}>
                          <div className="w-100 d-flex justify-content-end">
                            <IconButton
                              color="secondary"
                              size="sm"
                              data-id={id}
                              onClick={handlePreviewModalOpen}
                            >
                              <SpriteIcon name="loupe" size="sm" />
                            </IconButton>
                          </div>
                          <p className={classes.hoverLabel}>
                            Click to view look board details
                          </p>
                          <div className="w-100 d-flex justify-content-end">
                            <IconButton
                              variant="inverted-grey"
                              color="secondary"
                              size="sm"
                              className="mr-1"
                              data-id={id}
                              onClick={onDeleteLookBoard}
                            >
                              <SpriteIcon name="trash" size="sm" />
                            </IconButton>
                            <IconButton
                              variant="inverted-grey"
                              color="secondary"
                              size="sm"
                              className="mr-1"
                              onClick={handleUnderConstructionModalOpen}
                            >
                              <SpriteIcon name="edit" size="sm" />
                            </IconButton>
                            <IconButton
                              variant="inverted-grey"
                              color="secondary"
                              size="sm"
                            >
                              <SpriteIcon
                                name={isLiked ? 'like-filled' : 'like'}
                                size="sm"
                              />
                            </IconButton>
                          </div>
                        </div>
                      }
                    />
                  </div>
                )
              )}
            </div>
          </InfiniteScroll>
        ) : (
          <div className="flex-fill flex-center font-title text-gray text-lg">
            List is Empty
          </div>
        )}
      </div>
      <BasicModal
        open={previewModalOpen}
        onClose={handlePreviewModalClose}
        fullWidth
        maxWidth="md"
        scroll="body"
        classes={{ root: 'p-0' }}
      >
        <LookBoard
          title={selectedLookBoard?.title}
          color={colors[selectedLookBoard?.colorId]?.code}
          columns={selectedLookBoard?.columns}
          items={selectedLookBoard?.products}
          collapsed={false}
          interactive={false}
        />
      </BasicModal>
    </>
  );
};

MyLookBoardsComponent.propTypes = {
  loading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  lookBoardList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentType: PropTypes.string.isRequired,
  currentSort: PropTypes.string.isRequired,
  currentStatus: PropTypes.number.isRequired,
  onChangeSearchParams: PropTypes.func.isRequired,
  onDeleteLookBoard: PropTypes.func.isRequired,
  colors: PropTypes.shape({}).isRequired,
  toggleUnderConstructionModal: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  app: {
    enums: { colors },
  },
}) => ({
  colors,
});

const mapDispatchToProps = {
  toggleUnderConstructionModal: toggleUnderConstructionModalAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyLookBoardsComponent);
