import PropTypes from 'prop-types';

const descriptionOptionShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  imgUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

export default descriptionOptionShape;
