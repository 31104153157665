import {
  AUTH_INIT,
  AUTH_LOGIN,
  AUTH_LOGOUT,
} from 'modules/auth/store/constants';
import authService from 'modules/auth/authService';
import currentUserService from 'modules/currentUser/currentUserService';
import errorToastr from 'libs/toastr/errorToastr';

export const initAuthAction = () => async (dispatch) => {
  const token = authService.getAccessToken();
  if (token) {
    try {
      const currentUser = await currentUserService.getCurrentUser();
      dispatch({ type: AUTH_LOGIN, payload: currentUser });
    } catch (e) {
      errorToastr('Error', e.generalError);
    }
  }

  dispatch({ type: AUTH_INIT });
};

export const loginAction = (formValues) => async (dispatch) => {
  await authService.login(formValues);
  const currentUser = await currentUserService.getCurrentUser();
  dispatch({ type: AUTH_LOGIN, payload: currentUser });
};

export const signUpAction = (formValues) => async (dispatch) => {
  await authService.signUp(formValues);
  const { email, password } = formValues;
  await dispatch(loginAction({ email, password }));
};

export const verifyEmailAction = (userId, token) => async (dispatch) => {
  await authService.verifyEmail(userId, token);
  const currentUser = await currentUserService.getCurrentUser();
  dispatch({ type: AUTH_LOGIN, payload: currentUser });
};

export const logoutAction = () => async (dispatch) => {
  await authService.logout();
  dispatch({ type: AUTH_LOGOUT });
};
