import PropTypes from 'prop-types';

const preferencesShape = PropTypes.shape({
  profession: PropTypes.string,
  styles: PropTypes.arrayOf(PropTypes.number),
  subStyles: PropTypes.shape({}),
  roomTypes: PropTypes.arrayOf(PropTypes.number),
  colors: PropTypes.arrayOf(PropTypes.number),
  priceRange: PropTypes.number,
});

export default preferencesShape;
