import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import classes from 'components/MixMatchThumbnail/MixMatchThumbnail.module.scss';

const ImageSlider = ({ sliderRef, images, onChange }) => {
  return (
    <Slider ref={sliderRef} arrows={false} afterChange={onChange}>
      {images.map(({ id, url }) => (
        <div className={classes.slideContainer} key={id}>
          <div className={classes.slide}>
            <img src={url} alt="" />
          </div>
        </div>
      ))}
    </Slider>
  );
};

ImageSlider.propTypes = {
  sliderRef: PropTypes.oneOfType([PropTypes.object]).isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ImageSlider;
