export const signUpFormFields = {
  type: 'type',
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  password: 'password',
  passwordRepeat: 'passwordRepeat',
  countryId: 'countryId',
  businessName: 'businessName',
  businessType: 'businessType',
  businessUrl: 'businessUrl',
  showProducts: 'showProducts',
  terms: 'terms',
  description: 'description',
  languageId: 'languageId',
  zipCode: 'zipCode',
};

export const accountTypes = {
  personal: 'personal',
  business: 'business',
};

export const showProductsOptions = {
  all: 'all',
  onlyMyCountry: 'onlyMyCountry',
};

export const errorMessages = {
  required: 'This field is required',
  passwordInvalid: 'Password should be at least 8 chars',
  passwordsShouldMatch: 'Passwords should match',
};

export default {
  signUpFormFields,
  accountTypes,
  showProductsOptions,
  errorMessages,
};
