import React, { useState, useCallback } from 'react';
import { previewsCongif } from 'modules/home/constants';
import Previews from 'modules/home/components/GetTheLookYouLove/Previews';
import DetailModal from 'modules/home/components/GetTheLookYouLove/DetailModal/DetailModal';
import classes from './GetTheLookYouLove.module.scss';

const GetTheLookYouLove = () => {
  const [detailModalOpen, setDetailModalOpen] = useState(false);

  const handleOpenDetailModal = useCallback(() => {
    setDetailModalOpen(true);
  }, []);

  const handleCloseDetailModal = useCallback(() => {
    setDetailModalOpen(false);
  }, []);

  return (
    <>
      <section className={classes.root}>
        <div className="d-flex align-items-center flex-column">
          <h2 className={classes.title}>Get the Look you Love</h2>
          <p className={classes.description}>
            With Recommendations from <br />
            Decor Lovers & Skilled Personal Shoppers
          </p>
          <button
            onClick={handleOpenDetailModal}
            className={classes.detail}
            type="button"
          >
            View Detailed Info
          </button>
          <div className="mt-5">
            <Previews previews={previewsCongif} />
          </div>
        </div>
      </section>
      <DetailModal open={detailModalOpen} onClose={handleCloseDetailModal} />
    </>
  );
};

export default GetTheLookYouLove;
