import React from 'react';
import { toastr } from 'react-redux-toastr';
import ToastrIconContainer from 'libs/toastr/ToastrIconContainer/ToastrIconContainer';
import SpriteIcon from 'components/ui/SpriteIcon';

const warningToastr = (title, message, options = {}) => {
  toastr.warning(title, message, {
    icon: (
      <ToastrIconContainer type="warning">
        <SpriteIcon name="cross" size="sm" />
      </ToastrIconContainer>
    ),
    ...options,
    showCloseButton: true,
  });
};

export default warningToastr;
