import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProfileComponent from 'modules/account/profileAndPreferences/profile/ProfileComponent';
import { userShape } from 'modules/account/profileAndPreferences/propTypes';
import {
  getEnumAsSelectOptions,
  getUserAvatarUrl,
} from 'modules/app/store/selectors';
import { transformUserToProfileFormValues } from 'modules/account/profileAndPreferences/profile/transformers';
import {
  deactivateAccountAction,
  updateUserProfileAction,
} from 'modules/currentUser/actions';
import errorToastr from 'libs/toastr/errorToastr';
import successToastr from 'libs/toastr/successToastr';
import { urlPlaceholders } from 'constants/urlPlaceholders';
import mediaTypes from 'constants/mediaTypes';
import mediaService from 'modules/media/mediaService';
import authService from 'modules/auth/authService';

const ProfileContainer = ({
  user,
  countries,
  languages,
  userAvatarUrl,
  updateUserProfile,
  deactivateAccount,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isEditPassword, setIsEditPassword] = useState(false);
  const [profileFormValues, setProfileFormValue] = useState({});
  const [newImage, setNewImage] = useState(null);

  const newImageUrl = useMemo(() => {
    return newImage ? URL.createObjectURL(newImage) : newImage;
  }, [newImage]);

  const currentAvatarUrl = useMemo(() => {
    if (user.avatar) {
      const { userId, hash } = user.avatar;
      return userAvatarUrl
        .replace(urlPlaceholders.userId, userId)
        .replace(urlPlaceholders.hash, hash);
    }
    return null;
  }, [user, userAvatarUrl]);

  useEffect(() => {
    const profileData = transformUserToProfileFormValues(user);
    setProfileFormValue(profileData);
  }, [user]);

  const handleEnableEditMode = useCallback(() => {
    setIsEdit(true);
  }, []);

  const handleEnableEditPasswordMode = useCallback(() => {
    setIsEditPassword(true);
  }, []);

  const handleChangeProfile = useCallback(
    async (formValues) => {
      try {
        let avatarId = null;
        if (newImage) {
          const formData = new FormData();

          formData.append('type', mediaTypes.userAvatar);
          formData.append('file', newImage);

          const {
            result: { id },
          } = await mediaService.uploadMedia(formData);
          avatarId = id;
        }

        await updateUserProfile({ ...formValues, avatarId });
        setNewImage(null);
        setIsEdit(false);
        successToastr('Success', 'Profile was successfully updated');
      } catch (e) {
        errorToastr('Profile was not updated', e.generalError);
        return { ...e.validationErrors };
      }
      return true;
    },
    [newImage, updateUserProfile]
  );

  const handleSelectNewImage = useCallback(({ target: { files } }) => {
    setNewImage(files[0]);
  }, []);

  const handleCancelEditProfile = useCallback(() => {
    const profileData = transformUserToProfileFormValues(user);
    setProfileFormValue(profileData);
    setIsEdit(false);
    setNewImage(null);
  }, [user]);

  const handleChangePassword = useCallback(async (formValues) => {
    try {
      await authService.changePassword(formValues);
      successToastr('Success', 'Password successfully changed');
      setIsEditPassword(false);
    } catch (e) {
      errorToastr('Error', e.generalError);
      return { ...e.validationErrors };
    }
    return true;
  }, []);

  const handleCancelEditPassword = useCallback(() => {
    setIsEditPassword(false);
  }, []);

  const handleDeactivateUser = useCallback(async () => {
    try {
      await deactivateAccount();
    } catch (e) {
      errorToastr('Error', e.generalError);
    }
  }, [deactivateAccount]);

  return (
    <ProfileComponent
      isEdit={isEdit}
      isEditPassword={isEditPassword}
      onEnableEditMode={handleEnableEditMode}
      onEnableEditPasswordMode={handleEnableEditPasswordMode}
      profileValues={profileFormValues}
      user={user}
      avatarUrl={newImageUrl || currentAvatarUrl}
      countriesOptions={countries}
      languagesOptions={languages}
      onChangeProfile={handleChangeProfile}
      onChangePassword={handleChangePassword}
      onCancelEditProfile={handleCancelEditProfile}
      onCancelEditPassword={handleCancelEditPassword}
      onSelectNewImage={handleSelectNewImage}
      onDeactivateUser={handleDeactivateUser}
    />
  );
};

ProfileContainer.propTypes = {
  user: userShape.isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  languages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userAvatarUrl: PropTypes.string.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  deactivateAccount: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  auth: { user },
  app: {
    enums: { countries, languages },
    config,
  },
}) => ({
  user,
  countries: getEnumAsSelectOptions(countries),
  languages: getEnumAsSelectOptions(languages),
  userAvatarUrl: getUserAvatarUrl(config),
});

const mapDispatchToProps = {
  updateUserProfile: updateUserProfileAction,
  deactivateAccount: deactivateAccountAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
