import React from 'react';
import PropTypes from 'prop-types';

const TabPanel = ({ value, index, children, ...rest }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
    {...rest}
  >
    {value === index && <>{children}</>}
  </div>
);

TabPanel.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default TabPanel;
