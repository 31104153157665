import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useHistory } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import buildResourceUrl from 'utils/buildResourceUrl';
import { getProductImgUrl } from 'modules/app/store/selectors';
import {
  buildDroppableColumnsAction,
  removeItemFromCanvasAction,
  resetCanvasAction,
  saveLookBoardDetailsFormValuesAction,
  updateLookBoardDataAction,
} from 'modules/curateTheLook/store/actions';
import customEvent from 'utils/customEvent';
import { UPDATE_SCROLLER_HEIGHT } from 'constants/customEventNames';
import errorToastr from 'libs/toastr/errorToastr';
import successToastr from 'libs/toastr/successToastr';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import Button from 'components/ui/Button/Button';
import ConfirmModal from 'components/modals/ConfirmModal';
import CanvasTitlePopover from 'modules/curateTheLook/createLookBoard/components/CanvasTitlePopover';
import EditableLookBoardHeader from 'modules/curateTheLook/createLookBoard/components/EditableLookBoardHeader/EditableLookBoardHeader';
import { inspirationImageShape } from 'modules/inspirationImage/propTypes';
import lookBoardService from 'modules/lookBoard/lookBoardService';
import classes from './CreateLookBoardCanvas.module.scss';

const columnToClass = {
  1: classes.single,
  2: classes.double,
  3: classes.triple,
};

const CreateLookBoardCanvas = ({
  selectedTemplate,
  selectedImage,
  colors,
  productImgUrl,
  droppableColumns,
  lookBoardData,
  buildDroppableColumns,
  removeItemFromCanvas,
  updateLookBoardData,
  resetCanvas,
  saveLookBoardDetailsFormValues,
}) => {
  const history = useHistory();
  const containerRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [containerHeight, setContainerHeight] = useState(null);
  const [productHeight, setProductHeight] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [resetModalOpen, setResetModalOpen] = useState(false);

  const handleResetModalOpen = useCallback(() => {
    setResetModalOpen(true);
  }, []);

  const { minItems, maxItems } = useMemo(() => {
    if (selectedTemplate) {
      return {
        minItems: 1 * selectedTemplate.columnCount,
        maxItems: 4 * selectedTemplate.columnCount,
      };
    }
    return { minItems: 0, maxItems: 0 };
  }, [selectedTemplate]);

  const selectedProducts = useMemo(
    () =>
      droppableColumns
        .filter(({ product }) => Boolean(product))
        .map(({ product }) => product.id),
    [droppableColumns]
  );

  useEffect(() => {
    const refWidth = containerRef.current.clientWidth;
    const refHeight = Math.floor(refWidth * (3 / 4)); // aspect ratio = 4:3
    const itemHeight = Math.floor(refHeight / 2);

    setContainerHeight(refHeight);
    setProductHeight(itemHeight);
  }, []);

  const handleAddRow = useCallback(() => {
    const currentItemsLength = droppableColumns.length;
    buildDroppableColumns(selectedTemplate.columnCount, currentItemsLength);
  }, [droppableColumns, selectedTemplate, buildDroppableColumns]);

  const handleRemoveItem = useCallback(
    ({ currentTarget }) => {
      const columnId = currentTarget.getAttribute('data-column');
      removeItemFromCanvas(columnId);
    },
    [removeItemFromCanvas]
  );

  const handleOpenInfoPopover = useCallback(({ currentTarget }) => {
    setAnchorEl(currentTarget);
  }, []);

  const handleCloseInfoPopover = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleEnableEditMode = useCallback(() => {
    setEditMode(true);
  }, []);

  const handleDisableEditMode = useCallback(
    (state) => {
      updateLookBoardData(state);
      setEditMode(false);
    },
    [updateLookBoardData]
  );

  const handleResetCanvas = useCallback(
    (confirm) => {
      if (confirm) {
        buildDroppableColumns(selectedTemplate.columnCount);
        resetCanvas();
        setEditMode(false);
      }
      setResetModalOpen(false);
    },
    [resetCanvas, buildDroppableColumns, selectedTemplate]
  );

  const saveToDrafts = useCallback(async () => {
    try {
      updateLookBoardData({ products: selectedProducts });
      await lookBoardService.createLookBoard({
        ...lookBoardData,
        products: selectedProducts,
      });
      successToastr('Success!', 'Look Board successfully saved to drafts');
    } catch (e) {
      errorToastr('Error', e.generalError);
    }
  }, [updateLookBoardData, lookBoardData, selectedProducts]);

  const goFurther = useCallback(() => {
    updateLookBoardData({ products: selectedProducts });
    saveLookBoardDetailsFormValues(null);
    history.push(routesByName.curateTheLook.details);
  }, [
    saveLookBoardDetailsFormValues,
    updateLookBoardData,
    selectedProducts,
    history,
  ]);

  useEffect(() => {
    customEvent.trigger(UPDATE_SCROLLER_HEIGHT);
  }, [containerHeight]);

  return (
    <>
      {selectedTemplate && (
        <p className="text-sm mb-2">
          <b>Expandable Template:</b> {selectedTemplate.columnCount}
          -Columns ({minItems}-{maxItems} Items)
        </p>
      )}
      <div className={`${classes.root} mb-2`}>
        {editMode ? (
          <EditableLookBoardHeader
            color={lookBoardData.colorId}
            colors={colors}
            title={lookBoardData.title}
            onDisableEditMode={handleDisableEditMode}
          />
        ) : (
          <div
            className={classes.title}
            style={{ backgroundColor: colors[lookBoardData.colorId].code }}
          >
            <span className="mr-2">{lookBoardData.title}</span>
            <IconButton
              variant="inverted-white"
              color="secondary"
              size="xs"
              onClick={handleOpenInfoPopover}
            >
              <SpriteIcon name="info" size="sm" />
            </IconButton>
            <IconButton
              variant="inverted-white"
              color="secondary"
              size="sm"
              className={classes.editBtn}
              onClick={handleEnableEditMode}
            >
              <SpriteIcon name="edit" size="sm" />
            </IconButton>
          </div>
        )}
        <div
          style={{ minHeight: containerHeight || 630 }}
          className="flex-fill d-flex flex-column"
          ref={containerRef}
        >
          {selectedTemplate ? (
            <div
              className={clsx(
                classes.droppableContainer,
                columnToClass[selectedTemplate.columnCount]
              )}
            >
              <div className={classes.infoBlock}>
                <div className={classes.infoIcon}>
                  <SpriteIcon name="info" size="sm" />
                </div>
                <p>{`You need to add ${minItems} - ${maxItems} items`}</p>
              </div>
              {droppableColumns.map(({ id: columnId, product }) => (
                <Droppable key={columnId} droppableId={columnId}>
                  {(
                    { innerRef, droppableProps, placeholder },
                    { draggingFromThisWith, isDraggingOver }
                  ) => {
                    return (
                      <div
                        ref={innerRef}
                        {...droppableProps}
                        className={clsx(classes.droppableItem, {
                          [classes.invisible]:
                            product && !draggingFromThisWith && isDraggingOver,
                        })}
                        style={{
                          ...droppableProps.style,
                          height: productHeight,
                        }}
                      >
                        {product && (
                          <Draggable draggableId={String(product.id)} index={0}>
                            {(
                              {
                                draggableProps,
                                dragHandleProps,
                                innerRef: draggableRef,
                              },
                              { isDragging }
                            ) => (
                              <div
                                {...draggableProps}
                                {...dragHandleProps}
                                ref={draggableRef}
                                className={classes.draggableItem}
                              >
                                {!isDragging && (
                                  <IconButton
                                    className={classes.removeBtn}
                                    variant="inverted-white"
                                    color="secondary"
                                    size="xs"
                                    data-column={columnId}
                                    onClick={handleRemoveItem}
                                  >
                                    <SpriteIcon name="cross" size="sm" />
                                  </IconButton>
                                )}
                                <img
                                  alt=""
                                  src={buildResourceUrl(
                                    productImgUrl.small,
                                    product.media.userId,
                                    product.media.hash
                                  )}
                                />
                              </div>
                            )}
                          </Draggable>
                        )}
                        {placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              ))}
              <IconButton
                size="sm"
                className={classes.addMoreBtn}
                onClick={handleAddRow}
                disabled={droppableColumns.length === maxItems}
              >
                <SpriteIcon name="arrow-down" size="sm" />
              </IconButton>
            </div>
          ) : (
            <div className={classes.emptyTemplate}>
              <span>
                Select Image and
                <br />
                Drag & Drop Products Here
              </span>
            </div>
          )}
        </div>
      </div>
      {selectedTemplate && (
        <div className="d-flex justify-content-end align-items-center mb-3">
          <IconButton
            className="mr-1"
            variant="inverted-white"
            size="sm"
            onClick={handleResetModalOpen}
          >
            <SpriteIcon name="update-arrows" size="sm" />
          </IconButton>
          <span className="font-semi-bold primary-color text-sm">Reset</span>
        </div>
      )}
      <div className="d-flex justify-content-center mb-5">
        <Button
          variant="outlined"
          style={{ width: 'auto' }}
          className="mr-2"
          disabled={!selectedTemplate || selectedProducts.length === 0}
          onClick={saveToDrafts}
        >
          <SpriteIcon name="dashboard" size="sm" className="mr-1" />
          Save to Drafts
        </Button>
        <Button
          style={{ width: 'auto' }}
          disabled={
            !selectedImage || !selectedTemplate || selectedProducts.length === 0
          }
          onClick={goFurther}
        >
          Next: Look Board Details
        </Button>
      </div>
      <CanvasTitlePopover
        classes={{ root: classes.infoPopup }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseInfoPopover}
      />
      <ConfirmModal
        open={resetModalOpen}
        onClose={handleResetCanvas}
        title={
          <>
            Are you sure you want to <br />
            <span className="primary-color">reset</span> look board?
          </>
        }
      />
    </>
  );
};

CreateLookBoardCanvas.propTypes = {
  selectedTemplate: PropTypes.shape({
    columnCount: PropTypes.number.isRequired,
  }),
  selectedImage: inspirationImageShape,
  colors: PropTypes.shape({}).isRequired,
  productImgUrl: PropTypes.shape({
    small: PropTypes.string.isRequired,
  }).isRequired,
  droppableColumns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      product: PropTypes.shape({}),
    })
  ).isRequired,
  lookBoardData: PropTypes.shape({
    colorId: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  buildDroppableColumns: PropTypes.func.isRequired,
  removeItemFromCanvas: PropTypes.func.isRequired,
  updateLookBoardData: PropTypes.func.isRequired,
  resetCanvas: PropTypes.func.isRequired,
  saveLookBoardDetailsFormValues: PropTypes.func.isRequired,
};

CreateLookBoardCanvas.defaultProps = {
  selectedTemplate: null,
  selectedImage: null,
};

const mapStateToProps = ({
  app: {
    config,
    enums: { colors },
  },
  curateTheLook: {
    dndState,
    selectedTemplate,
    lookBoardData: { inspirationImageId, ...lookBoardData },
  },
  inspirationImage: { library },
}) => ({
  colors,
  productImgUrl: getProductImgUrl(config),
  droppableColumns: Object.values(dndState.dynamicColumns),
  selectedTemplate,
  selectedImage: library[inspirationImageId],
  lookBoardData,
});

const mapDispatchToProps = {
  buildDroppableColumns: buildDroppableColumnsAction,
  removeItemFromCanvas: removeItemFromCanvasAction,
  updateLookBoardData: updateLookBoardDataAction,
  resetCanvas: resetCanvasAction,
  saveLookBoardDetailsFormValues: saveLookBoardDetailsFormValuesAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateLookBoardCanvas);
