import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { routesByName } from 'constants/routes';
import buildResourceUrl from 'utils/buildResourceUrl';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import LookBoard from 'components/lookBoardThumbnails/LookBoard/LookBoard';
import LookBoardDetailsForm from 'modules/curateTheLook/lookBoardDetails/LookBoardDetailsForm';
import { inspirationImageShape } from 'modules/inspirationImage/propTypes';
import { getInspirationImgUrl } from 'modules/app/store/selectors';
import lookBoardDetailsValidator from 'modules/curateTheLook/lookBoardDetails/lookBoardDetailsValidator';
import { saveLookBoardDetailsFormValuesAction } from 'modules/curateTheLook/store/actions';
import classes from 'modules/curateTheLook/lookBoardDetails/LookBoardDetails.module.scss';

const LookBoardDetailsContainer = ({
  lookBoardData,
  selectedImage,
  colors,
  products,
  inspirationImageUrl,
  lookBoardDetailsFormValues,
  saveFormValues,
}) => {
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(true);

  const imgUrl = useMemo(() => {
    if (!selectedImage) {
      return null;
    }
    const {
      media: { userId, hash },
    } = selectedImage;
    return buildResourceUrl(inspirationImageUrl.medium, userId, hash);
  }, [inspirationImageUrl, selectedImage]);

  const initialFormValues = useMemo(() => {
    if (!lookBoardData || !selectedImage) {
      return {};
    }

    const { type, itemClassId, roomTypeId, styles } = selectedImage;

    return (
      lookBoardDetailsFormValues || {
        title: lookBoardData.title,
        type,
        itemClassId,
        roomTypeId,
        styleId: styles.length > 0 ? styles[0] : null,
      }
    );
  }, [lookBoardDetailsFormValues, lookBoardData, selectedImage]);

  const productList = useMemo(() => {
    if (!lookBoardData || !products) {
      return [];
    }
    return lookBoardData.products.map((id) => products[id]);
  }, [lookBoardData, products]);

  const goBack = useCallback(() => {
    history.push(routesByName.curateTheLook.canvas);
  }, [history]);

  const handleToggleCollapse = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  const handleEditLookBoard = useCallback(() => {
    history.push(routesByName.curateTheLook.canvas);
  }, [history]);

  const handleFormSubmit = useCallback(
    (formValues) => {
      saveFormValues(formValues);
      history.push(routesByName.curateTheLook.submit);
    },
    [saveFormValues, history]
  );

  return lookBoardData ? (
    <div className={classes.root}>
      <div className="d-flex align-items-center mb-3">
        <IconButton variant="inverted-white" size="sm" onClick={goBack}>
          <SpriteIcon name="left-arrow" size="sm" />
        </IconButton>
        <span className="primary-color text-sm ml-1">Back</span>
      </div>
      <div className="d-flex">
        <div className={`${classes.lookBoardSection} mr-4`}>
          <h3 className={classes.sectionTitle}>Your look board</h3>
          <p className={classes.sectionSubTitle}>
            <b>Only the first two rows of products</b> will show in the Get the
            Look section
          </p>
          <LookBoard
            title={lookBoardData.title}
            color={colors[lookBoardData.colorId].code}
            columns={lookBoardData.columns}
            items={productList}
            collapsed={collapsed}
            onToggleCollapse={handleToggleCollapse}
            onEdit={handleEditLookBoard}
          />
        </div>
        <div className="flex-fill">
          <h3 className={classes.sectionTitle}>Fill In Design Details</h3>
          <p className={classes.sectionSubTitle}>
            This information will be shown for all users
          </p>
          <div className={classes.detailsSection}>
            <div className="flex-fill p-4" style={{ marginRight: 108 }}>
              <h4
                className="font-title text-capitalize mb-3"
                style={{ fontSize: 24 }}
              >
                Look board details
              </h4>
              <LookBoardDetailsForm
                imageType={selectedImage?.type}
                initialValues={initialFormValues}
                validate={lookBoardDetailsValidator}
                onSubmit={handleFormSubmit}
              />
            </div>
            <div className={classes.selectedImgContainer}>
              <div className={classes.imgWrapper}>
                <img alt={selectedImage?.title} src={imgUrl} />
                <div className={classes.imgTitle}>
                  <div className={classes.label}>
                    <div className={classes.marker} />
                    <span>Inspiration image</span>
                  </div>
                  <span className="text-xs text-nowrap">
                    {selectedImage?.title}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

LookBoardDetailsContainer.propTypes = {
  lookBoardData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    colorId: PropTypes.number.isRequired,
    columns: PropTypes.number,
    products: PropTypes.arrayOf(PropTypes.number).isRequired,
    inspirationImageId: PropTypes.number,
  }),
  selectedImage: inspirationImageShape,
  colors: PropTypes.shape({}).isRequired,
  products: PropTypes.shape({}).isRequired,
  inspirationImageUrl: PropTypes.shape({
    medium: PropTypes.string.isRequired,
  }).isRequired,
  lookBoardDetailsFormValues: PropTypes.shape({}),
  saveFormValues: PropTypes.func.isRequired,
};

LookBoardDetailsContainer.defaultProps = {
  lookBoardData: null,
  selectedImage: null,
  lookBoardDetailsFormValues: null,
};

const mapSateToProps = ({
  app: {
    config,
    enums: { colors },
  },
  product: { library },
  curateTheLook: {
    lookBoardData: { inspirationImageId, ...lookBoardData },
    lookBoardDetailsFormValues,
  },
  inspirationImage: { library: inspirationImageLibrary },
}) => ({
  colors,
  products: library,
  lookBoardData,
  selectedImage: inspirationImageLibrary[inspirationImageId],
  inspirationImageUrl: getInspirationImgUrl(config),
  lookBoardDetailsFormValues,
});

const mapDispatchToProps = {
  saveFormValues: saveLookBoardDetailsFormValuesAction,
};

export default connect(
  mapSateToProps,
  mapDispatchToProps
)(LookBoardDetailsContainer);
