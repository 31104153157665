import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  imageSortKeys,
  imageSourceKeys,
  imageTypeKeys,
} from 'constants/inspirationImageSearchParams';
import Tab from 'components/ui/Tab/Tab';
import Button from 'components/ui/Button/Button';
import SpriteIcon from 'components/ui/SpriteIcon';
import errorToastr from 'libs/toastr/errorToastr';
import successToastr from 'libs/toastr/successToastr';
import { toggleAddImageModalAction } from 'modules/layout/store/actions';
import inspirationImageService from 'modules/inspirationImage/inspirationImageService';
import { myImagesTabsConfig } from 'modules/dashboard/constants';
import MyImagesComponent from 'modules/dashboard/myImages/MyImagesComponent';
import accountClasses from 'modules/account/Account.module.scss';

// TODO: Get limit value from application config
const limit = 10;

const initialSearchParams = {
  source: imageSourceKeys.my,
  subStyles: [],
  offset: 0,
  sort: imageSortKeys.newest,
  type: imageTypeKeys.all,
};

const MyImagesContainer = ({ toggleAddImageModal }) => {
  const [searchParams, setSearchParams] = useState(initialSearchParams);
  const [imageList, setImageList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  const loadMore = useCallback(async () => {
    setLoading(true);

    try {
      const preparedSearchParams = {
        ...searchParams,
        type:
          searchParams.type === imageTypeKeys.all
            ? undefined
            : searchParams.type,
      };
      const list = await inspirationImageService.getImages(
        preparedSearchParams
      );

      setImageList((prevState) => [...prevState, ...Object.values(list)]);
      setSearchParams((prevState) => ({
        ...prevState,
        offset: prevState.offset + list.length,
      }));

      setHasMore(list.length === limit);
      setLoading(false);
    } catch (e) {
      errorToastr('Error', e.generalError);
    }
  }, [searchParams]);

  useEffect(() => {
    (async () => {
      if (searchParams.offset === 0) {
        await loadMore();
      }
    })();
    // eslint-disable-next-line
  }, [
    searchParams.offset,
    searchParams.source,
    searchParams.type,
    searchParams.sort,
  ]);

  const handleTabChange = useCallback((source) => {
    setSearchParams((prevState) => ({ ...prevState, source, offset: 0 }));
    setImageList([]);
    setHasMore(true);
  }, []);

  const handleChangeImgType = useCallback((type) => {
    setSearchParams((prevState) => ({ ...prevState, type, offset: 0 }));
    setImageList([]);
    setHasMore(true);
  }, []);

  const handleChangeSortType = useCallback((sort) => {
    setSearchParams((prevState) => ({ ...prevState, sort, offset: 0 }));
    setImageList([]);
    setHasMore(true);
  }, []);

  const handleUnlikeImg = useCallback(
    async ({ currentTarget }) => {
      const imgId = currentTarget.getAttribute('data-img-id');
      try {
        await inspirationImageService.toggleLike(imgId, 0);
        const updatedImageList = imageList.filter(
          ({ id }) => id !== Number.parseInt(imgId, 10)
        );
        setImageList(updatedImageList);
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
    },
    [imageList]
  );

  const handleDeleteImg = useCallback(
    async ({ currentTarget }) => {
      const imgId = currentTarget.getAttribute('data-img-id');
      try {
        await inspirationImageService.deleteAddedImage(imgId);
        const updatedImageList = imageList.filter(
          ({ id }) => id !== Number.parseInt(imgId, 10)
        );
        setImageList(updatedImageList);
        successToastr('Success', 'Image successfully deleted');
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
    },
    [imageList]
  );

  const handleAddImageModalOpen = useCallback(() => {
    toggleAddImageModal(true);
  }, [toggleAddImageModal]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <p className={accountClasses.title}>My Images</p>
        <Button size="sm" inline onClick={handleAddImageModalOpen}>
          <SpriteIcon name="pin" style={{ marginLeft: -8 }} /> Add Image
        </Button>
      </div>
      <div className="d-flex">
        {myImagesTabsConfig.map(({ name, title }) => (
          <Tab
            key={name}
            title={title}
            isActive={name === searchParams.source}
            name={name}
            onToggle={handleTabChange}
          />
        ))}
      </div>
      <MyImagesComponent
        loading={loading}
        hasMore={hasMore}
        loadMore={loadMore}
        imgList={imageList}
        currentSource={searchParams.source}
        currentImgType={searchParams.type}
        currentSortType={searchParams.sort}
        onChangeImgType={handleChangeImgType}
        onChangeSortType={handleChangeSortType}
        onUnlike={handleUnlikeImg}
        onDeleteAddedImage={handleDeleteImg}
      />
    </>
  );
};

MyImagesContainer.propTypes = {
  toggleAddImageModal: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  toggleAddImageModal: toggleAddImageModalAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyImagesContainer);
