import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import { professionsMap } from 'modules/account/profileAndPreferences/preferences/constants';
import classes from 'modules/account/profileAndPreferences/preferences/Preferences.module.scss';
import {
  getFilteredOptionsByPreferences,
  getStyleImgUrl,
  getSubStyleImgUrl,
  getSubStylesByPreference,
} from 'modules/app/store/selectors';
import { urlPlaceholders } from 'constants/urlPlaceholders';
import ColorPoint from 'components/colorSelectors/ColorPoint/ColorPoint';
import roomTypesOptionShape from 'components/PreferenceQuiz/propTypes/roomTypesOptionShape';
import colorsOptionShape from 'components/PreferenceQuiz/propTypes/colorsOptionShape';
import stylesOptionShape from 'components/PreferenceQuiz/propTypes/stylesOptionShape';
import priceRangesOptionShape from 'components/PreferenceQuiz/propTypes/priceRangesOptionShape';

const PreferencesView = ({
  onEnableEditMode,
  profession,
  styles,
  subStyles,
  roomTypes,
  colors,
  priceRange,
  styleImgUrl,
  subStyleImgUrl,
}) => (
  <>
    <IconButton
      className={classes.editBtn}
      color="secondary"
      title="Edit Preferences"
      onClick={onEnableEditMode}
    >
      <SpriteIcon name="edit" size="sm" />
    </IconButton>

    {profession && (
      <div className="mb-5">
        <p className={classes.preferenceTitle}>Your Description</p>
        <span className="text-uppercase font-title">
          {professionsMap[profession].title}
        </span>
      </div>
    )}

    {styles.length > 0 && (
      <div className="mb-5">
        <p className={classes.preferenceTitle}>Favorite Styles</p>
        <div className="d-flex flex-wrap">
          {styles.map(({ id, name, media: { hash } }) => (
            <div className={classes.styleCard} key={id}>
              <div className={classes.stylesImgContainer}>
                <img
                  src={styleImgUrl.replace(urlPlaceholders.hash, hash)}
                  alt={name}
                />
              </div>
              <p className="text-ellipsis text-nowrap text-uppercase font-title text-sm p-2">
                {name}
              </p>
            </div>
          ))}
        </div>
      </div>
    )}

    {subStyles.map(
      ({ id: styleId, name: styleName, selectedSubStyles }) =>
        selectedSubStyles.length > 0 && (
          <div className="mb-5" key={styleId}>
            <p className={classes.preferenceTitle}>
              Selected Substyles for {styleName}
            </p>
            <div className="d-flex flex-wrap">
              {selectedSubStyles.map(({ id, name, media: { hash } }) => (
                <div className={classes.styleCard} key={id}>
                  <div className={classes.stylesImgContainer}>
                    <img
                      src={subStyleImgUrl.replace(urlPlaceholders.hash, hash)}
                      alt={name}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
    )}

    {roomTypes.length > 0 && (
      <div className="mb-5">
        <p className={classes.preferenceTitle}>Room Types</p>
        <div className="d-flex flex-wrap">
          {roomTypes.map(({ id, name, icon }) => (
            <div
              className="d-flex flex-column align-items-center mr-5 mb-2"
              key={id}
            >
              <div
                className={classes.roomTypeIcon}
                dangerouslySetInnerHTML={{ __html: icon }}
              />
              <p className="text-uppercase font-title text-sm">{name}</p>
            </div>
          ))}
        </div>
      </div>
    )}

    {colors.length > 0 && (
      <div className="mb-5">
        <p className={classes.preferenceTitle}>Favorite Colors</p>
        <div className="d-flex flex-wrap">
          {colors.map(({ id, name, code }) => (
            <ColorPoint
              key={id}
              id={id}
              size="xl"
              title={name}
              selected
              className="mr-4"
              color={code}
            />
          ))}
        </div>
      </div>
    )}

    {priceRange && (
      <div>
        <p className={classes.preferenceTitle}>Price Range</p>
        <p className="text-uppercase font-title">{`${priceRange.title} ${priceRange.name}`}</p>
      </div>
    )}
  </>
);

PreferencesView.propTypes = {
  onEnableEditMode: PropTypes.func.isRequired,
  profession: PropTypes.string,
  styles: PropTypes.arrayOf(stylesOptionShape).isRequired,
  subStyles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  roomTypes: PropTypes.arrayOf(roomTypesOptionShape).isRequired,
  colors: PropTypes.arrayOf(colorsOptionShape).isRequired,
  priceRange: priceRangesOptionShape,
  styleImgUrl: PropTypes.string.isRequired,
  subStyleImgUrl: PropTypes.string.isRequired,
};

PreferencesView.defaultProps = {
  profession: null,
  priceRange: null,
};

const mapStateToProps = ({
  auth: {
    user: { preferences },
  },
  app: {
    config,
    enums: { styles, subStyles, roomTypes, colors, priceRanges },
  },
}) => ({
  profession: preferences.profession,
  styles: getFilteredOptionsByPreferences(styles, preferences.styles),
  subStyles: getSubStylesByPreference(
    styles,
    subStyles,
    preferences.styles,
    preferences.subStyles
  ),
  roomTypes: getFilteredOptionsByPreferences(roomTypes, preferences.roomTypes),
  colors: getFilteredOptionsByPreferences(colors, preferences.colors),
  priceRange: priceRanges[preferences.priceRange],
  styleImgUrl: getStyleImgUrl(config),
  subStyleImgUrl: getSubStyleImgUrl(config),
});

export default connect(mapStateToProps)(PreferencesView);
