import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import ReCAPTCHA from 'react-google-recaptcha';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import { supportFormFields } from 'modules/support/constants';
import supportFormValidator from 'modules/support/supportForm/supportFormValidator';
import appConfig from 'constants/appConfig';

const SupportForm = ({
  onSubmit,
  disableSubmit,
  reCaptchaVerify,
  reCaptchaRef,
  initialValues,
}) => (
  <Form
    initialValues={initialValues}
    validate={supportFormValidator}
    onSubmit={onSubmit}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit} noValidate>
        <Field
          required
          label="Name"
          name={supportFormFields.name}
          component={TextInputWrapper}
        />
        <Field
          required
          label="Email"
          type="email"
          name={supportFormFields.email}
          component={TextInputWrapper}
        />
        <Field
          required
          label="Subject"
          name={supportFormFields.subject}
          className="mb-5"
          component={TextInputWrapper}
        />
        <Field
          required
          name={supportFormFields.message}
          label="Message"
          multiline
          rows={4}
          component={TextInputWrapper}
        />
        <div className="d-flex justify-content-center py-2 mb-3">
          <ReCAPTCHA
            ref={reCaptchaRef}
            sitekey={appConfig.reCaptchaSiteKey}
            render="explicit"
            onChange={reCaptchaVerify}
          />
        </div>
        <div className="d-flex justify-content-center">
          <div className="text-center">
            <FinalFormSubmitButton inline disabled={disableSubmit}>
              Submit
            </FinalFormSubmitButton>
          </div>
        </div>
      </form>
    )}
  />
);

SupportForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  disableSubmit: PropTypes.bool.isRequired,
  reCaptchaVerify: PropTypes.func.isRequired,
  reCaptchaRef: PropTypes.oneOfType([PropTypes.object]),
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }),
};

SupportForm.defaultProps = {
  initialValues: {
    name: null,
    email: null,
  },
  reCaptchaRef: null,
};

export default SupportForm;
