import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Popover from '@material-ui/core/Popover';
import { routesByName } from 'constants/routes';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import Button from 'components/ui/Button/Button';
import classes from 'modules/curateTheLook/createLookBoard/components/MoreOptionsPopover/MoreOptionsPopover.module.scss';

const MoreOptionsPopover = ({ anchorEl, open, onClose }) => {
  return (
    <Popover
      classes={{ paper: classes.root }}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <IconButton size="xs" className={`${classes.info} mr-1`}>
        <SpriteIcon name="info" size="sm" />
      </IconButton>
      <Button
        component={Link}
        to={routesByName.requestTheLook.index}
        className={`${classes.btn} mr-1`}
        size="custom"
        color="default"
      >
        <SpriteIcon name="update-arrows" size="sm" className="mr-1" />
        <span>Request</span>
      </Button>
      <Button
        component={Link}
        to={routesByName.getTheLook}
        className={`${classes.btn} ${classes.getTheLookLink}`}
        size="custom"
        color="default"
      >
        <SpriteIcon name="living-room" size="sm" className="mr-1" />
        <span className="text-capitalize">Get the look</span>
      </Button>
    </Popover>
  );
};

MoreOptionsPopover.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.object]),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

MoreOptionsPopover.defaultProps = {
  anchorEl: null,
};

export default MoreOptionsPopover;
