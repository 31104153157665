import React from 'react';
import PropTypes from 'prop-types';
import InspirationImage from 'components/imageThumbnails/InspirationImage/InspirationImage';
import ImageLink from 'components/imageThumbnails/ImageLink';
import classes from 'components/imageThumbnails/SimilarImage/SimilarImage.module.scss';
import OverlayLabel from 'components/imageThumbnails/OverlayLabel/OverlayLabel';

const SimilarImage = ({ link, overlayLabel, ...rest }) => (
  <InspirationImage
    {...rest}
    staticOverlay={
      <div className="h-100 flex-center">
        <OverlayLabel text="image have been removed due to copyright issue" />
      </div>
    }
    hoverOverlay={
      <div className="h-100 d-flex flex-column">
        <div className="d-flex flex-fill flex-center">
          <p className={classes.hoverLabel}>{overlayLabel}</p>
        </div>
        <div className="text-ellipsis">
          <ImageLink url={link} />
        </div>
      </div>
    }
  />
);

SimilarImage.propTypes = {
  link: PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  overlayLabel: PropTypes.string.isRequired,
};

export default SimilarImage;
