import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/ui/Button/Button';
import classes from './NoLookBoardsModal.module.scss';

const NoLookBoardsModal = ({ onCreateLookBoard, onCreateRequest }) => {
  return (
    <div className={classes.root}>
      <div className={classes.border} />
      <div className="flex-center flex-column mx-3">
        <p className={`${classes.title} mb-5`}>
          There are no Look Boards
          <br />
          created for this image yet
        </p>
        <div>
          <Button
            inline
            variant="outlined"
            className="mr-3"
            onClick={onCreateLookBoard}
          >
            Create look board
          </Button>
          <Button variant="outlined" inline onClick={onCreateRequest}>
            Request the look
          </Button>
        </div>
      </div>
      <div className={classes.border} />
    </div>
  );
};

NoLookBoardsModal.propTypes = {
  onCreateLookBoard: PropTypes.func.isRequired,
  onCreateRequest: PropTypes.func.isRequired,
};

export default NoLookBoardsModal;
