import {
  accountTypes,
  errorMessages,
  signUpFormFields,
} from 'modules/auth/signUp/constants';

const signUpValidator = ({
  [signUpFormFields.type]: type,
  [signUpFormFields.firstName]: firstName,
  [signUpFormFields.lastName]: lastName,
  [signUpFormFields.businessName]: businessName,
  [signUpFormFields.businessType]: businessType,
  [signUpFormFields.email]: email,
  [signUpFormFields.countryId]: countryId,
  [signUpFormFields.password]: password,
  [signUpFormFields.passwordRepeat]: passwordRepeat,
  [signUpFormFields.terms]: terms,
}) => {
  const errors = {};

  if (!firstName) {
    errors.firstName = errorMessages.required;
  }

  if (!lastName) {
    errors.lastName = errorMessages.required;
  }

  if (type === accountTypes.business && !businessName) {
    errors.businessName = errorMessages.required;
  }

  if (type === accountTypes.business && !businessType) {
    errors.businessType = errorMessages.required;
  }

  if (!email) {
    errors.email = errorMessages.required;
  }

  if (!countryId) {
    errors.countryId = errorMessages.required;
  }

  if (!password) {
    errors.password = errorMessages.required;
  } else if (password.length < 8) {
    errors.password = errorMessages.passwordInvalid;
  }

  if (password && !passwordRepeat) {
    errors.passwordRepeat = errorMessages.required;
  } else if (password !== passwordRepeat) {
    errors.passwordRepeat = errorMessages.passwordsShouldMatch;
  }

  return errors;
};

export default signUpValidator;
