const MuiTab = {
  root: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: '14px',
    textTransform: 'none',
    '&$selected': {
      fontWeight: 600,
    },
  },
};

export default MuiTab;
