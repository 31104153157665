import React from 'react';
import { ambassadorsSliderConfig } from 'modules/home/constants';
import SliderContainer from 'modules/home/components/Ambassadors/Slider/SliderContainer';
import Banner from 'modules/home/components/Ambassadors/Banner/Banner';
import classes from './Ambassadors.module.scss';

const Ambassadors = () => (
  <section className={classes.root}>
    <div className="flex-center flex-column text-center">
      <h2 className={classes.title}>Meet our Ambassadors</h2>
      <p className={classes.description}>
        Ambassadors are decor lovers who serve as personal shoppers to help
        others Get the <br />
        Look they love at a price they can afford. Ambassadors can create their
        own showcase <br />
        shop and earn points that can be used to shop at deep discount
      </p>
    </div>
    <SliderContainer slidesData={ambassadorsSliderConfig} />
    <div className={classes.bannerWrapper}>
      <Banner />
    </div>
  </section>
);

export default Ambassadors;
