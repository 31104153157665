import MuiFab from 'materialUi/overrides/MuiFab';
import MuiCheckbox from 'materialUi/overrides/MuiCheckbox';
import MuiFormControlLabel from 'materialUi/overrides/MuiFormControlLabel';
import MuiRadio from 'materialUi/overrides/MuiRadio';
import inputs from 'materialUi/overrides/inputs';
import menu from 'materialUi/overrides/menu';
import MuiTab from 'materialUi/overrides/MuiTab';

const overrides = {
  ...inputs,
  ...menu,
  MuiFab,
  MuiCheckbox,
  MuiRadio,
  MuiFormControlLabel,
  MuiTab,
};

export default overrides;
