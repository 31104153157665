const colorsConfig = {
  1: {
    id: 1,
    title: 'Aqua',
    color: '#6CD3CE',
  },
  2: {
    id: 2,
    title: 'Beige',
    color: '#CDC3AF',
  },
  3: {
    id: 3,
    title: 'Black',
    color: '#000000',
  },
  4: {
    id: 4,
    title: 'Blue (Light)',
    color: '#A6C4CE',
  },
  5: {
    id: 5,
    title: 'Blue',
    color: '#323DA5',
  },
  6: {
    id: 6,
    title: 'Blue (Navy)',
    color: '#213E56',
  },
  7: {
    id: 7,
    title: 'Blush',
    color: '#E2BCB7',
  },
  8: {
    id: 8,
    title: 'Bronze',
    color: '#B57B53',
  },
  9: {
    id: 9,
    title: 'Brown (Light)',
    color: '#C6906E',
  },
  10: {
    id: 10,
    title: 'Brown (Dark)',
    color: '#553E36',
  },
  11: {
    id: 11,
    title: 'Burgundy',
    color: '#460000',
  },
  12: {
    id: 12,
    title: 'Coral',
    color: '#FB7F73',
  },
  13: {
    id: 13,
    title: 'Fuchsia',
    color: '#890650',
  },
  14: {
    id: 14,
    title: 'Gold',
    color: '#967944',
  },
  15: {
    id: 15,
    title: 'Gray (Light)',
    color: '#C6C7C9',
  },
  16: {
    id: 16,
    title: 'Gray',
    color: '#7F8082',
  },
  17: {
    id: 17,
    title: 'Green',
    color: '#616E4D',
  },
  18: {
    id: 18,
    title: 'Green (Forest)',
    color: '#274D41',
  },
  19: {
    id: 19,
    title: 'Ivory',
    color: '#F4EEE5',
  },
  20: {
    id: 20,
    title: 'Lavender',
    color: '#DCD0FF',
  },
  21: {
    id: 21,
    title: 'Lime Green',
    color: '#9ABD66',
  },
  22: {
    id: 22,
    title: 'Mustard',
    color: '#CDA367',
  },
  23: {
    id: 23,
    title: 'Olive Green',
    color: '#8ACD79',
  },
  24: {
    id: 24,
    title: 'Orange',
    color: '#D86C46',
  },
  25: {
    id: 25,
    title: 'Peach',
    color: '#FFBFA0',
  },
  26: {
    id: 26,
    title: 'Pink (Light)',
    color: '#FFE3EB',
  },
  27: {
    id: 27,
    title: 'Pink',
    color: '#ED7FA8',
  },
  28: {
    id: 28,
    title: 'Purple',
    color: '#6A4179',
  },
  29: {
    id: 29,
    title: 'Red',
    color: '#A31A1D',
  },
  30: {
    id: 30,
    title: 'Sage',
    color: '#BAC4A2',
  },
  31: {
    id: 31,
    title: 'Tan',
    color: '#A18F7A',
  },
  32: {
    id: 32,
    title: 'Teal',
    color: '#008081',
  },
  33: {
    id: 33,
    title: 'Violet',
    color: '#584387',
  },
  34: {
    id: 34,
    title: 'Yellow',
    color: '#ECC94B',
  },
};

export default colorsConfig;
