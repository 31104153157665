import React from 'react';
import { Link } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import Button from 'components/ui/Button/Button';
import RoomTypes from 'modules/home/components/DiscoverContent/RoomTypes';
import PresentationCard from 'modules/home/components/PresentationCard/PresentationCard';
import discoverModalImg from 'assets/img/home-page/discover-modal.png';
import { roomTypesConfig } from 'modules/home/constants';
import classes from './DiscoverContent.module.scss';

const DiscoverContent = () => (
  <div className={classes.root}>
    <div className="d-flex">
      <div className={classes.cardWrapper}>
        <PresentationCard
          title="Browse the Look"
          description="Go to the Get the Look section to browse the look you love."
        >
          <div className={classes.cardContent}>
            <div className={classes.shadow}>
              <img className="w-100" src={discoverModalImg} alt="" />
            </div>
            <div className={classes.btnContainer}>
              <Link to={routesByName.getTheLook}>
                <Button size="lg">Browse the Look</Button>
              </Link>
            </div>
          </div>
        </PresentationCard>
      </div>
      <div className={classes.cardWrapper}>
        <PresentationCard
          title="Take the Style Quiz"
          description="Answer style questions to discover your look"
        >
          <div className={classes.cardContent}>
            <div className="text-center mb-2">
              <RoomTypes roomTypes={roomTypesConfig} />
            </div>
            <div className={classes.btnContainer}>
              <Link to={routesByName.getTheLook}>
                <Button size="lg">Take the Style Quiz</Button>
              </Link>
            </div>
          </div>
        </PresentationCard>
      </div>
    </div>
  </div>
);

export default DiscoverContent;
