/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useState } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { routesByName } from 'constants/routes';
import ReCAPTCHA from 'react-google-recaptcha';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import appConfig from 'constants/appConfig';
import { accountTypes, signUpFormFields } from 'modules/auth/signUp/constants';
import RadioInputWrapper from 'components/finalFormWrappers/RadioInputWrapper';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import { getEnumAsSelectOptions } from 'modules/app/store/selectors';
import SelectInputWrapper from 'components/finalFormWrappers/SelectInputWrapper';
import CheckboxInputWrapper from 'components/finalFormWrappers/CheckboxInputWrapper';
import PasswordInputWrapper from 'components/finalFormWrappers/PasswordInputWrapper';
import classes from './SignUp.module.scss';

const SignUpComponent = ({
  initialValues,
  validateForm,
  onSubmit,
  countries,
}) => {
  const { pathname } = useLocation();
  const [reCaptchaToken, setReCaptchaToken] = useState(null);

  const reCaptchaVerify = useCallback((token) => {
    setReCaptchaToken(token);
  }, []);

  return (
    <>
      <p className={clsx(classes.title, 'mb-2')}>
        NEW TO THE PIN THE LOOK?
        <br />
        Sign Up!
      </p>
      <p className={clsx(classes.subTitle, 'mb-3')}>
        Already have an account?{' '}
        <Link
          to={`${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`}
          className="primary-color"
        >
          Sign In!
        </Link>
      </p>
      <Form
        initialValues={initialValues}
        validate={validateForm}
        onSubmit={onSubmit}
        render={({ values, handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit}>
            <FormControl component="fieldset">
              <RadioGroup>
                <div className="d-flex mb-3">
                  <FormControlLabel
                    label="Personal"
                    classes={{
                      root: 'mr-5',
                      label: clsx({
                        'font-semi-bold': values.type === accountTypes.personal,
                      }),
                    }}
                    control={
                      <Field
                        name={signUpFormFields.type}
                        type="radio"
                        value={accountTypes.personal}
                        component={RadioInputWrapper}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Business"
                    classes={{
                      label: clsx({
                        'font-semi-bold': values.type === accountTypes.business,
                      }),
                    }}
                    control={
                      <Field
                        name={signUpFormFields.type}
                        type="radio"
                        value={accountTypes.business}
                        component={RadioInputWrapper}
                      />
                    }
                  />
                </div>
              </RadioGroup>
            </FormControl>
            <Field
              required
              label="First Name"
              name={signUpFormFields.firstName}
              component={TextInputWrapper}
            />
            <Field
              required
              label="Last Name"
              name={signUpFormFields.lastName}
              component={TextInputWrapper}
            />
            {values.type === accountTypes.business && (
              <>
                <Field
                  required
                  label="Company Name"
                  name={signUpFormFields.businessName}
                  component={TextInputWrapper}
                />
                <Field
                  required
                  label="Business Type"
                  name={signUpFormFields.businessType}
                  component={TextInputWrapper}
                />
                <Field
                  label="Website Url (Optional)"
                  name={signUpFormFields.businessUrl}
                  component={TextInputWrapper}
                />
              </>
            )}
            <Field
              required
              label="Email"
              type="email"
              name={signUpFormFields.email}
              component={TextInputWrapper}
            />
            <Field
              required
              label="Country"
              name={signUpFormFields.countryId}
              component={SelectInputWrapper}
              options={countries}
            />
            <Field
              required
              label="Password"
              type="password"
              name={signUpFormFields.password}
              component={PasswordInputWrapper}
            />
            <Field
              required
              label="Re-Enter Password"
              type="password"
              name={signUpFormFields.passwordRepeat}
              component={PasswordInputWrapper}
            />
            <FormControlLabel
              label="Only Show Products Sold In My Country"
              control={
                <Field
                  type="checkbox"
                  name={signUpFormFields.showProducts}
                  component={CheckboxInputWrapper}
                />
              }
            />
            <FormControlLabel
              label="I agree to Pin The Look's terms"
              control={
                <Field
                  type="checkbox"
                  name={signUpFormFields.terms}
                  component={CheckboxInputWrapper}
                />
              }
            />
            <div className="d-flex justify-content-center py-2 mb-3">
              <ReCAPTCHA
                sitekey={appConfig.reCaptchaSiteKey}
                render="explicit"
                onChange={reCaptchaVerify}
              />
            </div>
            <div className="text-center">
              <FinalFormSubmitButton
                size="sm"
                disabled={!reCaptchaToken}
                inline
              >
                Submit
              </FinalFormSubmitButton>
            </div>
          </form>
        )}
      />
    </>
  );
};

SignUpComponent.propTypes = {
  initialValues: PropTypes.shape({}).isRequired,
  validateForm: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

SignUpComponent.defaultProps = {};

const mapStateToProps = ({
  app: {
    enums: { countries },
  },
}) => ({
  countries: getEnumAsSelectOptions(countries),
});

export default connect(mapStateToProps)(SignUpComponent);
