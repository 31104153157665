import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from 'components/ui/Button/Button';
import classes from './Preferences.module.scss';

const EmptyPreferencesView = ({ onEnableEditMode }) => {
  return (
    <div className="flex-fill flex-center flex-column">
      <p
        className={clsx(
          classes.emptyText,
          'font-title text-uppercase text-center mb-3'
        )}
      >
        You have not added any <br />
        preferences yet
      </p>
      <Button inline onClick={onEnableEditMode}>
        Add Preferences
      </Button>
    </div>
  );
};

EmptyPreferencesView.propTypes = {
  onEnableEditMode: PropTypes.func.isRequired,
};

export default EmptyPreferencesView;
