import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SideBar from 'modules/getTheLook/components/Sidebar';
import { routesByName } from 'constants/routes';
import DetailsView from 'modules/getTheLook/DetailsView';
import ListView from 'modules/getTheLook/ListView';
import SearchParamsBlock from 'modules/getTheLook/components/SearchParamsBlock';
import LookBoardsHeader from 'modules/getTheLook/components/LookBoardsHeader/LookBoardsHeader';
import classes from './GetTheLook.module.scss';

const GetTheLookContainer = () => (
  <>
    <div className={classes.styleQuizArea}>Style quiz</div>
    <div className="flex-fill d-flex">
      <SideBar />
      <div className="flex-fill d-flex flex-column position-relative">
        <div className={classes.leftPanelBg} />
        <div className="d-flex">
          <div className={`${classes.leftPanelWrapper} pt-3`}>
            <SearchParamsBlock />
          </div>
          <div className="flex-fill">
            <div className={`${classes.mainContainer} d-flex flex-column`}>
              <LookBoardsHeader />
            </div>
          </div>
        </div>
        <Switch>
          <Route exact path={routesByName.getTheLook.index}>
            <ListView />
          </Route>
          <Route
            exact
            path={routesByName.getTheLook.details()}
            render={(props) => <DetailsView {...props} />}
          />
        </Switch>
      </div>
    </div>
  </>
);

export default GetTheLookContainer;
