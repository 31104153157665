import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import bannerBg from 'assets/img/customer-support-bg.png';
import Button from 'components/ui/Button/Button';
import CustomerSupportModal from 'modules/account/components/CustomerSupportModal/CustomerSupportModal';
import errorToastr from 'libs/toastr/errorToastr';
import successToastr from 'libs/toastr/successToastr';
import supportService from 'modules/support/supportService';
import { supportFormFields, supportFormTypes } from 'modules/support/constants';
import classes from './CustomerSupportBanner.module.scss';

const CustomerSupportBanner = ({ userName, userEmail }) => {
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);

  // TODO: Send reCaptcha token to backend
  const reCaptchaVerify = useCallback(() => {
    setDisableSubmit(false);
  }, []);

  const initialFormValues = useMemo(
    () => ({ name: userName, email: userEmail }),
    [userName, userEmail]
  );

  const handleOpenSupportModal = useCallback(() => {
    setSupportModalOpen(true);
  }, []);

  const handleCloseSupportModal = useCallback(() => {
    setSupportModalOpen(false);
  }, []);

  const handleSendSupportMessage = useCallback(async (formValues) => {
    try {
      await supportService.sendSupportMessage({
        [supportFormFields.type]: supportFormTypes.support,
        ...formValues,
      });
      successToastr('Success', 'Message successfully sent');
      setSupportModalOpen(false);
      setDisableSubmit(true);
    } catch (e) {
      errorToastr(e.generalError);
      return { ...e.validationErrors };
    }
    return true;
  }, []);

  return (
    <>
      <div className={classes.root}>
        <img src={bannerBg} alt="" />
        <div className={classes.content}>
          <p className={classes.title}>Questions?</p>
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            onClick={handleOpenSupportModal}
          >
            Contact Customer Support
          </Button>
        </div>
      </div>
      <CustomerSupportModal
        open={supportModalOpen}
        onClose={handleCloseSupportModal}
        initialValues={initialFormValues}
        onSubmit={handleSendSupportMessage}
        disableSubmit={disableSubmit}
        reCaptchaVerify={reCaptchaVerify}
      />
    </>
  );
};

CustomerSupportBanner.propTypes = {
  userName: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
};

const mapStateToProps = ({ auth: { user } }) => ({
  userName: `${user.firstName} ${user.lastName}`,
  userEmail: user.email,
});

export default connect(mapStateToProps)(CustomerSupportBanner);
