import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import LookBoard from 'components/lookBoardThumbnails/LookBoard/LookBoard';
import { inspirationImageShape } from 'modules/inspirationImage/propTypes';
import { getInspirationImgUrl } from 'modules/app/store/selectors';
import buildResourceUrl from 'utils/buildResourceUrl';
import { imageTypeKeys } from 'constants/inspirationImageSearchParams';
import {
  changeLookBoardStatusAction,
  resetCurateStateAction,
  submitLookBoardAction,
} from 'modules/curateTheLook/store/actions';
import { lookBoardStatusRadioOptions } from 'modules/curateTheLook/constants';
import CustomRadioGroup from 'modules/curateTheLook/createLookBoard/components/CustomRadioGroup';
import classes from 'modules/curateTheLook/lookBoardDetails/LookBoardDetails.module.scss';
import Button from 'components/ui/Button/Button';
import successToastr from 'libs/toastr/successToastr';
import errorToastr from 'libs/toastr/errorToastr';
import { routesByName } from 'constants/routes';

const SubmitLookBoardContainer = ({
  lookBoardData,
  selectedImage,
  colors,
  roomTypes,
  itemClasses,
  styles,
  products,
  inspirationImageUrl,
  lookBoardDetailsFormValues,
  lookBoardStatus,
  changeLookBoardStatus,
  submitLookBoard,
  resetCurateState,
}) => {
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(true);
  const imgUrl = useMemo(() => {
    if (!selectedImage) {
      return null;
    }
    const {
      media: { userId, hash },
    } = selectedImage;
    return buildResourceUrl(inspirationImageUrl.medium, userId, hash);
  }, [inspirationImageUrl, selectedImage]);

  const productList = useMemo(() => {
    if (!lookBoardData || !products) {
      return [];
    }
    return lookBoardData.products.map((id) => products[id]);
  }, [lookBoardData, products]);

  const colorList = useMemo(
    () => (selectedImage ? selectedImage.colors.map((id) => colors[id]) : []),
    [selectedImage, colors]
  );

  const goBack = useCallback(() => {
    history.push(routesByName.curateTheLook.details);
  }, [history]);

  const handleToggleCollapse = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  const handleEditLookBoard = useCallback(() => {
    history.push(routesByName.curateTheLook.canvas);
  }, [history]);

  const handleChangeStatus = useCallback(
    ({ target: { value: status } }) => {
      changeLookBoardStatus(status);
    },
    [changeLookBoardStatus]
  );

  const handleEditDetails = useCallback(() => {
    history.push(routesByName.curateTheLook.details);
  }, [history]);

  const handleSubmitLookBoard = useCallback(async () => {
    try {
      await submitLookBoard();
      history.push(routesByName.curateTheLook.success);
      resetCurateState();
      successToastr('Success', 'Look Board Successfully submitted');
    } catch (e) {
      errorToastr('Error', e.generalError);
    }
  }, [submitLookBoard, resetCurateState, history]);

  return lookBoardDetailsFormValues ? (
    <div className={classes.root}>
      <div className="d-flex align-items-center mb-3">
        <IconButton variant="inverted-white" size="sm" onClick={goBack}>
          <SpriteIcon name="left-arrow" size="sm" />
        </IconButton>
        <span className="primary-color text-sm ml-1">Back</span>
      </div>
      <h3 className={classes.sectionTitle}>Review & Submit Look Board</h3>
      <div className="d-flex">
        <div className={`${classes.lookBoardSection} mr-4`}>
          <LookBoard
            title={lookBoardData.title}
            color={colors[lookBoardData.colorId].code}
            columns={lookBoardData.columns}
            items={productList}
            collapsed={collapsed}
            onToggleCollapse={handleToggleCollapse}
            onEdit={handleEditLookBoard}
          />
        </div>
        <div className="flex-fill">
          <div className={classes.detailsSection}>
            <div className={classes.selectedImgContainer}>
              <div className={classes.imgWrapper}>
                <img alt={selectedImage?.title} src={imgUrl} />
                <div className={classes.imgTitle}>
                  <div className={classes.label}>
                    <div className={classes.marker} />
                    <span>Inspiration image</span>
                  </div>
                  <span className="text-xs text-nowrap">
                    {selectedImage?.title}
                  </span>
                </div>
              </div>
            </div>
            <div className={classes.lookBoardInfo}>
              <div className="d-flex mb-2">
                <div className="mr-5">
                  <div className="d-flex mb-2">
                    <span className={classes.infoTitle}>Title</span>
                    <span className={classes.infoText}>
                      {lookBoardDetailsFormValues.title}
                    </span>
                  </div>
                  <div className="d-flex mb-2">
                    {selectedImage.type === imageTypeKeys.room ? (
                      <>
                        <span className={classes.infoTitle}>Room Type</span>
                        <span className={classes.infoText}>
                          {
                            roomTypes[lookBoardDetailsFormValues.roomTypeId]
                              .name
                          }
                        </span>
                      </>
                    ) : (
                      <>
                        <span className={classes.infoTitle}>Item Class</span>
                        <span className={classes.infoText}>
                          {
                            itemClasses[lookBoardDetailsFormValues.itemClassId]
                              .name
                          }
                        </span>
                      </>
                    )}
                  </div>
                  <div className="d-flex mb-2">
                    <span className={classes.infoTitle}>Style</span>
                    <span className={classes.infoText}>
                      {styles[lookBoardDetailsFormValues.styleId].name}
                    </span>
                  </div>
                </div>
                <div>
                  <div className="d-flex mb-2">
                    <span className={classes.infoTitle}>Primary Colors</span>
                    <div className="d-flex">
                      {colorList.map(({ id: colorId, name, code }) => (
                        <div
                          key={colorId}
                          className={classes.colorPoint}
                          title={name}
                          style={{ background: code }}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <span className={classes.infoTitle}>Items</span>
                    <span className={classes.infoText}>
                      {productList
                        .map(
                          ({ itemClassId }) => itemClasses[itemClassId].singular
                        )
                        .join(', ')}
                    </span>
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <p className={`${classes.infoTitle} mb-1`}>
                  Make your look board
                </p>
                <CustomRadioGroup
                  controls={lookBoardStatusRadioOptions}
                  value={lookBoardStatus}
                  onChange={handleChangeStatus}
                />
              </div>
              <div className="mb-2">
                <p className={`${classes.infoTitle} mb-1`}>Description</p>
                <span className={classes.infoText}>
                  {lookBoardDetailsFormValues.description}
                </span>
              </div>
              <div className="flex-fill d-flex align-items-end">
                <div className="w-100 position-relative text-center">
                  <Button inline onClick={handleSubmitLookBoard}>
                    Submit
                  </Button>
                  <IconButton
                    className={classes.editBtn}
                    variant="inverted-grey"
                    color="secondary"
                    size="sm"
                    onClick={handleEditDetails}
                  >
                    <SpriteIcon name="edit" size="sm" />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

SubmitLookBoardContainer.propTypes = {
  lookBoardData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    colorId: PropTypes.number.isRequired,
    columns: PropTypes.number,
    products: PropTypes.arrayOf(PropTypes.number).isRequired,
    inspirationImageId: PropTypes.number,
  }),
  selectedImage: inspirationImageShape,
  colors: PropTypes.shape({}).isRequired,
  roomTypes: PropTypes.shape({}).isRequired,
  itemClasses: PropTypes.shape({}).isRequired,
  styles: PropTypes.shape({}).isRequired,
  products: PropTypes.shape({}).isRequired,
  inspirationImageUrl: PropTypes.shape({
    medium: PropTypes.string.isRequired,
  }).isRequired,
  lookBoardDetailsFormValues: PropTypes.shape({
    title: PropTypes.string.isRequired,
    roomTypeId: PropTypes.number,
    itemClassId: PropTypes.number,
    styleId: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
  }),
  lookBoardStatus: PropTypes.string.isRequired,
  changeLookBoardStatus: PropTypes.func.isRequired,
  submitLookBoard: PropTypes.func.isRequired,
  resetCurateState: PropTypes.func.isRequired,
};

SubmitLookBoardContainer.defaultProps = {
  lookBoardData: null,
  selectedImage: null,
  lookBoardDetailsFormValues: null,
};

const mapSateToProps = ({
  app: {
    config,
    enums: { colors, roomTypes, itemClasses, styles },
  },
  product: { library },
  curateTheLook: {
    lookBoardData: { inspirationImageId, ...lookBoardData },
    lookBoardDetailsFormValues,
    lookBoardStatus,
  },
  inspirationImage: { library: inspirationImageLibrary },
}) => ({
  colors,
  roomTypes,
  itemClasses,
  styles,
  products: library,
  lookBoardData,
  selectedImage: inspirationImageLibrary[inspirationImageId],
  inspirationImageUrl: getInspirationImgUrl(config),
  lookBoardDetailsFormValues,
  lookBoardStatus,
});

const mapDispatchToProps = {
  changeLookBoardStatus: changeLookBoardStatusAction,
  submitLookBoard: submitLookBoardAction,
  resetCurateState: resetCurateStateAction,
};

export default connect(
  mapSateToProps,
  mapDispatchToProps
)(SubmitLookBoardContainer);
