import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { routesByName } from 'constants/routes';

const PrivateRoute = ({ authenticated, children, ...rest }) => (
  <Route {...rest}>
    {authenticated ? children : <Redirect to={routesByName.home} />}
  </Route>
);

PrivateRoute.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const mapStateToProps = ({ auth: { user } }) => ({
  authenticated: Boolean(user),
});

export default connect(mapStateToProps)(PrivateRoute);
