import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import mediaTypes from 'constants/mediaTypes';
import { OPEN_PIN_PRODUCT_MODAL } from 'constants/customEventNames';
import customEvent from 'utils/customEvent';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import SearchInput from 'components/inputs/SearchInput/SearchInput';
import { toggleUnderConstructionModalAction } from 'modules/layout/store/actions';
import FilterInput from 'modules/dashboard/components/FilterInput/FilterInput';
import { productTypeOptions } from 'modules/curateTheLook/constants';
import classes from 'modules/curateTheLook/createLookBoard/CreateLookBoard.module.scss';

const ProductsSearchParams = ({
  currentType,
  searchQuery,
  onUpdateSearchParams,
  toggleUnderConstructionModal,
}) => {
  const inputRef = useRef(null);
  const [searchInputOpen, setSearchInputOpen] = useState(false);

  const handleOpenPinProductModal = useCallback(() => {
    customEvent.trigger(OPEN_PIN_PRODUCT_MODAL, {
      mediaType: mediaTypes.product,
    });
  }, []);

  const openSearchInput = useCallback(() => {
    setSearchInputOpen(true);
    inputRef.current.focus();
  }, []);

  const closeSearchInput = useCallback(() => {
    setSearchInputOpen(false);
  }, []);

  const handleChangeProductType = useCallback(
    (type) => {
      onUpdateSearchParams({ type });
    },
    [onUpdateSearchParams]
  );

  const handleChangeSearchQuery = useCallback(
    (search) => {
      onUpdateSearchParams({ search });
    },
    [onUpdateSearchParams]
  );

  const handleUnderConstructionModalOpen = useCallback(() => {
    toggleUnderConstructionModal(true);
  }, [toggleUnderConstructionModal]);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-1">
        <span className="font-title text-capitalize" style={{ fontSize: 22 }}>
          Select products or pin
        </span>
        <IconButton onClick={handleOpenPinProductModal}>
          <SpriteIcon name="pin" />
        </IconButton>
      </div>
      <p className="text-xs font-italic mb-3">
        Drag & Drop items into the template
      </p>
      <div className="position-relative d-flex mb-3">
        <FilterInput
          className="flex-fill mr-1"
          options={productTypeOptions}
          currentValue={currentType}
          onChange={handleChangeProductType}
        />
        <IconButton
          variant="inverted-white"
          className="mr-1"
          onClick={openSearchInput}
        >
          <SpriteIcon name="loupe" size="sm" />
        </IconButton>
        <IconButton
          variant="inverted-white"
          onClick={handleUnderConstructionModalOpen}
        >
          <SpriteIcon name="filters" size="sm" />
        </IconButton>
        <div
          className={clsx(classes.searchInput, {
            [classes.active]: searchInputOpen,
          })}
        >
          <SearchInput
            inputRef={inputRef}
            value={searchQuery}
            placeholder="Search by keywords"
            onChange={handleChangeSearchQuery}
            onCrossClick={closeSearchInput}
          />
        </div>
      </div>
    </>
  );
};

ProductsSearchParams.propTypes = {
  currentType: PropTypes.string.isRequired,
  searchQuery: PropTypes.string.isRequired,
  onUpdateSearchParams: PropTypes.func.isRequired,
  toggleUnderConstructionModal: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  toggleUnderConstructionModal: toggleUnderConstructionModalAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsSearchParams);
