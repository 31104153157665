import React from 'react';
import * as PropTypes from 'prop-types';
import { FormSpy } from 'react-final-form';
import Button from 'components/ui/Button/Button';

const FinalFormSubmitButton = ({ children, disabled, ...rest }) => (
  <FormSpy
    render={({ submitting, validating }) => (
      <Button
        type="submit"
        disabled={disabled || submitting || validating}
        {...rest}
      >
        {children}
      </Button>
    )}
  />
);

FinalFormSubmitButton.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

FinalFormSubmitButton.defaultProps = {
  disabled: false,
};

export default FinalFormSubmitButton;
