import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import classes from './Slider.module.scss';

const formatIndex = (index) => {
  let prefix = '';
  if (index < 9) {
    prefix = '0';
  }
  return `${prefix}${index + 1}`;
};

const SliderDots = ({ items, activeSlideIndex, onDotClick }) => (
  <div className={classes.dots}>
    <div className={classes.dotsInner}>
      {items.map(({ id }, index) => (
        <button
          key={id}
          className={clsx(classes.dot, {
            [classes.active]: index === activeSlideIndex,
          })}
          onClick={() => {
            onDotClick(index);
          }}
          type="button"
        >
          {formatIndex(index)}
        </button>
      ))}
    </div>
  </div>
);

SliderDots.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  activeSlideIndex: PropTypes.number.isRequired,
  onDotClick: PropTypes.func.isRequired,
};

export default SliderDots;
