import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { messageFieldKeys } from 'components/modals/SendMessageModal/constants';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import TextAreaWrapper from 'components/finalFormWrappers/TextAreaWrapper';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import classes from './SendMessageModal.module.scss';

const SendMessageModal = ({ open, onClose }) => {
  const handleFormSubmit = useCallback(
    (message) => {
      onClose({ message });
    },
    [onClose]
  );

  // TODO: Move validator to separate file
  const validateForm = useCallback(
    ({
      [messageFieldKeys.name]: name,
      [messageFieldKeys.subject]: subject,
      [messageFieldKeys.text]: text,
    }) => {
      const errors = {};

      if (!name) {
        errors.name = ['Required field'];
      }

      if (!subject) {
        errors.subject = ['Required field'];
      }

      if (!text) {
        errors.text = ['Required field'];
      }

      return errors;
    },
    []
  );

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      className={classes.root}
      maxWidth="md"
    >
      <Form
        onSubmit={handleFormSubmit}
        validate={validateForm}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Field
              label="Name"
              name={messageFieldKeys.name}
              component={TextInputWrapper}
            />
            <Field
              label="Subject"
              name={messageFieldKeys.subject}
              className="mb-5"
              component={TextInputWrapper}
            />
            <Field
              name={messageFieldKeys.text}
              className={classes.textArea}
              placeholder="Write a message"
              component={TextAreaWrapper}
            />
            <div className="d-flex justify-content-center">
              <div className={classes.submitBtnContainer}>
                <FinalFormSubmitButton>Send</FinalFormSubmitButton>
              </div>
            </div>
          </form>
        )}
      />
    </BasicModal>
  );
};

SendMessageModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SendMessageModal;
