import React from 'react';

const EmailVerificationComponent = () => {
  return (
    <h1 className="text-center primary-color py-5">
      Email Successfully Verified!
    </h1>
  );
};

export default EmailVerificationComponent;
