import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance';
import transformArrayToMap from 'utils/transformArrayToMap';

const urlPrefix = '/v1';

const inspirationImageService = {
  getImages(params) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/inspiration-image/search`,
      params
    ).then(({ result: list }) => transformArrayToMap(list));
  },

  getImageById(id) {
    return AuthorizedAxiosInstance.get(`${urlPrefix}/inspiration-image/${id}`);
  },

  getLookBoardDetails(id) {
    return AuthorizedAxiosInstance.get(
      `${urlPrefix}/inspiration-image/${id}/view-look-boards`
    );
  },

  createInspirationImage(data) {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/inspiration-image`, data);
  },

  changeImagePermission(imageId, permissions) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/inspiration-image/${imageId}/permissions`,
      { permissions }
    );
  },

  toggleLike(imageId, isLiked) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/inspiration-image/${imageId}/like`,
      { isLiked }
    );
  },

  deleteAddedImage(imageId) {
    return AuthorizedAxiosInstance.delete(
      `${urlPrefix}/inspiration-image/${imageId}`
    );
  },
};

export default inspirationImageService;
