import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import filter from 'lodash-es/filter';
import buildResourceUrl from 'utils/buildResourceUrl';
import {
  getInspirationImgUrl,
  getImageType,
  getStyleNames,
} from 'modules/app/store/selectors';
import { detailsPreviewProps } from 'modules/requestTheLook/components/DetailsPreview/propTypes';
import { toggleUnderConstructionModalAction } from 'modules/layout/store/actions';
import { resetRequestStateAction } from 'modules/requestTheLook/store/actions';
import { inspirationImageShape } from 'modules/inspirationImage/propTypes';
import { imageTypeKeys } from 'constants/inspirationImageSearchParams';
import { routesByName } from 'constants/routes';
import { Link } from 'react-router-dom';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import SuccessBanner from 'modules/requestTheLook/requestSuccess/components/SuccessBanner/SuccessBanner';
import AsideBanner from 'modules/requestTheLook/requestSuccess/components/AsideBanner/AsideBanner';
import ImageDetails from 'modules/requestTheLook/components/ImageDetails/ImageDetails';
import DetailsPreview from 'modules/requestTheLook/components/DetailsPreview/DetailsPreview';
import classes from './RequestSuccess.module.scss';

const RequestSuccessContainer = ({
  inspirationImageUrl,
  selectedImage,
  title,
  requestType,
  imgType,
  styles,
  priorityItems,
  resetRequestState,
  toggleUnderConstructionModal,
}) => {
  const [state, setState] = useState({});

  const handleUnderConstructionModalOpen = useCallback(() => {
    toggleUnderConstructionModal(true);
  }, [toggleUnderConstructionModal]);

  useEffect(() => {
    const imgUrl = buildResourceUrl(
      inspirationImageUrl.medium,
      selectedImage?.media.userId,
      selectedImage?.media.hash
    );
    const itemType = requestType === imageTypeKeys.room ? 'Room' : 'Item';
    setState({
      imgUrl,
      selectedImage,
      title,
      requestType,
      imgType,
      styles,
      priorityItems: filter(priorityItems, 'value'),
      itemType,
    });
    resetRequestState();
    // eslint-disable-next-line
  }, []);

  return state.selectedImage ? (
    <section className={classes.root}>
      <div className="mb-5">
        <Link to={routesByName.requestTheLook.index}>
          <div className="d-flex align-items-center mb-3">
            <IconButton variant="inverted-white" size="sm">
              <SpriteIcon name="left-arrow" size="sm" />
            </IconButton>
            <span className="primary-color text-sm ml-1">Back</span>
          </div>
        </Link>
      </div>
      <div className="flex-center flex-column mb-3">
        <h1 className={`${classes.title} font-semi-bold`}>Congratulations!</h1>
        <p className={`${classes.description} font-italic`}>
          Your request has been submitted
        </p>
      </div>
      <div className="mb-5">
        <SuccessBanner />
      </div>
      <div className="d-flex">
        <ImageDetails
          imgUrl={state.imgUrl}
          url={state.selectedImage.url}
          title={state.selectedImage.title}
        />
        <DetailsPreview
          title={state.title}
          requestType={state.requestType}
          imgType={state.imgType}
          styles={state.styles}
          priorityItems={state.priorityItems}
          itemType={state.itemType}
        >
          <button
            className={classes.btn}
            onClick={handleUnderConstructionModalOpen}
            type="button"
          >
            View Look Boards based <br /> on similar images
          </button>
        </DetailsPreview>
        <div className={classes.aside}>
          <AsideBanner />
        </div>
      </div>
    </section>
  ) : null;
};

RequestSuccessContainer.propTypes = {
  inspirationImageUrl: PropTypes.shape({
    medium: PropTypes.string.isRequired,
  }).isRequired,
  selectedImage: inspirationImageShape,
  ...detailsPreviewProps,
  resetRequestState: PropTypes.func.isRequired,
  toggleUnderConstructionModal: PropTypes.func.isRequired,
};

RequestSuccessContainer.defaultProps = {
  selectedImage: null,
};

const mapStateToProps = ({
  app: { config, enums },
  requestTheLook: {
    requestData: { inspirationImageId, title, requestType, items },
  },
  inspirationImage: { library },
}) => ({
  title,
  requestType,
  inspirationImageUrl: getInspirationImgUrl(config),
  selectedImage: library[inspirationImageId],
  imgType: getImageType(library[inspirationImageId] ?? {}, enums),
  styles: getStyleNames(library[inspirationImageId]?.styles ?? [], enums),
  priorityItems: items,
});

const mapDispatchToProps = {
  resetRequestState: resetRequestStateAction,
  toggleUnderConstructionModal: toggleUnderConstructionModalAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestSuccessContainer);
