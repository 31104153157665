import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { getTheLookTabsConfig } from 'modules/getTheLook/constants';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import { changeActiveTabAction } from 'modules/getTheLook/store/actions';
import classes from './LookBoardsHeader.module.scss';

const LookBoardsHeader = ({ activeTab, changeActiveTab }) => {
  const handleChangeActiveTab = useCallback(
    ({ currentTarget }) => {
      const newTab = currentTarget.getAttribute('data-tab');
      changeActiveTab(newTab);
    },
    [changeActiveTab]
  );

  return (
    <>
      <div className="align-self-end">
        <div className={classes.requestBanner}>
          <div className={classes.bannerTitle}>
            <p className="text-sm font-title text-uppercase">
              Request the Look
            </p>
            <span className="text-xs font-italic">of an image you love</span>
          </div>
          <div className="d-flex flex-column align-items-center px-2">
            <IconButton size="sm" className="mb-1">
              <SpriteIcon name="pin" size="sm" />
            </IconButton>
            <span className="text-xs">Start Pinning</span>
          </div>
        </div>
      </div>
      <h3 className={`${classes.pageTitle} mb-3 `}>Get The Look You Love</h3>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <p className="text-lg text-capitalize font-semi-bold">
          Trending Look Boards
        </p>
        <div className="d-flex">
          <IconButton variant="inverted-white" className="mr-1">
            <SpriteIcon name="info" />
          </IconButton>
          {getTheLookTabsConfig.map(({ value, title, icon }) => (
            <button
              key={value}
              type="button"
              className={clsx(classes.tabBtn, {
                [classes.active]: activeTab === value,
              })}
              data-tab={value}
              onClick={handleChangeActiveTab}
            >
              <SpriteIcon name={icon} size="sm" className="mr-1" />
              {title}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

LookBoardsHeader.propTypes = {
  activeTab: PropTypes.string.isRequired,
  changeActiveTab: PropTypes.func.isRequired,
};

const mapStateToProps = ({ getTheLook: { activeTab } }) => ({ activeTab });

const mapDispatchToProps = {
  changeActiveTab: changeActiveTabAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(LookBoardsHeader);
