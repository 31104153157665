import { routesByName } from 'constants/routes';

export const navLinksConfig = [
  {
    route: routesByName.account.profile,
    title: 'Profile & Preferences',
    icon: 'profile',
  },
  {
    route: routesByName.account.orders,
    title: 'Personal Shopper Orders',
    icon: 'shopping',
  },
  {
    route: routesByName.account.paymentDetails,
    title: 'Payment Details',
    icon: 'credit-card',
  },
];

export const profileTabKeys = {
  profile: 'profile',
  preferences: 'preferences',
};

export const profileTabsConfig = [
  {
    name: profileTabKeys.profile,
    title: 'Profile',
  },
  {
    name: profileTabKeys.preferences,
    title: 'Preferences',
  },
];

export const changePasswordFormFields = {
  currentPassword: 'currentPassword',
  newPassword: 'newPassword',
  confirmNewPassword: 'confirmNewPassword',
};
