import React from 'react';
import PropTypes from 'prop-types';
import MixMatchThumbnail from 'components/MixMatchThumbnail/MixMatchThumbnail';
import classes from './VisualizeIdeas.module.scss';

const MixMatchTab = ({ items }) => (
  <div className={classes.mixMatchContainer}>
    {items.map(({ id, title, items: products }) => (
      <div className={classes.item} key={id}>
        <MixMatchThumbnail title={title} imgUrls={products} />
      </div>
    ))}
  </div>
);

MixMatchTab.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          url: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
};

export default MixMatchTab;
