import { errorMessages, signInFormFields } from 'modules/auth/signIn/constants';

const signInValidator = ({
  [signInFormFields.email]: email,
  [signInFormFields.password]: password,
}) => {
  const errors = {};

  if (!email) {
    errors.email = errorMessages.required;
  }

  if (!password) {
    errors.password = errorMessages.required;
  }

  return errors;
};

export default signInValidator;
