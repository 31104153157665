import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import classes from './Card.module.scss';

const Card = ({ classes: cardClasses, className, title, children }) => (
  <div className={clsx([classes.root, className, cardClasses.root])}>
    <div className={clsx(classes.title, cardClasses.title)}>{title}</div>
    <div className={clsx(classes.content, cardClasses.content)}>{children}</div>
  </div>
);

Card.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
  }),
};

Card.defaultProps = {
  className: '',
  classes: {},
};

export default Card;
