import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { getInspirationImgUrl } from 'modules/app/store/selectors';
import { urlPlaceholders } from 'constants/urlPlaceholders';
import FilterInput from 'modules/dashboard/components/FilterInput/FilterInput';
import {
  myImagesSortOptions,
  myImageTypeOptions,
} from 'modules/dashboard/constants';
import ImgPreviewModal from 'components/modals/ImgPreviewModal/ImgPreviewModal';
import AddedImageItem from 'modules/dashboard/components/AddedImageItem';
import InfoPopup from 'modules/dashboard/components/InfoPopup';
import classes from 'modules/dashboard/myImages/MyImages.module.scss';

const MyImagesComponent = ({
  loading,
  hasMore,
  loadMore,
  imgList,
  currentSource,
  currentImgType,
  currentSortType,
  onChangeImgType,
  onChangeSortType,
  onUnlike,
  onDeleteAddedImage,
  currentUserId,
  inspirationImageUrl,
}) => {
  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [imgPreviewUrl, setImgPreviewUrl] = useState(null);
  const [infoPopupOpen, setInfoPopupOpen] = useState(false);

  const handleOpenPreview = useCallback(
    ({ currentTarget }) => {
      const userId = currentTarget.getAttribute('data-user-id');
      const hash = currentTarget.getAttribute('data-hash');
      const imgUrl = inspirationImageUrl.medium
        .replace(urlPlaceholders.userId, userId)
        .replace(urlPlaceholders.hash, hash);

      setImgPreviewUrl(imgUrl);
      setImgModalOpen(true);
    },
    [inspirationImageUrl.medium]
  );

  const handleImgModalClose = useCallback(() => {
    setImgModalOpen(false);
  }, []);

  const handleLoadMore = useCallback(() => {
    if (!hasMore || loading) {
      return;
    }
    loadMore();
  }, [loading, hasMore, loadMore]);

  const handleOpenInfoPopup = useCallback(() => {
    setInfoPopupOpen(true);
  }, []);

  const handleCloseInfoPopup = useCallback(() => {
    setInfoPopupOpen(false);
  }, []);

  return (
    <>
      <div className={classes.section}>
        <div className="d-flex justify-content-between mb-3">
          <FilterInput
            className={classes.imgTypeSelect}
            options={myImageTypeOptions}
            currentValue={currentImgType}
            onChange={onChangeImgType}
          />
          <FilterInput
            additionalLabel="Sort by:"
            className={classes.sortTypeSelect}
            options={myImagesSortOptions}
            currentValue={currentSortType}
            onChange={onChangeSortType}
          />
        </div>
        <InfiniteScroll
          hasMore={hasMore}
          loadMore={handleLoadMore}
          initialLoad={false}
        >
          <div className="row">
            {imgList.map(
              ({
                id,
                approval,
                publish,
                ownership,
                permission,
                userId: ownerId,
                media: { userId, hash },
              }) => (
                <div key={id} className="col-3">
                  <AddedImageItem
                    id={id}
                    userId={userId}
                    hash={hash}
                    currentSource={currentSource}
                    approval={approval}
                    publish={publish}
                    ownership={ownership}
                    permission={permission}
                    showPermissionSwitch={ownerId === currentUserId}
                    onUnlike={onUnlike}
                    onOpenPreview={handleOpenPreview}
                    onOpenInfoPopup={handleOpenInfoPopup}
                    onDeleteAddedImage={onDeleteAddedImage}
                  />
                </div>
              )
            )}
          </div>
        </InfiniteScroll>
      </div>
      <ImgPreviewModal
        open={imgModalOpen}
        onClose={handleImgModalClose}
        url={imgPreviewUrl}
      />
      <InfoPopup open={infoPopupOpen} onClose={handleCloseInfoPopup} />
    </>
  );
};

MyImagesComponent.propTypes = {
  loading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  imgList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      media: PropTypes.shape({
        userId: PropTypes.number.isRequired,
        hash: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  currentSource: PropTypes.string.isRequired,
  currentImgType: PropTypes.string.isRequired,
  currentSortType: PropTypes.string.isRequired,
  onChangeImgType: PropTypes.func.isRequired,
  onChangeSortType: PropTypes.func.isRequired,
  onUnlike: PropTypes.func.isRequired,
  onDeleteAddedImage: PropTypes.func.isRequired,
  currentUserId: PropTypes.number.isRequired,
  inspirationImageUrl: PropTypes.shape({
    medium: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ app: { config }, auth: { user } }) => ({
  inspirationImageUrl: getInspirationImgUrl(config),
  currentUserId: user.id,
});

export default connect(mapStateToProps)(MyImagesComponent);
