import axios from 'axios';
import appConfig from 'constants/appConfig';
import { localStorageKeys } from 'modules/app/constants';

const appService = {
  getSavedAppVersion() {
    return localStorage.getItem(localStorageKeys.appVersion);
  },

  saveAppVersion(version) {
    localStorage.setItem(localStorageKeys.appVersion, version);
  },

  getConfig() {
    return axios
      .get(appConfig.configUrl)
      .then(({ data: applicationConfig }) => {
        localStorage.setItem(
          localStorageKeys.applicationConfig,
          JSON.stringify(applicationConfig)
        );

        return applicationConfig;
      });
  },

  getAppResource(url) {
    return axios.get(url).then(({ data }) => data);
  },
};

export default appService;
