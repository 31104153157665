import {
  imageSourceKeys,
  imageTypeKeys,
} from 'constants/inspirationImageSearchParams';
import { getTheLookTabKeys } from 'modules/getTheLook/constants';
import {
  GTL_SET_ACTIVE_TAB,
  GTL_SET_IMAGES,
  GTL_UPDATE_HAS_MORE_STATUS,
  GTL_UPDATE_IMAGES,
  GTL_UPDATE_LOADING_STATUS,
  GTL_UPDATE_LOOK_BOARDS_DATA,
  GTL_UPDATE_SEARCH_PARAMS,
} from 'modules/getTheLook/store/constants';

const initialSearchParams = {
  source: imageSourceKeys.all,
  type: imageTypeKeys.room,
  search: '',
  offset: 0,
};

const initialState = {
  activeTab: getTheLookTabKeys.lookBoardView,
  searchParams: initialSearchParams,
  imageList: [],
  limit: 10,
  hasMore: true,
  loading: false,
  lookBoardsData: {},
};

const getTheLookReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case GTL_SET_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: payload,
      };
    }
    case GTL_UPDATE_SEARCH_PARAMS: {
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          ...payload,
        },
      };
    }
    case GTL_UPDATE_LOADING_STATUS: {
      return {
        ...state,
        loading: payload,
      };
    }
    case GTL_SET_IMAGES: {
      return {
        ...state,
        imageList: payload,
      };
    }
    case GTL_UPDATE_IMAGES: {
      return {
        ...state,
        imageList: [...state.imageList, ...payload],
      };
    }
    case GTL_UPDATE_HAS_MORE_STATUS: {
      return {
        ...state,
        hasMore: payload,
      };
    }
    case GTL_UPDATE_LOOK_BOARDS_DATA: {
      return {
        ...state,
        lookBoardsData: {
          ...state.lookBoardsData,
          ...payload,
        },
      };
    }
    default:
      return state;
  }
};

export default getTheLookReducer;
