const inputs = {
  MuiInputLabel: {
    root: {
      fontFamily: '"Poppins", sans-serif',
      color: '#BDBDBD',
    },
  },
  MuiInputBase: {
    root: {
      fontFamily: '"Poppins", sans-serif',
    },
  },
  MuiInput: {
    input: {
      padding: '10px 0',
      fontWeight: 600,
      fontSize: 14,
      '&::placeholder': {
        fontWeight: 400,
      },
    },
    underline: {
      '&::before': {
        borderColor: '#E0E0E0',
      },
    },
  },
  MuiFormHelperText: {
    root: {
      fontFamily: '"Poppins", sans-serif',
    },
  },
};

export default inputs;
