import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import Faqs from 'modules/ambassadors/faqs/Faqs';
import GetStartedContainer from 'modules/ambassadors/getStarted/GetStartedContainer';

const AmbassadorsContainer = () => (
  <Switch>
    <Route exact path={routesByName.ambassador.index}>
      <GetStartedContainer />
    </Route>
    <Route path={routesByName.ambassador.faqs}>
      <Faqs />
    </Route>
  </Switch>
);

export default AmbassadorsContainer;
