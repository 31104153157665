import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { lookBoardDetailsFormFields } from 'modules/curateTheLook/constants';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import { getEnumAsSelectOptions } from 'modules/app/store/selectors';
import { imageTypeKeys } from 'constants/inspirationImageSearchParams';
import SelectInputWrapper from 'components/finalFormWrappers/SelectInputWrapper';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';

const LookBoardDetailsForm = ({
  imageType,
  initialValues,
  validate,
  onSubmit,
  roomTypes,
  itemClasses,
  styles,
}) => (
  <Form
    initialValues={initialValues}
    validate={validate}
    onSubmit={onSubmit}
    render={({ handleSubmit }) => (
      <form noValidate onSubmit={handleSubmit}>
        <Field
          required
          name={lookBoardDetailsFormFields.title}
          label="Title"
          component={TextInputWrapper}
        />
        {imageType === imageTypeKeys.room && (
          <Field
            name={lookBoardDetailsFormFields.roomTypeId}
            label="Room Type"
            required
            component={SelectInputWrapper}
            options={roomTypes}
          />
        )}
        {imageType === imageTypeKeys.single && (
          <Field
            name={lookBoardDetailsFormFields.itemClassId}
            label="Item Class"
            required
            component={SelectInputWrapper}
            options={itemClasses}
          />
        )}
        <Field
          name={lookBoardDetailsFormFields.styleId}
          label="Style"
          required
          component={SelectInputWrapper}
          options={styles}
        />
        <Field
          required
          name={lookBoardDetailsFormFields.description}
          label="Description"
          multiline
          rows={4}
          component={TextInputWrapper}
        />
        <div className="text-center">
          <FinalFormSubmitButton inline>Review</FinalFormSubmitButton>
        </div>
      </form>
    )}
  />
);

LookBoardDetailsForm.propTypes = {
  initialValues: PropTypes.shape({}).isRequired,
  validate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  imageType: PropTypes.string,
  roomTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  itemClasses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  styles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

LookBoardDetailsForm.defaultProps = {
  imageType: null,
};

const mapStateToProps = ({
  app: {
    enums: { roomTypes, itemClasses, styles },
  },
}) => ({
  roomTypes: getEnumAsSelectOptions(roomTypes),
  itemClasses: getEnumAsSelectOptions(itemClasses),
  styles: getEnumAsSelectOptions(styles),
});

export default connect(mapStateToProps)(LookBoardDetailsForm);
