import React from 'react';
import * as PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    fontFamily: '"Poppins", sans-serif',
  },
  colorDefault: {
    backgroundColor: palette.grey[2],
    color: palette.primary.main,
  },
}));

const BasicAvatar = ({ firstName, lastName, src, className }) => {
  const classes = useStyles();
  return (
    <Avatar
      className={className}
      classes={{
        root: classes.root,
        colorDefault: classes.colorDefault,
      }}
      alt={`${firstName} ${lastName}`}
      src={src}
    >
      {`${firstName[0]}${lastName[0]}`}
    </Avatar>
  );
};

BasicAvatar.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  src: PropTypes.string,
  className: PropTypes.string,
};

BasicAvatar.defaultProps = {
  src: undefined,
  className: '',
};

export default BasicAvatar;
