import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { routesByName } from 'constants/routes';

const AmbassadorRoute = ({ isAmbassador, children, ...rest }) => (
  <Route {...rest}>
    {isAmbassador ? children : <Redirect to={routesByName.home} />}
  </Route>
);

AmbassadorRoute.propTypes = {
  isAmbassador: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const mapStateToProps = ({ auth: { user } }) => ({
  isAmbassador: Boolean(user?.ambassador),
});

export default connect(mapStateToProps)(AmbassadorRoute);
