import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import appReducer from 'modules/app/store/reducer';
import authReducer from 'modules/auth/store/reducer';
import layoutReducer from 'modules/layout/store/reducer';
import curateTheLookReducer from 'modules/curateTheLook/store/reducer';
import requestTheLookReducer from 'modules/requestTheLook/store/reducer';
import productsReducer from 'modules/product/store/reducer';
import inspirationImageReducer from 'modules/inspirationImage/store/reducer';
import getTheLookReducer from 'modules/getTheLook/store/reducer';

export default function createRootReducer(history) {
  return combineReducers({
    app: appReducer,
    auth: authReducer,
    layout: layoutReducer,
    getTheLook: getTheLookReducer,
    curateTheLook: curateTheLookReducer,
    requestTheLook: requestTheLookReducer,
    inspirationImage: inspirationImageReducer,
    product: productsReducer,
    router: connectRouter(history),
    toastr: toastrReducer,
  });
}
