import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mediaShape } from 'modules/media/propTypes';
import { productShape } from 'modules/product/propTypes';
import buildResourceUrl from 'utils/buildResourceUrl';
import { getUserAvatarUrl } from 'modules/app/store/selectors';
import { toggleUnderConstructionModalAction } from 'modules/layout/store/actions';
import ProductThumbnail from 'components/ProductThumbnail/ProductThumbnail';
import OverlayLabel from 'components/imageThumbnails/OverlayLabel/OverlayLabel';
import HoverOverlay from 'modules/getTheLook/components/HoverOverlay/HoverOverlay';

const ProductItem = ({
  product: { id, name, media, itemClassId, price, isLiked },
  user,
  onToggleLike,
  userAvatarUrl,
  toggleUnderConstructionModal,
}) => {
  const avatarUrl = useMemo(
    () =>
      user.avatar &&
      buildResourceUrl(userAvatarUrl, user.avatar.userId, user.avatar.hash),
    [userAvatarUrl, user]
  );

  const handleUnderConstructionModalOpen = useCallback(() => {
    toggleUnderConstructionModal(true);
  }, [toggleUnderConstructionModal]);

  const handleToggleLike = useCallback(() => {
    onToggleLike(id, Number(!isLiked));
  }, [onToggleLike, id, isLiked]);

  return (
    <ProductThumbnail
      name={name}
      media={media}
      itemClassId={itemClassId}
      price={price}
      isLiked={isLiked}
      staticOverlay={
        <OverlayLabel
          text="product is discontinued"
          labelClasses={{ label: 'text-lg' }}
        />
      }
      hoverOverlay={
        <HoverOverlay
          firstName={user.firstName}
          lastName={user.lastName}
          avatarUrl={avatarUrl}
          isLiked={isLiked}
          onToggleLike={handleToggleLike}
          onOpenUnderConstructionModal={handleUnderConstructionModalOpen}
        />
      }
    />
  );
};

ProductItem.propTypes = {
  product: productShape.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    avatar: mediaShape,
  }).isRequired,
  onToggleLike: PropTypes.func.isRequired,
  userAvatarUrl: PropTypes.string.isRequired,
  toggleUnderConstructionModal: PropTypes.func.isRequired,
};

const mapStateToProps = ({ app: { config } }) => ({
  userAvatarUrl: getUserAvatarUrl(config),
});

const mapDispatchToProps = {
  toggleUnderConstructionModal: toggleUnderConstructionModalAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductItem);
