import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import InfoIconPopover from 'components/ui/InfoIconPopover/InfoIconPopover';
import SpriteIcon from 'components/ui/SpriteIcon';
import IconButton from 'components/ui/IconButton/IconButton';
import AutoCompleteInput from 'modules/requestTheLook/requestDetails/components/AutoCompleteInput/AutoCompleteInput';
import { priorityItemShape } from 'modules/requestTheLook/requestDetails/requestForm/propTypes';
import { updatePriorityItemAction } from 'modules/requestTheLook/store/actions';
import classes from './PriorityItems.module.scss';

const PriorityItems = ({
  requestPriorityItems,
  handleAddAnotherItem,
  showAddAnotherItem,
  optionsList,
  updatePriorityItem,
}) => (
  <>
    <div className="d-flex align-items-center mb-4 position-relative">
      <div className="text-sm mr-1">
        <span className="font-semi-bold">Priority Items</span> (optional)
      </div>
      <InfoIconPopover
        iconProps={{
          variant: 'inverted-grey',
          color: 'secondary',
          size: 'xs',
        }}
        fullWidth
      >
        <p className="font-semi-bold mb-1">Title Description</p>
        <p className="text-xs mb-2">
          Select an item based upon your Inspiration Image. Then complete the
          specification(s) needed for this item. There is a fee of $25 per item
          to hire a skilled Personal Shopper to search for an item matching your
          specifications.
        </p>
        <p className="text-xs mb-2">
          Our personal shoppers have access to thousands of items not easily
          found online and are skilled at finding the best deals. The fee covers
          up to three requirements (dimensions, color, and price range).
        </p>
        <p className="text-xs">
          If you require additional features, you can request a quote from your
          Personal Shopper. If your Personal Shopper does not believe they can
          find items meeting any of your requirements, they will alert you
          upfront and you will not be charged
        </p>
      </InfoIconPopover>
      <div
        className={`${classes.optionalTitle} text-xs text-gray font-italic position-absolute`}
      >
        Select Up to 6 items you like from this room
      </div>
    </div>
    {requestPriorityItems &&
      requestPriorityItems.map((item, index) => (
        <AutoCompleteInput
          key={item?.value ?? `${Date.now()}-${index}`}
          index={index}
          value={item?.value ? item : undefined}
          handleChange={updatePriorityItem}
          options={optionsList}
        />
      ))}
    {showAddAnotherItem && (
      <div className="d-flex text-small primary-color align-items-center">
        <IconButton
          onClick={handleAddAnotherItem}
          className="mr-1"
          size="xs"
          type="button"
        >
          <SpriteIcon name="plus" size="xs" />
        </IconButton>
        Add Another Item
      </div>
    )}
  </>
);

PriorityItems.propTypes = {
  showAddAnotherItem: PropTypes.bool.isRequired,
  handleAddAnotherItem: PropTypes.func.isRequired,
  updatePriorityItem: PropTypes.func.isRequired,
  requestPriorityItems: PropTypes.arrayOf(priorityItemShape).isRequired,
  optionsList: PropTypes.arrayOf(priorityItemShape).isRequired,
};

const mapDispatchToProps = {
  updatePriorityItem: updatePriorityItemAction,
};

export default connect(null, mapDispatchToProps)(PriorityItems);
