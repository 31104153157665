import { imageTypeKeys } from 'constants/inspirationImageSearchParams';

export const getEnumAsSelectOptions = (resource) =>
  Object.values(resource).map(({ id: value, name: label }) => ({
    label,
    value,
  }));

export const getUserAvatarUrl = ({
  endpoints: {
    media: { userAvatar },
  },
}) => userAvatar.small;

export const getUserAvatarThumbUrl = ({
  endpoints: {
    media: { userAvatar },
  },
}) => userAvatar.thumb;

export const getStyleImgUrl = ({
  endpoints: {
    media: { style },
  },
}) => style.thumb;

export const getStyleImgPreviewUrl = ({
  endpoints: {
    media: { style },
  },
}) => style.large;

export const getSubStyleImgUrl = ({
  endpoints: {
    media: { subStyle },
  },
}) => subStyle.thumb;

export const getInspirationImgUrl = ({
  endpoints: {
    media: { inspirationImage },
  },
}) => inspirationImage;

export const getProductImgUrl = ({
  endpoints: {
    media: { product },
  },
}) => product;

export const getFilteredOptionsByPreferences = (options, preferences) =>
  preferences.length > 0
    ? preferences.map((preferenceId) => options[preferenceId])
    : [];

export const getSubStylesBySelectedStyles = (
  styles,
  subStyles,
  selectedStyleIds
) => {
  if (!selectedStyleIds) {
    return [];
  }
  return selectedStyleIds.reduce((accum, currValue) => {
    const { name } = styles[currValue];
    const selectedSubStyles = Object.values(subStyles).filter(
      ({ styleId }) => styleId === currValue
    );
    accum.push({
      id: currValue,
      name,
      subStyles: selectedSubStyles,
    });

    return accum;
  }, []);
};

export const getSubStylesByPreference = (
  styles,
  subStyles,
  selectedStyleIds,
  selectedSubStyleIds
) =>
  selectedStyleIds.reduce((accum, currValue) => {
    const { name } = styles[currValue];
    const selectedSubStyles = selectedSubStyleIds
      ? Object.values(subStyles).filter(
          ({ id, styleId }) =>
            styleId === currValue &&
            Object.keys(selectedSubStyleIds).includes(id.toString())
        )
      : [];
    accum.push({
      id: currValue,
      name,
      selectedSubStyles,
    });

    return accum;
  }, []);

export const getStyleNames = (styles, enums) =>
  styles.map((styleId) => enums.styles[styleId].name);

export const getImageType = (image, enums) =>
  image.type === imageTypeKeys.room
    ? enums.roomTypes[image.roomTypeId]
    : enums.itemClasses[image.itemClassId || 1]; // This mock '1' is only for development purposes, since seed II with type 'single' may not have itemClassId

export const getColors = (colors, enums) =>
  colors.map((colorId) => enums.colors[colorId]);
