import React, { useEffect } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import CreateLookBoardContainer from 'modules/curateTheLook/createLookBoard/CreateLookBoardContainer';
import LookBoardDetailsContainer from 'modules/curateTheLook/lookBoardDetails/LookBoardDetailsContainer';
import SubmitLookBoardContainer from 'modules/curateTheLook/submitLookBoard/SubmitLookBoardContainer';

const CurateTheLookContainer = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (pathname !== routesByName.curateTheLook.canvas) {
      history.replace(routesByName.curateTheLook.canvas);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Switch>
      <Route exact path={routesByName.curateTheLook.canvas}>
        <CreateLookBoardContainer />
      </Route>
      <Route exact path={routesByName.curateTheLook.details}>
        <LookBoardDetailsContainer />
      </Route>
      <Route exact path={routesByName.curateTheLook.submit}>
        <SubmitLookBoardContainer />
      </Route>
      <Route exact path={routesByName.curateTheLook.success}>
        Success page
      </Route>
    </Switch>
  );
};

export default CurateTheLookContainer;
