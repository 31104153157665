const MuiFormControlLabel = {
  label: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: 14,
    fontWeight: 'normal',
    color: '#333333',
    textTransform: 'capitalize',
  },
};

export default MuiFormControlLabel;
