import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { imageSourceKeys } from 'constants/inspirationImageSearchParams';
import { infoMenuShape } from 'modules/layout/SideNav/propTypes';
import InfoMenu from 'modules/layout/SideNav/InfoMenu/InfoMenu';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from 'modules/layout/SideNav/SideNav.module.scss';

const SideBar = ({
  currentSource,
  onUpdateSearchParams,
  unselectImage,
  infoMenuConfig,
}) => {
  const [infoPopupOpen, setInfoPopupOpen] = useState(false);

  const handleOpenInfoPopup = useCallback(() => {
    setInfoPopupOpen(true);
  }, []);

  const handleCloseInfoPopup = useCallback(() => {
    setInfoPopupOpen(false);
  }, []);

  const handleChangeImgSource = useCallback(
    ({ currentTarget }) => {
      const source = currentTarget.getAttribute('data-source');
      if (!infoPopupOpen || currentSource !== source) {
        onUpdateSearchParams({ source });
        unselectImage();
      }
      setInfoPopupOpen(false);
    },
    [currentSource, unselectImage, onUpdateSearchParams, infoPopupOpen]
  );

  return (
    <div className={classes.root}>
      <div
        className={clsx(classes.navLink, {
          [classes.activeTabInfo]: infoPopupOpen,
        })}
        title="Info"
        onClick={handleOpenInfoPopup}
      >
        <SpriteIcon name="info" />
      </div>
      <div
        title="Inspiration Images"
        className={clsx(classes.navLink, {
          [classes.activeTab]: currentSource === imageSourceKeys.all,
        })}
        data-source={imageSourceKeys.all}
        onClick={handleChangeImgSource}
      >
        <SpriteIcon name="living-room" size="lg" />
      </div>
      <div
        title="My Added Images"
        className={clsx(classes.navLink, {
          [classes.activeTab]:
            currentSource === imageSourceKeys.my ||
            currentSource === imageSourceKeys.liked,
        })}
        data-source={imageSourceKeys.my}
        onClick={handleChangeImgSource}
      >
        <SpriteIcon name="added-image" size="lg" />
      </div>
      <InfoMenu
        config={infoMenuConfig}
        isOpen={infoPopupOpen}
        onClose={handleCloseInfoPopup}
      />
    </div>
  );
};

SideBar.propTypes = {
  currentSource: PropTypes.string.isRequired,
  onUpdateSearchParams: PropTypes.func.isRequired,
  unselectImage: PropTypes.func.isRequired,
  infoMenuConfig: infoMenuShape.isRequired,
};

export default SideBar;
