import React from 'react';
import PropTypes from 'prop-types';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from './InfoMenu.module.scss';

const InfoMenuItem = ({ title, description, icon }) => (
  <div className="mb-5">
    <div className="d-flex align-items-center mb-2">
      <div className={classes.icon}>
        <SpriteIcon name={icon} />
      </div>
      {title}
    </div>
    <p className={classes.itemDescription}>{description}</p>
  </div>
);

InfoMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default InfoMenuItem;
