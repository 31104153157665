import React from 'react';
import PropTypes from 'prop-types';
import classes from './PresentationCard.module.scss';

const PresentationCard = ({ title, description, children }) => (
  <div className={classes.root}>
    <div className="flex-center flex-column text-center mb-4">
      {title && <h2 className={classes.title}>{title}</h2>}
      {description && <p className={classes.description}>{description}</p>}
    </div>
    {children}
  </div>
);

PresentationCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
};

PresentationCard.defaultProps = {
  title: null,
  description: null,
};

export default PresentationCard;
