import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import { getProductImgUrl } from 'modules/app/store/selectors';
import buildResourceUrl from 'utils/buildResourceUrl';
import { mediaShape } from 'modules/media/propTypes';
import classes from './ProductThumbnail.module.scss';

const ProductThumbnail = ({
  className,
  name,
  media,
  itemClassId, // eslint-disable-line
  price,
  isLiked,
  showStaticOverlay,
  staticOverlay,
  hoverOverlay,
  productImgUrl,
  itemClass,
}) => {
  const [hover, setHover] = useState(false);

  const imgUrl = useMemo(
    () => buildResourceUrl(productImgUrl.medium, media.userId, media.hash),
    [media, productImgUrl]
  );

  const handleHover = useCallback(() => {
    setHover(true);
  }, []);

  const handleBlur = useCallback(() => {
    setHover(false);
  }, []);

  return (
    <div
      className={clsx(classes.root, className)}
      onMouseEnter={handleHover}
      onMouseLeave={handleBlur}
    >
      <div className="flex-fill flex-center">
        <img
          alt={name}
          draggable="false"
          src={imgUrl}
          className={clsx({ [classes.blurred]: hover })}
        />
      </div>
      <div className={clsx('d-flex flex-column', classes.bottomBar)}>
        <div
          className={clsx(
            'd-flex justify-content-between align-items-center',
            classes.productLabel
          )}
        >
          <div className="py-1">
            <p className="text-ellipsis text-sm text-gray font-semi-bold mb-1">
              {name}
            </p>
            <i className="text-xs text-gray">{itemClass}</i>
          </div>
          <span className="primary-color text-sm font-semi-bold">{`$${price}`}</span>
        </div>
        <div className="d-flex justify-content-end align-items-center py-1">
          <IconButton size="sm" variant="inverted-grey">
            <SpriteIcon name={isLiked ? 'like-filled' : 'like'} size="sm" />
          </IconButton>
        </div>
      </div>
      {showStaticOverlay && (
        <div
          className={clsx(classes.staticOverlay, { [classes.hidden]: hover })}
        >
          {staticOverlay}
        </div>
      )}
      <div className={clsx(classes.overlay, { [classes.hidden]: !hover })}>
        {hoverOverlay}
      </div>
    </div>
  );
};

ProductThumbnail.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  isLiked: PropTypes.number.isRequired,
  itemClassId: PropTypes.number.isRequired,
  media: mediaShape.isRequired,
  hoverOverlay: PropTypes.node.isRequired,
  showStaticOverlay: PropTypes.bool,
  staticOverlay: PropTypes.node,
  productImgUrl: PropTypes.shape({
    medium: PropTypes.string.isRequired,
  }).isRequired,
  itemClass: PropTypes.string.isRequired,
};

ProductThumbnail.defaultProps = {
  className: '',
  showStaticOverlay: false,
  staticOverlay: null,
};

const mapStateToProps = (
  {
    app: {
      config,
      enums: { itemClasses },
    },
  },
  { itemClassId }
) => ({
  productImgUrl: getProductImgUrl(config),
  itemClass: itemClasses[itemClassId].name,
});

export default connect(mapStateToProps)(ProductThumbnail);
