import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import { navLinksConfig } from 'modules/account/constants';
import SideNav from 'modules/layout/SideNav/SideNav';
import ProfileAndPreferencesContainer from 'modules/account/profileAndPreferences/ProfileAndPreferencesContainer';
import CustomerSupportBanner from 'modules/account/components/CustomerSupportBanner/CustomerSupportBanner';
import classes from './Account.module.scss';

const AccountContainer = () => (
  <div className="flex-fill d-flex">
    <SideNav navLinks={navLinksConfig} />
    <div className={classes.routeContainer}>
      <Switch>
        <Redirect
          exact
          from={routesByName.account.index}
          to={routesByName.account.profile}
        />
        <Route path={routesByName.account.profile}>
          <ProfileAndPreferencesContainer />
        </Route>
        <Route path={routesByName.account.orders}>
          <div>orders</div>
        </Route>
        <Route path={routesByName.account.paymentDetails}>
          <div>paymentDetails</div>
        </Route>
      </Switch>
    </div>
    <div className={classes.rightSection}>
      <div className={classes.bannersContainer}>
        <CustomerSupportBanner />
      </div>
    </div>
  </div>
);

export default AccountContainer;
