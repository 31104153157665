import {
  imageSourceKeys,
  imageTypeKeys,
} from 'constants/inspirationImageSearchParams';
import roomImg from 'assets/img/request-the-look-page/room.jpg';
import lampImg from 'assets/img/request-the-look-page/lamp.jpg';

import requestPreviewImg from 'assets/img/home-page/sections-preview/request.svg';
import curatePreviewImg from 'assets/img/home-page/sections-preview/curate.svg';
import getPreviewImg from 'assets/img/home-page/sections-preview/get.svg';
import shopPreviewImg from 'assets/img/home-page/sections-preview/shop.svg';

import midChair from 'assets/img/home-page/furniture/chair-7.png';
import midTable from 'assets/img/home-page/furniture/table-6.png';
import midStool from 'assets/img/home-page/furniture/stool-5.png';
import midSofa from 'assets/img/home-page/furniture/sofa-5.png';

import centuryChair from 'assets/img/home-page/furniture/chair-5.png';
import centuryStool from 'assets/img/home-page/furniture/stool-2.png';
import centurySofa from 'assets/img/home-page/furniture/sofa-6.png';

export const infoMenuConfig = {
  title: 'Request the Look',
  description: 'Request the Look Section Guide',
  items: [
    {
      id: 1,
      title: 'Inspiration Images Database',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut ?',
      icon: 'living-room',
    },
    {
      id: 2,
      title: 'My Saved Images & Favorites',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut ?',
      icon: 'pictures',
    },
  ],
};

export const imageTypeOptions = [
  {
    value: imageSourceKeys.my,
    label: 'My Added Images',
  },
  {
    value: imageSourceKeys.liked,
    label: 'My Saved Images',
  },
];

export const imageSearchRadioOptions = [
  { value: imageTypeKeys.room, label: 'Room Images' },
  { value: imageTypeKeys.single, label: 'Single Items' },
];

export const requestTypes = {
  [imageTypeKeys.room]: 'Room Image',
  [imageTypeKeys.single]: 'Single Item',
};

export const requestTheLookTypes = [
  {
    title: 'Request the Look of a Room Image',
    description:
      'Request the Look of your Favorite Room Designs and Home Furnishing Items',
    type: imageTypeKeys.room,
    imgUrl: roomImg,
  },
  {
    title: 'Request the Look of a Single Item',
    description: 'Find Items that Coordinate with an Item you Love!',
    type: imageTypeKeys.single,
    imgUrl: lampImg,
  },
];

export const requestSuccessBannerConfig = [
  {
    id: 1,
    title: 'Your Request Matched with Ambassadors',
    imgUrl: requestPreviewImg,
  },
  {
    id: 2,
    title: 'Ambassadors Curate the Look for You',
    imgUrl: curatePreviewImg,
  },
  {
    id: 3,
    title: 'Look Board Recommendation Sent',
    imgUrl: getPreviewImg,
  },
  {
    id: 4,
    title: 'You Rate & Save/Buy the Best Matches',
    imgUrl: shopPreviewImg,
  },
];

export const requestSuccessExamplesConfig = [
  {
    id: 1,
    title: 'Mid Century Living',
    images: [
      {
        id: 1,
        url: midChair,
      },
      {
        id: 2,
        url: midTable,
      },
      {
        id: 3,
        url: midStool,
      },
      {
        id: 4,
        url: midSofa,
      },
    ],
  },
  {
    id: 2,
    title: 'Modern Blue Room',
    images: [
      {
        id: 1,
        url: centuryChair,
      },
      {
        id: 2,
        url: midTable,
      },
      {
        id: 3,
        url: centuryStool,
      },
      {
        id: 4,
        url: centurySofa,
      },
    ],
  },
];
