import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Popover from '@material-ui/core/Popover';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from './FilterInput.module.scss';

const FilterInput = ({
  className,
  additionalLabel,
  options,
  currentValue,
  onChange,
}) => {
  const inputRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverWidth, setPopoverWidth] = useState(undefined);

  useEffect(() => {
    const refWidth = inputRef.current.clientWidth;
    setPopoverWidth(refWidth);
  }, []);

  const optionTitle = useMemo(() => {
    const currentOption = options.find(({ value }) => value === currentValue);
    return currentOption ? currentOption.label : '';
  }, [options, currentValue]);

  const handleOpenDropdown = useCallback(({ currentTarget }) => {
    setAnchorEl(currentTarget);
  }, []);

  const handleCloseDropdown = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleChangeValue = useCallback(
    ({ currentTarget }) => {
      const option = currentTarget.getAttribute('data-option');
      onChange(option);
      setAnchorEl(null);
    },
    [onChange]
  );

  return (
    <>
      <div
        className={`${classes.root} ${className}`}
        onClick={handleOpenDropdown}
        ref={inputRef}
      >
        <span>{`${additionalLabel} ${optionTitle}`}</span>
        <SpriteIcon
          name="arrow-down"
          size="sm"
          className={clsx(classes.icon, { [classes.open]: Boolean(anchorEl) })}
        />
      </div>
      <Popover
        open={Boolean(anchorEl)}
        onClose={handleCloseDropdown}
        anchorEl={anchorEl}
        classes={{ paper: classes.optionsPopover }}
        PaperProps={{ style: { width: popoverWidth } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {options.map(({ label, value }) => (
          <div
            key={value}
            className={clsx(classes.option, {
              'font-semi-bold': value === currentValue,
            })}
            data-option={value}
            onClick={handleChangeValue}
          >
            {label}
          </div>
        ))}
      </Popover>
    </>
  );
};

FilterInput.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  additionalLabel: PropTypes.string,
  className: PropTypes.string,
};

FilterInput.defaultProps = {
  additionalLabel: '',
  className: '',
};

export default FilterInput;
