import BaseAxiosInstance from 'libs/axios/BaseAxiosInstance';
import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance';

const urlPrefix = '/v1';
const accessTokenKey = 'accessToken';
const refreshTokenKey = 'refreshToken';

const authService = {
  signUp(formValues) {
    return BaseAxiosInstance.post(`${urlPrefix}/auth/sign-up`, formValues);
  },

  verifyEmail(userId, token) {
    return BaseAxiosInstance.get(
      `${urlPrefix}/auth/email-verification/${userId}/${token}`
    );
  },

  async login(formValues) {
    const {
      result: {
        token_type: tokenType,
        access_token: accessToken,
        refresh_token: refreshToken,
      },
    } = await BaseAxiosInstance.post(`${urlPrefix}/auth/login`, formValues);

    this.setAccessToken(`${tokenType} ${accessToken}`);
    this.setRefreshToken(refreshToken);
  },

  getAccessToken() {
    return localStorage.getItem(accessTokenKey);
  },

  setAccessToken(token) {
    localStorage.setItem(accessTokenKey, token);
  },

  getRefreshToken() {
    return localStorage.getItem(refreshTokenKey);
  },

  setRefreshToken(token) {
    localStorage.setItem(refreshTokenKey, token);
  },

  async refreshToken() {
    const token = this.getRefreshToken();
    if (!token) {
      throw new Error('Refresh is impossible');
    }

    const {
      result: {
        token_type: tokenType,
        access_token: accessToken,
        refresh_token: refreshToken,
      },
    } = await BaseAxiosInstance.post(`${urlPrefix}/auth/refresh-token`, {
      refreshToken: token,
    });

    this.setAccessToken(`${tokenType} ${accessToken}`);
    this.setRefreshToken(refreshToken);
  },

  clearAuthTokens() {
    localStorage.removeItem(accessTokenKey);
    localStorage.removeItem(refreshTokenKey);
  },

  logout() {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/auth/logout`).then(() => {
      this.clearAuthTokens();
    });
  },

  changePassword(passwordData) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/auth/change-password`,
      passwordData
    );
  },
};

export default authService;
