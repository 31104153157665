import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@material-ui/core/Backdrop';
import mediaTypes from 'constants/mediaTypes';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from './AddImageModal.module.scss';

const AddImageModal = ({ open, onClose, onImageUpload, onMediaPin }) => {
  return (
    <Backdrop className={classes.backdrop} open={open} onClick={onClose}>
      <div className={`${classes.pinCard} mr-4`} onClick={onImageUpload}>
        <div className={classes.iconContainer}>
          <SpriteIcon name="image-upload" className={classes.icon} />
        </div>
        <p className={classes.title}>
          <b>Upload</b>
          <br />
          <span>an Image</span>
        </p>
      </div>
      <div
        className={classes.pinCard}
        data-media-type={mediaTypes.inspirationImage}
        onClick={onMediaPin}
      >
        <div className={classes.iconContainer}>
          <SpriteIcon name="pin-filled" className={classes.icon} />
        </div>
        <p className={classes.title}>
          <b>Pin an image</b>
          <br />
          <span>from a Website</span>
        </p>
      </div>
    </Backdrop>
  );
};

AddImageModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onImageUpload: PropTypes.func.isRequired,
  onMediaPin: PropTypes.func.isRequired,
};

export default AddImageModal;
