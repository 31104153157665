import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { Link, useHistory, useLocation } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Badge from '@material-ui/core/Badge';
import { ReactComponent as Logo } from 'assets/img/logo.svg';
import { routesByName } from 'constants/routes';
import { urlPlaceholders } from 'constants/urlPlaceholders';
import SpriteIcon from 'components/ui/SpriteIcon';
import Button from 'components/ui/Button/Button';
import SearchInput from 'components/inputs/SearchInput/SearchInput';
import { userShape } from 'modules/account/profileAndPreferences/propTypes';
import HeaderMenu from 'modules/layout/Header/HeaderMenu/HeaderMenu';
import HeaderThumbnail from 'modules/layout/Header/HeaderThumbnail';
import AuthModal from 'modules/layout/Header/AuthModal/AuthModal';
import SectionsNavigation from 'modules/layout/Header/SectionsNavigation';
import NotificationPopover from 'modules/layout/Header/Popovers/NotificationPopover';
import DialogsPopover from 'modules/layout/Header/Popovers/DialogsPopover';
import { getUserAvatarThumbUrl } from 'modules/app/store/selectors';
import classes from './Header.module.scss';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Header = ({ authenticated, user, userAvatarThumbUrl }) => {
  const { pathname } = useLocation();
  const queryParams = useQuery();
  const history = useHistory();

  const [authModal, setAuthModal] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const [dialogsAnchorEl, setDialogsAnchorEl] = useState(null);
  const [openSearchInput, setOpenSearchInput] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const currentAvatarUrl = useMemo(() => {
    if (user && user.avatar) {
      const { userId, hash } = user.avatar;
      return userAvatarThumbUrl
        .replace(urlPlaceholders.userId, userId)
        .replace(urlPlaceholders.hash, hash);
    }
    return null;
  }, [user, userAvatarThumbUrl]);

  useEffect(() => {
    const authParam = queryParams.get(routesByName.auth.key);
    setAuthModal(authParam);
  }, [queryParams]);

  const handleSearchQueryChange = useCallback((query) => {
    setSearchQuery(query);
  }, []);

  const handleAuthModalClose = useCallback(() => {
    history.push(pathname);
  }, [history, pathname]);

  const handleOpenNotificationModal = useCallback((event) => {
    setNotificationAnchorEl(event.currentTarget);
  }, []);

  const handleCloseNotificationModal = useCallback(() => {
    setNotificationAnchorEl(null);
  }, []);

  const handleOpenDialogsModal = useCallback((event) => {
    setDialogsAnchorEl(event.currentTarget);
  }, []);

  const handleCloseDialogsModal = useCallback(() => {
    setDialogsAnchorEl(null);
  }, []);

  const handleOpenMenu = useCallback((event) => {
    setMenuAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const handleOpenSearchInput = useCallback(() => {
    setOpenSearchInput(true);
  }, []);

  const handleCloseSearchInput = useCallback(() => {
    setOpenSearchInput(false);
  }, []);

  return (
    <>
      <div className={classes.root}>
        <div className="d-flex justify-content-between align-items-center">
          <Logo width={150} />
          <div className="d-flex align-items-center">
            <div className="position-relative mr-4">
              <IconButton onClick={handleOpenSearchInput}>
                <SpriteIcon name="loupe" size="sm" />
              </IconButton>
              <div
                className={clsx(classes.searchInput, {
                  [classes.active]: openSearchInput,
                })}
              >
                <SearchInput
                  value={searchQuery}
                  placeholder="Search by keywords"
                  onChange={handleSearchQueryChange}
                  onCrossClick={handleCloseSearchInput}
                />
              </div>
            </div>
            {authenticated && (
              <Link to={routesByName.dashboard.index} className="mr-4">
                <Button size="sm">
                  <SpriteIcon name="dashboard" size="sm" className="mr-1" />
                  Dashboard
                </Button>
              </Link>
            )}
            <Link to={routesByName.ambassador.index} className="mr-4">
              <Button size="sm">Ambassadors</Button>
            </Link>
            {authenticated ? (
              <>
                <Link to={routesByName.account.index}>
                  <HeaderThumbnail
                    src={currentAvatarUrl}
                    lastName={user.lastName}
                    firstName={user.firstName}
                  />
                </Link>
                <div className="secondary-color mx-4">
                  <Badge
                    className="cursor-pointer mr-4"
                    color="error"
                    badgeContent={1}
                    onClick={handleOpenNotificationModal}
                  >
                    <SpriteIcon name="envelope" size="md" />
                  </Badge>
                  <Badge
                    className="cursor-pointer"
                    color="error"
                    badgeContent={1}
                    onClick={handleOpenDialogsModal}
                  >
                    <SpriteIcon name="notification" size="md" />
                  </Badge>
                </div>
              </>
            ) : (
              <div className="d-flex align-items-center mr-4">
                <Link
                  to={`${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`}
                  className={classes.authLink}
                >
                  Sign In
                </Link>
                <div className={classes.verticalDivider} />
                <Link
                  to={`${pathname}?${routesByName.auth.key}=${routesByName.auth.signUp}`}
                  className={classes.authLink}
                >
                  Register
                </Link>
              </div>
            )}
            <IconButton onClick={handleOpenMenu}>
              <MoreHorizIcon />
            </IconButton>
          </div>
        </div>
        <SectionsNavigation />
      </div>
      <AuthModal action={authModal} onClose={handleAuthModalClose} />
      <NotificationPopover
        anchorEl={notificationAnchorEl}
        open={Boolean(notificationAnchorEl)}
        onClose={handleCloseNotificationModal}
      />
      <DialogsPopover
        anchorEl={dialogsAnchorEl}
        open={Boolean(dialogsAnchorEl)}
        onClose={handleCloseDialogsModal}
      />
      <HeaderMenu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleCloseMenu}
      />
    </>
  );
};

Header.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  userAvatarThumbUrl: PropTypes.string.isRequired,
  user: userShape,
};

Header.defaultProps = {
  user: undefined,
};

const mapStateToProps = ({ auth: { user }, app: { config } }) => ({
  authenticated: Boolean(user),
  user,
  userAvatarThumbUrl: getUserAvatarThumbUrl(config),
});

export default connect(mapStateToProps)(Header);
