import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { inspirationImageShape } from 'modules/inspirationImage/propTypes';
import { getInspirationImgUrl } from 'modules/app/store/selectors';
import buildResourceUrl from 'utils/buildResourceUrl';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import createLookBoardClasses from 'modules/curateTheLook/createLookBoard/CreateLookBoard.module.scss';
import selectedImageBlockClasses from 'modules/curateTheLook/createLookBoard/components/SelectedImageBlock/SelectedImageBlock.module.scss';
import classes from './CreateRequest.module.scss';

const MainArea = ({ inspirationImageUrl, selectedImage, onOpenPreview }) => {
  const title = useMemo(
    () =>
      !selectedImage
        ? 'Select an Inspiration Image or Product to continue'
        : 'To proceed with this Inspiration Image press "Next"',
    [selectedImage]
  );

  const imgUrl = useMemo(
    () =>
      selectedImage &&
      buildResourceUrl(
        inspirationImageUrl.small,
        selectedImage.media.userId,
        selectedImage.media.hash
      ),
    [inspirationImageUrl, selectedImage]
  );

  const imgUrlDetail = useMemo(
    () =>
      selectedImage &&
      buildResourceUrl(
        inspirationImageUrl.medium,
        selectedImage.media.userId,
        selectedImage.media.hash
      ),
    [inspirationImageUrl, selectedImage]
  );

  const handleOpenPreview = useCallback(() => {
    onOpenPreview(imgUrlDetail);
  }, [onOpenPreview, imgUrlDetail]);

  return (
    <div className={createLookBoardClasses.mainAreaWrapper}>
      <div className="d-flex flex-column">
        <p className="font-title text-capitalize mb-1" style={{ fontSize: 30 }}>
          {title}
        </p>
        <div className={createLookBoardClasses.processDescription}>
          <p className="primary-color font-semi-bold">Request the Look Here:</p>
          <p>
            <b>Step 1.</b> Select you Inspiration Image
          </p>
          <p>
            <b>Step 2.</b> Select Request Type at the right
          </p>
          <p>
            <b>Step 3.</b> Add Request Details on next page
          </p>
        </div>
        <div className={classes.imageContainer}>
          {selectedImage ? (
            <div className="position-relative">
              <IconButton
                className={selectedImageBlockClasses.selectedImageZoom}
                color="secondary"
                size="sm"
                onClick={handleOpenPreview}
              >
                <SpriteIcon name="loupe" size="sm" />
              </IconButton>
              <img className="mw-100" src={imgUrl} alt="" />
            </div>
          ) : (
            <div>
              Select Room Image or Furnishing/Fixture <br /> to Request the Look
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

MainArea.propTypes = {
  inspirationImageUrl: PropTypes.shape({
    small: PropTypes.string.isRequired,
    medium: PropTypes.string.isRequired,
  }).isRequired,
  selectedImage: inspirationImageShape,
  onOpenPreview: PropTypes.func.isRequired,
};

MainArea.defaultProps = {
  selectedImage: null,
};

const mapStateToProps = ({
  app: { config },
  requestTheLook: {
    requestData: { inspirationImageId },
  },
  inspirationImage: { library },
}) => ({
  inspirationImageUrl: getInspirationImgUrl(config),
  selectedImage: library[inspirationImageId],
});

export default connect(mapStateToProps)(MainArea);
