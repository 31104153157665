import React from 'react';
import PropTypes from 'prop-types';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import classes from './SquareModal.module.scss';

const SquareModal = ({
  open,
  onClose,
  title,
  description,
  children,
  ...rest
}) => (
  <BasicModal
    open={open}
    onClose={onClose}
    fullWidth
    maxWidth="md"
    classes={{ root: classes.root }}
    {...rest}
  >
    <div className="flex-center flex-column text-center">
      <h2 className={classes.title}>{title}</h2>
      {description && <p className={classes.description}>{description}</p>}
    </div>
    {children}
  </BasicModal>
);

SquareModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
};

SquareModal.defaultProps = {
  description: null,
};

export default SquareModal;
