import React from 'react';
import { requestSuccessExamplesConfig } from 'modules/requestTheLook/constants';
import Card from 'components/Card/Card';
import ImagesTable from 'components/ImagesTable/ImagesTable';
import classes from './AsideBanner.module.scss';

const AsideBanner = () => (
  <aside className={classes.root}>
    <div className={classes.info}>
      <div className={classes.infoContent}>
        <h3 className={`${classes.title} mb-3`}>Getting the Look</h3>
        <ul className={classes.list}>
          <li>
            Your Request will be matched with decor lovers who specialize in
            your style.
          </li>
          <li>
            You will receive recommendations in the form of Look Boards in the
            coming days & weeks.
          </li>
          <li>
            If our users are unable to find matching items, you will be
            informed.
          </li>
        </ul>
      </div>
    </div>
    <div className="px-2 py-5">
      <div className="flex-center">
        <h4 className={`${classes.dashedTitle}`}>Look Board Examples</h4>
      </div>
      <div className="d-flex">
        {requestSuccessExamplesConfig.map(({ id, title, images }) => (
          <div key={id} className="col-6">
            <Card
              classes={{
                root: classes.cardRoot,
                content: classes.cardContent,
                title: classes.cardTitle,
              }}
              title={title}
            >
              <ImagesTable imgUrls={images} />
            </Card>
          </div>
        ))}
      </div>
    </div>
  </aside>
);

export default AsideBanner;
