import PropTypes from 'prop-types';

export const slidesArray = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    imgUrl: PropTypes.string,
    text: PropTypes.string,
    name: PropTypes.string,
  })
);
