import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import {
  navLinksConfig,
  ambassadorNavLinksConfig,
  infoMenuConfig,
  ambassadorInfoMenuItemsConfig,
  getStartedSidebarConfig,
} from 'modules/dashboard/constants';
import AmbassadorRoute from 'components/AmbassadorRoute';
import SideNav from 'modules/layout/SideNav/SideNav';
import CustomerSupportBanner from 'modules/account/components/CustomerSupportBanner/CustomerSupportBanner';
import MyImagesContainer from 'modules/dashboard/myImages/MyImagesContainer';
import AmbassadorManagement from 'modules/dashboard/ambassadorManagement/AmbassadorManagement';
import AmbassadorManagementSidebar from 'modules/dashboard/ambassadorManagement/AmbassadorManagementSidebar';
import TipsBanner from 'modules/dashboard/components/TipsBanner/TipsBanner';
import classes from 'modules/account/Account.module.scss';
import MyLookBoardsContainer from 'modules/dashboard/myLookBoards/MyLookBoardsContainer';

const DashboardContainer = ({ isAmbassador }) => (
  <div className="flex-fill d-flex">
    <SideNav
      isAmbassador={isAmbassador}
      navLinks={navLinksConfig}
      ambassadorNavLinks={ambassadorNavLinksConfig}
      infoMenuConfig={infoMenuConfig}
      ambassadorInfoMenuItems={ambassadorInfoMenuItemsConfig}
    />
    <div className={classes.routeContainer}>
      <Switch>
        <Redirect
          exact
          from={routesByName.dashboard.index}
          to={routesByName.dashboard.images}
        />
        <AmbassadorRoute path={routesByName.dashboard.ambassadorManagement}>
          <AmbassadorManagement />
        </AmbassadorRoute>
        <AmbassadorRoute path={routesByName.dashboard.ambassadorShowcase}>
          <h1 className="title">Setup or Edit Showcase Page</h1>
        </AmbassadorRoute>
        <AmbassadorRoute path={routesByName.dashboard.ambassadorTeam}>
          <h1 className="title">My Nominated Ambassador Team</h1>
        </AmbassadorRoute>
        <Route path={routesByName.dashboard.images}>
          <MyImagesContainer />
        </Route>
        <Route path={routesByName.dashboard.lookBoards}>
          <MyLookBoardsContainer />
        </Route>
        <Route path={routesByName.dashboard.requests}>
          <div>My Requests</div>
        </Route>
        <Route path={routesByName.dashboard.community}>
          <div>Community</div>
        </Route>
        <Route path={routesByName.dashboard.messages}>
          <div>Messages</div>
        </Route>
      </Switch>
    </div>
    <div className={classes.rightSection}>
      <AmbassadorRoute path={routesByName.dashboard.ambassadorManagement}>
        <div className="mb-4">
          <AmbassadorManagementSidebar config={getStartedSidebarConfig} />
        </div>
        <div className="mb-4">
          <TipsBanner />
        </div>
      </AmbassadorRoute>
      <div className={classes.bannersContainer}>
        <CustomerSupportBanner />
      </div>
    </div>
  </div>
);

DashboardContainer.propTypes = {
  isAmbassador: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ auth: { user } }) => ({
  isAmbassador: Boolean(user?.ambassador),
});

export default connect(mapStateToProps)(DashboardContainer);
