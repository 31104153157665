import React, { useCallback, useState } from 'react';
import HeaderThumbnail from 'modules/layout/Header/HeaderThumbnail';
import AmbassadorThumbnail from 'components/userThumbnails/AmbassadorThumbnail';
import CommunityCard from 'components/userThumbnails/CommunityCard/CommunityCard';
import ContactThumbnail from 'components/userThumbnails/ContactThumbnail/ContactThumbnail';
import NomineeThumbnail from 'components/userThumbnails/NomineeThumbnail/NomineeThumbnail';

const firstName = 'Sofi';
const lastName = 'Williams';
const imgUrl = 'https://picsum.photos/100';
const contact = {
  firstName: 'John',
  lastName: 'Doe',
  avatarUrl: 'https://picsum.photos/100',
  date: 'May 9, 2020',
};

const communityConfig = {
  1: {
    id: 1,
    firstName: 'John',
    lastName: 'Doe',
    avatarUrl: 'https://picsum.photos/100',
    followersCount: 250,
    followed: true,
    nominated: 1591004399599,
    registered: 1591005399599,
  },
  2: {
    id: 2,
    firstName: 'AndrewAndrew',
    lastName: 'WilliamsAndrewAndrewAndrew',
    avatarUrl: 'https://picsum.photos/100',
    followersCount: 3380,
    followed: false,
    nominated: 1591004399599,
    registered: 1591005399599,
  },
  3: {
    id: 3,
    firstName: 'John',
    lastName: 'Doe',
    avatarUrl: 'https://picsum.photos/100',
    followersCount: 76,
    nominated: 1591004399599,
    registered: undefined,
  },
};

const UserThumbnails = () => {
  const [community, setCommunity] = useState(communityConfig);

  const handleFollowChange = useCallback(
    ({ id, followed }) => {
      setCommunity({
        ...community,
        [id]: {
          ...community[id],
          followed,
        },
      });
    },
    [community]
  );
  return (
    <>
      <div className="d-flex justify-content-around align-items-center mb-5">
        <HeaderThumbnail
          firstName={firstName}
          lastName={lastName}
          src={imgUrl}
        />
        <AmbassadorThumbnail label="example@mail.com" src={imgUrl} />
      </div>
      <div className="row">
        {Object.values(community).map((user) => (
          <div className="col-4" key={user.id}>
            <CommunityCard user={user} onFollow={handleFollowChange} />
          </div>
        ))}
      </div>
      <div className="py-2">
        <ContactThumbnail user={contact} />
      </div>
      <div className="row">
        {Object.values(communityConfig).map(
          ({
            id,
            firstName: first,
            lastName: last,
            avatarUrl,
            registered,
            nominated,
          }) => (
            <div className="col-4 mb-2" key={id}>
              <NomineeThumbnail
                id={id}
                firstName={first}
                lastName={last}
                avatarUrl={avatarUrl}
                registered={registered}
                nominated={nominated}
              />
            </div>
          )
        )}
      </div>
    </>
  );
};

export default UserThumbnails;
