import React, { useState, useMemo, useCallback } from 'react';
import { Route, Link } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import {
  getTheLookInfoConfig,
  curateTheLookInfoConfig,
  requestTheLookInfoConfig,
} from 'modules/layout/Header/constants';
import SpriteIcon from 'components/ui/SpriteIcon';
import OverviewModal from 'modules/layout/Header/OverviewModal/OverviewModal';
import classes from './Header.module.scss';

const SectionsNavigation = () => {
  const [getTheLookModalOpen, setGetTheLookModalOpen] = useState(false);
  const [curateTheLookModalOpen, setCurateTheLookModalOpen] = useState(false);
  const [requestTheLookModalOpen, setRequestTheLookModalOpen] = useState(false);

  const getTheLookModalSettings = useMemo(
    () => ({
      modalTitle: 'Get The Look Overview',
      modalDescription:
        'In this section, you can Get the Look of your favorite room designs and home furnishing items. Plus, you can find coordinating items for products you love.',
    }),
    []
  );

  const requestTheLookModalSettings = useMemo(
    () => ({
      modalTitle: 'Request the Look Overview',
      modalDescription:
        'In this section, you can Request the Look of your favorite room images and home furnishing Items. You can also request complementary items to go with another item or ideas to style a room.',
    }),
    []
  );

  const handleOpenGetTheLookModal = useCallback(() => {
    setGetTheLookModalOpen(true);
  }, []);

  const handleCloseGetTheLookModal = useCallback(() => {
    setGetTheLookModalOpen(false);
  }, []);

  const handleOpenCurateTheLookModal = useCallback(() => {
    setCurateTheLookModalOpen(true);
  }, []);

  const handleCloseCurateTheLookModal = useCallback(() => {
    setCurateTheLookModalOpen(false);
  }, []);

  const handleOpenRequestTheLookModal = useCallback(() => {
    setRequestTheLookModalOpen(true);
  }, []);

  const handleCloseRequestTheLookModal = useCallback(() => {
    setRequestTheLookModalOpen(false);
  }, []);

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div className="flex-fill d-flex justify-content-center">
          <div className="position-relative">
            <Link
              to={routesByName.getTheLook.index}
              className={classes.navLink}
            >
              <span>Get</span> <span className="secondary-color">The Look</span>
            </Link>
            <Route path={routesByName.getTheLook.index}>
              <button
                onClick={handleOpenGetTheLookModal}
                className={classes.infoBtn}
                type="button"
              >
                <SpriteIcon className={classes.infoIcon} name="info" />
                Get The Look Overview
              </button>
            </Route>
          </div>
        </div>
        <div className={classes.navLinkDivider} />
        <div className="flex-fill d-flex justify-content-center">
          <div className="position-relative">
            <Link
              to={routesByName.curateTheLook.canvas}
              className={classes.navLink}
            >
              <span>Curate</span>{' '}
              <span className="secondary-color">The Look</span>
            </Link>
            <Route path={routesByName.curateTheLook.index}>
              <button
                onClick={handleOpenCurateTheLookModal}
                className={classes.infoBtn}
                type="button"
              >
                <SpriteIcon className={classes.infoIcon} name="info" />
                Curate The Look Overview
              </button>
            </Route>
          </div>
        </div>
        <div className={classes.navLinkDivider} />
        <div className="flex-fill d-flex justify-content-center">
          <div className="position-relative">
            <Link
              to={routesByName.requestTheLook.index}
              className={classes.navLink}
            >
              <span>Request</span>{' '}
              <span className="secondary-color">The Look</span>
            </Link>
            <Route path={routesByName.requestTheLook.index}>
              <button
                onClick={handleOpenRequestTheLookModal}
                className={classes.infoBtn}
                type="button"
              >
                <SpriteIcon className={classes.infoIcon} name="info" />
                Request The Look Overview
              </button>
            </Route>
          </div>
        </div>
      </div>
      <OverviewModal
        config={getTheLookInfoConfig}
        open={getTheLookModalOpen}
        onClose={handleCloseGetTheLookModal}
        {...getTheLookModalSettings}
      />
      <OverviewModal
        config={curateTheLookInfoConfig}
        open={curateTheLookModalOpen}
        onClose={handleCloseCurateTheLookModal}
        modalTitle="Curate The Look Overview"
      />
      <OverviewModal
        config={requestTheLookInfoConfig}
        open={requestTheLookModalOpen}
        onClose={handleCloseRequestTheLookModal}
        {...requestTheLookModalSettings}
      />
    </>
  );
};

export default SectionsNavigation;
