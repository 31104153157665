import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SpriteIcon from 'components/ui/SpriteIcon';
import roomTypesOptionShape from 'components/PreferenceQuiz/propTypes/roomTypesOptionShape';
import classes from 'components/PreferenceQuiz/RoomTypeOption/RoomTypeOption.module.scss';

const RoomTypeOption = ({
  option: { id, name, icon },
  currentValue,
  onSelect,
  className,
}) => {
  const selected = currentValue.includes(id);
  const handleSelect = useCallback(() => {
    onSelect(id);
  }, [onSelect, id]);

  return (
    <div
      className={clsx(
        'd-inline-flex flex-column align-items-center cursor-pointer',
        className,
        { [classes.selected]: selected }
      )}
      onClick={handleSelect}
    >
      <div
        dangerouslySetInnerHTML={{ __html: icon }}
        className={classes.icon}
      />
      <p className="d-flex align-items-center">
        <SpriteIcon
          name={selected ? 'like-filled' : 'like'}
          size="sm"
          className="secondary-color mr-1"
        />
        <span className={classes.title}>{name}</span>
      </p>
    </div>
  );
};

RoomTypeOption.propTypes = {
  option: roomTypesOptionShape.isRequired,
  onSelect: PropTypes.func.isRequired,
  currentValue: PropTypes.arrayOf(PropTypes.number).isRequired,
  className: PropTypes.string,
};

RoomTypeOption.defaultProps = {
  className: '',
};

export default RoomTypeOption;
