import React from 'react';
import PropTypes from 'prop-types';
import SquareModal from 'components/modals/SquareModal/SquareModal';
import DiscoverContent from 'modules/home/components/DiscoverContent/DiscoverContent';

const DiscoverModal = ({ open, onClose }) => {
  const settings = {
    title: 'Discover Your Look',
  };

  return (
    <SquareModal open={open} onClose={onClose} {...settings}>
      <DiscoverContent />
    </SquareModal>
  );
};

DiscoverModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DiscoverModal;
