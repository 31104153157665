import currentUserService from 'modules/currentUser/currentUserService';
import { AUTH_LOGIN, AUTH_LOGOUT } from 'modules/auth/store/constants';
import authService from 'modules/auth/authService';

export const updateUserProfileAction = (profileData) => async (dispatch) => {
  const updatedUser = await currentUserService.updateProfile(profileData);
  dispatch({ type: AUTH_LOGIN, payload: updatedUser });
};

export const deactivateAccountAction = () => async (dispatch) => {
  await currentUserService.deactivateUser();
  dispatch({ type: AUTH_LOGOUT });
  authService.clearAuthTokens();
};

export const updateUserPreferencesAction = (preferences) => (
  dispatch,
  getState
) => {
  const {
    auth: { user },
  } = getState();
  const updatedUser = {
    ...user,
    preferences,
  };
  dispatch({ type: AUTH_LOGIN, payload: updatedUser });
};
