import {
  errorMessages,
  requestModalFields,
} from 'modules/ambassadors/components/RequestModal/constants';

const requestModalValidator = ({ [requestModalFields.message]: message }) => {
  const errors = {};

  if (!message) {
    errors.message = errorMessages.required;
  }

  return errors;
};

export default requestModalValidator;
