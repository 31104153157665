import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance';

const urlPrefix = '/v1';

const currentUserService = {
  getCurrentUser() {
    return AuthorizedAxiosInstance.get(`${urlPrefix}/user`).then(
      ({ result: user }) => user
    );
  },

  getUserById(userId) {
    return AuthorizedAxiosInstance.get(`${urlPrefix}/user/${userId}`).then(
      ({ result: user }) => user
    );
  },

  updateProfile(profileData) {
    return AuthorizedAxiosInstance.put(`${urlPrefix}/user`, profileData).then(
      ({ result: user }) => user
    );
  },

  updateProfession(profession) {
    return AuthorizedAxiosInstance.put(`${urlPrefix}/user/profession`, {
      profession,
    });
  },

  updateStyles(styles) {
    return AuthorizedAxiosInstance.put(`${urlPrefix}/user/styles`, { styles });
  },

  updateSubStyles(subStyles) {
    return AuthorizedAxiosInstance.put(`${urlPrefix}/user/sub-styles`, {
      subStyles,
    });
  },

  updateRoomTypes(roomTypes) {
    return AuthorizedAxiosInstance.put(`${urlPrefix}/user/room-types`, {
      roomTypes,
    });
  },

  updateColors(colors) {
    return AuthorizedAxiosInstance.put(`${urlPrefix}/user/colors`, { colors });
  },

  updatePriceRange(priceRange) {
    return AuthorizedAxiosInstance.put(`${urlPrefix}/user/price-range`, {
      priceRangeId: priceRange,
    });
  },

  updateAvatar(mediaId) {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/user/update-avatar`, {
      mediaId,
    });
  },

  deactivateUser() {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/user/deactivate`);
  },
};

export default currentUserService;
