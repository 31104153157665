import extendAxiosInstance from 'libs/axios/extendAxiosInstance';
import BaseAxiosInstance from 'libs/axios/BaseAxiosInstance';
import authService from 'modules/auth/authService';
import { AUTH_LOGOUT } from 'modules/auth/store/constants';
// import { userIdPlaceholder } from 'modules/CurrentUser/CurrentUserService';

const AuthorizedAxiosInstance = extendAxiosInstance(BaseAxiosInstance, {});

// const prepareUrl = (url, user) => {
//   if (!user) return url;
//   return url.includes(userIdPlaceholder) ? url.replace(userIdPlaceholder, user.id) : url;
// };

// eslint-disable-next-line no-unused-vars
export const setUpAuthInterceptorsAction = () => (dispatch, getState) => {
  AuthorizedAxiosInstance.interceptors.request.use(
    async (config) => {
      // const { currentUser } = getState();
      // const url = prepareUrl(config.url, currentUser);
      const headers = { ...config.headers };
      const authToken = authService.getAccessToken();

      if (!headers.Authorization && authToken) {
        headers.Authorization = authToken;
      }

      if (!headers.Authorization) {
        delete headers.Authorization;
      }

      return {
        ...config,
        headers,
        // url,
      };
    },
    (error) => Promise.reject(error)
  );

  AuthorizedAxiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response && error.response.status === 401) {
        try {
          await authService.refreshToken();
          return AuthorizedAxiosInstance.request({
            ...error.config,
            headers: {
              ...(error.config.headers || {}),
              Authorization: authService.getAccessToken(),
            },
          });
        } catch (e) {
          /*
           * In this case upper scope will receive message from first request eg. `Unauthenticated.`
           */
        }

        dispatch({ type: AUTH_LOGOUT });
        authService.clearAuthTokens();
      }

      throw error;
    }
  );
};

export default AuthorizedAxiosInstance;
