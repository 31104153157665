import React from 'react';
import { requestSuccessBannerConfig } from 'modules/requestTheLook/constants';
import womanImg from 'assets/img/request-the-look-page/woman.png';
import classes from './SuccessBanner.module.scss';

const SuccessBanner = () => (
  <div className={classes.root}>
    <img src={womanImg} alt="" />
    <div className={classes.steps}>
      <h2 className={`${classes.title} text-uppercase mb-5 pl-5`}>
        What Happens Next
      </h2>
      <div className="d-flex">
        {requestSuccessBannerConfig.map(({ id, title, imgUrl }) => (
          <div key={id} className={`${classes.step} text-center`}>
            <img className="mb-2" src={imgUrl} alt="" />
            <h4 className={classes.stepTitle}>{title}</h4>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default SuccessBanner;
