import React from 'react';
import clsx from 'clsx';
import getSectionImg from 'assets/img/home-page/how-it-works/get.png';
import requestSectionImg from 'assets/img/home-page/how-it-works/request.png';
import curateSectionImg from 'assets/img/home-page/how-it-works/curate.png';
import compareSectionImg from 'assets/img/home-page/how-it-works/compare.png';
import shopSectionImg from 'assets/img/home-page/how-it-works/shop.png';
import classes from './HowItWorks.module.scss';

const HowItWorks = () => (
  <section className={classes.root}>
    <div className="d-flex align-items-center text-center flex-column">
      <h2 className={classes.title}>How it Works</h2>
      <p className={classes.description}>
        Now It’s easy to Get the Look you Love! Simply select your favorite
        images from our database or Pin <br />
        one from the Web, and you’ll receive ideas from our Personal Shoppers at
        your Price Point. Then use <br />
        our tools to visualize aifferent items together and get great deals on
        the ones you want
      </p>
    </div>
    <div className="d-flex align-items-center flex-column">
      <div
        id="get-the-look"
        className={clsx([classes.section, classes.sectionGet])}
      >
        <img src={getSectionImg} alt="" />
        <div className={classes.sectionAbout}>
          <h3 className={classes.sectionTitle}>
            Get <span>the Look</span>
          </h3>
          <div className={classes.sectionDescription}>
            Browse popular curated images <br />
            via Look Boards and Products
            <br />
            Streams
          </div>
        </div>
      </div>
      <div
        id="request-the-look"
        className={clsx([classes.section, classes.sectionRequest])}
      >
        <div className={classes.sectionAbout}>
          <h3 className={classes.sectionTitle}>
            Request <span>the Look</span>
          </h3>
          <div className={classes.sectionDescription}>
            Get free help finding the look at <br />
            your price point from our <br />
            Ambassadors - Personal <br />
            shoppers who love your style!
          </div>
        </div>
        <img src={requestSectionImg} alt="" />
      </div>
      <div
        id="curate-the-look"
        className={clsx([classes.section, classes.sectionCurate])}
      >
        <img src={curateSectionImg} alt="" />
        <div className={classes.sectionAbout}>
          <h3 className={classes.sectionTitle}>
            Curate <span>the Look</span>
          </h3>
          <div className={classes.sectionDescription}>
            Choose from thousands of products in <br />
            our database to curate the look of your <br />
            favorite items yourself!
          </div>
        </div>
      </div>
      <div
        id="compare-the-look"
        className={clsx([classes.section, classes.sectionCompare])}
      >
        <div className={classes.sectionAbout}>
          <h3 className={classes.sectionTitle}>
            Compare <span>the Look</span>
          </h3>
          <div className={classes.sectionDescription}>
            Use our Mix & Match tool to easily <br />
            visualize your favorite items <br />
            together
          </div>
        </div>
        <img src={compareSectionImg} alt="" />
      </div>
      <div
        id="shop-the-look"
        className={clsx([classes.section, classes.sectionShop])}
      >
        <img src={shopSectionImg} alt="" />
        <div className={classes.sectionAbout}>
          <h3 className={classes.sectionTitle}>
            Shop <span>the Look</span>
          </h3>
          <div className={classes.sectionDescription}>
            Get the Look you Love at your price point <br />
            and enjoy special offers from in Network <br />
            vendors.
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default HowItWorks;
