import React from 'react';
import FinalFormInputWrapper from 'components/finalFormWrappers/FinalFormInputWrapper';
import TextInput from 'components/inputs/TextInput/TextInput';

const TextInputWrapper = (props) => (
  <FinalFormInputWrapper {...props} component={TextInput} />
);

TextInputWrapper.propTypes = {};

TextInputWrapper.defaultProps = {};

export default TextInputWrapper;
