import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SpriteIcon from 'components/ui/SpriteIcon';
import { stepTwoTabKeys } from 'modules/curateTheLook/constants';
import classes from './TabSwitch.module.scss';

const TabSwitch = ({ activeTab, onChange }) => (
  <div className={classes.wrapper}>
    <div className={classes.root}>
      <div
        className={clsx(classes.tab, {
          [classes.active]: activeTab === stepTwoTabKeys.templates,
        })}
        data-tab={stepTwoTabKeys.templates}
        onClick={onChange}
      >
        <SpriteIcon name="layout" size="lg" />
        <span
          className={clsx(classes.tabTitle, {
            'font-semi-bold': activeTab === stepTwoTabKeys.templates,
          })}
        >
          Templates
        </span>
      </div>
      <div
        className={clsx(classes.tab, {
          [classes.active]: activeTab === stepTwoTabKeys.products,
        })}
        data-tab={stepTwoTabKeys.products}
        onClick={onChange}
      >
        <SpriteIcon name="living-room" size="lg" />
        <span
          className={clsx(classes.tabTitle, {
            'font-semi-bold': activeTab === stepTwoTabKeys.products,
          })}
        >
          Products
        </span>
      </div>
    </div>
  </div>
);

TabSwitch.propTypes = {
  activeTab: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TabSwitch;
