import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { routesByName } from 'constants/routes';
import mediaTypes from 'constants/mediaTypes';
import {
  ownershipValuesMap,
  permissionsValuesMap,
  createImageFieldKeys,
} from 'modules/pinMedia/constants';
import mediaService from 'modules/media/mediaService';
import inspirationImageService from 'modules/inspirationImage/inspirationImageService';
import productService from 'modules/product/productService';
import errorToastr from 'libs/toastr/errorToastr';
import successToastr from 'libs/toastr/successToastr';
import { transformMediaArrayToMap } from 'modules/pinMedia/helpers';
import { toggleAddImageModalAction } from 'modules/layout/store/actions';
import FloatActionsBlock from 'modules/pinMedia/FloatActionsBlock/FloatActionsBlock';
import AddImageModal from 'modules/pinMedia/AddImageModal/AddImageModal';
import ChooseMethodModal from 'modules/pinMedia/ChooseMethodModal/ChooseMethodModal';
import UploadImageModal from 'modules/pinMedia/UploadImageModal/UploadImageModal';
import PinMediaModal from 'modules/pinMedia/PinMediaModal/PinMediaModal';
import SaveImageModal from 'modules/pinMedia/SaveImageModal/SaveImageModal';
import ParsedMediaModal from 'modules/pinMedia/ParsedMediaModal/ParsedMediaModal';
import useCustomEventListener from 'hooks/useCustomEventListener';
import { OPEN_PIN_PRODUCT_MODAL } from 'constants/customEventNames';

const PinMediaContainer = ({
  authenticated,
  addImageModalOpen,
  toggleAddImageModal,
}) => {
  const { pathname } = useLocation();
  const history = useHistory();

  const [newImage, setNewImage] = useState(null);
  const [currentMediaType, setCurrentMediaType] = useState(
    mediaTypes.inspirationImage
  );
  const [parsedMediaList, setParsedMediaList] = useState(null);
  const [parsedProduct, setParsedProduct] = useState(null);

  const [chooseMethodModalOpen, setChooseMethodModalOpen] = useState(false);
  const [uploadImageModalOpen, setUploadImageModalOpen] = useState(false);
  const [pinMediaModalOpen, setPinMediaModalOpen] = useState(false);
  const [saveImageModalOpen, setSaveImageModalOpen] = useState(false);
  const [parsedMediaModalOpen, setParsedMediaModalOpen] = useState(false);

  useCustomEventListener(
    OPEN_PIN_PRODUCT_MODAL,
    ({ detail: { mediaType } }) => {
      setCurrentMediaType(mediaType);
      setPinMediaModalOpen(true);
    }
  );

  const handleAddImageModalClose = useCallback(() => {
    toggleAddImageModal(false);
  }, [toggleAddImageModal]);

  const handleChooseMethodModalOpen = useCallback(() => {
    if (!authenticated) {
      const redirectUrl = `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`;
      history.push(redirectUrl);
      return;
    }
    setChooseMethodModalOpen(true);
  }, [pathname, history, authenticated]);

  const handleChooseMethodModalClose = useCallback(() => {
    setChooseMethodModalOpen(false);
  }, []);

  const handleUploadImageModalOpen = useCallback(() => {
    setUploadImageModalOpen(true);
  }, []);

  const handleUploadImageModalClose = useCallback(() => {
    setUploadImageModalOpen(false);
  }, []);

  const handlePinMediaModalOpen = useCallback(({ currentTarget }) => {
    const mediaType = currentTarget.getAttribute('data-media-type');
    setCurrentMediaType(mediaType);
    setPinMediaModalOpen(true);
  }, []);

  const handlePinMediaModalClose = useCallback(() => {
    setPinMediaModalOpen(false);
  }, []);

  const handleSaveImageModalClose = useCallback(() => {
    setSaveImageModalOpen(false);
  }, []);

  const handleParsedMediaModalClose = useCallback(() => {
    setParsedMediaModalOpen(false);
    setParsedMediaList(null);
  }, []);

  const handleAcceptImage = useCallback((imgData) => {
    setNewImage(imgData);
    setUploadImageModalOpen(false);
    setSaveImageModalOpen(true);
  }, []);

  const parseImages = useCallback(async (sourceUrl) => {
    try {
      const { result: images } = await mediaService.parseImages(sourceUrl);
      const preparedMediaList = transformMediaArrayToMap(images);

      setParsedMediaList(preparedMediaList);
      setPinMediaModalOpen(false);
      setParsedMediaModalOpen(true);
    } catch (e) {
      errorToastr('Error', e.generalError);
    }
  }, []);

  const parseProduct = useCallback(async (sourceUrl) => {
    try {
      const {
        result: { id, currency, description, images, name, price, url },
      } = await mediaService.parseProduct(sourceUrl);

      const productInfo = {
        id,
        name,
        url,
        price: `${price} ${currency}`,
        description,
      };
      const preparedMediaList = transformMediaArrayToMap(images);

      setParsedProduct(productInfo);
      setParsedMediaList(preparedMediaList);
      setPinMediaModalOpen(false);
      setParsedMediaModalOpen(true);
    } catch (e) {
      errorToastr('Error', e.generalError);
    }
  }, []);

  const handleParseUrl = useCallback(
    async ({ url }) => {
      if (currentMediaType === mediaTypes.inspirationImage) {
        parseImages(url);
      } else {
        parseProduct(url);
      }
    },
    [currentMediaType, parseImages, parseProduct]
  );

  const createInspirationImage = useCallback(async (mediaId, formValues) => {
    const {
      [createImageFieldKeys.ownership]: ownership,
      [createImageFieldKeys.permissions]: permissions,
    } = formValues;

    const permissionsValue =
      ownership === ownershipValuesMap.thirdPartyOwned
        ? permissionsValuesMap.open
        : permissions;

    await inspirationImageService.createInspirationImage({
      ...formValues,
      mediaId,
      permissions: permissionsValue,
    });
  }, []);

  const createProduct = useCallback(async (mediaId, formValues) => {
    const { id, name, itemClassId, description, size } = formValues;
    await productService.createProduct({
      mediaId,
      id,
      name,
      itemClassId,
      description,
      size,
    });
  }, []);

  const handleSaveImage = useCallback(
    async (formValues) => {
      try {
        const formData = new FormData();

        formData.append('type', mediaTypes.inspirationImage);
        formData.append('file', newImage.file);

        const {
          result: { id: mediaId },
        } = await mediaService.uploadMedia(formData);

        await createInspirationImage(mediaId, formValues);

        setNewImage(null);
        setSaveImageModalOpen(false);
        successToastr('Success', 'New inspiration image was created!');
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
    },
    [newImage, createInspirationImage]
  );

  const handleCreateParsedMedia = useCallback(
    async ({ mediaUrl, formValues }) => {
      try {
        const {
          result: { id: mediaId },
        } = await mediaService.uploadMediaByUrl(currentMediaType, mediaUrl);

        if (currentMediaType === mediaTypes.inspirationImage) {
          await createInspirationImage(mediaId, formValues);
        } else {
          await createProduct(mediaId, formValues);
        }

        setParsedProduct(null);
        setParsedMediaList(null);
        setParsedMediaModalOpen(false);
        successToastr('Success', 'New media was created!');
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
    },
    [currentMediaType, createInspirationImage, createProduct]
  );

  return (
    <>
      <FloatActionsBlock
        onOpenChooseMethodModal={handleChooseMethodModalOpen}
      />
      <AddImageModal
        open={addImageModalOpen}
        onClose={handleAddImageModalClose}
        onImageUpload={handleUploadImageModalOpen}
        onMediaPin={handlePinMediaModalOpen}
      />
      <ChooseMethodModal
        open={chooseMethodModalOpen}
        onClose={handleChooseMethodModalClose}
        onImageUpload={handleUploadImageModalOpen}
        onMediaPin={handlePinMediaModalOpen}
      />
      <UploadImageModal
        fullWidth
        maxWidth="md"
        open={uploadImageModalOpen}
        onClose={handleUploadImageModalClose}
        onAcceptImg={handleAcceptImage}
      />
      <PinMediaModal
        fullWidth
        maxWidth="md"
        open={pinMediaModalOpen}
        onClose={handlePinMediaModalClose}
        onParseUrl={handleParseUrl}
      />
      <SaveImageModal
        open={saveImageModalOpen}
        onClose={handleSaveImageModalClose}
        imgUrl={newImage && newImage.url}
        onSaveImg={handleSaveImage}
      />
      <ParsedMediaModal
        open={parsedMediaModalOpen}
        onClose={handleParsedMediaModalClose}
        mediaType={currentMediaType}
        mediaList={parsedMediaList || {}}
        initialFormValues={parsedProduct || {}}
        onCreateMedia={handleCreateParsedMedia}
      />
    </>
  );
};

PinMediaContainer.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  addImageModalOpen: PropTypes.bool.isRequired,
  toggleAddImageModal: PropTypes.func.isRequired,
};

const mapSateToProps = ({ auth: { user }, layout: { addImageModal } }) => ({
  authenticated: Boolean(user),
  addImageModalOpen: addImageModal,
});

const mapDispatchToProps = {
  toggleAddImageModal: toggleAddImageModalAction,
};

export default connect(mapSateToProps, mapDispatchToProps)(PinMediaContainer);
