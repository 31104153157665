import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, ReactReduxContext } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import 'styles/index.scss';
import appConfig from 'constants/appConfig';
import { ReactComponent as Sprite } from 'assets/sprite.svg';
import store, { history } from 'store/store';
import App from 'App';
import theme from 'materialUi/theme';
import ErrorBoundary from 'components/ErrorBoundary';

ReactDOM.render(
  <Provider store={store} context={ReactReduxContext}>
    <ConnectedRouter history={history} context={ReactReduxContext}>
      <ErrorBoundary>
        <StylesProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Sprite />
            <App />
          </ThemeProvider>
        </StylesProvider>
      </ErrorBoundary>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

if (appConfig.isDev && module.hot) {
  module.hot.accept();
}
