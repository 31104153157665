import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@material-ui/core/Backdrop';
import PinImage from 'assets/img/pin-image-img.svg';
import PinProduct from 'assets/img/pin-product-img.svg';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import mediaTypes from 'constants/mediaTypes';
import classes from './ChooseMethodModal.module.scss';

const ChooseMethodModal = ({ open, onClose, onImageUpload, onMediaPin }) => (
  <Backdrop className={classes.backdrop} open={open} onClick={onClose}>
    <div className={`${classes.pinCard} mr-4`}>
      <img src={PinImage} alt="" className={classes.icon} />
      <p className={`${classes.title} mb-3`}>
        <span>Add Image to use</span>
        <br />
        <b>as Inspiration Image</b>
      </p>
      <div className="d-flex">
        <IconButton
          size="lg"
          color="secondary"
          className="mr-2"
          onClick={onImageUpload}
        >
          <SpriteIcon name="image-upload" size="md" />
        </IconButton>
        <IconButton
          size="lg"
          color="secondary"
          data-media-type={mediaTypes.inspirationImage}
          onClick={onMediaPin}
        >
          <SpriteIcon name="pin-filled" size="md" />
        </IconButton>
      </div>
    </div>
    <div className={classes.pinCard}>
      <img src={PinProduct} alt="" className={classes.icon} />
      <p className={`${classes.title} mb-3`}>
        <span>Add Product Image</span>
        <br />
        <b>to use in Look Boards</b>
      </p>
      <div className="d-flex">
        <IconButton
          size="lg"
          color="secondary"
          data-media-type={mediaTypes.product}
          onClick={onMediaPin}
        >
          <SpriteIcon name="pin-filled" size="md" />
        </IconButton>
      </div>
    </div>
  </Backdrop>
);

ChooseMethodModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onImageUpload: PropTypes.func.isRequired,
  onMediaPin: PropTypes.func.isRequired,
};

export default ChooseMethodModal;
