import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance';

const urlPrefix = '/v1';

const mediaService = {
  uploadMedia(data) {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/media/upload`, data);
  },

  uploadMediaByUrl(type, url) {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/media/upload-url`, {
      type,
      url,
    });
  },

  parseImages(url) {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/media/parse-images`, {
      url,
    });
  },

  parseProduct(url) {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/media/parse-product`, {
      url,
    });
  },
};

export default mediaService;
