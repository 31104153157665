import { showProductsOptions } from 'modules/auth/signUp/constants';

export const transformFormValuesToRegisterData = (formValues) => {
  const { showProducts, terms } = formValues;

  return {
    ...formValues,
    showProducts: showProducts
      ? showProductsOptions.onlyMyCountry
      : showProductsOptions.all,
    terms: Number(Boolean(terms)),
  };
};

export default {
  transformFormValuesToRegisterData,
};
