import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import InspirationImage from 'components/imageThumbnails/InspirationImage/InspirationImage';
import OverlayLabel from 'components/imageThumbnails/OverlayLabel/OverlayLabel';
import classes from './PopupImage.module.scss';

const PopupImage = ({ overlayLabel, selected, ...rest }) => (
  <InspirationImage
    disableHover={selected}
    showStaticOverlay={selected}
    {...rest}
    staticOverlay={
      <div className={clsx('h-100 flex-center')}>
        <OverlayLabel
          text="selected image"
          labelClasses={{ label: 'font-semi-bold' }}
        />
      </div>
    }
    hoverOverlay={
      <div className="h-100 flex-center">
        <p className={classes.hoverLabel}>{overlayLabel}</p>
      </div>
    }
  />
);

PopupImage.propTypes = {
  overlayLabel: PropTypes.string.isRequired,
  selected: PropTypes.bool,
};

PopupImage.defaultProps = {
  selected: false,
};

export default PopupImage;
