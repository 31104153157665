const accountPrefix = '/account';
const dashboardPrefix = '/dashboard';
const getTheLookPrefix = '/get-the-look';
const curateTheLookPrefix = '/curate-the-look';
const requestTheLookPrefix = '/request-the-look';
const ambassadorPrefix = '/ambassador';

export const routesByName = {
  home: '/',
  auth: {
    key: 'auth',
    signIn: 'sign-in',
    signUp: 'sign-up',
    emailVerification: '/auth/email-verification/:userId/:token',
  },
  account: {
    index: accountPrefix,
    profile: `${accountPrefix}/profile`,
    orders: `${accountPrefix}/orders`,
    paymentDetails: `${accountPrefix}/payment-details`,
  },
  dashboard: {
    index: dashboardPrefix,
    images: `${dashboardPrefix}/images`,
    lookBoards: `${dashboardPrefix}/look-boards`,
    requests: `${dashboardPrefix}/requests`,
    community: `${dashboardPrefix}/community`,
    messages: `${dashboardPrefix}/messages`,
    ambassadorManagement: `${dashboardPrefix}/ambassador-management`,
    ambassadorShowcase: `${dashboardPrefix}/ambassador-showcase`,
    ambassadorTeam: `${dashboardPrefix}/ambassador-team`,
  },
  getTheLook: {
    index: getTheLookPrefix,
    details: (imageId = ':imageId') => `${getTheLookPrefix}/${imageId}`,
  },
  curateTheLook: {
    index: curateTheLookPrefix,
    canvas: `${curateTheLookPrefix}/canvas`,
    details: `${curateTheLookPrefix}/details`,
    submit: `${curateTheLookPrefix}/submit`,
    success: `${curateTheLookPrefix}/success`,
  },
  requestTheLook: {
    index: requestTheLookPrefix,
    details: `${requestTheLookPrefix}/details`,
    submit: `${requestTheLookPrefix}/submit`,
    success: `${requestTheLookPrefix}/success`,
  },
  ambassador: {
    index: ambassadorPrefix,
    faqs: `${ambassadorPrefix}/faqs`,
  },
  dialogs: '/dialogs',
  forums: '/forums',
  about: '/about',
  contact: '/contact',
  blog: '/blog',
  terms: '/terms',
  faqs: '/faqs',
  privacy: '/privacy',
  howItworks: '/how-it-works',
  getStartedNow: '/get-started-now',
  requestInvitation: '/request-an-invitation',
  roteWithParams(param = ':param') {
    return `/prefix/${param}`;
  },
  components: '/components',
};

export default {
  routesByName,
};
