import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LinearProgress from 'components/PreferenceQuiz/LinearProgress/LinearProgress';
import { stepsConfig } from 'components/PreferenceQuiz/preferenceQuizConfig';
import DescriptionStep from 'components/PreferenceQuiz/steps/DescriptionStep';
import StylesStep from 'components/PreferenceQuiz/steps/StylesStep';
import RoomTypeStep from 'components/PreferenceQuiz/steps/RoomTypeStep';
import ColorsStep from 'components/PreferenceQuiz/steps/ColorsStep';
import PriceStep from 'components/PreferenceQuiz/steps/PriceStep';
import classes from 'components/PreferenceQuiz/PreferenceQuiz.module.scss';
import decorLoverImgUrl from 'assets/img/preference-profession-decor-lover.png';
import designerImgUrl from 'assets/img/preference-profession-designer.png';
import onlineShopperImgUrl from 'assets/img/preference-profession-online-shopper.png';
import { updateUserPreferencesAction } from 'modules/currentUser/actions';
import errorToastr from 'libs/toastr/errorToastr';
import currentUserService from 'modules/currentUser/currentUserService';
import preferencesShape from 'components/PreferenceQuiz/propTypes/preferencesShape';
import priceRangesOptionShape from 'components/PreferenceQuiz/propTypes/priceRangesOptionShape';
import colorsOptionShape from 'components/PreferenceQuiz/propTypes/colorsOptionShape';
import { professionsMap } from 'modules/account/profileAndPreferences/preferences/constants';
import roomTypesOptionShape from 'components/PreferenceQuiz/propTypes/roomTypesOptionShape';
import stylesOptionShape from 'components/PreferenceQuiz/propTypes/stylesOptionShape';
import SubStylesStep from 'components/PreferenceQuiz/steps/SubStylesStep';
import { getSubStylesBySelectedStyles } from 'modules/app/store/selectors';

const descriptionOptions = [
  {
    id: 1,
    imgUrl: decorLoverImgUrl,
    title: 'Decor Lover',
    description: 'I love decorating and sharing decorating ideas with others.',
    icon: 'plants',
    value: professionsMap.decorLover.value,
  },
  {
    id: 2,
    imgUrl: designerImgUrl,
    title: 'Design Professional',
    description:
      'I am an interior designer or architect and can provide additional design services.',
    icon: 'interior-design',
    value: professionsMap.designProfessional.value,
  },
  {
    id: 3,
    imgUrl: onlineShopperImgUrl,
    title: 'Skilled Online Shopper',
    description:
      'I am a skilled online shopper and can help others Get the Look according to their specifications.',
    icon: 'monitor',
    value: professionsMap.onlineShopper.value,
  },
];

const PreferenceQuiz = ({
  currentPreferences,
  styleOptions,
  subStyleOptions,
  roomTypesOptions,
  colorsOptions,
  priceRangesOptions,
  onCancelEdit,
  updateUserPreferences,
}) => {
  const [currentStep, setCurrentStep] = useState(stepsConfig[0]);
  const [loading, setLoading] = useState(false);

  const getCurrentSubStyles = useMemo(() => {
    if (!currentPreferences.subStyles) {
      return null;
    }

    return Object.keys(currentPreferences.subStyles).map((subStyleId) =>
      Number.parseInt(subStyleId, 10)
    );
  }, [currentPreferences]);

  const goToNextStep = useCallback(() => {
    const nextStep = stepsConfig[currentStep.number];
    if (nextStep) {
      setCurrentStep(nextStep);
    }
  }, [currentStep]);

  const handleSaveProfession = useCallback(
    async (profession) => {
      setLoading(true);
      try {
        const {
          result: { preferences },
        } = await currentUserService.updateProfession(profession);
        updateUserPreferences(preferences);
        goToNextStep();
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
      setLoading(false);
    },
    [goToNextStep, updateUserPreferences]
  );

  const handleSaveStyles = useCallback(
    async (styles) => {
      setLoading(true);
      try {
        const {
          result: { preferences },
        } = await currentUserService.updateStyles(styles);
        updateUserPreferences(preferences);
        goToNextStep();
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
      setLoading(false);
    },
    [goToNextStep, updateUserPreferences]
  );

  const handleSaveSubStyles = useCallback(
    async (subStyles) => {
      setLoading(true);
      try {
        const preparedData = subStyles.reduce((accum, currValue) => {
          /* eslint-disable no-param-reassign */
          accum[currValue] = 1;

          return accum;
        }, {});
        const {
          result: { preferences },
        } = await currentUserService.updateSubStyles(preparedData);
        updateUserPreferences(preferences);
        goToNextStep();
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
      setLoading(false);
    },
    [goToNextStep, updateUserPreferences]
  );

  const handleSaveRoomTypes = useCallback(
    async (roomTypes) => {
      setLoading(true);
      try {
        const {
          result: { preferences },
        } = await currentUserService.updateRoomTypes(roomTypes);
        updateUserPreferences(preferences);
        goToNextStep();
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
      setLoading(false);
    },
    [goToNextStep, updateUserPreferences]
  );

  const handleSaveColors = useCallback(
    async (colors) => {
      setLoading(true);
      try {
        const {
          result: { preferences },
        } = await currentUserService.updateColors(colors);
        updateUserPreferences(preferences);
        goToNextStep();
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
      setLoading(false);
    },
    [goToNextStep, updateUserPreferences]
  );

  const handleSavePriceRange = useCallback(
    async (priceRange) => {
      setLoading(true);
      try {
        const {
          result: { preferences },
        } = await currentUserService.updatePriceRange(priceRange);
        updateUserPreferences(preferences);
        onCancelEdit();
      } catch (e) {
        setLoading(false);
        errorToastr('Error', e.generalError);
      }
    },
    [onCancelEdit, updateUserPreferences]
  );

  const getCurrentStep = useMemo(() => {
    switch (currentStep.number) {
      case 1:
        return (
          <DescriptionStep
            isLoading={loading}
            currentValue={currentPreferences.profession}
            options={descriptionOptions}
            onCancelEdit={onCancelEdit}
            onSaveProfession={handleSaveProfession}
          />
        );
      case 2:
        return (
          <StylesStep
            isLoading={loading}
            currentValue={currentPreferences.styles}
            options={styleOptions}
            onCancelEdit={onCancelEdit}
            onSaveStyles={handleSaveStyles}
          />
        );
      case 3:
        return (
          <SubStylesStep
            isLoading={loading}
            currentValue={getCurrentSubStyles}
            options={subStyleOptions}
            onCancelEdit={onCancelEdit}
            onSaveSubStyles={handleSaveSubStyles}
          />
        );
      case 4:
        return (
          <RoomTypeStep
            isLoading={loading}
            currentValue={currentPreferences.roomTypes}
            options={roomTypesOptions}
            onCancelEdit={onCancelEdit}
            onSaveRoomTypes={handleSaveRoomTypes}
          />
        );
      case 5:
        return (
          <ColorsStep
            isLoading={loading}
            currentValue={currentPreferences.colors}
            options={colorsOptions}
            onCancelEdit={onCancelEdit}
            onSaveColors={handleSaveColors}
          />
        );
      case 6:
        return (
          <PriceStep
            isLoading={loading}
            currentValue={currentPreferences.priceRange}
            options={priceRangesOptions}
            onCancelEdit={onCancelEdit}
            onSavePriceRange={handleSavePriceRange}
          />
        );
      default:
        return null;
    }
  }, [
    currentPreferences,
    getCurrentSubStyles,
    onCancelEdit,
    loading,
    currentStep,
    styleOptions,
    subStyleOptions,
    roomTypesOptions,
    colorsOptions,
    priceRangesOptions,
    handleSaveProfession,
    handleSaveStyles,
    handleSaveSubStyles,
    handleSaveRoomTypes,
    handleSaveColors,
    handleSavePriceRange,
  ]);

  return (
    <>
      <div className="row mb-2">
        <div className="col-4">
          <h2 className={classes.quizTitle}>
            {`Step ${currentStep.number}. ${currentStep.subtitle}?`}
          </h2>
        </div>
        <div className="col-8">
          <div>
            <div className="d-flex mb-2">
              {stepsConfig.map(({ number, title }) => (
                <p className={classes.stepTitle} key={number}>
                  <b>Step {number}</b>
                  <br />
                  <i>{title}</i>
                </p>
              ))}
            </div>
            <LinearProgress progress={currentStep.progress} />
          </div>
        </div>
      </div>
      <p className={`${classes.selectDescription} mb-1`}>
        {currentStep.selectDescription}
      </p>
      <div className={classes.stepContainer}>{getCurrentStep}</div>
    </>
  );
};

PreferenceQuiz.propTypes = {
  currentPreferences: preferencesShape.isRequired,
  styleOptions: PropTypes.arrayOf(stylesOptionShape).isRequired,
  subStyleOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  roomTypesOptions: PropTypes.arrayOf(roomTypesOptionShape).isRequired,
  colorsOptions: PropTypes.arrayOf(colorsOptionShape).isRequired,
  priceRangesOptions: PropTypes.arrayOf(priceRangesOptionShape).isRequired,

  updateUserPreferences: PropTypes.func.isRequired,
  onCancelEdit: PropTypes.func.isRequired,
};

const mapSateToProps = ({
  app: {
    enums: { styles, subStyles, roomTypes, colors, priceRanges },
  },
  auth: {
    user: { preferences },
  },
}) => ({
  styleOptions: Object.values(styles),
  subStyleOptions: getSubStylesBySelectedStyles(
    styles,
    subStyles,
    preferences && preferences.styles
  ),
  roomTypesOptions: Object.values(roomTypes),
  colorsOptions: Object.values(colors),
  priceRangesOptions: Object.values(priceRanges),
  currentPreferences: preferences || {},
});

const mapDispatchToProps = {
  updateUserPreferences: updateUserPreferencesAction,
};

export default connect(mapSateToProps, mapDispatchToProps)(PreferenceQuiz);
