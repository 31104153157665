import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { routesByName } from 'constants/routes';
import { Link } from 'react-router-dom';
import { inspirationImageShape } from 'modules/inspirationImage/propTypes';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from 'modules/requestTheLook/createRequest/CreateRequest.module.scss';

const RequestType = ({
  title,
  description,
  type,
  handleSelectType,
  requestType,
  imgUrl,
  selectedImage,
}) => {
  const active = useMemo(() => type === selectedImage?.type, [
    selectedImage,
    type,
  ]);

  const selected = useMemo(() => requestType && requestType === type, [
    requestType,
    type,
  ]);

  return (
    <div
      className={clsx(classes.requestType, 'mb-2', {
        [classes.active]: active,
        [classes.selected]: selected,
      })}
      data-type={type}
      onClick={handleSelectType}
    >
      <img src={imgUrl} alt="" />
      <div className={classes.requestTypeContent}>
        <h4 className={classes.requestTypeTitle}>
          <span className={classes.requestTypeTitleCircle}>
            {selected && (
              <SpriteIcon name="correct" className="primary-color" />
            )}
          </span>
          {title}
        </h4>
        <p className={classes.requestTypeDescription}>{description}</p>
        {selected && (
          <Link
            className={classes.requestTypeBtn}
            to={routesByName.requestTheLook.details}
          >
            Next
          </Link>
        )}
      </div>
    </div>
  );
};

RequestType.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  imgUrl: PropTypes.string.isRequired,
  handleSelectType: PropTypes.func.isRequired,
  requestType: PropTypes.string,
  selectedImage: inspirationImageShape,
};

RequestType.defaultProps = {
  selectedImage: null,
  requestType: '',
};

export default RequestType;
