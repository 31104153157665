import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import SpriteIcon from 'components/ui/SpriteIcon';
import IconButton from 'components/ui/IconButton/IconButton';
import classes from 'modules/getTheLook/GetTheLook.module.scss';
import Button from 'components/ui/Button/Button';

const GTLSlider = ({
  sliderRef,
  slideLength,
  activeSlideIndex,
  onSlidePrev,
  onSlideNext,
  onSlideChange,
  onViewAll,
  children,
}) => (
  <div className="position-relative">
    <IconButton
      onClick={onSlidePrev}
      disabled={activeSlideIndex === 0}
      className={classes.sliderControl}
      style={{ left: -64 }}
    >
      <SpriteIcon name="prev-icon" size="sm" />
    </IconButton>
    <Slider
      ref={sliderRef}
      arrows={false}
      infinite={false}
      slidesToShow={3}
      slidesToScroll={1}
      afterChange={onSlideChange}
    >
      {children}
    </Slider>
    <IconButton
      onClick={onSlideNext}
      disabled={activeSlideIndex >= slideLength - 3}
      className={classes.sliderControl}
      style={{ right: -64 }}
    >
      <SpriteIcon name="next-icon" size="sm" />
    </IconButton>
    <div className={classes.viewAllBtn}>
      <Button variant="default" size="xs" onClick={onViewAll}>
        <span className="mr-1">View All</span>
        <SpriteIcon name="right-arrow" size="sm" />
      </Button>
    </div>
  </div>
);

GTLSlider.propTypes = {
  sliderRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  slideLength: PropTypes.number.isRequired,
  activeSlideIndex: PropTypes.number.isRequired,
  onSlidePrev: PropTypes.func.isRequired,
  onSlideNext: PropTypes.func.isRequired,
  onSlideChange: PropTypes.func.isRequired,
  onViewAll: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default GTLSlider;
