import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';

const FinalFormInputWrapper = ({
  component: Component,
  helperText: helperTextProp,
  input,
  meta: { submitError, dirtySinceLastSubmit, touched, error, submitting },
  ...rest
}) => {
  const hasError =
    Boolean((submitError && !dirtySinceLastSubmit) || error) &&
    touched &&
    !submitting;

  const helperText = useMemo(() => {
    if (!hasError) return helperTextProp;

    return error || submitError;
  }, [hasError, error, submitError, helperTextProp]);

  return (
    <Component helperText={helperText} error={hasError} {...input} {...rest} />
  );
};

FinalFormInputWrapper.propTypes = {
  input: PropTypes.shape({}).isRequired,
  helperText: PropTypes.string,
  meta: PropTypes.shape({
    submitError: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    dirtySinceLastSubmit: PropTypes.bool,
    touched: PropTypes.bool,
    submitting: PropTypes.bool,
    error: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
    ]),
  }).isRequired,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

FinalFormInputWrapper.defaultProps = {
  helperText: null,
};

export default FinalFormInputWrapper;
