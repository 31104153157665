import React, { useState } from 'react';
import Button from 'components/ui/Button/Button';
import FiltersModal from 'components/modals/FiltersModal/FiltersModal';

const ColorFilter = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div>
        <Button onClick={handleClickOpen}>Open Filters modal</Button>
      </div>
      <FiltersModal open={open} onClose={handleClose} />
    </>
  );
};

export default ColorFilter;
