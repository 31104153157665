import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import SpriteIcon from 'components/ui/SpriteIcon';
import clsx from 'clsx';
import classes from './Tabs.module.scss';

const Tabs = ({ children, selected }) => {
  const [selectedTab, setSelectedTab] = useState(selected);

  const handleChange = useCallback((e) => {
    setSelectedTab(+e.target.dataset.index);
  }, []);

  return (
    <div className={classes.root}>
      <div className="d-flex justify-content-end align-items-center mb-3">
        {children.map(({ props: { title, icon } }, index) => (
          <button
            className={clsx(classes.btn, {
              [classes.btnActive]: index === selectedTab,
            })}
            key={title}
            onClick={handleChange}
            data-index={index}
            type="button"
          >
            <SpriteIcon name={icon} size="sm" className="mr-1" />
            {title}
          </button>
        ))}
      </div>
      <div className={classes.tabContent}>{children[selectedTab]}</div>
    </div>
  );
};

Tabs.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  selected: PropTypes.number,
};

Tabs.defaultProps = {
  selected: 0,
};

export default Tabs;
