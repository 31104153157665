import React, { useEffect } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import CreateRequestContainer from 'modules/requestTheLook/createRequest/CreateRequestContainer';
import RequestDetailsContainer from 'modules/requestTheLook/requestDetails/RequestDetailsContainer';
import SubmitRequestContainer from 'modules/requestTheLook/submitRequest/SubmitRequestContainer';
import RequestSuccessContainer from 'modules/requestTheLook/requestSuccess/RequestSuccessContainer';

const RequestTheLookContainer = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (pathname !== routesByName.requestTheLook.index) {
      history.replace(routesByName.requestTheLook.index);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Switch>
      <Route exact path={routesByName.requestTheLook.index}>
        <CreateRequestContainer />
      </Route>
      <Route exact path={routesByName.requestTheLook.details}>
        <RequestDetailsContainer />
      </Route>
      <Route exact path={routesByName.requestTheLook.submit}>
        <SubmitRequestContainer />
      </Route>
      <Route exact path={routesByName.requestTheLook.success}>
        <RequestSuccessContainer />
      </Route>
    </Switch>
  );
};

export default RequestTheLookContainer;
