import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import descriptionOptionShape from 'components/PreferenceQuiz/propTypes/descriptionOptionShape';
import DescriptionCard from 'components/PreferenceQuiz/DescriptionCard/DescriptionCard';
import Button from 'components/ui/Button/Button';

const DescriptionStep = ({
  isLoading,
  options,
  currentValue,
  onSaveProfession,
  onCancelEdit,
}) => {
  const [professionValues, setProfessionValue] = useState(currentValue);

  const handleChangeProfessionValue = useCallback((value) => {
    setProfessionValue(value);
  }, []);

  const handleSaveProfession = useCallback(() => {
    onSaveProfession(professionValues);
  }, [onSaveProfession, professionValues]);
  return (
    <>
      <div className="row mb-3">
        {options.map((option) => (
          <div className="col-4" key={option.id}>
            <DescriptionCard
              option={option}
              currentValue={professionValues}
              onSelect={handleChangeProfessionValue}
            />
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-center">
        <Button
          inline
          className="mr-2"
          onClick={handleSaveProfession}
          disabled={isLoading}
        >
          Next
        </Button>
        <Button
          inline
          variant="outlined"
          onClick={onCancelEdit}
          disabled={isLoading}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

DescriptionStep.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(descriptionOptionShape).isRequired,
  currentValue: PropTypes.string,
  onSaveProfession: PropTypes.func.isRequired,
  onCancelEdit: PropTypes.func.isRequired,
};

DescriptionStep.defaultProps = {
  currentValue: undefined,
};

export default DescriptionStep;
