import {
  LAYOUT_TOGGLE_ADD_IMAGE_MODAL,
  LAYOUT_TOGGLE_UNDER_CONSTRUCTION_MODAL,
} from 'modules/layout/store/constants';

export const toggleAddImageModalAction = (isOpen) => (dispatch) => {
  dispatch({ type: LAYOUT_TOGGLE_ADD_IMAGE_MODAL, payload: isOpen });
};

export const toggleUnderConstructionModalAction = (isOpen) => (dispatch) => {
  dispatch({ type: LAYOUT_TOGGLE_UNDER_CONSTRUCTION_MODAL, payload: isOpen });
};
