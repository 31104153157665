import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import buildResourceUrl from 'utils/buildResourceUrl';
import { getProductImgUrl } from 'modules/app/store/selectors';
import classes from './ItemThumbnail.module.scss';

const ItemThumbnail = ({
  src: { userId, hash },
  height,
  className,
  productImgUrl,
}) => {
  const skeletonSize = useMemo(() => {
    return height - 16; // 16 = 2 * default spacing
  }, [height]);

  const imgUrl = useMemo(
    () => buildResourceUrl(productImgUrl.medium, userId, hash),
    [userId, hash, productImgUrl]
  );

  const [loaded, setLoaded] = useState(false);

  const handleImageLoad = useCallback(() => {
    setLoaded(true);
  }, []);

  return (
    <div className={clsx(classes.root, className)} style={{ height }}>
      <img
        src={imgUrl}
        alt=""
        className={classes.image}
        style={loaded ? {} : { display: 'none' }}
        onLoad={handleImageLoad}
      />
      {!loaded && (
        <Skeleton
          animation="wave"
          variant="rect"
          width={skeletonSize}
          height={skeletonSize}
        />
      )}
    </div>
  );
};

ItemThumbnail.propTypes = {
  src: PropTypes.shape({
    userId: PropTypes.number.isRequired,
    hash: PropTypes.string.isRequired,
  }).isRequired,
  height: PropTypes.number.isRequired,
  className: PropTypes.string,
  productImgUrl: PropTypes.shape({
    medium: PropTypes.string.isRequired,
  }).isRequired,
};

ItemThumbnail.defaultProps = {
  className: '',
};

const mapStateToProps = ({ app: { config } }) => ({
  productImgUrl: getProductImgUrl(config),
});

export default connect(mapStateToProps)(ItemThumbnail);
