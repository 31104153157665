import {
  LAYOUT_TOGGLE_ADD_IMAGE_MODAL,
  LAYOUT_TOGGLE_UNDER_CONSTRUCTION_MODAL,
} from 'modules/layout/store/constants';

const initialState = {
  addImageModal: false,
  underConstructionModalOpen: false,
};

const layoutReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case LAYOUT_TOGGLE_ADD_IMAGE_MODAL: {
      return { ...state, addImageModal: payload };
    }
    case LAYOUT_TOGGLE_UNDER_CONSTRUCTION_MODAL: {
      return { ...state, underConstructionModalOpen: payload };
    }
    default:
      return state;
  }
};

export default layoutReducer;
