import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance';

const urlPrefix = '/v1';

const requestService = {
  submitRequest(data) {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/request`, data);
  },
};

export default requestService;
