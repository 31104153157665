import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import buildResourceUrl from 'utils/buildResourceUrl';
import errorToastr from 'libs/toastr/errorToastr';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import ImageLink from 'components/imageThumbnails/ImageLink';
import InspirationImage from 'components/imageThumbnails/InspirationImage/InspirationImage';
import { getInspirationImgUrl } from 'modules/app/store/selectors';
import { toggleUnderConstructionModalAction } from 'modules/layout/store/actions';
import { updateIIStatusLikeAction } from 'modules/inspirationImage/store/actions';
import inspirationImageService from 'modules/inspirationImage/inspirationImageService';
import MoreOptionsPopover from 'modules/curateTheLook/createLookBoard/components/MoreOptionsPopover/MoreOptionsPopover';
import classes from './ImageItem.module.scss';

const ImageItem = ({
  id,
  url,
  userId,
  hash,
  isLiked,
  onOpenPreview,
  onSelectImage,
  inspirationImageUrl,
  toggleUnderConstructionModal,
  updateLikeStatus,
}) => {
  const [moreOptionsAnchorEl, setMoreOptionsAnchorEl] = useState(null);

  const imgUrl = useMemo(
    () => buildResourceUrl(inspirationImageUrl.medium, userId, hash),
    [inspirationImageUrl, userId, hash]
  );

  const handleOpenPreview = useCallback(() => {
    onOpenPreview(imgUrl);
  }, [onOpenPreview, imgUrl]);

  const handleToggleLike = useCallback(async () => {
    try {
      await inspirationImageService.toggleLike(id, Number(!isLiked));
      updateLikeStatus(id, Number(!isLiked));
    } catch (e) {
      errorToastr('Error', e.generalError);
    }
  }, [id, isLiked, updateLikeStatus]);

  const handleMoreOptionsPopupOpen = useCallback(({ currentTarget }) => {
    setMoreOptionsAnchorEl(currentTarget);
  }, []);

  const handleMoreOptionsPopupClose = useCallback(() => {
    setMoreOptionsAnchorEl(null);
  }, []);

  const handleUnderConstructionModalOpen = useCallback(() => {
    toggleUnderConstructionModal(true);
  }, [toggleUnderConstructionModal]);

  const handleSelectImage = useCallback(() => {
    onSelectImage(id);
  }, [onSelectImage, id]);

  return (
    <InspirationImage
      id={id}
      className="mb-3"
      imgUrl={imgUrl}
      hoverOverlay={
        <>
          <div className="h-100 flex-column flex-vertical-center justify-content-between">
            <div className="w-100 d-flex justify-content-end">
              <IconButton
                color="secondary"
                size="sm"
                onClick={handleOpenPreview}
              >
                <SpriteIcon name="loupe" size="sm" />
              </IconButton>
            </div>
            <p className={classes.hoverLabel} onClick={handleSelectImage}>
              Click to select inspiration image
            </p>
            <div className="w-100 d-flex align-items-center">
              <div className="flex-fill text-ellipsis">
                {url && <ImageLink url={url} />}
              </div>
              <IconButton
                variant="inverted-grey"
                size="sm"
                className="mr-1"
                onClick={handleToggleLike}
              >
                <SpriteIcon name={isLiked ? 'like-filled' : 'like'} size="sm" />
              </IconButton>
              <IconButton
                variant="inverted-grey"
                size="sm"
                className="mr-1"
                onClick={handleUnderConstructionModalOpen}
              >
                <SpriteIcon name="share" size="sm" />
              </IconButton>
              <IconButton
                variant="inverted-grey"
                size="sm"
                onClick={handleMoreOptionsPopupOpen}
              >
                <SpriteIcon name="more-vertical" size="sm" />
              </IconButton>
            </div>
          </div>
          <MoreOptionsPopover
            open={Boolean(moreOptionsAnchorEl)}
            onClose={handleMoreOptionsPopupClose}
            anchorEl={moreOptionsAnchorEl}
          />
        </>
      }
    />
  );
};

ImageItem.propTypes = {
  id: PropTypes.number.isRequired,
  url: PropTypes.string,
  userId: PropTypes.number.isRequired,
  hash: PropTypes.string.isRequired,
  isLiked: PropTypes.number.isRequired,
  onOpenPreview: PropTypes.func.isRequired,
  onSelectImage: PropTypes.func.isRequired,
  inspirationImageUrl: PropTypes.shape({
    medium: PropTypes.string.isRequired,
  }).isRequired,
  toggleUnderConstructionModal: PropTypes.func.isRequired,
  updateLikeStatus: PropTypes.func.isRequired,
};

ImageItem.defaultProps = {
  url: null,
};

const mapStateToProps = ({ app: { config } }) => ({
  inspirationImageUrl: getInspirationImgUrl(config),
});

const mapDispatchToProps = {
  toggleUnderConstructionModal: toggleUnderConstructionModalAction,
  updateLikeStatus: updateIIStatusLikeAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageItem);
