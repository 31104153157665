export const notificationsMock = [
  {
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589824729604,
  },
  {
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589824729504,
  },
  {
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589824729604,
  },
  {
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589824719604,
  },
  {
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589824729604,
  },
  {
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589823729604,
  },
  {
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589824729604,
  },
  {
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589823729604,
  },
  {
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589814729604,
  },
  {
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589724729604,
  },
  {
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589824529604,
  },
  {
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589825729604,
  },
  {
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589844729604,
  },
  {
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589834729604,
  },
  {
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589824729604,
  },
  {
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589924729604,
  },
  {
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589724729604,
  },
  {
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589624729604,
  },
  {
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589524729604,
  },
  {
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589824729604,
  },
  {
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589424729604,
  },
];

export const dialogsMock = [
  {
    photo: 'https://picsum.photos/40',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589824729604,
  },
  {
    photo: 'https://picsum.photos/40',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589824729504,
  },
  {
    photo: 'https://picsum.photos/40',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589824729604,
  },
  {
    photo: 'https://picsum.photos/40',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589824719604,
  },
  {
    photo: 'https://picsum.photos/40',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589824729604,
  },
  {
    photo: 'https://picsum.photos/40',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589823729604,
  },
  {
    photo: 'https://picsum.photos/40',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589824729604,
  },
  {
    photo: 'https://picsum.photos/40',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589823729604,
  },
  {
    photo: 'https://picsum.photos/40',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589814729604,
  },
];

export const forumsMock = [
  {
    photo: 'https://picsum.photos/50',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589824729604,
  },
  {
    photo: 'https://picsum.photos/50',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589824729504,
  },
  {
    photo: 'https://picsum.photos/50',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589824729604,
  },
  {
    photo: 'https://picsum.photos/50',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589824719604,
  },
  {
    photo: 'https://picsum.photos/50',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589824729604,
  },
  {
    photo: 'https://picsum.photos/50',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut',
    date: 1589823729604,
  },
];
