import React from 'react';
import FinalFormInputWrapper from 'components/finalFormWrappers/FinalFormInputWrapper';
import TextAreaInput from 'components/inputs/TextAreaInput/TextAreaInput';

const TextAreaWrapper = (props) => (
  <FinalFormInputWrapper {...props} component={TextAreaInput} />
);

TextAreaWrapper.propTypes = {};

TextAreaWrapper.defaultProps = {};

export default TextAreaWrapper;
