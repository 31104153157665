import {
  APP_INITED,
  APP_SET_CHECKSUM,
  APP_SET_CONFIG,
  APP_UPDATE_ENUMS,
} from 'modules/app/store/constants';
import { initAuthAction } from 'modules/auth/store/actions';
import appConfig from 'constants/appConfig';
import appService from 'modules/app/appService';
import { localStorageKeys } from 'modules/app/constants';
import { setUpAuthInterceptorsAction } from 'libs/axios/AuthorizedAxiosInstance';

export const setConfigAction = (payload) => ({
  type: APP_SET_CONFIG,
  payload,
});

export const setChecksumAction = (payload) => ({
  type: APP_SET_CHECKSUM,
  payload,
});

export const updateEnumsAction = (payload) => ({
  type: APP_UPDATE_ENUMS,
  payload,
});

const getNewChecksum = async (checksumUrl) => {
  const newChecksum = await appService.getAppResource(checksumUrl);
  localStorage.setItem(localStorageKeys.checksum, JSON.stringify(newChecksum));
  return newChecksum;
};

const updateEnums = async (currentChecksum, newChecksum, enumUrlsObj) => {
  const enumsToUpdate = Object.keys(newChecksum).filter(
    (currentEnum) =>
      newChecksum[currentEnum] !== currentChecksum[currentEnum] &&
      currentEnum !== 'application'
  );

  const promiseArr = enumsToUpdate.map((currentEnum) =>
    appService.getAppResource(enumUrlsObj[currentEnum])
  );

  const enumsValues = await Promise.all(promiseArr);

  const updatedEnums = enumsToUpdate.reduce((accum, enumKey, index) => {
    /* eslint-disable no-param-reassign */

    accum[enumKey] = enumsValues[index].reduce((acc, currValue) => {
      acc[currValue.id] = currValue;
      return acc;
    }, {});

    return accum;
  }, {});

  localStorage.setItem(localStorageKeys.enums, JSON.stringify(updatedEnums));

  return updatedEnums;
};

export const initAppAction = () => async (dispatch) => {
  dispatch(setUpAuthInterceptorsAction());
  await dispatch(initAuthAction());
  const savedAppVersion = appService.getSavedAppVersion();

  if (appConfig.appVersion !== savedAppVersion) {
    localStorage.removeItem(localStorageKeys.applicationConfig);
    appService.saveAppVersion(appConfig.appVersion);
  }

  let config = JSON.parse(
    localStorage.getItem(localStorageKeys.applicationConfig)
  );

  if (!config) {
    config = await appService.getConfig();

    const {
      endpoints: { configs },
    } = config;

    const newChecksum = await getNewChecksum(configs.checksum);

    await updateEnums({}, newChecksum, configs);
  }

  dispatch(setConfigAction(config));

  const {
    endpoints: { configs },
  } = config;

  let checksum = JSON.parse(localStorage.getItem(localStorageKeys.checksum));

  if (!checksum) {
    checksum = await getNewChecksum(configs.checksum);
    await updateEnums({}, checksum, configs);
  }

  dispatch(setChecksumAction(checksum));

  let enums = JSON.parse(localStorage.getItem(localStorageKeys.enums));

  if (!enums) {
    enums = await updateEnums({}, checksum, configs);
  }

  dispatch(updateEnumsAction(enums));
  dispatch({ type: APP_INITED });
};
