import React, { useCallback, useState } from 'react';
import MainImage from 'components/imageThumbnails/MainImage/MainImage';
import SimilarImage from 'components/imageThumbnails/SimilarImage/SimilarImage';
import PopupImage from 'modules/pinMedia/PopupImage/PopupImage';
import ProductThumbnail from 'components/ProductThumbnail/ProductThumbnail';

const inspirationImagesMock = {
  1: {
    id: 1,
    imgUrl: 'https://picsum.photos/300/450',
    removed: true,
    selected: false,
    url: 'https://google.com',
  },
  2: {
    id: 2,
    imgUrl: 'https://picsum.photos/300/450',
    removed: false,
    selected: true,
    url: 'https://google.com',
  },
  3: {
    id: 3,
    imgUrl: 'https://picsum.photos/300/450',
    removed: false,
    selected: false,
    url: 'https://google.com',
  },
};

const productsMock = {
  1: {
    id: 1,
    imgUrl: 'https://picsum.photos/200/200',
    name: 'Loft Chair',
    category: 'Potterny born',
    price: 1260,
    discontinued: true,
    user: {
      firstName: 'John',
      lastName: 'Doe',
      avatarUrl: 'https://picsum.photos/40/40',
    },
    likes: 326,
    saves: 75,
  },
  2: {
    id: 2,
    imgUrl: 'https://picsum.photos/200/200',
    name: 'Loft Chair',
    category: 'Potterny born',
    price: 1260,
    discontinued: false,
    user: {
      firstName: 'John',
      lastName: 'Doe',
      avatarUrl: 'https://picsum.photos/40/40',
    },
    likes: 326,
    saves: 75,
  },
  3: {
    id: 3,
    imgUrl: 'https://picsum.photos/200/200',
    name: 'Loft Chair',
    category: 'Potterny born',
    price: 1260,
    discontinued: false,
    user: {
      firstName: 'John',
      lastName: 'Doe',
      avatarUrl: 'https://picsum.photos/40/40',
    },
    likes: 326,
    saves: 75,
  },
};

const ImageThumbnails = () => {
  const [imageList, setImageList] = useState(inspirationImagesMock);

  const handleImageSelect = useCallback(
    (id) => {
      const imageListCopy = { ...imageList };

      Object.keys(imageListCopy).forEach((key) => {
        imageListCopy[key].selected = false;
      });

      setImageList({
        ...imageListCopy,
        [id]: { ...imageListCopy[id], selected: true },
      });
    },
    [imageList]
  );

  return (
    <>
      <div className="row mb-4">
        {Object.values(inspirationImagesMock).map(
          ({ id, imgUrl, removed, url }) => (
            <div className="col-4" key={id}>
              <MainImage
                id={id}
                imgUrl={imgUrl}
                blurred={removed}
                showStaticOverlay={removed}
                overlayLabel="choose inspiration image"
                link={url}
              />
            </div>
          )
        )}
      </div>
      <div className="row mb-4">
        {Object.values(inspirationImagesMock).map(
          ({ id, imgUrl, removed, link }) => (
            <div className="col-4" key={id}>
              <SimilarImage
                id={id}
                imgUrl={imgUrl}
                blurred={removed}
                showStaticOverlay={removed}
                overlayLabel="choose inspiration image"
                link={link}
              />
            </div>
          )
        )}
      </div>
      <div className="row mb-4">
        {Object.values(imageList).map(({ id, imgUrl, selected }) => (
          <div className="col-4" key={id}>
            <PopupImage
              id={id}
              imgUrl={imgUrl}
              disableHover={selected}
              showStaticOverlay={selected}
              onSelect={handleImageSelect}
              overlayLabel="select image"
            />
          </div>
        ))}
      </div>
      <div className="d-flex flex-wrap">
        {Object.values(productsMock).map((product) => (
          <ProductThumbnail key={product.id} {...product} />
        ))}
      </div>
    </>
  );
};

ImageThumbnails.propTypes = {};

ImageThumbnails.defaultProps = {};

export default ImageThumbnails;
