export const transformMediaArrayToMap = (array) =>
  array.reduce((accum, currVal, index) => {
    /* eslint-disable no-param-reassign */
    accum[index] = {
      id: index,
      ...array[index],
    };

    return accum;
  }, {});
