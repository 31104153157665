import React, { useCallback, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import SpriteIcon from 'components/ui/SpriteIcon';

const useStyles = makeStyles(({ palette, spacing }) => ({
  profileAvatar: {
    width: spacing(12),
    height: spacing(12),
    marginRight: spacing(2.5),
    backgroundColor: palette.grey[2],
    color: palette.grey[3],
  },
  uploadText: {
    fontWeight: 600,
    fontSize: 14,
  },
}));

const AmbassadorPhotoUpload = () => {
  const classes = useStyles();
  const [newImageUrl, setNewImageUrl] = useState(null);

  const handleChange = useCallback(({ target: { files } }) => {
    setNewImageUrl(URL.createObjectURL(files[0]));
  }, []);

  return (
    <label className="d-inline-flex align-items-center cursor-pointer">
      <input type="file" className="d-none" onChange={handleChange} />
      <Avatar src={newImageUrl} className={classes.profileAvatar}>
        <SpriteIcon name="file-upload" size="lg" />
      </Avatar>
      <p className={classes.uploadText}>Upload Your Photo</p>
    </label>
  );
};

AmbassadorPhotoUpload.propTypes = {};

AmbassadorPhotoUpload.defaultProps = {};

export default AmbassadorPhotoUpload;
