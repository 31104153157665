import React, { useState, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import errorToastr from 'libs/toastr/errorToastr';
import successToastr from 'libs/toastr/successToastr';
import SpriteIcon from 'components/ui/SpriteIcon';
import supportService from 'modules/support/supportService';
import { supportFormFields, supportFormTypes } from 'modules/support/constants';
import SupportForm from 'modules/support/supportForm/SupportForm';
import bannerImg from 'assets/img/contact-page/banner.png';
import classes from './Contact.module.scss';

const ContactContainer = () => {
  const [disableSubmit, setDisableSubmit] = useState(true);
  const reCaptchaRef = useRef();

  // TODO: Send reCaptcha token to backend
  const reCaptchaVerify = useCallback(() => {
    setDisableSubmit(false);
  }, []);

  const handleSendContactMessage = useCallback(async (formValues, form) => {
    try {
      await supportService.sendSupportMessage({
        [supportFormFields.type]: supportFormTypes.contact,
        ...formValues,
      });

      successToastr('Success', 'Message successfully sent');

      // reset form
      Object.keys(formValues).forEach((key) => {
        form.change(key, undefined);
        form.resetFieldState(key);
      });

      reCaptchaRef.current.reset();
      setDisableSubmit(true);
    } catch (e) {
      errorToastr(e.generalError);
      return { ...e.validationErrors };
    }
    return true;
  }, []);

  return (
    <section className={classes.root}>
      <div className={classes.container}>
        <div className={classes.panel}>
          <div className={classes.panelLine} />
          <Link className={classes.link} to={routesByName.howItworks}>
            <SpriteIcon name="play-icon" size="lg" />
            How &nbsp;<b>It Works</b>
          </Link>
        </div>
        <div className="d-flex">
          <div className={classes.formContainer}>
            <h1 className={classes.title}>Contact us</h1>
            <SupportForm
              onSubmit={handleSendContactMessage}
              disableSubmit={disableSubmit}
              reCaptchaVerify={reCaptchaVerify}
              reCaptchaRef={reCaptchaRef}
            />
          </div>
          <div className={classes.imgContainer}>
            <img src={bannerImg} alt="" />
            <div className={classes.banner}>
              <div className="position-relative text-center mb-1">
                <span className={classes.bannerTitle}>Pin The Look</span>
                <div className={classes.crossline} />
              </div>
              <p className={classes.bannerSubtitle}>
                be a part of creating something beautiful
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactContainer;
