import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import { faqsConfig } from 'modules/ambassadors/constants';
import SpriteIcon from 'components/ui/SpriteIcon';
import Button from 'components/ui/Button/Button';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import ModalContent from 'modules/ambassadors/faqs/ModalContent';
import classes from './Faqs.module.scss';

const Faqs = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const handleOpenModal = useCallback(({ target }) => {
    setModalContent(faqsConfig[Number(target.dataset.index)]);
    setModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  return (
    <>
      <section className={classes.root}>
        <div className="mb-5">
          <Link to={routesByName.ambassador.index} className={classes.backLink}>
            <div className={classes.backLinkIcon}>
              <SpriteIcon name="left-arrow" size="sm" />
            </div>
            Back
          </Link>
        </div>
        <div className="mb-5 d-flex justify-content-between align-items-center">
          <div>
            <h1 className={classes.title}>Ambassador Faqs</h1>
            <p className={classes.description}>
              Learn About what Ambassadors do and the Benefits of become one
            </p>
          </div>
          <Link to={routesByName.contact}>
            <Button inline variant="outlined" color="secondary" size="sm">
              Ask a Question
            </Button>
          </Link>
        </div>
        <div className={classes.itemsWrapper}>
          <div className="d-flex flex-wrap">
            {faqsConfig.map(({ id, question }, index) => (
              <div key={id} className={classes.faqItem}>
                <p className="font-italic text-sm mb-2">{question}</p>
                <button
                  onClick={handleOpenModal}
                  data-index={index}
                  className={classes.modalBtn}
                  type="button"
                >
                  Know Answer
                </button>
              </div>
            ))}
          </div>
        </div>
      </section>
      {modalContent && (
        <BasicModal maxWidth="md" open={modalOpen} onClose={handleCloseModal}>
          <ModalContent content={modalContent} />
        </BasicModal>
      )}
    </>
  );
};

export default Faqs;
