import {
  errorMessages,
  codeModalFields,
} from 'modules/ambassadors/components/CodeModal/constants';

const codeModalValidator = ({ [codeModalFields.code]: code }) => {
  const errors = {};

  if (!code) {
    errors.code = errorMessages.required;
  } else if (code.length < 6 || code.length > 6) {
    errors.code = errorMessages.codeLength;
  }

  return errors;
};

export default codeModalValidator;
