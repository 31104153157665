import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr/lib/ReduxToastr';
import { routesByName } from 'constants/routes';
import PrivateRoute from 'components/PrivateRoute';
import HomePage from 'modules/pages/HomePage';
import Page404 from 'modules/pages/Page404';
import UnderConstruction from 'modules/pages/UnderConstruction';
import Header from 'modules/layout/Header/Header';
import Footer from 'modules/layout/Footer/Footer';
import PinMediaArea from 'modules/pinMedia/PinMediaContainer';
import { initAppAction } from 'modules/app/store/actions';
import EmailVerificationContainer from 'modules/auth/emailVerification/EmailVerificationContainer';
import AccountContainer from 'modules/account/AccountContainer';
import DashboardContainer from 'modules/dashboard/DashboardContainer';
import CurateTheLookContainer from 'modules/curateTheLook/CurateTheLookContainer';
import UnderConstructionModal from 'components/modals/UnderConstructionModal';
import { toggleUnderConstructionModalAction } from 'modules/layout/store/actions';
import HomeContainer from 'modules/home/HomeContainer';
import AmbassadorsContainer from 'modules/ambassadors/AmbassadorsContainer';
import ContactContainer from 'modules/contact/ContactContainer';
import RequestTheLookContainer from 'modules/requestTheLook/RequestTheLookContainer';
import GetTheLookContainer from 'modules/getTheLook/GetTheLookContainer';

// TODO: Create welcome screen
function App({
  inited,
  initApp,
  underConstructionModalOpen,
  toggleUnderConstructionModal,
}) {
  useEffect(() => {
    (async () => {
      await initApp();
    })();
    // eslint-disable-next-line
  }, []);

  const handleUnderConstructionModalClose = useCallback(() => {
    toggleUnderConstructionModal(false);
  }, [toggleUnderConstructionModal]);

  return inited ? (
    <BrowserRouter>
      <Header />
      <div className="flex-fill d-flex flex-column">
        <Switch>
          <Route exact path={routesByName.home}>
            <HomeContainer />
          </Route>
          <Route exact path={routesByName.auth.emailVerification}>
            <EmailVerificationContainer />
          </Route>
          <PrivateRoute path={routesByName.account.index}>
            <AccountContainer />
          </PrivateRoute>
          <PrivateRoute path={routesByName.dashboard.index}>
            <DashboardContainer />
          </PrivateRoute>
          <Route path={routesByName.getTheLook.index}>
            <GetTheLookContainer />
          </Route>
          <Route path={routesByName.curateTheLook.index}>
            <CurateTheLookContainer />
          </Route>
          <Route path={routesByName.requestTheLook.index}>
            <RequestTheLookContainer />
          </Route>
          <PrivateRoute exact path={routesByName.components}>
            <HomePage />
          </PrivateRoute>
          <Route
            exact
            path={routesByName.about}
            component={UnderConstruction}
          />
          <Route exact path={routesByName.contact}>
            <ContactContainer />
          </Route>
          <Route exact path={routesByName.blog} component={UnderConstruction} />
          <Route
            exact
            path={routesByName.terms}
            component={UnderConstruction}
          />
          <Route exact path={routesByName.faqs} component={UnderConstruction} />
          <Route
            exact
            path={routesByName.privacy}
            component={UnderConstruction}
          />
          <Route
            exact
            path={routesByName.howItworks}
            component={UnderConstruction}
          />
          <Route path={routesByName.ambassador.index}>
            <AmbassadorsContainer />
          </Route>
          <Route
            exact
            path={routesByName.requestInvitation}
            component={UnderConstruction}
          />
          <Route
            exact
            path={routesByName.getStartedNow}
            component={UnderConstruction}
          />
          <Route exact path="*" component={Page404} />
        </Switch>
      </div>
      <PinMediaArea />
      <Footer />
      <ReduxToastr position="bottom-left" preventDuplicates />
      <UnderConstructionModal
        open={underConstructionModalOpen}
        onClose={handleUnderConstructionModalClose}
      />
    </BrowserRouter>
  ) : (
    <div>Loading...</div>
  );
}

App.propTypes = {
  inited: PropTypes.bool.isRequired,
  initApp: PropTypes.func.isRequired,
  underConstructionModalOpen: PropTypes.bool.isRequired,
  toggleUnderConstructionModal: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  app: { inited },
  layout: { underConstructionModalOpen },
}) => ({
  inited,
  underConstructionModalOpen,
});

const mapDispatchToProps = {
  initApp: initAppAction,
  toggleUnderConstructionModal: toggleUnderConstructionModalAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
