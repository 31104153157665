import {
  RTL_SET_IMAGES,
  RTL_UPDATE_IMAGES,
  RTL_UPDATE_REQUEST_DATA,
  RTL_UPDATE_PRIORITY_ITEM,
  RTL_ADD_PRIORITY_ITEM,
  RTL_UPDATE_II_SEARCH_PARAMS,
  RTL_RESET_REQUEST_DATA,
  RTL_RESET_REQUEST_FORM,
  RTL_RESET_STATE,
} from 'modules/requestTheLook/store/constants';
import { updateIILibraryAction } from 'modules/inspirationImage/store/actions';

export const setImagesAction = (list) => (dispatch) => {
  const imageIds = Object.keys(list);
  dispatch(updateIILibraryAction(list));
  dispatch({
    type: RTL_SET_IMAGES,
    payload: imageIds,
  });
};

export const updateImagesAction = (list) => (dispatch) => {
  const imageIds = Object.keys(list);
  dispatch(updateIILibraryAction(list));
  dispatch({
    type: RTL_UPDATE_IMAGES,
    payload: imageIds,
  });
};

export const updateRequestDataAction = (payload) => (dispatch) => {
  dispatch({ type: RTL_UPDATE_REQUEST_DATA, payload });
};

export const selectImageAction = (imgId) => (dispatch) => {
  dispatch(updateRequestDataAction({ inspirationImageId: imgId }));
};

export const unselectImageAction = () => (dispatch) => {
  dispatch({ type: RTL_RESET_REQUEST_DATA });
};

export const resetRequestFormAction = () => (dispatch) => {
  dispatch({ type: RTL_RESET_REQUEST_FORM });
};

export const updatePriorityItemAction = ({ index, values }) => (dispatch) => {
  dispatch({
    type: RTL_UPDATE_PRIORITY_ITEM,
    payload: { index, values },
  });
};

export const addPriorityItemAction = () => (dispatch) => {
  dispatch({ type: RTL_ADD_PRIORITY_ITEM });
};

export const resetRequestStateAction = () => (dispatch) => {
  dispatch({ type: RTL_RESET_STATE });
};

export const updateIISearchParamsAction = (params) => (dispatch) => {
  dispatch({ type: RTL_UPDATE_II_SEARCH_PARAMS, payload: params });
};
