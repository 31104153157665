import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { ReactComponent as Logo } from 'assets/img/logo.svg';
import ButtonList from 'modules/tempComponents/ButtonList';
import Tumblers from 'modules/tempComponents/Tumblers';
import BasicForm from 'modules/tempComponents/BasicForm';
import CurateColorPicker from 'modules/tempComponents/CurateColorPicker/CurateColorPicker';
import ColorFilter from 'modules/tempComponents/ColorFilter';
import SendMessage from 'modules/tempComponents/SendMessage';
import Uploaders from 'modules/tempComponents/Uploaders';
import UserThumbnails from 'modules/tempComponents/UserThumbnails';
import PreferenceQuiz from 'components/PreferenceQuiz/PreferenceQuiz';
import ImageThumbnails from 'modules/tempComponents/ImageThumbnails';
import MixMatchThumbnail from 'components/MixMatchThumbnail/MixMatchThumbnail';
import productMock from 'components/MixMatchThumbnail/productMock';
import LookBoardThumbnails from 'modules/tempComponents/LookBoardThumbnails';
import CreateLookBoardArea from 'modules/tempComponents/CreateLookBoardArea';
import successToastr from 'libs/toastr/successToastr';
import errorToastr from 'libs/toastr/errorToastr';
import infoToastr from 'libs/toastr/infoToastr';
import warningToastr from 'libs/toastr/warningToastr';

const HomePage = () => {
  useEffect(() => {
    successToastr('Success!', 'Application was successfully initialized');
    errorToastr('Error', 'Something went wrong');
    infoToastr(
      'Info',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, ' +
        'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' +
        ' Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris' +
        ' nisi ut aliquip ex ea commodo consequat.'
    );
    warningToastr('Warning', 'Something important needs to be fixed');
  }, []);

  return (
    <div className="container">
      <div>
        <Logo width={200} />
      </div>
      <ButtonList />
      <Tumblers />
      <BasicForm />
      <div className="py-4">
        <CurateColorPicker />
      </div>
      <div className="py-4">
        <ColorFilter />
      </div>
      <div className="py-4">
        <SendMessage />
      </div>
      <div className="py-4 d-flex justify-content-around align-items-center">
        <Uploaders />
      </div>
      <div className="py-4">
        <UserThumbnails />
      </div>
      <div className="py-4">
        <ImageThumbnails />
      </div>
      <div className="py-4">
        <Paper className="p-5">
          <PreferenceQuiz />
        </Paper>
      </div>
      <div className="py-4">
        <MixMatchThumbnail {...productMock} />
      </div>
      <div className="py-4">
        <LookBoardThumbnails />
      </div>
      <div className="py-4" style={{ marginBottom: 100 }}>
        <CreateLookBoardArea />
      </div>
    </div>
  );
};

HomePage.propTypes = {};

HomePage.defaultProps = {};

export default HomePage;
