import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from './FloatActionsBlock.module.scss';

const FloatActionsBlock = ({ onOpenChooseMethodModal }) => {
  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className={classes.root}>
      <button type="button" className={classes.actionBtn} onClick={scrollToTop}>
        <SpriteIcon name="arrow-up" size="lg" />
      </button>
      <button
        type="button"
        className={classes.actionBtn}
        onClick={onOpenChooseMethodModal}
      >
        <SpriteIcon name="pin" size="lg" />
      </button>
    </div>
  );
};

FloatActionsBlock.propTypes = {
  onOpenChooseMethodModal: PropTypes.func.isRequired,
};

export default FloatActionsBlock;
