import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { requestTheLookTypes } from 'modules/requestTheLook/constants';
import { inspirationImageShape } from 'modules/inspirationImage/propTypes';
import { updateRequestDataAction } from 'modules/requestTheLook/store/actions';
import SpriteIcon from 'components/ui/SpriteIcon';
import RequestType from 'modules/requestTheLook/createRequest/components/RequestType';
import createLookBoardClasses from 'modules/curateTheLook/createLookBoard/CreateLookBoard.module.scss';

const RightPanel = ({ selectedImage, updateRequestData, requestType }) => {
  const handleSelectType = useCallback(
    ({
      currentTarget: {
        dataset: { type },
      },
    }) => {
      if (type === requestType) {
        return;
      }

      if (selectedImage?.type === type) {
        updateRequestData({ requestType: type });
      }
    },
    [updateRequestData, selectedImage, requestType]
  );

  return (
    <div className={createLookBoardClasses.sidePanel}>
      <div className={`${createLookBoardClasses.stepTitle} mb-3`}>
        {requestType && (
          <SpriteIcon name="checked" size="sm" className="mr-1 primary-color" />
        )}
        <span>Step 2: Select Request Type</span>
      </div>
      {requestTheLookTypes.map(({ type, ...rest }) => (
        <RequestType
          key={type}
          type={type}
          handleSelectType={handleSelectType}
          requestType={requestType}
          selectedImage={selectedImage}
          {...rest}
        />
      ))}
    </div>
  );
};

RightPanel.propTypes = {
  selectedImage: inspirationImageShape,
  updateRequestData: PropTypes.func.isRequired,
  requestType: PropTypes.string,
};

RightPanel.defaultProps = {
  selectedImage: null,
  requestType: '',
};

const mapStateToProps = ({
  requestTheLook: {
    requestData: { inspirationImageId, requestType },
  },
  inspirationImage: { library },
}) => ({
  selectedImage: library[inspirationImageId],
  requestType,
});

const mapDispatchToProps = {
  updateRequestData: updateRequestDataAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(RightPanel);
