export const getTheLookInfoConfig = {
  steps: [
    {
      id: 1,
      title: 'Select Image Type',
      icon: 'hotel',
      description: {
        text: 'Get the look of:',
        bold: true,
      },
      list: [
        {
          id: 1,
          title: 'A Room Image',
        },
        {
          id: 2,
          title: 'A Product Image',
        },
      ],
    },
    {
      id: 2,
      title: 'Select Inspiration Image',
      icon: 'picture',
      description: {
        text: 'Select a room or product Image that you love!',
        bold: false,
      },
    },
    {
      id: 3,
      title: 'View The Look',
      icon: 'dashboard',
      description: {
        text: 'View the Look of your inspiration images by:',
        bold: true,
      },
      list: [
        {
          id: 1,
          title: 'Look Boards',
        },
        {
          id: 2,
          title: 'Product Streams',
        },
        {
          id: 3,
          title: 'Mix & Match',
        },
      ],
    },
    {
      id: 4,
      title: 'View Products',
      icon: 'chair',
      description: {
        text: 'Click on products to view prices and details',
        bold: false,
      },
    },
  ],
};

export const curateTheLookInfoConfig = {
  fontSize: 'sm',
  steps: [
    {
      id: 1,
      title: 'Select or Add Image',
      icon: 'picture',
      description: {
        text: 'Select an Image to Curate:',
        bold: true,
      },
      list: [
        {
          id: 1,
          title: 'From our Database',
        },
        {
          id: 2,
          title: 'Add a New Image',
        },
      ],
    },
    {
      id: 2,
      title: 'Select Template',
      icon: 'hotel',
      description: {
        text: 'Select Template by the number of columns required',
        bold: false,
      },
    },
    {
      id: 3,
      title: 'Curate with products',
      icon: 'living-room',
      description: {
        text: 'Curate the Look with:',
        bold: true,
      },
      list: [
        {
          id: 1,
          title: 'Items from the Product Database',
        },
        {
          id: 2,
          title: 'Items you Add',
        },
      ],
    },
    {
      id: 4,
      title: 'Edit Template',
      icon: 'edit',
      description: {
        text: 'Edit the banner color and text',
        bold: false,
      },
    },
    {
      id: 5,
      title: 'Input Design Details',
      icon: 'shopping-list',
      description: {
        text: 'Input details about your Look Board and Share',
        bold: true,
      },
    },
  ],
};

export const requestTheLookInfoConfig = {
  steps: [
    {
      id: 1,
      title: 'Select or Add Image',
      icon: 'picture',
      description: {
        text: 'Select a Get the Look Image:',
        bold: true,
      },
      list: [
        {
          id: 1,
          title: 'From our Database',
        },
        {
          id: 2,
          title: 'Add a New Image',
        },
      ],
    },
    {
      id: 2,
      title: 'Enter Your Request Details',
      icon: 'shopping-list',
      description: {
        text:
          'Input details about your Request and we’ll forward to Shopping experts who specialize in your style.',
        bold: false,
      },
    },
  ],
};
