import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';

const CheckboxInputWrapper = ({ input }) => {
  return <Checkbox {...input} />;
};

CheckboxInputWrapper.propTypes = {
  input: PropTypes.shape({}).isRequired,
};

export default CheckboxInputWrapper;
