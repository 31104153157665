import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import SubStyleCard from 'components/PreferenceQuiz/SubStyleCard/SubStyleCard';

const SubStylesSlider = ({ currentValue, subStyles, onLike }) => {
  const sliderRef = useRef(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const handleSlideChange = useCallback((index) => {
    setActiveSlideIndex(index);
  }, []);

  const handleSlidePrev = useCallback(() => {
    sliderRef.current.slickPrev();
  }, []);

  const handleSlideNext = useCallback(() => {
    sliderRef.current.slickNext();
  }, []);

  return (
    <div className="d-flex align-items-center mb-2">
      <IconButton
        size="sm"
        onClick={handleSlidePrev}
        disabled={activeSlideIndex === 0}
      >
        <SpriteIcon name="prev-icon" size="xs" />
      </IconButton>
      <div className="flex-fill" style={{ width: 100 }}>
        <Slider
          ref={sliderRef}
          arrows={false}
          infinite={false}
          slidesToShow={3}
          slidesToScroll={1}
          afterChange={handleSlideChange}
        >
          {subStyles.map((subStyle) => (
            <SubStyleCard
              key={subStyle.id}
              onLike={onLike}
              selected={currentValue.includes(subStyle.id)}
              {...subStyle}
            />
          ))}
        </Slider>
      </div>
      <IconButton
        size="sm"
        onClick={handleSlideNext}
        disabled={activeSlideIndex >= subStyles.length - 3}
      >
        <SpriteIcon name="next-icon" size="xs" />
      </IconButton>
    </div>
  );
};

SubStylesSlider.propTypes = {
  currentValue: PropTypes.arrayOf(PropTypes.number).isRequired,
  subStyles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onLike: PropTypes.func.isRequired,
};

export default SubStylesSlider;
