import { routesByName } from 'constants/routes';
import {
  imageSortKeys,
  imageSourceKeys,
  imageTypeKeys,
} from 'constants/inspirationImageSearchParams';
import {
  lookBoardSortKeys,
  lookBoardSourceKeys,
  lookBoardStatusKeys,
  lookBoardTypeKeys,
} from 'constants/lookBoardSearchParams';
import addImg from 'assets/img/dashboard-page/add-images.png';
import curateImg from 'assets/img/dashboard-page/curate-images.png';
import setupImg from 'assets/img/dashboard-page/setup-showcase.png';

export const navLinksConfig = [
  {
    route: routesByName.dashboard.images,
    title: 'My Images',
    icon: 'pictures',
  },
  {
    route: routesByName.dashboard.lookBoards,
    title: 'My Look Boards',
    icon: 'sofa',
  },
  {
    route: routesByName.dashboard.requests,
    title: 'My Requests',
    icon: 'exchange',
  },
  {
    route: routesByName.dashboard.community,
    title: 'Community',
    icon: 'followers',
  },
  {
    route: routesByName.dashboard.messages,
    title: 'Messages',
    icon: 'envelope',
  },
];

export const myImagesTabsConfig = [
  {
    name: imageSourceKeys.my,
    title: 'My Added Images',
  },
  {
    name: imageSourceKeys.liked,
    title: 'My Saved Images',
  },
];

export const myLookBoardsTabsConfig = [
  {
    name: lookBoardSourceKeys.my,
    title: 'My Curated Look Boards',
  },
  {
    name: lookBoardSourceKeys.liked,
    title: 'My Saved Look Boards',
  },
];

export const myImageTypeOptions = [
  {
    label: 'All Images',
    value: imageTypeKeys.all,
  },
  {
    label: 'Room Images',
    value: imageTypeKeys.room,
  },
  {
    label: 'Single Item Images',
    value: imageTypeKeys.single,
  },
];

export const myLookBoardsTypeOptions = [
  {
    label: 'All Curated Look Boards',
    value: lookBoardTypeKeys.all,
  },
  {
    label: 'Curated Room Image Look Boards',
    value: lookBoardTypeKeys.room,
  },
  {
    label: 'Curated Single Item  Look Boards',
    value: lookBoardTypeKeys.single,
  },
];

export const myImagesSortOptions = [
  {
    label: 'Newest',
    value: imageSortKeys.newest,
  },
  {
    label: 'Highest rated',
    value: imageSortKeys.rated,
  },
];

export const myLookBoardsSortOptions = [
  {
    label: 'Newest',
    value: lookBoardSortKeys.newest,
  },
  {
    label: 'Highest rated',
    value: lookBoardSortKeys.highest,
  },
];

export const lookBoardStatusRadioOptions = [
  { value: lookBoardStatusKeys.published, label: 'Published' },
  { value: lookBoardStatusKeys.drafts, label: 'Drafts' },
];

export const ambassadorNavLinksConfig = [
  {
    route: routesByName.dashboard.ambassadorManagement,
    title: 'Ambassador overview',
    miniTitle: 'Ambassadors',
    icon: 'ambassador',
  },
  {
    route: routesByName.dashboard.ambassadorShowcase,
    title: 'Setup or Edit Showcase Page',
    icon: 'browser',
  },
  {
    route: routesByName.dashboard.ambassadorTeam,
    title: 'My Nominated Ambassador Team',
    icon: 'follow',
  },
];

export const ambassadorInfoMenuItemsConfig = [
  {
    id: 1,
    title: 'Ambassador Overview',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut ?',
    icon: 'ambassador',
  },
  {
    id: 2,
    title: 'Showcase Setup',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut ?',
    icon: 'browser',
  },
  {
    id: 3,
    title: 'Nominations',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut ?',
    icon: 'follow',
  },
];

export const infoMenuConfig = {
  title: 'Information',
  items: [
    {
      id: 1,
      title: 'My Images',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut ?',
      icon: 'pictures',
    },
    {
      id: 2,
      title: 'My Look Boards',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut ?',
      icon: 'sofa',
    },
    {
      id: 3,
      title: 'My Requests',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut ?',
      icon: 'exchange',
    },
    {
      id: 4,
      title: 'Community',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut ?',
      icon: 'followers',
    },
    {
      id: 5,
      title: 'Messages',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut ?',
      icon: 'envelope',
    },
  ],
};

export const getStartedSidebarConfig = [
  {
    route: routesByName.dashboard.images,
    title: 'Add images',
    description: 'Add Your Favorite Room Images',
    image: addImg,
  },
  {
    route: routesByName.curateTheLook.index,
    title: 'Curate images',
    description: 'Curate Requests and your Favorite Room Images',
    image: curateImg,
  },
  {
    route: routesByName.dashboard.ambassadorShowcase,
    title: 'Showcase Page',
    description: 'Setup Your Showcase Page',
    image: setupImg,
  },
];
