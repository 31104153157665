import React from 'react';
import PropTypes from 'prop-types';
import classes from './ImagesTable.module.scss';

const createRows = (items) => {
  const size = 2;
  const rows = [];
  for (let i = 0; i < Math.ceil(items.length / size); i += 1) {
    rows[i] = { id: i, items: items.slice(i * size, i * size + size) };
  }
  return rows;
};

const ImagesTable = ({ imgUrls }) => (
  <table className={classes.root}>
    <tbody>
      {createRows(imgUrls).map(({ id, items }) => (
        <tr className={classes.row} key={id}>
          {items.map(({ id: imgID, url }) => (
            <td className={classes.cell} key={imgID}>
              <img src={url} alt="" />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

ImagesTable.propTypes = {
  imgUrls: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
    })
  ).isRequired,
};

export default ImagesTable;
