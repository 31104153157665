import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import classes from './ColorPoint.module.scss';

const ColorPoint = ({
  id,
  size,
  color,
  title,
  className,
  interactive,
  selected,
  onChange,
  ...rest
}) => {
  const handleToggle = useCallback(() => {
    onChange(id);
  }, [id, onChange]);

  return (
    <div
      title={title}
      className={clsx(
        classes.root,
        classes[size],
        {
          'cursor-pointer': interactive,
          [classes.selected]: selected,
        },
        className
      )}
      style={{
        backgroundColor: color,
      }}
      onClick={interactive ? handleToggle : undefined}
      {...rest}
    />
  );
};

ColorPoint.propTypes = {
  id: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  title: PropTypes.string,
  className: PropTypes.string,
  interactive: PropTypes.bool,
  selected: PropTypes.bool,
  onChange: PropTypes.func,
};

ColorPoint.defaultProps = {
  size: 'lg',
  title: '',
  className: '',
  interactive: false,
  selected: undefined,
  onChange: undefined,
};

export default ColorPoint;
