import { validationMessages } from 'modules/pinMedia/constants';

const urlPattern = /(http:|https:)+[^\s]+[\w]/;

const pinMediaValidator = ({ url }) => {
  const errors = {};

  if (!url) {
    errors.url = [validationMessages.required];
  } else if (!urlPattern.test(url)) {
    errors.url = [validationMessages.urlNotMatch];
  }

  return errors;
};

export default pinMediaValidator;
