import React from 'react';
import { toastr } from 'react-redux-toastr';
import ToastrIconContainer from 'libs/toastr/ToastrIconContainer/ToastrIconContainer';
import SpriteIcon from 'components/ui/SpriteIcon';

const infoToastr = (title, message, options = {}) => {
  toastr.info(title, message, {
    icon: (
      <ToastrIconContainer type="info">
        <SpriteIcon name="info" size="sm" />
      </ToastrIconContainer>
    ),
    ...options,
    showCloseButton: true,
  });
};

export default infoToastr;
