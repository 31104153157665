import { useRef, useEffect } from 'react';
import throttle from 'lodash-es/throttle';

const useEventListener = (
  element,
  eventName,
  handler,
  { useThrottle = false, delay = 100 } = {}
) => {
  const savedHandler = useRef();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) {
      return undefined;
    }

    const eventListener = (event) => savedHandler.current(event);

    const updatedEventListener = useThrottle
      ? throttle(() => {
          eventListener();
        }, delay)
      : eventListener;

    element.addEventListener(eventName, updatedEventListener);
    return () => {
      element.removeEventListener(eventName, updatedEventListener);
    };
  }, [eventName, element, useThrottle, delay]);
};

export default useEventListener;
