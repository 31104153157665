import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/ui/Button/Button';
import SubStylesSlider from 'components/PreferenceQuiz/SubStylesSlider';

const SubStylesStep = ({
  isLoading,
  currentValue,
  options,
  onCancelEdit,
  onSaveSubStyles,
}) => {
  const [inputValue, setInputValue] = useState([]);

  useEffect(() => {
    if (Array.isArray(currentValue)) {
      setInputValue(currentValue);
    }
  }, [currentValue]);

  const handleLikeSubStyle = useCallback(
    (subStyle) => {
      let valueCopy = [...inputValue];
      const isSubStyleSelected = inputValue.includes(subStyle);

      if (isSubStyleSelected) {
        valueCopy = valueCopy.filter((item) => item !== subStyle);
      } else {
        valueCopy.push(subStyle);
      }
      setInputValue(valueCopy);
    },
    [inputValue]
  );

  const handleSaveSubStyles = useCallback(() => {
    onSaveSubStyles(inputValue);
  }, [onSaveSubStyles, inputValue]);

  return (
    <>
      <div>
        {options.map(({ id, name, subStyles }) => (
          <div key={id}>
            <p className="font-semi-bold mb-2">{name}</p>
            <SubStylesSlider
              currentValue={inputValue}
              subStyles={subStyles}
              onLike={handleLikeSubStyle}
            />
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-center pt-3">
        <Button
          inline
          className="mr-2"
          onClick={handleSaveSubStyles}
          disabled={isLoading}
        >
          Next
        </Button>
        <Button
          inline
          variant="outlined"
          onClick={onCancelEdit}
          disabled={isLoading}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

SubStylesStep.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  currentValue: PropTypes.arrayOf(PropTypes.number),
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onCancelEdit: PropTypes.func.isRequired,
  onSaveSubStyles: PropTypes.func.isRequired,
};

SubStylesStep.defaultProps = {
  currentValue: undefined,
};

export default SubStylesStep;
