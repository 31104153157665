import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from './DiscoverContent.module.scss';

const RoomTypes = ({ roomTypes }) => {
  return (
    <div className={classes.roomTypes}>
      <ul className={classes.btnList}>
        <li>
          <div className={classes.btn}>
            <SpriteIcon name="checked" className={classes.icon} />
            Room Type
          </div>
        </li>
        <li>
          <div className={clsx([classes.btn, classes.primary])}>Style</div>
        </li>
        <li>
          <div className={classes.btn}>Color</div>
        </li>
        <li>
          <div className={classes.btn}>Items</div>
        </li>
      </ul>
      <div className={classes.titleSm}>Tell Us If You Like The Room Shown</div>
      <ul className={classes.rooms}>
        {roomTypes.map(({ id, type, imgUrl }) => (
          <li key={id}>
            <div className={classes.room}>
              <div className={classes.img}>
                <img src={imgUrl} alt="" />
              </div>
              <div className={classes.type}>{type}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

RoomTypes.propTypes = {
  roomTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      imgUrl: PropTypes.string,
    })
  ).isRequired,
};

export default RoomTypes;
