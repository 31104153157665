import { supportFormFields, errorMessages } from 'modules/support/constants';

const supportFormValidator = ({
  [supportFormFields.name]: name,
  [supportFormFields.email]: email,
  [supportFormFields.subject]: subject,
  [supportFormFields.message]: message,
}) => {
  const errors = {};

  if (!name) {
    errors.name = errorMessages.required;
  }

  if (!email) {
    errors.email = errorMessages.required;
  }

  if (!subject) {
    errors.subject = errorMessages.required;
  }

  if (!message) {
    errors.message = errorMessages.required;
  }

  return errors;
};

export default supportFormValidator;
