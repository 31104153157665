import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance';

const urlPrefix = '/v1';

const lookBoardService = {
  getLookBoards(params) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/look-board/search`,
      params
    );
  },

  createLookBoard(data) {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/look-board`, data);
  },

  submitLookBoard(lookBoardId, data) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/look-board/${lookBoardId}/submit`,
      data
    );
  },

  deleteLookBoard(id) {
    return AuthorizedAxiosInstance.delete(`${urlPrefix}/look-board/${id}`);
  },

  toggleLike(lookBoardId, isLiked) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/look-board/${lookBoardId}/like`,
      { isLiked }
    );
  },
};

export default lookBoardService;
