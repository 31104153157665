import React from 'react';
import PropTypes from 'prop-types';
import InspirationImage from 'components/imageThumbnails/InspirationImage/InspirationImage';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import ImageLink from 'components/imageThumbnails/ImageLink';
import OverlayLabel from 'components/imageThumbnails/OverlayLabel/OverlayLabel';
import classes from './MainImage.module.scss';

const MainImage = ({ link, overlayLabel, ...rest }) => (
  <InspirationImage
    {...rest}
    staticOverlay={
      <div className="h-100 flex-center">
        <OverlayLabel text="image have been removed due to copyright issue" />
      </div>
    }
    hoverOverlay={
      <div className="h-100 flex-column flex-vertical-center justify-content-between">
        <div className="w-100 d-flex justify-content-end">
          <IconButton color="secondary">
            <SpriteIcon name="loupe" size="md" />
          </IconButton>
        </div>
        <p className={classes.hoverLabel}>{overlayLabel}</p>
        <div className="w-100 d-flex align-items-end">
          <div className="flex-fill text-ellipsis">
            <ImageLink url={link} />
          </div>
          <IconButton variant="inverted-grey" size="sm" className="mr-1">
            <SpriteIcon name="like" size="sm" />
          </IconButton>
          <IconButton variant="inverted-grey" size="sm" className="mr-1">
            <SpriteIcon name="add-folder" size="sm" />
          </IconButton>
          <IconButton variant="inverted-grey" size="sm" className="mr-1">
            <SpriteIcon name="share" size="sm" />
          </IconButton>
          <IconButton variant="inverted-grey" size="sm">
            <SpriteIcon name="more-vertical" size="sm" />
          </IconButton>
        </div>
      </div>
    }
  />
);

MainImage.propTypes = {
  link: PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  overlayLabel: PropTypes.string.isRequired,
};

export default MainImage;
