import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import Button from 'components/ui/Button/Button';
import SquareModal from 'components/modals/SquareModal/SquareModal';
import Card from 'components/Card/Card';
import requestModalImg from 'assets/img/home-page/request-modal.png';
import ImagesTable from 'components/ImagesTable/ImagesTable';
import { imagesConfig } from 'modules/home/constants';
import classes from './RequestModal.module.scss';

const RequestModal = ({ open, onClose }) => {
  const settings = useMemo(
    () => ({
      title: 'Request the Look',
      description:
        'Request the Look of your favorite room images and home furnishing items',
    }),
    []
  );

  return (
    <SquareModal open={open} onClose={onClose} {...settings}>
      <div className={classes.root}>
        <div className="container-fluid mb-4">
          <div className="row">
            <div className="col-6 d-flex flex-column align-items-center">
              <h3 className={classes.title}>Request the Look of image</h3>
              <img className="w-100" src={requestModalImg} alt="" />
            </div>
            <div className="col-6 d-flex flex-column align-items-center">
              <h3 className={classes.title}>Get Look Board Recommendations</h3>
              <Card
                classes={{ root: classes.card }}
                title="Oval Shabby Chic Table"
              >
                <ImagesTable imgUrls={imagesConfig.ovalShabyCollection} />
              </Card>
            </div>
          </div>
        </div>
        <div className="flex-center">
          <Link to={routesByName.requestTheLook.index}>
            <Button className={classes.btn}>Request the Look</Button>
          </Link>
        </div>
      </div>
    </SquareModal>
  );
};

RequestModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RequestModal;
