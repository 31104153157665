const appConfig = {
  isDev: process.env.NODE_ENV === 'development',
  appVersion: process.env.REACT_APP_appVersion,
  apiUrl: process.env.REACT_APP_apiUrl,
  configUrl: process.env.REACT_APP_appConfigUrl,
  reCaptchaSiteKey: process.env.REACT_APP_reCaptchaSiteKey,
  sentryDSN: process.env.REACT_APP_sentryDSN,
};

export default appConfig;
