import React, { useCallback, useEffect, useState } from 'react';
import LookBoard from 'components/lookBoardThumbnails/LookBoard/LookBoard';
import LookBoardPreview from 'components/lookBoardThumbnails/LookBoard/LookBoardPreview';

const lookBoardMock = [
  {
    id: 1,
    columnCount: 1,
    items: [
      {
        id: 1,
        src: 'https://picsum.photos/215/215',
      },
    ],
  },
  {
    id: 3,
    columnCount: 2,
    items: [
      {
        id: 1,
        src: 'https://picsum.photos/215/215',
      },
      {
        id: 2,
        src: 'https://picsum.photos/215/215',
      },
    ],
  },
  {
    id: 5,
    columnCount: 3,
    items: [
      {
        id: 1,
        src: 'https://picsum.photos/215/215',
      },
      {
        id: 2,
        src: 'https://picsum.photos/215/215',
      },
      {
        id: 3,
        src: 'https://picsum.photos/215/215',
      },
    ],
  },
  {
    id: 2,
    columnCount: 1,
    items: [
      {
        id: 1,
        src: 'https://picsum.photos/215/215',
      },
      {
        id: 2,
        src: 'https://picsum.photos/215/215',
      },
      {
        id: 3,
        src: 'https://picsum.photos/215/215',
      },
      {
        id: 4,
        src: 'https://picsum.photos/215/215',
      },
    ],
  },
  {
    id: 4,
    columnCount: 2,
    items: [
      {
        id: 1,
        src: 'https://picsum.photos/215/215',
      },
      {
        id: 2,
        src: 'https://picsum.photos/215/215',
      },
      {
        id: 3,
        src: 'https://picsum.photos/215/215',
      },
      {
        id: 4,
        src: 'https://picsum.photos/215/215',
      },
      {
        id: 5,
        src: 'https://picsum.photos/215/215',
      },
      {
        id: 6,
        src: 'https://picsum.photos/215/215',
      },
    ],
  },
  {
    id: 6,
    columnCount: 3,
    items: [
      {
        id: 1,
        src: 'https://picsum.photos/215/215',
      },
      {
        id: 2,
        src: 'https://picsum.photos/215/215',
      },
      {
        id: 3,
        src: 'https://picsum.photos/215/215',
      },
      {
        id: 4,
        src: 'https://picsum.photos/215/215',
      },
      {
        id: 5,
        src: 'https://picsum.photos/215/215',
      },
      {
        id: 6,
        src: 'https://picsum.photos/215/215',
      },
      {
        id: 7,
        src: 'https://picsum.photos/215/215',
      },
      {
        id: 8,
        src: 'https://picsum.photos/215/215',
      },
      {
        id: 9,
        src: 'https://picsum.photos/215/215',
      },
    ],
  },
];

const LookBoardThumbnails = () => {
  const [previewList, setPreviewList] = useState([]);
  const [currentLookBoard, setCurrentLookBoard] = useState({
    ...lookBoardMock[5],
    collapsed: true,
  });

  const addPlaceholders = (items) => {
    switch (items.length) {
      case 2: {
        const itemsCopy = [...items];
        itemsCopy.splice(
          1,
          0,
          { id: itemsCopy.length + 1 },
          { id: itemsCopy.length + 2 }
        );
        return itemsCopy;
      }
      case 3: {
        const itemsCopy = [...items];
        itemsCopy.push({ id: items.length + 1 });
        return itemsCopy;
      }
      default: {
        return items;
      }
    }
  };

  useEffect(() => {
    const listWithPlaceholders = lookBoardMock.map((lookboard) => ({
      ...lookboard,
      items: addPlaceholders(lookboard.items),
    }));
    setPreviewList(listWithPlaceholders);
  }, []);

  const handleToggle = useCallback(() => {
    setCurrentLookBoard({
      ...currentLookBoard,
      collapsed: !currentLookBoard.collapsed,
    });
  }, [currentLookBoard]);

  return (
    <>
      <div className="row mb-5">
        {previewList.map(({ id, items }) => (
          <div className="col-4" key={id}>
            <LookBoardPreview items={items} className="mb-4" />
          </div>
        ))}
      </div>
      <div className="row">
        <div className="col-5">
          <LookBoard {...currentLookBoard} onToggleCollapse={handleToggle} />
        </div>
      </div>
    </>
  );
};

export default LookBoardThumbnails;
