import React from 'react';
import PropTypes from 'prop-types';
import BasicModal from 'components/modals/BasicModal/BasicModal';

const InfoPopup = ({ open, onClose }) => {
  return (
    <BasicModal open={open} onClose={onClose} closeBtn={false}>
      <p
        className="text-sm font-italic text-center"
        style={{ lineHeight: 1.8 }}
      >
        <span className="primary-color">After validation</span> your uploaded{' '}
        <br />
        image will appear in the
        <br />
        <span className="primary-color">Get the Look</span> section
      </p>
    </BasicModal>
  );
};

InfoPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default InfoPopup;
