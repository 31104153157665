import PropTypes from 'prop-types';
import { priorityItemShape } from 'modules/requestTheLook/requestDetails/requestForm/propTypes';

export const detailsPreviewProps = {
  title: PropTypes.string.isRequired,
  requestType: PropTypes.string.isRequired,
  imgType: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  styles: PropTypes.arrayOf(PropTypes.string).isRequired,
  priorityItems: PropTypes.arrayOf(priorityItemShape).isRequired,
};
