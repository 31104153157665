export const stepsConfig = [
  {
    number: 1,
    title: 'Your Description',
    subtitle: 'What Best Describes You',
    selectDescription: 'Select one',
    progress: 0,
  },
  {
    number: 2,
    title: 'Favorite Styles',
    subtitle: 'What Style(s) do you like best',
    selectDescription: 'Select up to 10',
    progress: 18,
  },
  {
    number: 3,
    title: 'Sub Styles',
    subtitle: 'What  Sub Style(s) do you like best',
    selectDescription:
      'Indicate which room images you like by click on the heart',
    progress: 35,
  },
  {
    number: 4,
    title: 'Room Types',
    subtitle: 'Which Room Types to you Prefer to Decorate',
    selectDescription: 'Scroll through to select up to 5',
    progress: 50,
  },
  {
    number: 5,
    title: 'Favorite Colors',
    subtitle: 'What colors do you like best',
    selectDescription: 'Select up to 10 Colors',
    progress: 67,
  },
  {
    number: 6,
    title: 'Price Range',
    subtitle: 'What is your Price Range',
    selectDescription: 'Select all that apply',
    progress: 85,
  },
];
