import { imageTypeKeys } from 'constants/inspirationImageSearchParams';

export const requestFormFields = {
  title: 'title',
};

export const errorMessages = {
  required: 'This field is required',
};

export const requestFormLabels = {
  [imageTypeKeys.room]: 'Room Images',
  [imageTypeKeys.single]: 'Single Item',
};

export const defaultPriorityItem = { label: '', value: null };
