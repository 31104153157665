import React from 'react';
import PropTypes from 'prop-types';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import classes from './OwnershipInfoPopup.module.scss';

const OwnershipInfoPopup = ({ open, onClose }) => {
  return (
    <BasicModal open={open} onClose={onClose} classes={{ root: classes.root }}>
      <p className={classes.title}>Image Ownership</p>
      <p className={classes.description}>
        If you exclusively own the copyright to this image, then select &ldquo;I
        own the Image&rdquo;. This will give you the right to specify how you
        want the image used on Pin the Look.
      </p>
    </BasicModal>
  );
};

OwnershipInfoPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default OwnershipInfoPopup;
