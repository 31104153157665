import React from 'react';
import PropTypes from 'prop-types';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import authModalImg from 'assets/img/contact-page/banner.png';
import { routesByName } from 'constants/routes';
import SignUpContainer from 'modules/auth/signUp/SignUpContainer';
import SignInContainer from 'modules/auth/signIn/SignInContainer';
import classes from './AuthModal.module.scss';

const AuthModal = ({ action, onClose }) => (
  <BasicModal
    open={Boolean(action)}
    onClose={onClose}
    classes={{
      root: 'p-0',
      closeBtn: classes.closeBtn,
    }}
    fullWidth
    maxWidth="md"
    scroll="body"
  >
    <div className="d-flex">
      <div className={classes.formContainer}>
        {action === routesByName.auth.signIn && (
          <SignInContainer onCloseModal={onClose} />
        )}
        {action === routesByName.auth.signUp && (
          <SignUpContainer onCloseModal={onClose} />
        )}
      </div>
      <div className="w-50">
        <div className={classes.imgContainer}>
          <img src={authModalImg} alt="" />
          <div className={classes.banner}>
            <div className="position-relative text-center mb-1">
              <span className={classes.bannerTitle}>Pin The Look</span>
              <div className={classes.crossline} />
            </div>
            <p className={classes.bannerSubtitle}>
              be a part of creating something beautiful
            </p>
          </div>
        </div>
      </div>
    </div>
  </BasicModal>
);

AuthModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  action: PropTypes.string,
};

AuthModal.defaultProps = {
  action: undefined,
};

export default AuthModal;
