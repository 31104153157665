import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import classes from 'components/PreferenceQuiz/steps/steps.module.scss';
import priceRangesOptionShape from 'components/PreferenceQuiz/propTypes/priceRangesOptionShape';
import Button from 'components/ui/Button/Button';

const PriceStep = ({
  isLoading,
  currentValue,
  options,
  onCancelEdit,
  onSavePriceRange,
}) => {
  const [inputValue, setInputValue] = useState(currentValue);

  const handleSelect = useCallback(({ currentTarget }) => {
    const currentVal = currentTarget.getAttribute('data-range');
    setInputValue(Number.parseInt(currentVal, 10));
  }, []);

  const handleSavePriceRange = useCallback(() => {
    onSavePriceRange(inputValue);
  }, [onSavePriceRange, inputValue]);

  return (
    <>
      <div className="d-flex justify-content-around pt-5">
        {options.map(({ id, name, title }) => (
          <div
            className="d-flex flex-column align-items-center cursor-pointer"
            key={id}
            data-range={id}
            onClick={handleSelect}
          >
            <div
              className={clsx(classes.priceOptionIcon, {
                [classes.selected]: inputValue === id,
              })}
            >
              {name}
            </div>
            <p
              className={clsx(classes.priceOptionTitle, {
                'secondary-color': inputValue === id,
              })}
            >
              {title}
            </p>
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-center">
        <Button
          inline
          className="mr-2"
          onClick={handleSavePriceRange}
          disabled={isLoading}
        >
          Submit
        </Button>
        <Button
          inline
          variant="outlined"
          onClick={onCancelEdit}
          disabled={isLoading}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

PriceStep.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  currentValue: PropTypes.number,
  options: PropTypes.arrayOf(priceRangesOptionShape).isRequired,
  onCancelEdit: PropTypes.func.isRequired,
  onSavePriceRange: PropTypes.func.isRequired,
};

PriceStep.defaultProps = {
  currentValue: undefined,
};

export default PriceStep;
