import React from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ColorPoint from 'components/colorSelectors/ColorPoint/ColorPoint';

const useStyles = makeStyles({
  root: {
    columnCount: 4,
  },
});

const ColorSelect = ({ colorList, selectedColors, onChange }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {colorList.map(({ id, title, color }) => (
        <div className="mb-2" key={id}>
          <FormControlLabel
            control={
              <Checkbox
                id={String(id)}
                checked={Boolean(selectedColors[id])}
                onChange={onChange}
                name={title}
                size="small"
              />
            }
            label={
              <div className="d-flex align-items-center">
                <ColorPoint color={color} id={id} size="xl" className="mr-2" />
                <span>{title}</span>
              </div>
            }
          />
        </div>
      ))}
    </div>
  );
};

ColorSelect.propTypes = {
  colorList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      color: PropTypes.string,
    })
  ).isRequired,
  selectedColors: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ColorSelect;
