import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import canvasClasses from 'modules/curateTheLook/createLookBoard/components/CreateLookBoardCanvas/CreateLookBoardCanvas.module.scss';
import SpriteIcon from 'components/ui/SpriteIcon';
import IconButton from 'components/ui/IconButton/IconButton';
import SelectColorPopover from 'components/modals/SelectColorPopover/SelectColorPopover';
import classes from './EditableLookBoardHeader.module.scss';

const EditableLookBoardHeader = ({
  color,
  colors,
  title,
  onDisableEditMode,
}) => {
  const inputRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const [lookBoardColor, setLookBoardColor] = useState(color);
  const [inputValue, setInputValue] = useState(title);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleColorPickerOpen = useCallback(({ currentTarget }) => {
    setAnchorEl(currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleApplyColors = useCallback(
    (id) => {
      setLookBoardColor(id);
      handleClose();
    },
    [handleClose]
  );

  const handleSaveLookBoardState = useCallback(() => {
    onDisableEditMode({
      colorId: lookBoardColor,
      title: inputValue || title,
    });
  }, [title, onDisableEditMode, lookBoardColor, inputValue]);

  const handleChangeInputValue = useCallback(({ currentTarget: { value } }) => {
    setInputValue(value);
  }, []);

  return (
    <>
      <div
        className={canvasClasses.title}
        style={{ backgroundColor: colors[lookBoardColor].code }}
      >
        <input
          ref={inputRef}
          value={inputValue}
          placeholder={title}
          onChange={handleChangeInputValue}
          className={classes.titleInput}
        />
        <div className={classes.selectColorSection}>
          <span className="font-title text-lg text-capitalize mr-5">
            Edit section
          </span>
          <IconButton
            variant="inverted-grey"
            color="secondary"
            size="sm"
            onClick={handleColorPickerOpen}
          >
            <SpriteIcon name="brush" size="sm" />
          </IconButton>
        </div>
        <IconButton
          variant="inverted-white"
          color="secondary"
          size="sm"
          className={canvasClasses.editBtn}
          onClick={handleSaveLookBoardState}
        >
          <SpriteIcon name="cross" size="sm" />
        </IconButton>
      </div>
      <SelectColorPopover
        currentColor={lookBoardColor}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        onApply={handleApplyColors}
      />
    </>
  );
};

EditableLookBoardHeader.propTypes = {
  color: PropTypes.number.isRequired,
  colors: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  onDisableEditMode: PropTypes.func.isRequired,
};

export default EditableLookBoardHeader;
