import React from 'react';
import PropTypes from 'prop-types';
import classes from './VisualizeIdeas.module.scss';

const ProductStreamTab = ({ items }) => (
  <div className={classes.productStreamContainer}>
    {items.map(({ id, title, items: products }) => (
      <div key={id} className={classes.row}>
        <div className={classes.rowTitle}>
          <span>{title}</span>
        </div>
        <div className="d-flex justify-content-between">
          {products.map(({ id: itemId, imgUrl, price }) => (
            <div key={itemId} className="px-1 text-center d-flex flex-column">
              <img className={classes.rowImage} src={imgUrl} alt="" />
              <div className="mt-2 text-sm primary-color">&#36;{price}</div>
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>
);

ProductStreamTab.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          imgUrl: PropTypes.string.isRequired,
          price: PropTypes.number.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
};

export default ProductStreamTab;
