import React from 'react';
import ProfilePhoto from 'components/photoUploaders/ProfilePhoto';
import AmbassadorPhotoUpload from 'components/photoUploaders/AmbassadorPhotoUpload';

const firstName = 'John';
const lastName = 'Doe';
// const imgUrl = 'https://picsum.photos/100';
const imgUrl = null;

const Uploaders = () => {
  return (
    <>
      <ProfilePhoto firstName={firstName} lastName={lastName} src={imgUrl} />
      <AmbassadorPhotoUpload />
    </>
  );
};

export default Uploaders;
