import {
  APP_INITED,
  APP_SET_CHECKSUM,
  APP_SET_CONFIG,
  APP_UPDATE_ENUMS,
} from 'modules/app/store/constants';

const initialState = {
  inited: false,
  config: null,
  checksum: null,
  enums: {},
};

const appReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case APP_INITED: {
      return { ...state, inited: true };
    }
    case APP_SET_CONFIG: {
      return { ...state, config: payload };
    }
    case APP_SET_CHECKSUM: {
      return { ...state, checksum: payload };
    }
    case APP_UPDATE_ENUMS: {
      return {
        ...state,
        enums: {
          ...state.enums,
          ...payload,
        },
      };
    }
    default:
      return state;
  }
};

export default appReducer;
