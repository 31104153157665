import React from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';

const TextInput = ({ className, ...rest }) => (
  <div className={clsx('mb-3', className)}>
    <TextField fullWidth {...rest} />
  </div>
);

TextInput.propTypes = {
  className: PropTypes.string,
};

TextInput.defaultProps = {
  className: '',
};

export default TextInput;
