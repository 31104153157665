import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import TemplateSelect from 'modules/curateTheLook/createLookBoard/components/TemplateSelect/TemplateSelect';
import CreateLookBoardCanvas from 'modules/curateTheLook/createLookBoard/components/CreateLookBoardCanvas/CreateLookBoardCanvas';

const templatesConfig = [
  {
    id: 1,
    columnCount: 1,
    title: 'One Column Template',
    imageList: [
      {
        id: 1,
        src: 'assets/img/chair-1.png',
      },
    ],
  },
  {
    id: 2,
    columnCount: 2,
    title: 'Two Column Template',
    isDefault: true,
    imageList: [
      {
        id: 1,
        src: 'https://picsum.photos/210/210',
      },
      {
        id: 2,
        src: 'https://picsum.photos/210/210',
      },
    ],
  },
  {
    id: 3,
    columnCount: 3,
    title: 'Three Column Template',
    imageList: [
      {
        id: 1,
        src: 'https://picsum.photos/210/210',
      },
      {
        id: 2,
        src: 'https://picsum.photos/210/210',
      },
      {
        id: 3,
        src: 'https://picsum.photos/210/210',
      },
    ],
  },
];

const productsMock = {
  '1': { id: '1', content: 'Product 1' },
  '2': { id: '2', content: 'Product 2' },
  '3': { id: '3', content: 'Product 3' },
  '4': { id: '4', content: 'Product 4' },
};

const dndInitialState = {
  productList: {
    id: 'productList',
    productIds: Object.keys(productsMock),
  },
  dynamicColumns: {},
};

const useStyles = makeStyles({
  product: {
    padding: 16,
    backgroundColor: '#ffffff',
    border: '1px solid green',
    marginBottom: 8,
    userSelect: 'none',
  },
});

const CreateLookBoardArea = () => {
  const classes = useStyles();

  const [productsLibrary] = useState(productsMock);
  const [templateList] = useState(templatesConfig);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [dndState, setDndState] = useState(dndInitialState);

  const buildDroppableColumns = useCallback((count, startNumber = 0) => {
    const arrayFromColumnCount = Array.from(
      Array(count),
      (_, i) => i + startNumber + 1
    );

    const cols = arrayFromColumnCount.reduce((accum, currItem) => {
      const columnId = `item-${currItem}`;
      /* eslint-disable no-param-reassign */
      accum[columnId] = { id: columnId, product: null };

      return accum;
    }, {});

    setDndState((prevState) => {
      let prevColumns = {};
      const productListCopy = { ...prevState.productList };
      if (startNumber > 0) {
        prevColumns = { ...prevState.dynamicColumns };
      } else {
        productListCopy.productIds = Object.keys(productsMock);
      }

      return {
        ...prevState,
        productList: productListCopy,
        dynamicColumns: {
          ...prevColumns,
          ...cols,
        },
      };
    });
  }, []);

  useEffect(() => {
    const defaultTemplate = templateList.find(({ isDefault }) =>
      Boolean(isDefault)
    );

    setSelectedTemplate({ ...defaultTemplate, rows: 1 });
    buildDroppableColumns(defaultTemplate.columnCount);
    // eslint-disable-next-line
  }, []);

  const handleSelectTemplate = useCallback(
    (templateId) => {
      const template = templateList.find(({ id }) => templateId === id);

      setSelectedTemplate({ ...template, rows: 1 });
      buildDroppableColumns(template.columnCount);
    },
    [templateList, buildDroppableColumns]
  );

  const handleAddRow = useCallback(() => {
    const currentItemsLength = Object.values(dndState.dynamicColumns).length;
    buildDroppableColumns(selectedTemplate.columnCount, currentItemsLength);
  }, [dndState, buildDroppableColumns, selectedTemplate]);

  const productsColumn = dndState.productList;
  const products = productsColumn.productIds.map(
    (productId) => productsLibrary[productId]
  );

  const handleDragEnd = useCallback(
    (result) => {
      const { source, destination, draggableId } = result;

      if (!destination) {
        return;
      }

      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }

      const sourceColumn =
        dndState[source.droppableId] ||
        dndState.dynamicColumns[source.droppableId];
      const destColumn = dndState.dynamicColumns[destination.droppableId];
      const sourceColumnCopy = { ...sourceColumn };
      const destColumnCopy = { ...destColumn };

      if (sourceColumn.id === 'productList') {
        sourceColumnCopy.productIds.splice(source.index, 1);
      } else {
        sourceColumnCopy.product = null;
      }

      destColumnCopy.product = productsLibrary[draggableId];
      setDndState((prevState) => {
        if (sourceColumn.id === 'productList') {
          return {
            ...prevState,
            [sourceColumnCopy.id]: sourceColumnCopy,
            dynamicColumns: {
              ...prevState.dynamicColumns,
              [destColumnCopy.id]: destColumnCopy,
            },
          };
        }

        return {
          ...prevState,
          dynamicColumns: {
            ...prevState.dynamicColumns,
            [sourceColumnCopy.id]: sourceColumnCopy,
            [destColumnCopy.id]: destColumnCopy,
          },
        };
      });
    },
    [dndState, productsLibrary]
  );

  return (
    <>
      <div className="row mb-5">
        {templateList.map(
          ({ id, title, columnCount, imageList, isDefault }) => (
            <div className="col-4" key={id}>
              <TemplateSelect
                id={id}
                title={title}
                columnCount={columnCount}
                imageList={imageList}
                isDefault={isDefault}
                selected={id === (selectedTemplate && selectedTemplate.id)}
                onSelect={handleSelectTemplate}
              />
            </div>
          )
        )}
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="d-flex">
          <CreateLookBoardCanvas selectedTemplate={selectedTemplate} />

          <Droppable droppableId={productsColumn.id} isDropDisabled>
            {(provided) => (
              <div
                ref={provided.innerRef}
                style={{ background: '#ffffff', border: '1px solid blue' }}
                className="flex-fill"
              >
                {products.map(({ id, content }, index) => (
                  <Draggable key={id} draggableId={id} index={index}>
                    {({ draggableProps, dragHandleProps, innerRef }) => (
                      <>
                        <div
                          {...draggableProps}
                          {...dragHandleProps}
                          ref={innerRef}
                          className={classes.product}
                        >
                          {content}
                        </div>
                      </>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </>
  );
};

CreateLookBoardArea.propTypes = {};

export default CreateLookBoardArea;
