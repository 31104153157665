import {
  II_UPDATE_LIBRARY,
  II_UPDATE_IMAGE_LIKE_STATUS,
} from 'modules/inspirationImage/store/constants';

export const updateIILibraryAction = (list) => (dispatch) => {
  dispatch({ type: II_UPDATE_LIBRARY, payload: list });
};

export const updateIIStatusLikeAction = (id, isLiked) => (dispatch) => {
  dispatch({ type: II_UPDATE_IMAGE_LIKE_STATUS, payload: { id, isLiked } });
};
