import React from 'react';
import PropTypes from 'prop-types';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import CreateImageForm from 'modules/pinMedia/CreateImageForm/CreateImageForm';
import createImageValidator from 'modules/pinMedia/CreateImageForm/createImageValidator';
import classes from './SaveImageModal.module.scss';

const SaveImageModal = ({ open, onClose, imgUrl, onSaveImg }) => (
  <BasicModal open={open} onClose={onClose} maxWidth="md" scroll="body">
    <p className={classes.modalTitle}>Uploaded Image</p>
    <div className="flex-fill d-flex">
      <div className={`${classes.imageBlock} mr-3`}>
        <div className={classes.currentImageContainer}>
          <img src={imgUrl} alt="" />
        </div>
      </div>
      <div className={`${classes.formBlock} pr-2`}>
        <CreateImageForm validate={createImageValidator} onSubmit={onSaveImg} />
      </div>
    </div>
  </BasicModal>
);

SaveImageModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  imgUrl: PropTypes.string,
  onSaveImg: PropTypes.func.isRequired,
};

SaveImageModal.defaultProps = {
  imgUrl: null,
};

export default SaveImageModal;
