import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { loginAction } from 'modules/auth/store/actions';
import { connect } from 'react-redux';
import SignInComponent from 'modules/auth/signIn/SignInComponent';
import signInValidator from 'modules/auth/signIn/signInValidator';
import errorToastr from 'libs/toastr/errorToastr';

const SignInContainer = ({ login, onCloseModal }) => {
  const onSubmit = useCallback(
    async (values) => {
      try {
        await login(values);
        onCloseModal();
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
      return true;
    },
    [login, onCloseModal]
  );

  return <SignInComponent validateForm={signInValidator} onSubmit={onSubmit} />;
};

SignInContainer.propTypes = {
  login: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  login: loginAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInContainer);
