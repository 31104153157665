export const infoMenuConfig = {
  title: 'Get the Look',
  description: 'Get the Look Section Guide',
  items: [
    {
      id: 1,
      title: 'Inspiration Images Database',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut ?',
      icon: 'living-room',
    },
  ],
};

export const getTheLookTabKeys = {
  lookBoardView: 'lookBoardView',
  productStream: 'productStream',
  mixAndMatch: 'mixAndMatch',
};

export const getTheLookTabsConfig = [
  {
    value: getTheLookTabKeys.mixAndMatch,
    title: 'Mix & Match',
    icon: 'shuffle-icon',
  },
  {
    value: getTheLookTabKeys.lookBoardView,
    title: 'Look Board View',
    icon: 'dashboard',
  },
  {
    value: getTheLookTabKeys.productStream,
    title: 'Product Stream View',
    icon: 'living-room',
  },
];
