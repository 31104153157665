export const lookBoardSourceKeys = {
  all: 'all',
  my: 'my',
  liked: 'liked',
};

export const lookBoardTypeKeys = {
  all: 'all',
  room: 'room',
  single: 'single',
};

export const lookBoardSortKeys = {
  newest: 'newest',
  highest: 'highest',
};

export const lookBoardStatusKeys = {
  published: 'published',
  drafts: 'drafts',
};
