import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash-es/groupBy';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ProductsGroup from 'modules/getTheLook/components/ProductsGroup/ProductsGroup';
import SpriteIcon from 'components/ui/SpriteIcon';
import ProductItem from 'modules/getTheLook/components/ProductItem';
import customEvent from 'utils/customEvent';
import {
  SET_INITIAL_SCROLLER_HEIGHT,
  UPDATE_SCROLLER_HEIGHT,
} from 'constants/customEventNames';
import classes from 'modules/curateTheLook/createLookBoard/components/SelectedImageBlock/SelectedImageBlock.module.scss';

const ProductsStream = ({
  products,
  users,
  itemClasses,
  onToggleLikeProduct,
}) => {
  const history = useHistory();
  const [productGroups, setProductGroups] = useState({});
  const [selectedGroup, setSelectedGroup] = useState(null);

  useEffect(() => {
    const groups = groupBy(Object.values(products), 'itemClassId');
    setProductGroups(groups);
  }, [products]);

  const handleSelectGroup = useCallback((id) => {
    setSelectedGroup(id);
  }, []);

  const handleUnselectGroup = useCallback(() => {
    setSelectedGroup(null);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      customEvent.trigger(SET_INITIAL_SCROLLER_HEIGHT);
      customEvent.trigger(UPDATE_SCROLLER_HEIGHT);
    }, 0);
  }, [selectedGroup]);

  return (
    <>
      {selectedGroup ? (
        <div className="col-12">
          <div
            className="d-flex align-items-center cursor-pointer mb-3"
            onClick={handleUnselectGroup}
          >
            <SpriteIcon
              name="left-arrow"
              size="sm"
              className="primary-color mr-1"
            />
            <span className={classes.goBackBtn}>
              Back To All Matching Items
            </span>
          </div>
          <div className="row">
            {productGroups[selectedGroup].map((product) => (
              <div key={product.id} className="col-4 px-1 pb-2">
                <ProductItem
                  product={{ ...product }}
                  user={users[product.userId]}
                  onToggleLike={onToggleLikeProduct}
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        Object.keys(productGroups).map((itemClassId) => (
          <div className="col-12 mb-5" key={itemClassId}>
            <ProductsGroup
              itemClassId={itemClassId}
              groupName={itemClasses[itemClassId].name}
              products={productGroups[itemClassId]}
              users={users}
              onToggleLikeProduct={onToggleLikeProduct}
              onSelectGroup={handleSelectGroup}
            />
          </div>
        ))
      )}
    </>
  );
};

ProductsStream.propTypes = {
  products: PropTypes.shape({}).isRequired,
  users: PropTypes.shape({}).isRequired,
  itemClasses: PropTypes.shape({}).isRequired,
  onToggleLikeProduct: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  app: {
    enums: { itemClasses },
  },
}) => ({ itemClasses });

export default connect(mapStateToProps)(ProductsStream);
