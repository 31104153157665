import React from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles({
  avatar: {
    width: 60,
    height: 60,
    marginRight: 12,
  },
  label: {
    fontWeight: 600,
  },
});

const AmbassadorThumbnail = ({ label, src }) => {
  const classes = useStyles();

  return (
    <div className="d-inline-flex align-items-center">
      <Avatar className={classes.avatar} src={src} />
      <p className={classes.label}>{label}</p>
    </div>
  );
};

AmbassadorThumbnail.propTypes = {
  label: PropTypes.string.isRequired,
  src: PropTypes.string,
};

AmbassadorThumbnail.defaultProps = {
  src: undefined,
};

export default AmbassadorThumbnail;
