import React, { useCallback, useState } from 'react';
import { profileTabKeys, profileTabsConfig } from 'modules/account/constants';
import Tab from 'components/ui/Tab/Tab';
import ProfileContainer from 'modules/account/profileAndPreferences/profile/ProfileContainer';
import PreferencesContainer from 'modules/account/profileAndPreferences/preferences/PreferencesContainer';
import accountClasses from 'modules/account/Account.module.scss';

const ProfileAndPreferencesContainer = () => {
  const [activeTab, setActiveTab] = useState(profileTabsConfig[0].name);

  const handleTabChange = useCallback((tab) => {
    setActiveTab(tab);
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <p className={accountClasses.title}>Profile & Preferences</p>
        <div className="d-flex">
          {profileTabsConfig.map(({ name, title }) => (
            <Tab
              key={name}
              title={title}
              isActive={name === activeTab}
              name={name}
              onToggle={handleTabChange}
            />
          ))}
        </div>
      </div>
      {activeTab === profileTabKeys.profile && <ProfileContainer />}
      {activeTab === profileTabKeys.preferences && <PreferencesContainer />}
    </>
  );
};

export default ProfileAndPreferencesContainer;
