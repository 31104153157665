import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import buildResourceUrl from 'utils/buildResourceUrl';
import InspirationImage from 'components/imageThumbnails/InspirationImage/InspirationImage';
import { getProductImgUrl } from 'modules/app/store/selectors';
import ImageLink from 'components/imageThumbnails/ImageLink';
import classes from './ProductItem.module.scss';

const ProductItem = ({ id, url, userId, hash, productImgUrl }) => (
  <InspirationImage
    id={id}
    className={classes.root}
    imgUrl={buildResourceUrl(productImgUrl.small, userId, hash)}
    hoverOverlay={
      <div className="h-100 flex-center">
        <p
          className="text-center text-capitalize font-semi-bold text-sm"
          style={{ lineHeight: 1.3 }}
        >
          Drag & Drop item into template
        </p>
        <div className={classes.productLink}>
          <ImageLink url={url} />
        </div>
      </div>
    }
  />
);

ProductItem.propTypes = {
  id: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  hash: PropTypes.string.isRequired,
  productImgUrl: PropTypes.shape({
    small: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ app: { config } }) => ({
  productImgUrl: getProductImgUrl(config),
});

export default connect(mapStateToProps)(ProductItem);
