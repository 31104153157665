import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Fab from '@material-ui/core/Fab';
import BasicAvatar from 'components/ui/BasicAvatar';
import Button from 'components/ui/Button/Button';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from './CommunityCard.module.scss';

const CommunityCard = ({
  user: { id, firstName, lastName, avatarUrl, followed, followersCount },
  onFollow,
}) => {
  const handleFollowAction = useCallback(() => {
    onFollow({ id, followed: !followed });
  }, [onFollow, followed, id]);

  return (
    <div className={classes.root}>
      <div className="flex-vertical-center">
        <BasicAvatar
          className={classes.avatar}
          lastName={firstName}
          firstName={lastName}
          src={avatarUrl}
        />
      </div>
      <div className={classes.userInfo}>
        <h3
          className={`${classes.title} text-ellipsis`}
        >{`${firstName} ${lastName}`}</h3>
        <p className={`${classes.description} text-ellipsis`}>
          {`${followersCount} users follow ${firstName}`}
        </p>
      </div>
      <div
        className={clsx({
          'd-flex flex-column justify-content-between align-items-end': onFollow,
        })}
      >
        <Fab size="small" color="primary" className="mb-2">
          <MoreVertIcon />
        </Fab>
        {Boolean(onFollow) && (
          <Button
            variant={followed ? 'contained' : 'outlined'}
            className={clsx(classes.followBtn, {
              [classes.followed]: followed,
            })}
            size="sm"
            onClick={handleFollowAction}
          >
            {followed && (
              <SpriteIcon name="rounded-check" size="sm" className="mr-1" />
            )}
            Follow
          </Button>
        )}
      </div>
    </div>
  );
};

CommunityCard.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    avatarUrl: PropTypes.string,
    followersCount: PropTypes.number,
    followed: PropTypes.bool,
  }).isRequired,
  onFollow: PropTypes.func,
};

CommunityCard.defaultProps = {
  onFollow: undefined,
};

export default CommunityCard;
