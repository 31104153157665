export const approvalStatusKeys = {
  pending: 'pending',
  approved: 'approved',
  rejected: 'rejected',
};

export const publishStatusKeys = {
  notPublish: 'notPublish',
  published: 'published',
  personal: 'personal',
};

export const ownershipStatusKeys = {
  thirdPartyOwned: 'thirdPartyOwned',
  selfOwned: 'selfOwned',
};

export const permissionStatusKeys = {
  open: 'open',
  exclusive: 'exclusive',
};
