import React, { useCallback, useState } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Switch from 'components/inputs/Switch/Switch';

const Tumblers = () => {
  const [state, setState] = useState({
    primary: false,
    secondary: false,
  });

  const [radioState, setRadioState] = useState('female');

  const [switchState, setSwitchState] = useState(false);

  const handleChange = useCallback(
    (event) => {
      setState({ ...state, [event.target.name]: event.target.checked });
    },
    [state]
  );

  const handleRadioChange = useCallback((event) => {
    setRadioState(event.target.value);
  }, []);

  const handleSwitchChange = useCallback((event) => {
    setSwitchState(event.target.checked);
  }, []);

  return (
    <div className="row">
      <div className="col-4">
        <h2 className="title">Basic Checkbox</h2>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.primary}
              onChange={handleChange}
              name="primary"
              color="primary"
              size="small"
            />
          }
          label="primary Checkbox"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={state.secondary}
              onChange={handleChange}
              name="secondary"
              color="secondary"
              size="small"
            />
          }
          label="secondary Checkbox"
        />
      </div>
      <div className="col-4">
        <h2 className="title">Basic Radio</h2>
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={radioState}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            classes={{ label: radioState === 'female' && 'font-semi-bold' }}
            value="female"
            control={<Radio color="primary" />}
            label="Female"
          />
          <FormControlLabel value="male" control={<Radio />} label="Male" />
          <FormControlLabel value="other" control={<Radio />} label="Other" />
          <FormControlLabel
            value="disabled"
            disabled
            control={<Radio />}
            label="(Disabled option)"
          />
        </RadioGroup>
      </div>
      <div className="col-4">
        <h2 className="title">Switches</h2>
        <Switch
          onChange={handleSwitchChange}
          name="switch"
          label="Basic switcher"
          checked={switchState}
        />
      </div>
    </div>
  );
};

export default Tumblers;
