import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LookBoardPreview from 'components/lookBoardThumbnails/LookBoard/LookBoardPreview';
import buildResourceUrl from 'utils/buildResourceUrl';
import { getUserAvatarUrl } from 'modules/app/store/selectors';
import { toggleUnderConstructionModalAction } from 'modules/layout/store/actions';
import HoverOverlay from 'modules/getTheLook/components/HoverOverlay/HoverOverlay';
import { mediaShape } from 'modules/media/propTypes';

const LookBoardItem = ({
  lookBoard: { id, title, colorId, isLiked, products: productIds },
  user,
  products,
  onToggleLike,
  userAvatarUrl,
  toggleUnderConstructionModal,
}) => {
  const lookBoardProducts = useMemo(
    () => productIds.map((productId) => products[productId]),
    [productIds, products]
  );

  const avatarUrl = useMemo(
    () =>
      user.avatar &&
      buildResourceUrl(userAvatarUrl, user.avatar.userId, user.avatar.hash),
    [userAvatarUrl, user]
  );

  const handleUnderConstructionModalOpen = useCallback(() => {
    toggleUnderConstructionModal(true);
  }, [toggleUnderConstructionModal]);

  const handleToggleLike = useCallback(() => {
    onToggleLike(id, Number(!isLiked));
  }, [onToggleLike, id, isLiked]);

  return (
    <LookBoardPreview
      title={title}
      colorId={colorId}
      isLiked={isLiked}
      products={lookBoardProducts}
      hoverOverlay={
        <HoverOverlay
          firstName={user.firstName}
          lastName={user.lastName}
          avatarUrl={avatarUrl}
          isLiked={isLiked}
          onToggleLike={handleToggleLike}
          onOpenUnderConstructionModal={handleUnderConstructionModalOpen}
        />
      }
    />
  );
};

LookBoardItem.propTypes = {
  lookBoard: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    colorId: PropTypes.number.isRequired,
    isLiked: PropTypes.number.isRequired,
    products: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    avatar: mediaShape,
  }).isRequired,
  products: PropTypes.shape({}).isRequired,
  onToggleLike: PropTypes.func.isRequired,
  userAvatarUrl: PropTypes.string.isRequired,
  toggleUnderConstructionModal: PropTypes.func.isRequired,
};

const mapStateToProps = ({ app: { config } }) => ({
  userAvatarUrl: getUserAvatarUrl(config),
});

const mapDispatchToProps = {
  toggleUnderConstructionModal: toggleUnderConstructionModalAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(LookBoardItem);
