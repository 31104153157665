import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from './Slider.module.scss';

const SliderArrows = ({ onSlidePrev, onSlideNext }) => (
  <div className={classes.sliderArrows}>
    <button
      onClick={onSlidePrev}
      className={clsx(classes.btn, classes.prevBtn)}
      type="button"
    >
      <SpriteIcon name="prev-icon" size="sm" />
    </button>
    <button
      onClick={onSlideNext}
      className={clsx(classes.btn, classes.nextBtn)}
      type="button"
    >
      <SpriteIcon name="next-icon" size="sm" />
    </button>
  </div>
);

SliderArrows.propTypes = {
  onSlidePrev: PropTypes.func.isRequired,
  onSlideNext: PropTypes.func.isRequired,
};

export default SliderArrows;
