import {
  createProductFieldKeys,
  validationMessages,
} from 'modules/pinMedia/constants';

const createProductValidator = ({
  [createProductFieldKeys.name]: name,
  [createProductFieldKeys.itemClassId]: itemClassId,
}) => {
  const errors = {};

  if (!name) {
    errors[createProductFieldKeys.name] = [validationMessages.required];
  }

  if (!itemClassId) {
    errors[createProductFieldKeys.itemClassId] = [validationMessages.required];
  }

  return errors;
};

export default createProductValidator;
