import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classes from './AmbassadorManagement.module.scss';

const AmbassadorManagementSidebar = ({ config }) => (
  <aside className={classes.sidebar}>
    <h3 className={classes.title}>Get Started Now&gt;&gt;</h3>
    <ul className={classes.sidebarList}>
      {config.map(({ route, title, description, image }, index) => (
        <li key={route}>
          <p className="font-semi-bold text-lg mb-2">{`${
            index + 1
          }. ${description}`}</p>
          <Link className={classes.sidebarLink} to={route}>
            <div className={classes.sidebarLinkBackdrop}>{title}</div>
            <img src={image} alt="" />
          </Link>
        </li>
      ))}
    </ul>
  </aside>
);

AmbassadorManagementSidebar.propTypes = {
  config: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default AmbassadorManagementSidebar;
