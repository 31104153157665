import React, { useState, useCallback } from 'react';
import clsx from 'clsx';
import { infoMenuConfig } from 'modules/getTheLook/constants';
import InfoMenu from 'modules/layout/SideNav/InfoMenu/InfoMenu';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from 'modules/layout/SideNav/SideNav.module.scss';

const SideBar = () => {
  const [infoMenuOpen, setInfoMenuOpen] = useState(false);

  const handleOpenInfoMenu = useCallback(() => {
    setInfoMenuOpen(true);
  }, []);

  const handleCloseInfoMenu = useCallback(() => {
    setInfoMenuOpen(false);
  }, []);

  return (
    <div className={classes.root}>
      <div
        className={clsx(classes.navLink, {
          [classes.activeTabInfo]: infoMenuOpen,
        })}
        title="Info"
        onClick={handleOpenInfoMenu}
      >
        <SpriteIcon name="info" />
      </div>
      <div
        title="Inspiration Images"
        className={clsx(classes.navLink, classes.activeTab)}
      >
        <SpriteIcon name="living-room" size="lg" />
      </div>
      <InfoMenu
        config={infoMenuConfig}
        isOpen={infoMenuOpen}
        onClose={handleCloseInfoMenu}
      />
    </div>
  );
};

export default SideBar;
