import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import classes from './CustomScrollBar.module.scss';

const CustomScrollBar = ({ children, scrollBarRef, ...rest }) => {
  return (
    <Scrollbars
      ref={scrollBarRef}
      autoHeight
      renderTrackVertical={(props) => (
        <div {...props} className={classes.scrollbarTrack} />
      )}
      renderThumbVertical={(props) => (
        <div {...props} className={classes.scrollbarThumb} />
      )}
      {...rest}
    >
      {children}
    </Scrollbars>
  );
};

CustomScrollBar.propTypes = {
  children: PropTypes.node.isRequired,
  scrollBarRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

CustomScrollBar.defaultProps = {
  scrollBarRef: null,
};

export default CustomScrollBar;
