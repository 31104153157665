import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { inspirationImageShape } from 'modules/inspirationImage/propTypes';
import ImageItem from 'modules/curateTheLook/createLookBoard/components/ImageItem/ImageItem';
import NoLookBoardsModal from 'modules/getTheLook/components/NoLookBoardsModal/NoLookBoardsModal';
import transformArrayToMap from 'utils/transformArrayToMap';
import LookBoardItem from 'modules/getTheLook/components/LookBoardItem';
import {
  toggleLikeLookBoardAction,
  toggleLikeProductAction,
} from 'modules/getTheLook/store/actions';
import classes from 'modules/getTheLook/GetTheLook.module.scss';
import errorToastr from 'libs/toastr/errorToastr';
import { selectImageAction as selectImageCTL } from 'modules/curateTheLook/store/actions';
import { selectImageAction as selectImageRTL } from 'modules/requestTheLook/store/actions';
import { routesByName } from 'constants/routes';
import { getTheLookTabKeys } from 'modules/getTheLook/constants';
import ProductItem from 'modules/getTheLook/components/ProductItem';
import useCallbackRef from 'hooks/useCallbackRef';
import GTLSlider from 'modules/getTheLook/components/GTLSlider';

const ListItem = ({
  image: {
    id,
    url,
    isLiked,
    media: { userId, hash },
  },
  onOpenImgModal,
  activeTab,
  lookBoards,
  products,
  users,
  toggleLikeLookBoard,
  toggleLikeProduct,
  createLookBoard,
  createRequest,
}) => {
  const history = useHistory();
  const [sliderNode, sliderRef] = useCallbackRef();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [slideLength, setSlideLength] = useState(0);
  const [hover, setHover] = useState(false);

  const updateSlideLength = useCallback(() => {
    sliderNode.slickGoTo(0, true);
    setActiveSlideIndex(0);

    switch (activeTab) {
      case getTheLookTabKeys.lookBoardView: {
        setSlideLength(lookBoards.length);
        break;
      }
      case getTheLookTabKeys.productStream: {
        setSlideLength(Object.keys(products).slice(0, 6).length);
        break;
      }
      case getTheLookTabKeys.mixAndMatch: {
        setSlideLength(0);
        break;
      }
      default: {
        break;
      }
    }
  }, [activeTab, lookBoards, products, sliderNode]);

  useEffect(() => {
    if (lookBoards && sliderNode) {
      updateSlideLength();
    }
    // eslint-disable-next-line
  }, [activeTab, lookBoards, sliderNode]);

  const handleHover = useCallback(() => {
    setHover(true);
  }, []);

  const handleBlur = useCallback(() => {
    setHover(false);
  }, []);

  const handleSlideChange = useCallback((index) => {
    setActiveSlideIndex(index);
  }, []);

  const handleSlidePrev = useCallback(() => {
    sliderNode.slickPrev();
  }, [sliderNode]);

  const handleSlideNext = useCallback(() => {
    sliderNode.slickNext();
  }, [sliderNode]);

  const handleToggleLikeLookBoard = useCallback(
    async (lookBoardId, likeStatus) => {
      try {
        await toggleLikeLookBoard(id, lookBoardId, likeStatus);
      } catch (e) {
        errorToastr('Error', e.message);
      }
    },
    [toggleLikeLookBoard, id]
  );

  const handleToggleLikeProduct = useCallback(
    async (productId, likeStatus) => {
      try {
        await toggleLikeProduct(id, productId, likeStatus);
      } catch (e) {
        errorToastr('Error', e.message);
      }
    },
    [toggleLikeProduct, id]
  );

  const handleCreateLookBoard = useCallback(() => {
    createLookBoard(id);
    history.push(routesByName.curateTheLook.index);
  }, [createLookBoard, id, history]);

  const handleCreateRequest = useCallback(() => {
    createRequest(id);
    history.push(routesByName.requestTheLook.index);
  }, [createRequest, id, history]);

  const handleSelectImage = useCallback(() => {
    history.push(routesByName.getTheLook.details(id));
  }, [history, id]);

  return (
    <div
      className={clsx('d-flex position-relative', {
        [classes.hoveredItem]: hover,
      })}
    >
      <div
        className={`${classes.leftPanelWrapper}`}
        onMouseEnter={handleHover}
        onMouseLeave={handleBlur}
      >
        <ImageItem
          id={id}
          url={url}
          userId={userId}
          hash={hash}
          isLiked={isLiked}
          onOpenPreview={onOpenImgModal}
          onSelectImage={handleSelectImage}
        />
      </div>
      <div className="flex-fill pb-3 d-flex align-items-center">
        <div className={classes.mainContainer}>
          {!lookBoards && <div className="text-center">Loading...</div>}
          {lookBoards && lookBoards.length === 0 && (
            <NoLookBoardsModal
              onCreateLookBoard={handleCreateLookBoard}
              onCreateRequest={handleCreateRequest}
            />
          )}
          {lookBoards && lookBoards.length > 0 && (
            <GTLSlider
              sliderRef={sliderRef}
              slideLength={slideLength}
              activeSlideIndex={activeSlideIndex}
              onSlidePrev={handleSlidePrev}
              onSlideNext={handleSlideNext}
              onSlideChange={handleSlideChange}
              onViewAll={handleSelectImage}
            >
              {activeTab === getTheLookTabKeys.lookBoardView &&
                lookBoards.map((lookBoard) => (
                  <div key={lookBoard.id} className="px-1">
                    <LookBoardItem
                      lookBoard={lookBoard}
                      user={users[lookBoard.userId]}
                      products={products}
                      onToggleLike={handleToggleLikeLookBoard}
                    />
                  </div>
                ))}
              {activeTab === getTheLookTabKeys.productStream &&
                Object.values(products)
                  .slice(0, 6)
                  .map((product) => (
                    <div key={product.id} className="px-1">
                      <ProductItem
                        product={{ ...product }}
                        user={users[product.userId]}
                        onToggleLike={handleToggleLikeProduct}
                      />
                    </div>
                  ))}
            </GTLSlider>
          )}
        </div>
      </div>
    </div>
  );
};

ListItem.propTypes = {
  image: inspirationImageShape.isRequired,
  onOpenImgModal: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  lookBoards: PropTypes.arrayOf(PropTypes.shape({})),
  products: PropTypes.shape({}),
  users: PropTypes.shape({}),
  toggleLikeLookBoard: PropTypes.func.isRequired,
  toggleLikeProduct: PropTypes.func.isRequired,
  createLookBoard: PropTypes.func.isRequired,
  createRequest: PropTypes.func.isRequired,
};

ListItem.defaultProps = {
  lookBoards: null,
  products: {},
  users: {},
};

const mapStateToProps = (
  { getTheLook: { activeTab, lookBoardsData } },
  { image: { id } }
) => ({
  activeTab,
  lookBoards: lookBoardsData[id]?.lookBoards.slice(0, 6),
  products:
    lookBoardsData[id] && transformArrayToMap(lookBoardsData[id].products),
  users: lookBoardsData[id] && transformArrayToMap(lookBoardsData[id].users),
});

const mapDispatchToProps = {
  toggleLikeLookBoard: toggleLikeLookBoardAction,
  toggleLikeProduct: toggleLikeProductAction,
  createLookBoard: selectImageCTL,
  createRequest: selectImageRTL,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListItem);
