import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SpriteIcon from 'components/ui/SpriteIcon';
import SquareModal from 'components/modals/SquareModal/SquareModal';
import classes from './OverviewModal.module.scss';

const OverviewModal = ({
  open,
  onClose,
  modalTitle,
  config,
  modalDescription,
}) => (
  <SquareModal
    open={open}
    onClose={onClose}
    title={modalTitle}
    description={modalDescription}
  >
    <div className="d-flex justify-content-center py-5">
      {config.steps.map(({ id, title, icon, description, list }) => (
        <div key={id} className={classes.item}>
          <div className={classes.preview}>
            <div className={classes.icon}>
              <SpriteIcon name={icon} size="lg" />
            </div>
            <div
              className={clsx(classes.title, {
                'text-sm': config.fontSize === 'sm',
              })}
            >
              {title}
            </div>
          </div>
          <div className={classes.about}>
            <div
              className={clsx(classes.description, {
                'font-semi-bold': description.bold,
                [classes.descriptionSm]: config.fontSize === 'sm',
              })}
            >
              {description.text}
            </div>
            {list && (
              <ul
                className={clsx(classes.list, {
                  [classes.listSm]: config.fontSize === 'sm',
                })}
              >
                {list.map(({ id: itemId, title: itemTitle }) => (
                  <li key={itemId}>{itemTitle}</li>
                ))}
              </ul>
            )}
          </div>
        </div>
      ))}
    </div>
  </SquareModal>
);

OverviewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  modalDescription: PropTypes.string,
  config: PropTypes.shape({
    fontSize: PropTypes.oneOf(['sm', 'md']),
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        description: PropTypes.shape({
          text: PropTypes.string.isRequired,
          bold: PropTypes.bool.isRequired,
        }).isRequired,
        list: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
          })
        ),
      })
    ),
  }).isRequired,
};

OverviewModal.defaultProps = {
  modalDescription: null,
};

export default OverviewModal;
