import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { createProductFieldKeys } from 'modules/pinMedia/constants';
import { getEnumAsSelectOptions } from 'modules/app/store/selectors';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import SelectInputWrapper from 'components/finalFormWrappers/SelectInputWrapper';
import classes from 'modules/pinMedia/CreateImageForm/CreateImageForm.module.scss';

const CreateProductForm = ({
  validate,
  onSubmit,
  initialValues,
  itemClasses,
}) => (
  <Form
    initialValues={initialValues}
    onSubmit={onSubmit}
    validate={validate}
    render={({ handleSubmit }) => (
      <form
        className=" h-100 d-flex flex-column"
        noValidate
        onSubmit={handleSubmit}
      >
        <div className="flex-fill">
          <Field
            label="Name"
            required
            name={createProductFieldKeys.name}
            component={TextInputWrapper}
          />
          <Field
            label="Price"
            disabled
            name={createProductFieldKeys.price}
            component={TextInputWrapper}
            InputProps={{
              classes: { disabled: classes.disabledInput },
            }}
          />
          <Field
            name={createProductFieldKeys.itemClassId}
            label="Item Class"
            required
            component={SelectInputWrapper}
            options={itemClasses}
          />
          <Field
            label="URL"
            disabled
            name={createProductFieldKeys.url}
            component={TextInputWrapper}
            InputProps={{
              classes: { disabled: classes.disabledInput },
            }}
          />
          <Field
            label="Description (optional)"
            name={createProductFieldKeys.description}
            multiline
            rows={5}
            maxLength={160}
            component={TextInputWrapper}
          />
          <Field
            label="Size (optional)"
            name={createProductFieldKeys.size}
            component={TextInputWrapper}
          />
        </div>
        <div className="d-flex justify-content-center">
          <div className={classes.submitBtnContainer}>
            <FinalFormSubmitButton color="secondary" size="sm">
              Save
            </FinalFormSubmitButton>
          </div>
        </div>
      </form>
    )}
  />
);

CreateProductForm.propTypes = {
  validate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  itemClasses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = ({
  app: {
    enums: { itemClasses },
  },
}) => ({
  itemClasses: getEnumAsSelectOptions(itemClasses),
});

export default connect(mapStateToProps)(CreateProductForm);
