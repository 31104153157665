import React from 'react';
import PropTypes from 'prop-types';
import CustomScrollBar from 'components/ui/CustomScrollbar/CustomScrollBar';
import PopupImage from 'modules/pinMedia/PopupImage/PopupImage';
import classes from 'modules/pinMedia/SaveImageModal/SaveImageModal.module.scss';

const SimilarImagesBlock = ({
  similarMediaList,
  onSelectMedia,
  selectedMedia,
}) => (
  <CustomScrollBar
    autoHeightMax={890}
    renderView={(props) => (
      <div {...props} className={classes.similarImageContainer} />
    )}
  >
    <div className="d-flex flex-wrap pr-1">
      {Object.values(similarMediaList).map(({ id, url }) => (
        <div className="col-4 mb-2" key={id}>
          <PopupImage
            id={id}
            imgUrl={url}
            selected={selectedMedia && id === selectedMedia.id}
            onSelect={onSelectMedia}
            overlayLabel="select image"
          />
        </div>
      ))}
    </div>
  </CustomScrollBar>
);

SimilarImagesBlock.propTypes = {
  similarMediaList: PropTypes.shape({}).isRequired,
  onSelectMedia: PropTypes.func.isRequired,
  selectedMedia: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
};

SimilarImagesBlock.defaultProps = {
  selectedMedia: null,
};

export default SimilarImagesBlock;
