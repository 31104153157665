import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { verifyEmailAction } from 'modules/auth/store/actions';
import EmailVerificationComponent from 'modules/auth/emailVerification/EmailVerificationComponent';
import errorToastr from 'libs/toastr/errorToastr';

const EmailVerificationContainer = ({ verifyEmail }) => {
  const { userId, token } = useParams();
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        await verifyEmail(userId, token);
        setVerified(true);
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
    })();
    // eslint-disable-next-line
  }, []);

  return verified ? <EmailVerificationComponent /> : null;
};

EmailVerificationContainer.propTypes = {
  verifyEmail: PropTypes.func.isRequired,
};

const mapSateToProps = () => ({});
const mapDispatchToProps = { verifyEmail: verifyEmailAction };

export default connect(
  mapSateToProps,
  mapDispatchToProps
)(EmailVerificationContainer);
