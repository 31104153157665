import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ColorPoint from 'components/colorSelectors/ColorPoint/ColorPoint';
import colorsOptionShape from 'components/PreferenceQuiz/propTypes/colorsOptionShape';
import Button from 'components/ui/Button/Button';

const ColorsStep = ({
  isLoading,
  currentValue,
  options,
  onCancelEdit,
  onSaveColors,
}) => {
  const [inputValue, setInputValue] = useState([]);

  useEffect(() => {
    if (Array.isArray(currentValue)) {
      setInputValue(currentValue);
    }
  }, [currentValue]);

  const handleChange = useCallback(
    (optionValue) => {
      let valueCopy = [...inputValue];
      const isOptionExist = inputValue.includes(optionValue);

      if (isOptionExist) {
        valueCopy = valueCopy.filter((item) => item !== optionValue);
      } else if (valueCopy.length < 10) {
        valueCopy.push(optionValue);
      }

      setInputValue(valueCopy);
    },
    [inputValue]
  );

  const handleSaveColors = useCallback(() => {
    onSaveColors(inputValue);
  }, [onSaveColors, inputValue]);

  return (
    <>
      <div className="d-flex flex-wrap">
        {Object.values(options).map(({ id, name, code }) => (
          <ColorPoint
            key={id}
            id={id}
            interactive
            size="xl"
            title={name}
            selected={inputValue.includes(id)}
            className="mr-3 mb-3"
            onChange={handleChange}
            color={code}
          />
        ))}
      </div>
      <div className="d-flex justify-content-center">
        <Button
          inline
          className="mr-2"
          onClick={handleSaveColors}
          disabled={isLoading}
        >
          Next
        </Button>
        <Button
          inline
          variant="outlined"
          onClick={onCancelEdit}
          disabled={isLoading}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

ColorsStep.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  currentValue: PropTypes.arrayOf(PropTypes.number),
  options: PropTypes.arrayOf(colorsOptionShape).isRequired,
  onCancelEdit: PropTypes.func.isRequired,
  onSaveColors: PropTypes.func.isRequired,
};

ColorsStep.defaultProps = {
  currentValue: undefined,
};

export default ColorsStep;
