import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import filter from 'lodash-es/filter';
import { inspirationImageShape } from 'modules/inspirationImage/propTypes';
import {
  getInspirationImgUrl,
  getImageType,
  getStyleNames,
} from 'modules/app/store/selectors';
import { detailsPreviewProps } from 'modules/requestTheLook/components/DetailsPreview/propTypes';
import buildResourceUrl from 'utils/buildResourceUrl';
import { routesByName } from 'constants/routes';
import { Link, useHistory } from 'react-router-dom';
import requestService from 'modules/request/requestService';
import { imageTypeKeys } from 'constants/inspirationImageSearchParams';
import Button from 'components/ui/Button/Button';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import errorToastr from 'libs/toastr/errorToastr';
import successToastr from 'libs/toastr/successToastr';
import ImageDetails from 'modules/requestTheLook/components/ImageDetails/ImageDetails';
import DetailsPreview from 'modules/requestTheLook/components/DetailsPreview/DetailsPreview';
import classes from './SubmitRequest.module.scss';

const SubmitRequestContainer = ({
  inspirationImageUrl,
  selectedImage,
  title,
  requestType,
  imgType,
  styles,
  priorityItems,
}) => {
  const history = useHistory();

  const imgUrl = useMemo(
    () =>
      selectedImage &&
      buildResourceUrl(
        inspirationImageUrl.medium,
        selectedImage.media.userId,
        selectedImage.media.hash
      ),
    [inspirationImageUrl, selectedImage]
  );

  const itemType = useMemo(
    () => (requestType === imageTypeKeys.room ? 'Room' : 'Item'),
    [requestType]
  );

  const filteredPriorityItems = useMemo(() => filter(priorityItems, 'value'), [
    priorityItems,
  ]);

  const handleSubmit = useCallback(async () => {
    try {
      const data = {
        title,
        imageId: selectedImage.id,
        items: filteredPriorityItems.map(({ value }) => value),
      };
      await requestService.submitRequest(data);
      history.push(routesByName.requestTheLook.success);
      successToastr('Success', 'Request Successfully submitted');
    } catch (e) {
      errorToastr('Error', e.generalError);
    }
  }, [title, selectedImage, history, filteredPriorityItems]);

  return selectedImage ? (
    <section className={classes.root}>
      <div className="mb-5">
        <Link to={routesByName.requestTheLook.details}>
          <div className="d-flex align-items-center mb-3">
            <IconButton variant="inverted-white" size="sm">
              <SpriteIcon name="left-arrow" size="sm" />
            </IconButton>
            <span className="primary-color text-sm ml-1">Back</span>
          </div>
        </Link>
      </div>
      <h1 className={`${classes.title} text-center mb-4`}>
        Review&Submit your request
      </h1>
      <div className="d-flex justify-content-center">
        <ImageDetails
          imgUrl={imgUrl}
          url={selectedImage?.url}
          title={selectedImage?.title}
          editRoute={routesByName.requestTheLook.details}
        />
        <DetailsPreview
          title={title}
          requestType={requestType}
          imgType={imgType}
          styles={styles}
          priorityItems={filteredPriorityItems}
          itemType={itemType}
        >
          <Button
            onClick={handleSubmit}
            className={classes.submitBtn}
            variant="outlined"
            size="lg"
            inline
          >
            Submit
          </Button>
        </DetailsPreview>
      </div>
    </section>
  ) : null;
};

SubmitRequestContainer.propTypes = {
  inspirationImageUrl: PropTypes.shape({
    medium: PropTypes.string.isRequired,
  }).isRequired,
  selectedImage: inspirationImageShape,
  ...detailsPreviewProps,
};

SubmitRequestContainer.defaultProps = {
  selectedImage: null,
};

const mapStateToProps = ({
  app: { config, enums },
  requestTheLook: {
    requestData: { inspirationImageId, title, requestType, items },
  },
  inspirationImage: { library },
}) => ({
  title,
  requestType,
  inspirationImageUrl: getInspirationImgUrl(config),
  selectedImage: library[inspirationImageId],
  imgType: getImageType(library[inspirationImageId] ?? {}, enums),
  styles: getStyleNames(library[inspirationImageId]?.styles ?? [], enums),
  priorityItems: items,
});

export default connect(mapStateToProps)(SubmitRequestContainer);
