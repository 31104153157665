import {
  II_UPDATE_LIBRARY,
  II_UPDATE_IMAGE_LIKE_STATUS,
} from 'modules/inspirationImage/store/constants';

const initialState = {
  library: {},
};

const inspirationImageReducer = (
  state = { ...initialState },
  { type, payload }
) => {
  switch (type) {
    case II_UPDATE_LIBRARY: {
      return {
        ...state,
        library: {
          ...state.library,
          ...payload,
        },
      };
    }
    case II_UPDATE_IMAGE_LIKE_STATUS: {
      const { id, isLiked } = payload;
      return {
        ...state,
        library: {
          ...state.library,
          [id]: {
            ...state.library[id],
            isLiked,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default inspirationImageReducer;
