const menu = {
  MuiMenuItem: {
    root: {
      fontFamily: '"Poppins", sans-serif',
      fontSize: '14px',
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
};

export default menu;
