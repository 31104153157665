import {
  lookBoardDetailsFormFields,
  errorMessages,
} from 'modules/curateTheLook/constants';
import { imageTypeKeys } from 'constants/inspirationImageSearchParams';

const lookBoardDetailsValidator = ({
  [lookBoardDetailsFormFields.title]: title,
  [lookBoardDetailsFormFields.type]: type,
  [lookBoardDetailsFormFields.styleId]: styleId,
  [lookBoardDetailsFormFields.roomTypeId]: roomTypeId,
  [lookBoardDetailsFormFields.itemClassId]: itemClassId,
  [lookBoardDetailsFormFields.description]: description,
}) => {
  const errors = {};

  if (!title) {
    errors.title = errorMessages.required;
  }

  if (!styleId) {
    errors.styleId = errorMessages.required;
  }

  if (!description) {
    errors.description = errorMessages.required;
  }

  if (type === imageTypeKeys.room && !roomTypeId) {
    errors.roomTypeId = errorMessages.required;
  }

  if (type === imageTypeKeys.single && !itemClassId) {
    errors.itemClassId = errorMessages.required;
  }

  return errors;
};

export default lookBoardDetailsValidator;
