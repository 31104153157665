import React, { useState, useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import SpriteIcon from 'components/ui/SpriteIcon';
import Button from 'components/ui/Button/Button';
import CodeModal from 'modules/ambassadors/components/CodeModal/CodeModal';
import codeModalValidator from 'modules/ambassadors/components/CodeModal/codeModalValidator';
import RequestModal from 'modules/ambassadors/components/RequestModal/RequestModal';
import requestModalValidator from 'modules/ambassadors/components/RequestModal/requestModalValidator';
import { requestModalDefaultValues } from 'modules/ambassadors/components/RequestModal/constants';
import videoPreviewImg from 'assets/img/ambassador-page/video-preview.png';
import womanImg from 'assets/img/ambassador-page/woman.png';
import classes from './GetStarted.module.scss';

const GetStartedContainer = ({ authenticated, isAmbassador, userName }) => {
  const { pathname } = useLocation();
  const [codeModalOpen, setCodeModalOpen] = useState(false);
  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const reCaptchaRef = useRef();

  const prefilledRequestModalValues = useMemo(
    () => ({ from: userName, ...requestModalDefaultValues }),
    [userName]
  );

  const handleOpenCodeModal = useCallback(() => {
    setCodeModalOpen(true);
  }, []);

  const handleCloseCodeModal = useCallback(() => {
    setCodeModalOpen(false);
  }, []);

  const handleOpenRequestModal = useCallback(() => {
    setRequestModalOpen(true);
  }, []);

  const handleCloseRequestModal = useCallback(() => {
    setRequestModalOpen(false);
  }, []);

  const handleSendCode = useCallback(async (values) => {
    console.log('Sending code...');
    console.log(values);
  }, []);

  const handleSendRequest = useCallback(async (values) => {
    console.log('Sending request...');
    console.log(values);

    reCaptchaRef.current.reset();
    setDisableSubmit(true);
  }, []);

  const reCaptchaVerify = useCallback(() => {
    setDisableSubmit(false);
  }, []);

  return (
    <>
      <section className={classes.root}>
        <div className="mb-4 d-flex justify-content-between">
          <div>
            <h1 className={classes.title}>Become a Pin the Look Ambassador</h1>
            <p className={classes.description}>
              Help others Get the Look they Love!
            </p>
          </div>
          <div className="d-flex">
            {isAmbassador && (
              <Link to={routesByName.dashboard.ambassadorManagement}>
                <Button className="mr-2" inline size="sm">
                  Ambassador Management
                </Button>
              </Link>
            )}
            <Link to={routesByName.ambassador.faqs}>
              <Button inline variant="outlined" size="sm">
                Ambassador FAQs
              </Button>
            </Link>
          </div>
        </div>
        <div className={classes.container}>
          {!isAmbassador && (
            <div className="d-flex justify-content-end mb-2">
              {authenticated ? (
                <Button
                  onClick={handleOpenRequestModal}
                  inline
                  variant="outlined"
                >
                  Request an Invitation
                </Button>
              ) : (
                <Link
                  to={`${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`}
                >
                  <Button inline variant="outlined">
                    Request an Invitation
                  </Button>
                </Link>
              )}
            </div>
          )}
          <div className="d-flex">
            <div className="w-50">
              <div className="text-center mb-2">
                <h2 className={classes.title}>
                  Showcase your Decorating Style!
                </h2>
              </div>
              <div className="text-center px-1 mb-2">
                <p className={classes.description}>
                  Tap into your inner Interior Designer by curating your
                  favorite room images and helping others get the look they
                  love!
                </p>
              </div>
              <div className="flex-center mb-5">
                {!isAmbassador && (
                  <Button
                    onClick={handleOpenCodeModal}
                    className={classes.btnCode}
                    inline
                    size="sm"
                  >
                    <div className={classes.btnCodeTitle}>Get Started Now!</div>
                    Code Required
                  </Button>
                )}
              </div>
              <div className="d-flex">
                <div className="flex-fill">
                  <div className={classes.banner}>
                    <div className={classes.bannerShape} />
                    <div className="position-absolute top-0 left-0 w-100 h-100 z2 d-flex text-center align-items-center px-3">
                      <div>
                        <div className={classes.bannerQuote}>
                          ‘’Pin the Look makes it <br />
                          fun & easy to help <br />
                          others get the look <br />
                          they love’’
                        </div>
                        <div className={classes.bannerAuthor}>
                          Janice l. Ambassador
                        </div>
                      </div>
                    </div>
                    <img src={womanImg} alt="" />
                  </div>
                </div>
                <div className={classes.listContainer}>
                  <h2 className={classes.title}>Join Our Team</h2>
                  <ul className={classes.list}>
                    <li>Help Others Find the Look They Love</li>
                    <li>Create your own Showcase Page</li>
                    <li>Share your Look Boards Online</li>
                    <li>Get Discounts & Special Offers</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="flex-fill pl-2">
              <div className={classes.video}>
                <img src={videoPreviewImg} alt="" />
                <div className={classes.videoText}>
                  <h3 className={classes.videoTitle}>
                    How to become an <br /> Ambassador
                  </h3>
                  <Link
                    className={classes.videoLink}
                    to={routesByName.howItworks}
                  >
                    <SpriteIcon name="play-btn-icon" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CodeModal
        onSubmit={handleSendCode}
        validate={codeModalValidator}
        open={codeModalOpen}
        onClose={handleCloseCodeModal}
        authenticated={authenticated}
        pathname={pathname}
        onOpenRequestModal={handleOpenRequestModal}
      />
      <RequestModal
        onSubmit={handleSendRequest}
        validate={requestModalValidator}
        open={requestModalOpen}
        onClose={handleCloseRequestModal}
        prefilledFormValues={prefilledRequestModalValues}
        disableSubmit={disableSubmit}
        reCaptchaVerify={reCaptchaVerify}
        reCaptchaRef={reCaptchaRef}
      />
    </>
  );
};

GetStartedContainer.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  isAmbassador: PropTypes.bool.isRequired,
  userName: PropTypes.string,
};

GetStartedContainer.defaultProps = {
  userName: null,
};

const mapSateToProps = ({ auth: { user } }) => ({
  authenticated: Boolean(user),
  isAmbassador: Boolean(user?.ambassador),
  userName: user && `${user.firstName} ${user.lastName}`,
});

export default connect(mapSateToProps, null)(GetStartedContainer);
