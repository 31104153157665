import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import classes from './InspirationImage.module.scss';

const InspirationImage = ({
  className,
  id,
  imgUrl,
  blurred,
  disableHover,
  showStaticOverlay,
  staticOverlay,
  hoverOverlay,
  onSelect,
}) => {
  const [hover, setHover] = useState(false);

  const handleHover = useCallback(() => {
    if (!disableHover) {
      setHover(true);
    }
  }, [disableHover]);

  const handleBlur = useCallback(() => {
    setHover(false);
  }, []);

  const handleSelectImage = useCallback(() => {
    onSelect(id);
  }, [onSelect, id]);

  return (
    <div
      className={clsx(classes.root, className)}
      onMouseEnter={handleHover}
      onMouseLeave={handleBlur}
      onClick={onSelect && handleSelectImage}
    >
      <img
        alt=""
        draggable="false"
        src={imgUrl}
        className={clsx({ [classes.blurred]: blurred })}
      />
      {showStaticOverlay && (
        <div className={classes.overlay}>{staticOverlay}</div>
      )}
      <div
        className={clsx('p-1', classes.overlay, { [classes.hidden]: !hover })}
      >
        {hoverOverlay}
      </div>
    </div>
  );
};

InspirationImage.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onSelect: PropTypes.func,
  imgUrl: PropTypes.string.isRequired,
  hoverOverlay: PropTypes.node.isRequired,
  blurred: PropTypes.bool,
  disableHover: PropTypes.bool,
  showStaticOverlay: PropTypes.bool,
  staticOverlay: PropTypes.node,
  className: PropTypes.string,
};

InspirationImage.defaultProps = {
  blurred: false,
  disableHover: false,
  showStaticOverlay: false,
  className: '',
  staticOverlay: null,
  onSelect: undefined,
};

export default InspirationImage;
