import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import classes from './MessageItem.module.scss';

const MessageItem = ({ thumbnail, text, date }) => {
  return (
    <div className={clsx(classes.root, 'd-flex align-items-center mr-3 pr-2')}>
      <div className="mr-2">{thumbnail}</div>
      <div className="text-xs text-ellipsis">
        <p className="text-ellipsis text-nowrap mb-1">{text}</p>
        <span className="text-gray">{moment(date).fromNow(true)}</span>
      </div>
    </div>
  );
};

MessageItem.propTypes = {
  thumbnail: PropTypes.oneOfType([PropTypes.element]).isRequired,
  text: PropTypes.string.isRequired,
  date: PropTypes.number.isRequired,
};

export default MessageItem;
