import React from 'react';
import PropTypes from 'prop-types';
import classes from './Faqs.module.scss';

const ModalContent = ({ content }) => (
  <div className={classes.modalContent}>
    <div className="mb-5">
      <div className="font-title text-lg text-uppercase mb-1">Question</div>
      <p className="font-semi-bold">{content.question}</p>
    </div>
    <div className="font-title text-lg text-uppercase mb-1">Answer</div>
    {content.answer.map(({ id, paragraph }) => (
      <p className="mb-3" key={id}>
        {paragraph}
      </p>
    ))}
  </div>
);

ModalContent.propTypes = {
  content: PropTypes.shape({
    question: PropTypes.string.isRequired,
    answer: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        paragraph: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default ModalContent;
