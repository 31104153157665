import PropTypes from 'prop-types';

const stylesOptionShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  media: PropTypes.shape({
    hash: PropTypes.string.isRequired,
  }).isRequired,
});

export default stylesOptionShape;
