import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import errorToastr from 'libs/toastr/errorToastr';
import { urlPlaceholders } from 'constants/urlPlaceholders';
import { imageSourceKeys } from 'constants/inspirationImageSearchParams';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import InspirationImage from 'components/imageThumbnails/InspirationImage/InspirationImage';
import OverlayLabel from 'components/imageThumbnails/OverlayLabel/OverlayLabel';
import { getInspirationImgUrl } from 'modules/app/store/selectors';
import {
  approvalStatusKeys,
  ownershipStatusKeys,
  permissionStatusKeys,
  publishStatusKeys,
} from 'modules/inspirationImage/constants';
import inspirationImageService from 'modules/inspirationImage/inspirationImageService';
import PermissionsSwitch from 'modules/dashboard/components/PermissionsSwitch/PermissionsSwitch';
import classes from 'modules/dashboard/myImages/MyImages.module.scss';

const AddedImageItem = ({
  id,
  userId,
  hash,
  currentSource,
  approval,
  publish,
  ownership,
  permission,
  showPermissionSwitch,
  onUnlike,
  onOpenPreview,
  onOpenInfoPopup,
  onDeleteAddedImage,
  inspirationImageUrl,
}) => {
  const [currentPermission, setCurrentPermission] = useState(permission);

  const switchIsVisible = useMemo(
    () =>
      showPermissionSwitch &&
      approval === approvalStatusKeys.approved &&
      publish === publishStatusKeys.published &&
      ownership === ownershipStatusKeys.selfOwned,
    [showPermissionSwitch, approval, publish, ownership]
  );

  const handleChangePermission = useCallback(
    async (isPublic) => {
      const newValue = isPublic
        ? permissionStatusKeys.open
        : permissionStatusKeys.exclusive;
      try {
        await inspirationImageService.changeImagePermission(id, newValue);
        setCurrentPermission(newValue);
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
    },
    [id]
  );

  return (
    <InspirationImage
      id={id}
      className="mb-3"
      imgUrl={inspirationImageUrl.medium
        .replace(urlPlaceholders.userId, userId)
        .replace(urlPlaceholders.hash, hash)}
      showStaticOverlay={approval === approvalStatusKeys.pending}
      disableHover={approval === approvalStatusKeys.pending}
      staticOverlay={
        <div className="h-100 flex-center position-relative">
          <OverlayLabel
            text={
              <span>
                Under <br />
                review
              </span>
            }
          />
          <IconButton
            variant="inverted-grey"
            size="sm"
            className={classes.infoIcon}
            onClick={onOpenInfoPopup}
          >
            <SpriteIcon name="info" size="sm" />
          </IconButton>
        </div>
      }
      hoverOverlay={
        <div className="h-100 flex-column flex-vertical-center justify-content-between">
          <div className="w-100 d-flex justify-content-end">
            <IconButton
              color="secondary"
              size="sm"
              data-user-id={userId}
              data-hash={hash}
              onClick={onOpenPreview}
            >
              <SpriteIcon name="loupe" size="sm" />
            </IconButton>
          </div>
          <div className="w-100 d-flex justify-content-end align-items-center">
            <div className="flex-fill">
              {switchIsVisible && (
                <PermissionsSwitch
                  checked={currentPermission === permissionStatusKeys.open}
                  onChange={handleChangePermission}
                />
              )}
            </div>
            {currentSource === imageSourceKeys.my ? (
              <IconButton
                variant="inverted-grey"
                size="sm"
                data-img-id={id}
                onClick={onDeleteAddedImage}
              >
                <SpriteIcon name="trash" size="sm" />
              </IconButton>
            ) : (
              <IconButton
                variant="inverted-grey"
                size="sm"
                data-img-id={id}
                onClick={onUnlike}
              >
                <SpriteIcon name="like-filled" size="sm" />
              </IconButton>
            )}
          </div>
        </div>
      }
    />
  );
};

AddedImageItem.propTypes = {
  id: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  hash: PropTypes.string.isRequired,
  currentSource: PropTypes.string.isRequired,
  approval: PropTypes.string.isRequired,
  publish: PropTypes.string.isRequired,
  ownership: PropTypes.string.isRequired,
  permission: PropTypes.string.isRequired,
  showPermissionSwitch: PropTypes.bool.isRequired,
  onOpenPreview: PropTypes.func.isRequired,
  onUnlike: PropTypes.func.isRequired,
  onOpenInfoPopup: PropTypes.func.isRequired,
  onDeleteAddedImage: PropTypes.func.isRequired,
  inspirationImageUrl: PropTypes.shape({
    medium: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ app: { config } }) => ({
  inspirationImageUrl: getInspirationImgUrl(config),
});

export default connect(mapStateToProps)(AddedImageItem);
