import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import SearchInput from 'components/inputs/SearchInput/SearchInput';
import { imageSourceKeys } from 'constants/inspirationImageSearchParams';
import FilterInput from 'modules/dashboard/components/FilterInput/FilterInput';
import Button from 'components/ui/Button/Button';
import {
  imageSearchRadioOptions,
  imageTypeOptions,
} from 'modules/requestTheLook/constants';
import {
  toggleAddImageModalAction,
  toggleUnderConstructionModalAction,
} from 'modules/layout/store/actions';
import CustomRadioGroup from 'modules/curateTheLook/createLookBoard/components/CustomRadioGroup';
import createLookBoardClasses from 'modules/curateTheLook/createLookBoard/CreateLookBoard.module.scss';
import imagesSearchParamsBlockClasses from 'modules/curateTheLook/createLookBoard/components/ImagesSearchParamsBlock/ImagesSearchParamsBlock.module.scss';

const ImagesSearchParamsBlock = ({
  currentSource,
  currentImgType,
  searchQuery,
  selectedImage,
  onUpdateSearchParams,
  toggleAddImageModal,
  toggleUnderConstructionModal,
}) => {
  const inputRef = useRef(null);
  const [searchInputOpen, setSearchInputOpen] = useState(false);

  const subTitle = useMemo(
    () =>
      currentSource === imageSourceKeys.all
        ? 'Inspiration Images Database'
        : 'My Added Images',
    [currentSource]
  );

  const openSearchInput = useCallback(() => {
    setSearchInputOpen(true);
    inputRef.current.focus();
  }, []);

  const closeSearchInput = useCallback(() => {
    setSearchInputOpen(false);
  }, []);

  const handleChangeSearchQuery = useCallback(
    (search) => {
      onUpdateSearchParams({ search });
    },
    [onUpdateSearchParams]
  );

  const handleChangeImgSource = useCallback(
    (source) => {
      onUpdateSearchParams({ source });
    },
    [onUpdateSearchParams]
  );

  const handleChangeImgType = useCallback(
    ({ target: { value: type } }) => {
      onUpdateSearchParams({ type });
    },
    [onUpdateSearchParams]
  );

  const handleAddImageModalOpen = useCallback(() => {
    toggleAddImageModal(true);
  }, [toggleAddImageModal]);

  const handleUnderConstructionModalOpen = useCallback(() => {
    toggleUnderConstructionModal(true);
  }, [toggleUnderConstructionModal]);

  return (
    <>
      <div className="position-relative d-flex mb-2">
        <div
          className={clsx(imagesSearchParamsBlockClasses.filtersBlock, {
            [imagesSearchParamsBlockClasses.invisible]: searchInputOpen,
          })}
        >
          <h3 className="text-lg font-semi-bold mb-1">Request The Look</h3>
          <p className="text-xs font-italic">{subTitle}</p>
        </div>
        {!selectedImage && (
          <>
            <IconButton
              variant="inverted-white"
              className="mr-1"
              onClick={openSearchInput}
            >
              <SpriteIcon name="loupe" size="sm" />
            </IconButton>
            <IconButton
              variant="inverted-white"
              onClick={handleUnderConstructionModalOpen}
            >
              <SpriteIcon name="filters" size="sm" />
            </IconButton>
            <div
              className={clsx(createLookBoardClasses.searchInput, {
                [createLookBoardClasses.active]: searchInputOpen,
              })}
            >
              <SearchInput
                inputRef={inputRef}
                value={searchQuery}
                placeholder="Search by keywords"
                onChange={handleChangeSearchQuery}
                onCrossClick={closeSearchInput}
              />
            </div>
          </>
        )}
      </div>
      <div className={`${createLookBoardClasses.stepTitle} mb-1`}>
        {selectedImage && (
          <SpriteIcon name="checked" size="sm" className="mr-1 primary-color" />
        )}
        <span className="flex-fill mr-2">Step 1: Select inspiration image</span>
        {currentSource === imageSourceKeys.all && !selectedImage && (
          <Button
            className={imagesSearchParamsBlockClasses.pinBtn}
            size="xs"
            inline
            style={{ minWidth: 130 }}
            variant="outlined"
            onClick={handleAddImageModalOpen}
          >
            <div className="d-flex align-items-center">
              or Add New Image
              <div className={imagesSearchParamsBlockClasses.pinBtnIcon}>
                <SpriteIcon name="pin" size="xs" />
              </div>
            </div>
          </Button>
        )}
      </div>
      {!selectedImage && (
        <>
          <div className="text-xs font-italic py-1 mb-1">
            Use filters for Best Results
          </div>
          {currentSource !== imageSourceKeys.all && (
            <div
              className={`${imagesSearchParamsBlockClasses.myImageBlock} mb-2`}
            >
              <FilterInput
                className={imagesSearchParamsBlockClasses.myImageSelect}
                options={imageTypeOptions}
                currentValue={currentSource}
                onChange={handleChangeImgSource}
              />
              <p className="flex-fill text-center text-capitalize text-xs">
                Add new image
              </p>
              <IconButton size="sm" onClick={handleAddImageModalOpen}>
                <SpriteIcon name="plus" size="sm" />
              </IconButton>
            </div>
          )}
          <CustomRadioGroup
            controls={imageSearchRadioOptions}
            value={currentImgType}
            onChange={handleChangeImgType}
          />
        </>
      )}
    </>
  );
};

ImagesSearchParamsBlock.propTypes = {
  currentSource: PropTypes.string.isRequired,
  currentImgType: PropTypes.string.isRequired,
  searchQuery: PropTypes.string.isRequired,
  selectedImage: PropTypes.shape({}),
  onUpdateSearchParams: PropTypes.func.isRequired,
  toggleAddImageModal: PropTypes.func.isRequired,
  toggleUnderConstructionModal: PropTypes.func.isRequired,
};

ImagesSearchParamsBlock.defaultProps = {
  selectedImage: null,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  toggleAddImageModal: toggleAddImageModalAction,
  toggleUnderConstructionModal: toggleUnderConstructionModalAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImagesSearchParamsBlock);
