import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CustomInfiniteScroll from 'components/CustomInfiniteScroll';
import SpriteIcon from 'components/ui/SpriteIcon';
import buildResourceUrl from 'utils/buildResourceUrl';
import {
  getColors,
  getImageType,
  getInspirationImgUrl,
  getStyleNames,
  getUserAvatarThumbUrl,
} from 'modules/app/store/selectors';
import { inspirationImageShape } from 'modules/inspirationImage/propTypes';
import { imageTypeKeys } from 'constants/inspirationImageSearchParams';
import { toggleUnderConstructionModalAction } from 'modules/layout/store/actions';
import { updateIIStatusLikeAction } from 'modules/inspirationImage/store/actions';
import IconButton from 'components/ui/IconButton/IconButton';
import ImageLink from 'components/imageThumbnails/ImageLink';
import BasicAvatar from 'components/ui/BasicAvatar';
import Button from 'components/ui/Button/Button';
import CustomScrollBar from 'components/ui/CustomScrollbar/CustomScrollBar';
import inspirationImageService from 'modules/inspirationImage/inspirationImageService';
import errorToastr from 'libs/toastr/errorToastr';
import SimilarImageItem from 'modules/curateTheLook/createLookBoard/components/SimilarImageItem/SimilarImageItem';
import currentUserService from 'modules/currentUser/currentUserService';
import { selectImageAction } from 'modules/requestTheLook/store/actions';
import selectedImageBlockClasses from 'modules/curateTheLook/createLookBoard/components/SelectedImageBlock/SelectedImageBlock.module.scss';

// TODO: Get limit value from application config
const limit = 10;

const SelectedImageBlock = ({
  selectedImage: {
    id,
    userId,
    isLiked,
    title,
    url,
    type,
    styles: styleIds,
    colors: colorIds,
    roomTypeId,
    itemClassId,
    media: { userId: mediaUserId, hash },
  },
  searchParams,
  onBack,
  userAvatarThumbUrl,
  inspirationImageUrl,
  styles,
  imgType,
  colors,
  toggleUnderConstructionModal,
  selectImage,
  onOpenPreview,
  updateLikeStatus,
}) => {
  const containerRef = useRef(null);
  const scrollBarRef = useRef(null);
  const [customScrollerHeight, setCustomScrollerHeight] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [currentSearchParams, setCurrentSearchParams] = useState(searchParams);

  useEffect(() => {
    if (containerRef.current) {
      const refHeight = containerRef.current.clientHeight;
      setCustomScrollerHeight(Math.round(refHeight));
    }

    (async () => {
      try {
        const user = await currentUserService.getUserById(userId);
        setCurrentUser(user);
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
    })();
    // eslint-disable-next-line
  }, []);

  const handleToggleLike = useCallback(async () => {
    try {
      await inspirationImageService.toggleLike(id, Number(!isLiked));
      updateLikeStatus(id, Number(!isLiked));
    } catch (e) {
      errorToastr('Error', e.generalError);
    }
  }, [id, isLiked, updateLikeStatus]);

  const loadFirst = useCallback(async () => {
    setLoading(true);

    try {
      const result = await inspirationImageService.getImages(
        currentSearchParams
      );
      const list = Object.values(result);

      setImageList(list.filter(({ id: currId }) => currId !== id));
      setCurrentSearchParams((prevState) => ({
        ...prevState,
        offset: prevState.offset + list.length,
      }));
      setHasMore(list.length === limit);
      setLoading(false);
    } catch (e) {
      errorToastr('Error', e.generalError);
    }
  }, [currentSearchParams, id]);

  useEffect(() => {
    setImageList([]);
    const optionalParams =
      type === imageTypeKeys.room
        ? { roomTypes: [roomTypeId] }
        : { items: itemClassId ? [itemClassId] : [] }; // This ternary only for development purposes, since seed II with type 'single' may not have itemClassId
    setCurrentSearchParams({
      ...searchParams,
      styles: styleIds,
      colors: colorIds,
      ...optionalParams,
      offset: 0,
    });
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    (async () => {
      if (currentSearchParams.offset === 0) {
        await loadFirst();
      }
    })();
    // eslint-disable-next-line
  }, [currentSearchParams.offset]);

  const imgUrl = useMemo(
    () => buildResourceUrl(inspirationImageUrl.medium, mediaUserId, hash),
    [inspirationImageUrl, mediaUserId, hash]
  );

  const handleOpenPreview = useCallback(() => {
    onOpenPreview(imgUrl);
  }, [onOpenPreview, imgUrl]);

  const avatarUrl = useMemo(() => {
    if (currentUser && currentUser.avatar) {
      const { userId: currentUserId, hash: avatarHash } = currentUser.avatar;
      return buildResourceUrl(userAvatarThumbUrl, currentUserId, avatarHash);
    }
    return null;
  }, [currentUser, userAvatarThumbUrl]);

  const loadMore = useCallback(async () => {
    setLoading(true);

    if (currentSearchParams.offset === 0) {
      return;
    }

    try {
      const result = await inspirationImageService.getImages(
        currentSearchParams
      );
      const list = Object.values(result);
      setImageList((prevState) => {
        return [
          ...prevState,
          ...list.filter(({ id: currId }) => currId !== id),
        ];
      });
      setCurrentSearchParams((prevState) => ({
        ...prevState,
        offset: prevState.offset + list.length,
      }));

      setHasMore(list.length === limit);
      setLoading(false);
    } catch (e) {
      errorToastr('Error', e.generalError);
    }
  }, [currentSearchParams, id]);

  const handleSelectImage = useCallback(
    (imgId) => {
      selectImage(imgId);
      scrollBarRef.current.scrollToTop();
    },
    [selectImage]
  );

  const handleUnderConstructionModalOpen = useCallback(() => {
    toggleUnderConstructionModal(true);
  }, [toggleUnderConstructionModal]);

  return (
    <div className="flex-fill d-flex flex-column pt-2">
      <div
        className="d-flex align-items-center cursor-pointer mb-3"
        onClick={onBack}
      >
        <SpriteIcon
          name="left-arrow"
          size="sm"
          className="primary-color mr-1"
        />
        <span className={selectedImageBlockClasses.goBackBtn}>
          Back To All Inspiration Images
        </span>
      </div>
      <div className="flex-fill" ref={containerRef}>
        <CustomScrollBar
          scrollBarRef={scrollBarRef}
          autoHeightMin={customScrollerHeight}
          autoHeightMax={customScrollerHeight}
          renderView={(props) => <div {...props} className="pr-2" />}
        >
          <div className={`${selectedImageBlockClasses.selectedImage} mb-1`}>
            <IconButton
              className={selectedImageBlockClasses.selectedImageZoom}
              color="secondary"
              size="sm"
              onClick={handleOpenPreview}
            >
              <SpriteIcon name="loupe" size="sm" />
            </IconButton>
            <img alt="" draggable="false" src={imgUrl} />
            <div className={selectedImageBlockClasses.selectedImageInfo}>
              <div className={selectedImageBlockClasses.selectedImageInfoTitle}>
                Inspiration Image
              </div>
              {title}
            </div>
          </div>
          <div className="d-flex justify-content-end mb-3">
            <IconButton
              variant="inverted-white"
              size="sm"
              className="mr-1"
              onClick={handleToggleLike}
            >
              <SpriteIcon name={isLiked ? 'like-filled' : 'like'} size="sm" />
            </IconButton>
            <IconButton
              variant="inverted-white"
              size="sm"
              className="mr-1"
              onClick={handleUnderConstructionModalOpen}
            >
              <SpriteIcon name="share" size="sm" />
            </IconButton>
            <IconButton
              variant="inverted-white"
              size="sm"
              onClick={handleUnderConstructionModalOpen}
            >
              <SpriteIcon name="more-vertical" size="sm" />
            </IconButton>
          </div>
          {url && (
            <div className="text-ellipsis mb-3">
              <ImageLink url={url} />
            </div>
          )}
          <div className={`${selectedImageBlockClasses.bordered} mb-3`}>
            {currentUser && (
              <div className="d-flex align-items-center mb-3">
                <div className="flex-fill d-flex align-items-center text-ellipsis mr-2">
                  <BasicAvatar
                    firstName={currentUser.firstName}
                    lastName={currentUser.lastName}
                    src={avatarUrl}
                  />
                  <p className="text-sm ml-1 text-ellipsis font-semi-bold">{`${currentUser.firstName} ${currentUser.lastName}`}</p>
                </div>
                <Button
                  inline
                  color="secondary"
                  variant="outlined"
                  size="sm"
                  onClick={handleUnderConstructionModalOpen}
                >
                  Follow
                </Button>
              </div>
            )}
            <div className="text-sm">
              <div className="d-flex mb-2">
                <span className="font-semi-bold mr-2">Styles:</span>
                <span className="font-italic">{styles.join(', ')}</span>
              </div>
              <div className="d-flex mb-2">
                <span className="font-semi-bold mr-2">Type:</span>
                <span className="font-italic">{imgType.name}</span>
              </div>
              <div className="d-flex">
                <span className="font-semi-bold mr-2">Colors:</span>
                <div className="d-flex">
                  {colors.map(({ id: colorId, name, code }) => (
                    <div
                      key={colorId}
                      className={selectedImageBlockClasses.colorPoint}
                      title={name}
                      style={{ background: code }}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <p className="mb-3 text-lg text-capitalize font-semi-bold">
            Similar images
          </p>
          <CustomInfiniteScroll
            parentHeight={customScrollerHeight}
            isLoading={loading}
            hasMore={hasMore}
            loadMore={loadMore}
            initialLoad={false}
            useWindow={false}
          >
            <div className="row">
              {imageList.map(
                ({
                  id: similarImageId,
                  media: { userId: similarImageUserId, hash: similarImageHash },
                }) => (
                  <div key={similarImageId} className="col-6 mb-2">
                    <SimilarImageItem
                      id={similarImageId}
                      userId={similarImageUserId}
                      hash={similarImageHash}
                      onSelectImage={handleSelectImage}
                    />
                  </div>
                )
              )}
            </div>
          </CustomInfiniteScroll>
        </CustomScrollBar>
      </div>
    </div>
  );
};

SelectedImageBlock.propTypes = {
  searchParams: PropTypes.shape({}).isRequired,
  onBack: PropTypes.func.isRequired,
  selectedImage: inspirationImageShape.isRequired,
  userAvatarThumbUrl: PropTypes.string.isRequired,
  inspirationImageUrl: PropTypes.shape({
    medium: PropTypes.string.isRequired,
  }).isRequired,
  styles: PropTypes.arrayOf(PropTypes.string).isRequired,
  imgType: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  colors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  toggleUnderConstructionModal: PropTypes.func.isRequired,
  selectImage: PropTypes.func.isRequired,
  onOpenPreview: PropTypes.func.isRequired,
  updateLikeStatus: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  app: { config, enums },
  requestTheLook: {
    requestData: { inspirationImageId },
  },
  inspirationImage: { library },
}) => ({
  selectedImage: library[inspirationImageId],
  userAvatarThumbUrl: getUserAvatarThumbUrl(config),
  inspirationImageUrl: getInspirationImgUrl(config),
  styles: getStyleNames(library[inspirationImageId]?.styles ?? [], enums),
  imgType: getImageType(library[inspirationImageId], enums),
  colors: getColors(library[inspirationImageId]?.colors ?? [], enums),
});

const mapDispatchToProps = {
  toggleUnderConstructionModal: toggleUnderConstructionModalAction,
  selectImage: selectImageAction,
  updateLikeStatus: updateIIStatusLikeAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectedImageBlock);
