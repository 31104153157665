import React from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';

const RadioInputWrapper = ({ input }) => {
  return <Radio {...input} />;
};

RadioInputWrapper.propTypes = {
  input: PropTypes.shape({}).isRequired,
};

export default RadioInputWrapper;
