import React from 'react';
import PropTypes from 'prop-types';
import { detailsPreviewProps } from 'modules/requestTheLook/components/DetailsPreview/propTypes';
import { requestTypes } from 'modules/requestTheLook/constants';
import classes from './DetailsPreview.module.scss';

const DetailsPreview = ({
  title,
  requestType,
  priorityItems,
  imgType,
  styles,
  children,
  itemType,
}) => (
  <div className={classes.root}>
    <h2 className={classes.title}>{title}</h2>
    <ul className={classes.list}>
      <li className={classes.listItem}>
        <b>Request Type:</b> {requestTypes[requestType]}
      </li>
      <li className={classes.listItem}>
        <b>{itemType} Type:</b> {imgType.name}
      </li>
      {styles.length > 0 && (
        <li className={classes.listItem}>
          <b>{itemType} Style:</b> {styles.join(', ')}
        </li>
      )}
      {priorityItems.length > 0 && (
        <li className={classes.listItem}>
          <b>Priority Items:</b>
          <ul className={classes.itemsList}>
            {priorityItems.map(({ label, value }) => (
              <li key={value}>{label}</li>
            ))}
          </ul>
        </li>
      )}
    </ul>
    {children}
  </div>
);

DetailsPreview.propTypes = {
  ...detailsPreviewProps,
  itemType: PropTypes.string.isRequired,
  children: PropTypes.node,
};

DetailsPreview.defaultProps = {
  children: null,
};

export default DetailsPreview;
