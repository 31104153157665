import React from 'react';
import * as PropTypes from 'prop-types';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from 'components/photoUploaders/ImageUploadHandler/ImageUploadHandler.module.scss';

const ImageUploadHandler = ({ onChange }) => (
  <label
    className={`${classes.uploadHandler} d-inline-flex align-items-center`}
  >
    <input type="file" className="d-none" onChange={onChange} />
    <div className={`${classes.uploadHandlerBtn} mr-1`}>
      <SpriteIcon name="file-upload" size="sm" />
    </div>
    <span>Upload New Image</span>
  </label>
);

ImageUploadHandler.propTypes = {
  onChange: PropTypes.func.isRequired,
};

ImageUploadHandler.defaultProps = {};

export default ImageUploadHandler;
