const productMock = {
  id: 1,
  title: 'Chair',
  rating: 4,
  imgUrls: [
    {
      id: 1,
      url: 'https://picsum.photos/200/200',
    },
    {
      id: 2,
      url: 'https://picsum.photos/201/201',
    },
    {
      id: 3,
      url: 'https://picsum.photos/202/202',
    },
  ],
};

export default productMock;
