import React from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';

const ImageLink = ({ url }) => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href={url}
    className="d-flex align-items-center mr-2"
  >
    <IconButton color="secondary" size="xs" className="mr-1">
      <SpriteIcon name="link" size="sm" />
    </IconButton>
    <i
      className="text-xs text-ellipsis text-nowrap"
      style={{ lineHeight: 1.2 }}
    >
      {url}
    </i>
  </a>
);

ImageLink.propTypes = {
  url: PropTypes.string.isRequired,
};

export default ImageLink;
