import React from 'react';
import PropTypes from 'prop-types';
import TemplateSelect from 'modules/curateTheLook/createLookBoard/components/TemplateSelect/TemplateSelect';

const ChooseTemplate = ({
  templateList,
  selectedTemplate,
  onSelectTemplate,
}) => (
  <div>
    <p className="font-title mb-1" style={{ fontSize: 22 }}>
      Select a Template
    </p>
    <p className="font-italic text-xs mb-4">
      Click on a template to view in Canvas
    </p>
    <p className="primary-color font-semi-bold text-lg mb-1">
      Expandable Templates
    </p>
    <p className="text-gray font-italic text-xs mb-4">
      Simple Expandable Templates to easily add up to 12 items
    </p>
    {templateList.map(
      ({ id, title, subTitle, columnCount, imageList, isDefault }) => (
        <div className="mb-3" key={id}>
          <TemplateSelect
            id={id}
            title={title}
            subTitle={subTitle}
            columnCount={columnCount}
            imageList={imageList}
            isDefault={isDefault}
            selected={selectedTemplate && id === selectedTemplate.id}
            onSelect={onSelectTemplate}
          />
        </div>
      )
    )}
  </div>
);

ChooseTemplate.propTypes = {
  templateList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedTemplate: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  onSelectTemplate: PropTypes.func.isRequired,
};

ChooseTemplate.defaultProps = {
  selectedTemplate: null,
};

export default ChooseTemplate;
