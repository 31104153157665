import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  createLookBoardStepKeys,
  lookBoardTemplatesConfig,
  stepTwoTabKeys,
} from 'modules/curateTheLook/constants';
import {
  changeTemplateAction,
  setProductsAction,
  updateProductsAction,
  updateProductSearchParamsAction,
} from 'modules/curateTheLook/store/actions';
import useCustomEventListener from 'hooks/useCustomEventListener';
import {
  SET_INITIAL_SCROLLER_HEIGHT,
  UPDATE_SCROLLER_HEIGHT,
} from 'constants/customEventNames';
import errorToastr from 'libs/toastr/errorToastr';
import productService from 'modules/product/productService';
import TabSwitch from 'modules/curateTheLook/createLookBoard/components/TabSwitch/TabSwitch';
import ChooseTemplate from 'modules/curateTheLook/createLookBoard/components/ChooseTemplate';
import ProductsSearchParams from 'modules/curateTheLook/createLookBoard/components/ProductsSearchParams/ProductsSearchParams';
import CustomScrollBar from 'components/ui/CustomScrollbar/CustomScrollBar';
import ProductsDatabase from 'modules/curateTheLook/createLookBoard/components/ProductsDatabase/ProductsDatabase';
import classes from 'modules/curateTheLook/createLookBoard/CreateLookBoard.module.scss';

// TODO: Get limit value from application config
const limit = 10;

const RightPanel = ({
  currentStep,
  selectedTemplate,
  searchParams,
  setProducts,
  updateProducts,
  changeTemplate,
  updateProductSearchParams,
}) => {
  const containerRef = useRef(null);
  const scrollBarRef = useRef(null);

  const [initialScrollerHeight, setInitialScrollerHeight] = useState(0);
  const [customScrollerHeight, setCustomScrollerHeight] = useState(0);
  const [activeTab, setActiveTab] = useState(stepTwoTabKeys.templates);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleUpdateContainerHeight = useCallback(() => {
    if (containerRef.current) {
      const refHeight = containerRef.current.clientHeight;
      setCustomScrollerHeight(Math.round(refHeight));
    }
  }, []);

  useCustomEventListener(SET_INITIAL_SCROLLER_HEIGHT, () => {
    setCustomScrollerHeight(initialScrollerHeight);
  });

  useCustomEventListener(UPDATE_SCROLLER_HEIGHT, () => {
    handleUpdateContainerHeight();
  });

  useEffect(() => {
    if (currentStep === createLookBoardStepKeys.addProducts) {
      const refHeight = containerRef.current.clientHeight;
      setInitialScrollerHeight(Math.round(refHeight));
      handleUpdateContainerHeight();
    }
  }, [currentStep, handleUpdateContainerHeight]);

  const handleChangeTab = useCallback(({ currentTarget }) => {
    const tab = currentTarget.getAttribute('data-tab');
    setActiveTab(tab);
    scrollBarRef.current.scrollToTop();
  }, []);

  const handleUpdateSearchParams = useCallback(
    (params) => {
      updateProductSearchParams({
        ...params,
        offset: 0,
      });

      setHasMore(false);
    },
    [updateProductSearchParams]
  );

  const loadFirst = useCallback(async () => {
    setLoading(true);

    try {
      const list = await productService.getProducts(searchParams);
      const productArrLength = Object.keys(list).length;

      setProducts(list);
      if (productArrLength > 0) {
        updateProductSearchParams({
          offset: searchParams.offset + productArrLength,
        });
      }

      setHasMore(productArrLength === limit);
      setLoading(false);
    } catch (e) {
      errorToastr('Error', e.generalError);
    }
  }, [updateProductSearchParams, setProducts, searchParams]);

  const loadMore = useCallback(async () => {
    setLoading(true);

    try {
      const list = await productService.getProducts(searchParams);
      const productArrLength = Object.keys(list).length;

      updateProducts(list);
      updateProductSearchParams({
        offset: searchParams.offset + productArrLength,
      });

      setHasMore(productArrLength === limit);
      setLoading(false);
    } catch (e) {
      errorToastr('Error', e.generalError);
    }
  }, [updateProductSearchParams, updateProducts, searchParams]);

  useEffect(() => {
    (async () => {
      if (searchParams.offset === 0) {
        await loadFirst();
      }
    })();
    // eslint-disable-next-line
  }, [searchParams]);

  const handleSelectTemplate = useCallback(
    (templateId) => {
      changeTemplate(lookBoardTemplatesConfig[templateId]);
    },
    [changeTemplate]
  );

  return (
    <div className={classes.sidePanel}>
      <div className={`${classes.stepTitle} mb-3`}>
        <span>Step 2: Curate Look Board</span>
      </div>
      {currentStep === createLookBoardStepKeys.addProducts && (
        <>
          <TabSwitch activeTab={activeTab} onChange={handleChangeTab} />
          <div className="flex-fill" ref={containerRef}>
            <CustomScrollBar
              scrollBarRef={scrollBarRef}
              autoHeightMin={customScrollerHeight}
              autoHeightMax={customScrollerHeight}
              renderView={(props) => <div {...props} className="pr-2" />}
            >
              {activeTab === stepTwoTabKeys.templates && (
                <ChooseTemplate
                  templateList={Object.values(lookBoardTemplatesConfig)}
                  selectedTemplate={selectedTemplate}
                  onSelectTemplate={handleSelectTemplate}
                />
              )}
              {activeTab === stepTwoTabKeys.products && (
                <>
                  <ProductsSearchParams
                    currentType={searchParams.type}
                    searchQuery={searchParams.search}
                    onUpdateSearchParams={handleUpdateSearchParams}
                  />
                  <ProductsDatabase
                    scrollerHeight={customScrollerHeight}
                    loading={loading}
                    hasMore={hasMore}
                    loadMore={loadMore}
                  />
                </>
              )}
            </CustomScrollBar>
          </div>
        </>
      )}
    </div>
  );
};

RightPanel.propTypes = {
  currentStep: PropTypes.string.isRequired,
  selectedTemplate: PropTypes.shape({}),
  searchParams: PropTypes.shape({
    type: PropTypes.string.isRequired,
    search: PropTypes.string,
    offset: PropTypes.number.isRequired,
  }).isRequired,
  setProducts: PropTypes.func.isRequired,
  updateProducts: PropTypes.func.isRequired,
  changeTemplate: PropTypes.func.isRequired,
  updateProductSearchParams: PropTypes.func.isRequired,
};

RightPanel.defaultProps = {
  selectedTemplate: null,
};

const mapStateToProps = ({
  curateTheLook: { currentStep, selectedTemplate, productSearchParams },
}) => ({
  currentStep,
  selectedTemplate,
  searchParams: productSearchParams,
});

const mapDispatchToProps = {
  setProducts: setProductsAction,
  updateProducts: updateProductsAction,
  changeTemplate: changeTemplateAction,
  updateProductSearchParams: updateProductSearchParamsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(RightPanel);
