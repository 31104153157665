import React from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import CustomScrollBar from 'components/ui/CustomScrollbar/CustomScrollBar';
import { notificationsMock } from 'modules/layout/Header/Popovers/messagesMock';
import MessageItem from 'modules/layout/Header/MessageItem/MeesageItem';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from './Popovers.module.scss';

const NotificationPopover = ({ open, onClose, anchorEl }) => (
  <BasicModal
    anchorEl={anchorEl}
    open={open}
    onClose={onClose}
    component={Popover}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    className={classes.root}
  >
    <p className="font-title text-lg mb-2">Notifications</p>
    <p className="text-sm text-gray mb-2">New</p>
    <CustomScrollBar autoHeightMin={350} autoHeightMax={450}>
      {notificationsMock.map(({ text, date }) => (
        <MessageItem
          key={date}
          text={text}
          date={date}
          thumbnail={
            <div className={classes.notificationThumbnail}>
              <SpriteIcon name="timer" size="lg" />
            </div>
          }
        />
      ))}
    </CustomScrollBar>
  </BasicModal>
);

NotificationPopover.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.object]),
};

NotificationPopover.defaultProps = {
  anchorEl: null,
};

export default NotificationPopover;
