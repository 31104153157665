import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ImageSlider from 'components/MixMatchThumbnail/ImageSlider';
import MatchRating from 'components/MixMatchThumbnail/MatchRating';
import SliderControls from 'components/MixMatchThumbnail/SliderControls';
import classes from './MixMatchThumbnail.module.scss';

const MixMatchThumbnail = ({ title, rating, imgUrls, showRating }) => {
  const sliderRef = useRef(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [ratingValue, setRatingValue] = React.useState(rating);

  const handleRatingUpdate = useCallback((event, newValue) => {
    setRatingValue(newValue);
  }, []);

  const handleSlideChange = useCallback((index) => {
    setActiveSlideIndex(index);
  }, []);

  const handleSlideNext = useCallback(() => {
    sliderRef.current.slickNext();
  }, []);

  const handleSlidePrev = useCallback(() => {
    sliderRef.current.slickPrev();
  }, []);

  const handleDotClick = useCallback(({ currentTarget }) => {
    const currentSlideIndex = currentTarget.getAttribute('data-index');
    sliderRef.current.slickGoTo(currentSlideIndex);
  }, []);

  return (
    <div className={classes.root}>
      <p className="text-lg font-semi-bold mb-2">{title}</p>
      <div className="flex-fill d-flex flex-column justify-content-center">
        <ImageSlider
          sliderRef={sliderRef}
          images={imgUrls}
          onChange={handleSlideChange}
        />
      </div>
      {showRating && (
        <MatchRating
          name={title}
          value={ratingValue}
          onChange={handleRatingUpdate}
        />
      )}
      <SliderControls
        items={imgUrls}
        activeSlideIndex={activeSlideIndex}
        onDotClick={handleDotClick}
        onSlidePrev={handleSlidePrev}
        onSlideNext={handleSlideNext}
      />
    </div>
  );
};

MixMatchThumbnail.propTypes = {
  // id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  rating: PropTypes.number,
  imgUrls: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
    })
  ).isRequired,
  showRating: PropTypes.bool,
};

MixMatchThumbnail.defaultProps = {
  rating: null,
  showRating: false,
};

export default MixMatchThumbnail;
