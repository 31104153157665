export const professionsMap = {
  decorLover: {
    title: 'Decor Lover',
    value: 'decorLover',
  },
  designProfessional: {
    title: 'Design Professional',
    value: 'designProfessional',
  },
  onlineShopper: {
    title: 'Online Shopper',
    value: 'onlineShopper',
  },
};
