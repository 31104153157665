import React from 'react';
import PropTypes from 'prop-types';
import InputSkeleton from 'components/inputs/InputSkeleton/InputSkeleton';
import classes from './CustomTextInput.module.scss';

const CustomTextInput = ({ type, placeholder, ...rest }) => {
  return (
    <InputSkeleton
      {...rest}
      input={<input type={type} placeholder={placeholder} />}
      inputClasses={{
        inputWrapper: classes.inputWrapper,
        input: classes.input,
      }}
    />
  );
};

CustomTextInput.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
};

CustomTextInput.defaultProps = {
  type: 'text',
  placeholder: '',
};

export default CustomTextInput;
