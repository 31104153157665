import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import HelperText from 'components/inputs/HelperText/HelperText';
import classes from './InputSkeleton.module.scss';

class InputSkeleton extends PureComponent {
  state = {
    focused: false,
  };

  onChangeWrapper = (event) => {
    const { maxLength, onChange } = this.props;
    const {
      target: { value },
    } = event;

    if (maxLength && value && maxLength < value.length) {
      return;
    }

    if (onChange) {
      onChange(event);
    }
  };

  handleFocus = () => {
    this.setState({ focused: true });
  };

  handleBlur = () => {
    this.setState({ focused: false });
  };

  maxLengthString() {
    const { maxLength, value } = this.props;

    if (!maxLength) {
      return null;
    }

    return (
      <HelperText message={`${value.length}/${maxLength}`} className="pt-1" />
    );
  }

  render() {
    const { focused } = this.state;
    const {
      label,
      helperText,
      inputClasses,
      error,
      value,
      prependContent,
      appendContent,
      className,
      noMargin,
      input,
      maxLength,
      onChange,
      // eslint-disable-next-line react/prop-types
      children,
      ...rest
    } = this.props;

    return (
      <div
        className={clsx(inputClasses.root, className, {
          [classes.hasError]: error,
          'mb-3': !noMargin,
        })}
      >
        {label ? (
          <label
            className={clsx(
              classes.label,
              { [classes.focused]: focused || Boolean(value) },
              inputClasses.label
            )}
          >
            {label}
          </label>
        ) : null}
        <div className={`${classes.inputWrapper} ${inputClasses.inputWrapper}`}>
          {prependContent && <div>{prependContent}</div>}
          {React.cloneElement(input, {
            ...rest,
            className: clsx(
              classes.input,
              { 'with-error': error },
              inputClasses.input
            ),
            value,
            onChange: this.onChangeWrapper,
            onFocus: this.handleFocus,
            onBlur: this.handleBlur,
          })}
          {appendContent && <div>{appendContent}</div>}
        </div>
        {this.maxLengthString()}
        {helperText && (
          <div className="pt-1">
            <HelperText isError={error} message={helperText} />
          </div>
        )}
      </div>
    );
  }
}

InputSkeleton.propTypes = {
  input: PropTypes.node.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  helperText: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  error: PropTypes.bool,
  noMargin: PropTypes.bool,
  maxLength: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  prependContent: PropTypes.node,
  appendContent: PropTypes.node,
  inputClasses: PropTypes.shape({
    root: PropTypes.string,
    label: PropTypes.string,
    inputWrapper: PropTypes.string,
    input: PropTypes.string,
  }),
};

InputSkeleton.defaultProps = {
  label: '',
  className: '',
  helperText: null,
  error: false,
  noMargin: false,
  maxLength: undefined,
  value: undefined,
  onChange: undefined,
  prependContent: null,
  appendContent: null,
  inputClasses: {},
};

export default InputSkeleton;
