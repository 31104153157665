import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import SpriteIcon from 'components/ui/SpriteIcon';
import moment from 'moment';
import classes from './Footer.module.scss';

// TODO: Replace links paths to actual routes

const Footer = ({ authenticated }) => {
  const { pathname } = useLocation();
  const currentYear = useMemo(() => moment().format('YYYY'), []);

  return (
    <div className={classes.root}>
      <div className="d-flex">
        <div className="d-flex align-items-end">
          <div className={classes.column}>
            <div className="font-semi-bold text-uppercase primary-color text-lg mb-2">
              Company
            </div>
            <ul className={classes.list}>
              <li className="mb-1">
                <Link to={routesByName.about}>About Us</Link>
              </li>
              <li className="mb-1">
                <Link to={routesByName.faqs}>Pin the Look FAQs</Link>
              </li>
              <li className="mb-1">
                <Link to={routesByName.contact}>Contact Us</Link>
              </li>
              <li>
                <Link to={routesByName.privacy}>Terms & Privacy</Link>
              </li>
            </ul>
          </div>
          <div className={classes.horizontalDivider} />
          <div className={classes.column}>
            <div className="font-semi-bold text-uppercase primary-color text-lg mb-2">
              How It Works
            </div>
            <ul className={classes.list}>
              <li className="mb-1">
                <Link to={routesByName.howItworks}>Overview</Link>
              </li>
              <li className="mb-1">
                <Link to={routesByName.requestTheLook.index}>
                  Request the Look
                </Link>
              </li>
              <li className="mb-1">
                <Link to={routesByName.curateTheLook.index}>
                  Curate the Look
                </Link>
              </li>
              <li>
                <Link to={routesByName.getTheLook}>Get the Look</Link>
              </li>
            </ul>
          </div>
          <div className={classes.horizontalDivider} />
          <div className={classes.column}>
            <div className="font-semi-bold text-uppercase primary-color text-lg mb-2">
              Ambassadors
            </div>
            <ul className={classes.list}>
              <li className="mb-1">
                <Link to={routesByName.ambassador.index}>Overview</Link>
              </li>
              <li className="mb-1">
                <Link
                  to={
                    authenticated
                      ? routesByName.requestInvitation
                      : `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
                  }
                >
                  Invitation Request
                </Link>
              </li>
              <li>
                <Link to={routesByName.ambassador.faqs}>Ambassador FAQs</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex-fill d-flex align-items-center justify-content-end">
          <div className={classes.horizontalDivider} />
          <div className="d-flex flex-column">
            <div className="d-flex align-items-center">
              <span className={classes.socialLinksTitle}>Keep Connected</span>
              <Link to={routesByName.about} className={classes.socialLink}>
                <SpriteIcon name="social-facebook" size="lg" />
              </Link>
              <Link to={routesByName.about} className={classes.socialLink}>
                <SpriteIcon name="social-twitter" size="lg" />
              </Link>
              <Link to={routesByName.about} className={classes.socialLink}>
                <SpriteIcon name="social-instagram" size="lg" />
              </Link>
            </div>
            <div className="mt-2 font-italic font-semi-bold text-sm">
              Pin the Look 2020-{currentYear}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  authenticated: PropTypes.bool.isRequired,
};

const mapSateToProps = ({ auth: { user } }) => ({
  authenticated: Boolean(user),
});

export default connect(mapSateToProps, null)(Footer);
