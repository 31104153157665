export const requestModalFields = {
  from: 'from',
  subject: 'subject',
  message: 'message',
};

export const errorMessages = {
  required: 'This field is required',
};

export const requestModalDefaultValues = {
  subject: 'Ambassador Invitation Request',
};

export const requestModalPlaceholders = {
  message:
    'Please tell us about your interest and skills, and let us know if you have any questions',
};

export default {
  requestModalFields,
  errorMessages,
};
