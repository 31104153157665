import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import { Field, Form } from 'react-final-form';
import { codeModalFields } from 'modules/ambassadors/components/CodeModal/constants';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import classes from './CodeModal.module.scss';

const CodeModal = ({
  open,
  onClose,
  authenticated,
  pathname,
  onSubmit,
  validate,
  onOpenRequestModal,
}) => {
  const handleOpenRequestModal = useCallback(() => {
    onClose();
    onOpenRequestModal();
  }, [onClose, onOpenRequestModal]);

  return (
    <BasicModal open={open} onClose={onClose} fullWidth maxWidth="sm">
      <div className={classes.root}>
        <div className="flex-center flex-column text-center">
          <h2 className={classes.title}>Code Required</h2>
          <p className={classes.description}>
            Please, enter your access code or{' '}
            {authenticated ? (
              <button
                onClick={handleOpenRequestModal}
                className={clsx(classes.link, classes.btn)}
                type="button"
              >
                Request an Invitation
              </button>
            ) : (
              <Link
                className={classes.link}
                onClick={onClose}
                to={`${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`}
              >
                Request an Invitation
              </Link>
            )}
          </p>
          <Form
            validate={validate}
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <Field
                  className={classes.codeField}
                  required
                  name={codeModalFields.code}
                  component={TextInputWrapper}
                />
                <div>
                  <FinalFormSubmitButton
                    className="mt-4"
                    variant="outlined"
                    inline
                  >
                    Get Started Now!
                  </FinalFormSubmitButton>
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </BasicModal>
  );
};

CodeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  onOpenRequestModal: PropTypes.func.isRequired,
};

export default CodeModal;
