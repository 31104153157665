import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SignUpComponent from 'modules/auth/signUp/SignUpComponent';
import { accountTypes, signUpFormFields } from 'modules/auth/signUp/constants';
import signUpValidator from 'modules/auth/signUp/signUpValidator';
import { signUpAction } from 'modules/auth/store/actions';
import { transformFormValuesToRegisterData } from 'modules/auth/signUp/transformers';
import errorToastr from 'libs/toastr/errorToastr';
import successToastr from 'libs/toastr/successToastr';

const SignUpContainer = ({ signUp, onCloseModal }) => {
  const [formValues] = useState({
    [signUpFormFields.type]: accountTypes.personal,
  });

  const onSubmit = useCallback(
    async (values) => {
      const preparedUserData = transformFormValuesToRegisterData(values);

      try {
        await signUp(preparedUserData);
        successToastr('Success', 'You successfully registered');
        onCloseModal();
      } catch (e) {
        errorToastr('Error', e.generalError);
        return {
          ...e.validationErrors,
        };
      }
      return true;
    },
    [signUp, onCloseModal]
  );

  return (
    <SignUpComponent
      initialValues={formValues}
      validateForm={signUpValidator}
      onSubmit={onSubmit}
    />
  );
};

SignUpContainer.propTypes = {
  signUp: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

SignUpContainer.defaultProps = {};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  signUp: signUpAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpContainer);
