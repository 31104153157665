import PropTypes from 'prop-types';

export const navLinksArrayOf = PropTypes.arrayOf(
  PropTypes.shape({
    route: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    miniTitle: PropTypes.string,
    icon: PropTypes.string.isRequired,
  })
);

export const infoMenuItemsArrayOf = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  })
);

export const infoMenuShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  items: infoMenuItemsArrayOf.isRequired,
});
