import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { defaultPriorityItem } from 'modules/requestTheLook/requestDetails/requestForm/constants';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextInput from 'components/inputs/TextInput/TextInput';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from './AutoCompleteInput.module.scss';

const AutoCompleteInput = ({ index, handleChange, ...rest }) => {
  const onChange = useCallback(
    (_, option) => {
      const values = option ?? { ...defaultPriorityItem };
      handleChange({ index, values });
    },
    [index, handleChange]
  );

  return (
    <Autocomplete
      {...rest}
      onChange={onChange}
      classes={{
        root: 'position-relative',
        inputRoot: classes.inputRoot,
        input: classes.input,
        clearIndicator: classes.clearIndicator,
        option: classes.option,
      }}
      fullWidth
      popupIcon={false}
      closeIcon={
        <SpriteIcon
          className={classes.clearIcon}
          name="trash-filled"
          size="xs"
        />
      }
      getOptionLabel={({ label }) => label}
      getOptionSelected={() => true}
      renderInput={(params) => (
        <>
          <SpriteIcon
            className={`${classes.icon} primary-color`}
            name="search"
            size="sm"
          />
          <TextInput {...params} placeholder="Click to start Search Item" />
        </>
      )}
    />
  );
};

AutoCompleteInput.propTypes = {
  index: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default AutoCompleteInput;
