import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { imageTypeKeys } from 'constants/inspirationImageSearchParams';
import { getImageType } from 'modules/app/store/selectors';
import { priorityItemShape } from 'modules/requestTheLook/requestDetails/requestForm/propTypes';
import { Field, Form, FormSpy } from 'react-final-form';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import {
  requestFormFields,
  requestFormLabels,
} from 'modules/requestTheLook/requestDetails/requestForm/constants';
import InfoIconPopover from 'components/ui/InfoIconPopover/InfoIconPopover';
import Button from 'components/ui/Button/Button';
import PriorityItems from 'modules/requestTheLook/requestDetails/components/PriorityItems/PriorityItems';
import classes from './RequestForm.module.scss';

const RequestFormComponent = ({
  onSubmit,
  onTitleChange,
  validateForm,
  initialValues,
  handleAddAnotherItem,
  requestPriorityItems,
  showAddAnotherItem,
  optionsList,
  handleUnderConstructionModalOpen,
  requestType,
  imgType,
}) => (
  <Form
    initialValues={initialValues}
    validate={validateForm}
    onSubmit={onSubmit}
    render={({ handleSubmit }) => (
      <form className="d-flex flex-fill" noValidate onSubmit={handleSubmit}>
        <FormSpy onChange={onTitleChange} />
        <div className="d-flex flex-column flex-fill">
          <div className="row mb-5">
            <div className="col-4">
              <div className="d-flex align-items-center mb-4">
                <div className="text-sm font-semi-bold mr-1">
                  Request Title<span className="secondary-color">*</span>
                </div>
                <InfoIconPopover
                  iconProps={{
                    variant: 'inverted-grey',
                    color: 'secondary',
                    size: 'xs',
                  }}
                  fullWidth
                >
                  <p className="font-semi-bold mb-1">Title Description</p>
                  <p className="text-xs">
                    Select up to 6 priority items from your inspiration image.
                    Our decor lovers will then create Look Board recommendations
                    that will include one or more of these priority items
                  </p>
                </InfoIconPopover>
              </div>
              <Field
                required
                name={requestFormFields.title}
                component={TextInputWrapper}
              />
            </div>
            <div className="col-1" />
            <div className="col-4">
              {requestType === imageTypeKeys.single ? (
                <>
                  <div
                    className={`${classes.formTitle} text-sm font-semi-bold mb-4`}
                  >
                    {requestFormLabels[requestType]}
                  </div>
                  <div className={classes.fakeInput}>{imgType.singular}</div>
                </>
              ) : (
                <PriorityItems
                  showAddAnotherItem={showAddAnotherItem}
                  handleAddAnotherItem={handleAddAnotherItem}
                  requestPriorityItems={requestPriorityItems}
                  optionsList={optionsList}
                />
              )}
            </div>
          </div>
          <div className={`${classes.formButtons} row`}>
            <div className="col-6 flex-center text-center flex-column">
              <h3 className={classes.dashTitle}>Free Request</h3>
              <button
                onClick={handleUnderConstructionModalOpen}
                className={`${classes.underlineBtn} primary-color text-sm font-italic mb-3`}
                type="button"
              >
                Learn More
              </button>
              <FinalFormSubmitButton variant="outlined" size="lg" inline>
                Review Request
              </FinalFormSubmitButton>
            </div>
            <div className="col-6">
              <div className="col-6 flex-center text-center flex-column">
                <h3 className={classes.dashTitle}>Paid Request</h3>
                <button
                  onClick={handleUnderConstructionModalOpen}
                  className={`${classes.underlineBtn} primary-color text-sm font-italic mb-3`}
                  type="button"
                >
                  Learn More
                </button>
                <Button
                  onClick={handleUnderConstructionModalOpen}
                  variant="outlined"
                  size="lg"
                  inline
                  type="button"
                >
                  Upgrade Request
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    )}
  />
);

RequestFormComponent.propTypes = {
  validateForm: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onTitleChange: PropTypes.func.isRequired,
  handleAddAnotherItem: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
  requestPriorityItems: PropTypes.arrayOf(priorityItemShape).isRequired,
  optionsList: PropTypes.arrayOf(priorityItemShape).isRequired,
  showAddAnotherItem: PropTypes.bool.isRequired,
  handleUnderConstructionModalOpen: PropTypes.func.isRequired,
  requestType: PropTypes.string.isRequired,
  imgType: PropTypes.shape({
    singular: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = ({
  app: { enums },
  requestTheLook: {
    requestData: { inspirationImageId },
  },
  inspirationImage: { library },
}) => ({
  imgType: getImageType(library[inspirationImageId], enums),
});

export default connect(mapStateToProps)(RequestFormComponent);
