import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import Fab from '@material-ui/core/Fab';
import SpriteIcon from 'components/ui/SpriteIcon';
import ColorPoint from 'components/colorSelectors/ColorPoint/ColorPoint';
import SelectColorPopover from 'components/modals/SelectColorPopover/SelectColorPopover';
import colorsConfig from 'components/modals/SelectColorPopover/colorsConfig';
import classes from './CurateColorPicker.module.scss';

const CurateColorPicker = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedColors, setSelectedColors] = useState([]);

  const handleColorPickerOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleApplyColors = useCallback(
    (colors) => {
      setSelectedColors(colors.map((colorId) => colorsConfig[colorId]));
      handleClose();
    },
    [handleClose]
  );

  return (
    <>
      <div
        className={clsx('d-flex align-items-center mb-1', classes.pickerTitle)}
      >
        <span className="mr-1">Primary Colors</span>
        <Fab size="small" color="primary" onClick={handleColorPickerOpen}>
          <SpriteIcon name="brush" size="md" />
        </Fab>
      </div>
      <div className={`d-flex ${classes.colorsContainer}`}>
        {selectedColors.map(({ id, color }) => (
          <ColorPoint
            key={id}
            id={id}
            className={classes.selectedColor}
            color={color}
          />
        ))}
      </div>
      <SelectColorPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        onApply={handleApplyColors}
      />
    </>
  );
};

export default CurateColorPicker;
