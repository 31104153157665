import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance';

const urlPrefix = '/v1';

const supportService = {
  sendSupportMessage(data) {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/support`, data);
  },
};

export default supportService;
