import PropTypes from 'prop-types';
import { mediaShape } from 'modules/media/propTypes';
import { imageTypeKeys } from 'constants/inspirationImageSearchParams';

export const inspirationImageShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  title: PropTypes.string,
  isLiked: PropTypes.number.isRequired,
  type: PropTypes.oneOf(Object.values(imageTypeKeys)),
  roomTypeId: PropTypes.number,
  url: PropTypes.string,
  media: mediaShape.isRequired,
});
