import React from 'react';
import PropTypes from 'prop-types';
import BasicModal from 'components/modals/BasicModal/BasicModal';

const UnderConstructionModal = ({ open, onClose }) => (
  <BasicModal open={open} onClose={onClose} fullWidth>
    <p className="font-title text-lg text-center p-5">
      Ths feature is under construction
    </p>
  </BasicModal>
);

UnderConstructionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UnderConstructionModal;
