import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { urlPlaceholders } from 'constants/urlPlaceholders';
import stylesOptionShape from 'components/PreferenceQuiz/propTypes/stylesOptionShape';
import {
  getStyleImgPreviewUrl,
  getStyleImgUrl,
} from 'modules/app/store/selectors';
import SpriteIcon from 'components/ui/SpriteIcon';
import IconButton from 'components/ui/IconButton/IconButton';
import classes from 'components/PreferenceQuiz/StylesCard/StylesCard.module.scss';

const StylesCard = ({
  option: {
    id,
    name,
    media: { hash },
  },
  currentValue,
  onSelect,
  className,
  styleImgUrl,
  styleImgPreviewUrl,
  onOpenPreviewModal,
}) => {
  const selected = currentValue.includes(id);
  const handleSelect = useCallback(() => {
    onSelect(id);
  }, [onSelect, id]);

  const handleOpenPreview = useCallback(() => {
    const previewUrl = styleImgPreviewUrl.replace(urlPlaceholders.hash, hash);
    onOpenPreviewModal(previewUrl);
  }, [onOpenPreviewModal, styleImgPreviewUrl, hash]);

  return (
    <div
      className={clsx(classes.root, className, {
        [classes.selected]: selected,
      })}
    >
      <div className={classes.imgContainer} onClick={handleSelect}>
        <img src={styleImgUrl.replace(urlPlaceholders.hash, hash)} alt={name} />
      </div>
      <div className="d-flex align-items-center p-3" onClick={handleSelect}>
        <div className="mr-2">
          {selected ? (
            <SpriteIcon name="checked" size="md" />
          ) : (
            <div className={classes.unselectedIcon} />
          )}
        </div>
        <p className="text-ellipsis text-nowrap font-semi-bold text-sm">
          {name}
        </p>
      </div>
      <IconButton
        className={classes.previewBtn}
        type="button"
        onClick={handleOpenPreview}
      >
        <SpriteIcon name="loupe" size="sm" />
      </IconButton>
    </div>
  );
};

StylesCard.propTypes = {
  option: stylesOptionShape.isRequired,
  onSelect: PropTypes.func.isRequired,
  currentValue: PropTypes.arrayOf(PropTypes.number).isRequired,
  styleImgUrl: PropTypes.string.isRequired,
  styleImgPreviewUrl: PropTypes.string.isRequired,
  onOpenPreviewModal: PropTypes.func.isRequired,
  className: PropTypes.string,
};

StylesCard.defaultProps = {
  className: '',
};

const mapStateToProps = ({ app: { config } }) => ({
  styleImgUrl: getStyleImgUrl(config),
  styleImgPreviewUrl: getStyleImgPreviewUrl(config),
});

export default connect(mapStateToProps)(StylesCard);
