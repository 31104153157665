import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from './PermissionsSwitch.module.scss';

const PermissionsSwitch = ({ checked, onChange }) => {
  const label = useMemo(() => (checked ? 'Private' : 'Public'), [checked]);

  const handleChange = useCallback(() => {
    onChange(!checked);
  }, [onChange, checked]);

  return (
    <div className={`${classes.root} mr-1`} onClick={handleChange}>
      <span>{`Make ${label}`}</span>
      <div className={clsx(classes.thumb, { [classes.checked]: checked })}>
        <SpriteIcon name="diamond" size="sm" />
      </div>
    </div>
  );
};

PermissionsSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PermissionsSwitch;
