import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import createLookBoardClasses from 'modules/curateTheLook/createLookBoard/CreateLookBoard.module.scss';
import SearchInput from 'components/inputs/SearchInput/SearchInput';
import CustomRadioGroup from 'modules/curateTheLook/createLookBoard/components/CustomRadioGroup';
import { imageSearchRadioOptions } from 'modules/curateTheLook/constants';
import { updateSearchParamsAction } from 'modules/getTheLook/store/actions';
import { toggleUnderConstructionModalAction } from 'modules/layout/store/actions';
import classes from 'modules/curateTheLook/createLookBoard/components/ImagesSearchParamsBlock/ImagesSearchParamsBlock.module.scss';
import { routesByName } from 'constants/routes';
import { imageTypeKeys } from 'constants/inspirationImageSearchParams';

const SearchParamsBlock = ({
  searchParams,
  toggleUnderConstructionModal,
  updateSearchParams,
}) => {
  const { pathname } = useLocation();
  const inputRef = useRef(null);
  const [searchInputOpen, setSearchInputOpen] = useState(false);

  const showControls = useMemo(
    () => pathname === routesByName.getTheLook.index,
    [pathname]
  );

  const subTitle = useMemo(
    () => ({
      label:
        searchParams.type === imageTypeKeys.room
          ? 'Room Images'
          : 'Single Items',
      description:
        searchParams.type === imageTypeKeys.room
          ? 'Room Design'
          : 'Single Item',
    }),
    [searchParams.type]
  );

  const openSearchInput = useCallback(() => {
    setSearchInputOpen(true);
    inputRef.current.focus();
  }, []);

  const closeSearchInput = useCallback(() => {
    setSearchInputOpen(false);
  }, []);

  const handleUpdateSearchParams = useCallback(
    (params) => {
      updateSearchParams({ ...params, offset: 0 });
    },
    [updateSearchParams]
  );

  const handleChangeSearchQuery = useCallback(
    (search) => {
      handleUpdateSearchParams({ search });
    },
    [handleUpdateSearchParams]
  );

  const handleChangeImgType = useCallback(
    ({ target: { value: type } }) => {
      handleUpdateSearchParams({ type });
    },
    [handleUpdateSearchParams]
  );

  const handleUnderConstructionModalOpen = useCallback(() => {
    toggleUnderConstructionModal(true);
  }, [toggleUnderConstructionModal]);

  return (
    <>
      <div className="position-relative d-flex mb-2">
        <div
          className={clsx(classes.filtersBlock, {
            [classes.invisible]: searchInputOpen,
          })}
        >
          <h3 className="text-lg font-semi-bold mb-1">Get The Look</h3>
          <p className="text-xs font-italic">Inspiration Image Database</p>
        </div>
        {showControls && (
          <>
            <IconButton
              variant="inverted-white"
              className="mr-1"
              onClick={openSearchInput}
            >
              <SpriteIcon name="loupe" size="sm" />
            </IconButton>
            <IconButton
              variant="inverted-white"
              onClick={handleUnderConstructionModalOpen}
            >
              <SpriteIcon name="filters" size="sm" />
            </IconButton>

            <div
              className={clsx(createLookBoardClasses.searchInput, {
                [createLookBoardClasses.active]: searchInputOpen,
              })}
            >
              <SearchInput
                inputRef={inputRef}
                value={searchParams.search}
                placeholder="Search by keywords"
                onChange={handleChangeSearchQuery}
                onCrossClick={closeSearchInput}
              />
            </div>
          </>
        )}
      </div>
      {showControls && (
        <CustomRadioGroup
          controls={imageSearchRadioOptions}
          value={searchParams.type}
          onChange={handleChangeImgType}
        />
      )}
      <div className="mt-2 text-capitalize">
        <p className="font-semi-bold mb-1">{subTitle.label}</p>
        <p className="text-xs font-italic">
          Get the look of a {subTitle.description}
        </p>
      </div>
    </>
  );
};

SearchParamsBlock.propTypes = {
  toggleUnderConstructionModal: PropTypes.func.isRequired,
  updateSearchParams: PropTypes.func.isRequired,
  searchParams: PropTypes.shape({
    search: PropTypes.string,
    type: PropTypes.string.isRequired,
    offset: PropTypes.number.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ getTheLook: { searchParams } }) => ({
  searchParams,
});

const mapDispatchToProps = {
  toggleUnderConstructionModal: toggleUnderConstructionModalAction,
  updateSearchParams: updateSearchParamsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchParamsBlock);
