import React from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import BasicAvatar from 'components/ui/BasicAvatar';
import classes from './HoverOverlay.module.scss';

const HoverOverlay = ({
  firstName,
  lastName,
  avatarUrl,
  isLiked,
  onToggleLike,
  onOpenUnderConstructionModal,
}) => {
  return (
    <div className={classes.lookBoardOverlay}>
      <div className="w-100 d-flex justify-content-end p-1">
        <IconButton
          className="mr-1"
          variant="inverted-grey"
          size="sm"
          onClick={onOpenUnderConstructionModal}
        >
          <SpriteIcon name="info" size="sm" />
        </IconButton>
        <IconButton
          className="mr-1"
          variant="inverted-grey"
          size="sm"
          onClick={onOpenUnderConstructionModal}
        >
          <SpriteIcon name="share" size="sm" />
        </IconButton>
        <IconButton
          variant="inverted-grey"
          size="sm"
          onClick={onOpenUnderConstructionModal}
        >
          <SpriteIcon name="more-vertical" size="sm" />
        </IconButton>
      </div>
      <div className={classes.bottomBar}>
        <div className={classes.userBlock}>
          <BasicAvatar
            firstName={firstName}
            lastName={lastName}
            src={avatarUrl}
            className="mr-2"
          />
          <p className="text-ellipsis text-sm font-semi-bold">
            {`${firstName} ${lastName}`}
          </p>
        </div>
        <div className="w-100 d-flex justify-content-end py-1">
          <IconButton
            variant="inverted-grey"
            color="secondary"
            size="sm"
            onClick={onToggleLike}
          >
            <SpriteIcon name={isLiked ? 'like-filled' : 'like'} size="sm" />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

HoverOverlay.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
  isLiked: PropTypes.number.isRequired,
  onToggleLike: PropTypes.func.isRequired,
  onOpenUnderConstructionModal: PropTypes.func.isRequired,
};

HoverOverlay.defaultProps = {
  avatarUrl: null,
};

export default HoverOverlay;
