import {
  imageTypeValuesMap,
  createImageFieldKeys,
  validationMessages,
} from 'modules/pinMedia/constants';

const createImageValidator = ({
  [createImageFieldKeys.type]: type,
  [createImageFieldKeys.title]: title,
  [createImageFieldKeys.roomType]: roomType,
  [createImageFieldKeys.itemClassId]: itemClassId,
}) => {
  const errors = {};

  if (!type) {
    errors[createImageFieldKeys.type] = [validationMessages.required];
  }

  if (!title) {
    errors[createImageFieldKeys.title] = [validationMessages.required];
  }
  if (type === imageTypeValuesMap.room && !roomType) {
    errors[createImageFieldKeys.roomType] = [validationMessages.required];
  }
  if (type === imageTypeValuesMap.single && !itemClassId) {
    errors[createImageFieldKeys.itemClassId] = [validationMessages.required];
  }

  return errors;
};

export default createImageValidator;
