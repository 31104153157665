import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from 'components/MixMatchThumbnail/MixMatchThumbnail.module.scss';

const SliderControls = ({
  items,
  activeSlideIndex,
  onSlidePrev,
  onSlideNext,
  onDotClick,
}) => {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <button
        type="button"
        className={classes.slideControl}
        onClick={onSlidePrev}
      >
        <SpriteIcon name="arrow-down" size="sm" className={classes.prevSlide} />
      </button>
      <div className="d-flex">
        {items.map(({ id }, index) => (
          <div
            key={id}
            className={clsx(classes.dot, {
              [classes.active]: index === activeSlideIndex,
            })}
            data-index={index}
            onClick={onDotClick}
          />
        ))}
      </div>
      <button
        type="button"
        className={classes.slideControl}
        onClick={onSlideNext}
      >
        <SpriteIcon name="arrow-down" size="sm" className={classes.nextSlide} />
      </button>
    </div>
  );
};

SliderControls.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  activeSlideIndex: PropTypes.number.isRequired,
  onSlidePrev: PropTypes.func.isRequired,
  onSlideNext: PropTypes.func.isRequired,
  onDotClick: PropTypes.func.isRequired,
};

export default SliderControls;
