import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import CustomInfiniteScroll from 'components/CustomInfiniteScroll';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import {
  columnViewRadioOptions,
  stepTwoColumnViewKeys,
} from 'modules/curateTheLook/constants';
import ProductItem from 'modules/curateTheLook/createLookBoard/components/ProductItem/ProductItem';
import CustomRadioGroup from 'modules/curateTheLook/createLookBoard/components/CustomRadioGroup';

const ProductsDatabase = ({
  loading,
  hasMore,
  loadMore,
  productList,
  productsColumnId,
  scrollerHeight,
}) => {
  const [columnView, setColumnView] = useState(stepTwoColumnViewKeys.double);

  const handleChaneColumnView = useCallback(({ target: { value } }) => {
    setColumnView(value);
  }, []);

  function getStyle(style, snapshot) {
    if (!snapshot.isDropAnimating) {
      return style;
    }
    return {
      ...style,
      // cannot be 0, but make it super tiny
      transitionDuration: `0.001s`,
    };
  }

  return (
    <>
      <CustomRadioGroup
        controls={columnViewRadioOptions}
        value={columnView}
        onChange={handleChaneColumnView}
      />
      <CustomInfiniteScroll
        parentHeight={scrollerHeight}
        isLoading={loading}
        hasMore={hasMore}
        loadMore={loadMore}
        initialLoad={false}
        useWindow={false}
      >
        <Droppable droppableId={productsColumnId} isDropDisabled>
          {(provided) => (
            <div ref={provided.innerRef} className="row">
              {productList.map(
                ({ id, url, media: { userId, hash } }, index) => (
                  <div
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={id}
                    className={clsx('mb-2', {
                      'col-6': columnView === stepTwoColumnViewKeys.double,
                      'col-12': columnView === stepTwoColumnViewKeys.single,
                    })}
                  >
                    <Draggable draggableId={String(id)} index={index}>
                      {(
                        { draggableProps, dragHandleProps, innerRef },
                        snapshot
                      ) => (
                        <>
                          <div
                            {...draggableProps}
                            {...dragHandleProps}
                            style={getStyle(draggableProps.style, snapshot)}
                            ref={innerRef}
                          >
                            <ProductItem
                              id={id}
                              url={url}
                              userId={userId}
                              hash={hash}
                            />
                          </div>
                        </>
                      )}
                    </Draggable>
                  </div>
                )
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </CustomInfiniteScroll>
    </>
  );
};

ProductsDatabase.propTypes = {
  loading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  productList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  productsColumnId: PropTypes.string.isRequired,
  scrollerHeight: PropTypes.number.isRequired,
};

const mapSateToProps = ({
  curateTheLook: { dndState },
  product: { library },
}) => ({
  productsColumnId: dndState.productList.id,
  productList: dndState.productList.productIds.map((id) => library[id]),
});

const mapDispatchToProps = {};

export default connect(mapSateToProps, mapDispatchToProps)(ProductsDatabase);
