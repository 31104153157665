import React, { useCallback, useState } from 'react';
import * as PropTypes from 'prop-types';

import colorsConfig from 'components/modals/FiltersModal/colorsConfig';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import ColorSelect from 'components/colorSelectors/ColorSelect';

const FiltersModal = ({ open, onClose }) => {
  const [selectedColors, setSelectedColors] = useState({});

  const handleChange = useCallback(
    (event) => {
      const { id, checked } = event.target;
      const selectedColorsCopy = { ...selectedColors };

      if (!checked) {
        delete selectedColorsCopy[id];
      } else if (Object.keys(selectedColorsCopy).length < 3) {
        selectedColorsCopy[id] = colorsConfig[id];
      }

      setSelectedColors(selectedColorsCopy);
    },
    [selectedColors]
  );

  return (
    <BasicModal open={open} onClose={onClose} maxWidth="md">
      <ColorSelect
        colorList={Object.values(colorsConfig)}
        onChange={handleChange}
        selectedColors={selectedColors}
      />
    </BasicModal>
  );
};

FiltersModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

FiltersModal.defaultProps = {};

export default FiltersModal;
