import React from 'react';
import SpriteIcon from 'components/ui/SpriteIcon';
import ambassadorImg from 'assets/img/dashboard-page/ambassador-overview.jpg';
import classes from './AmbassadorManagement.module.scss';

const AmbassadorManagement = () => (
  <section className={classes.root}>
    <h1 className={classes.title}>Ambassador management</h1>
    <div className={classes.banner}>
      <div className="d-flex align-items-center">
        <div className={classes.icon}>
          <SpriteIcon name="mortarboard" />
        </div>
        <h2 className="ml-1 text-sm font-semi-bold">Overview</h2>
      </div>
    </div>
    <div className={classes.content}>
      <div className={classes.contentBlock}>
        <h2 className={classes.contentTitle}>WELCOME TO THE TEAM!</h2>
        <p className="font-italic mb-3">
          You are now a Pin the Look Ambassador
        </p>
        <p className="font-semi-bold primary-color mb-2">
          So what happens next?
        </p>
        <p className="font-semi-bold mb-3">
          As an ambassador, you can help others find the look they love in a
          number of ways:
        </p>
        <ul className={classes.list}>
          <li>Curate the Look of user requests sent to you</li>
          <li>
            Create your own Showcase Page featuring curated Look Boards for your
            favorite room images
          </li>
          <li>
            Share your Look Board Designs and Showcase page link on Facebook and
            Instagram
          </li>
        </ul>
        <p>
          As an &quot;active&quot; Ambassador, you will gain access to discounts
          and special offers from our home furnishing partners. Once you setup
          your Showcase page and curate Look Boards, you will start receiving
          special offers.
        </p>
      </div>
      <div className={classes.image}>
        <img className="w-100 h-100" src={ambassadorImg} alt="" />
      </div>
    </div>
  </section>
);

export default AmbassadorManagement;
