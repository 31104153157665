import shabyChair from 'assets/img/home-page/shaby-collection/chair.png';
import shabyTable from 'assets/img/home-page/shaby-collection/table.png';
import shabyLamp from 'assets/img/home-page/shaby-collection/lamp.png';
import shabyVase from 'assets/img/home-page/shaby-collection/vase.png';

import roomModern from 'assets/img/home-page/room-types/modern.jpg';
import roomFarm from 'assets/img/home-page/room-types/farmhouse.jpg';

import getPreviewImg from 'assets/img/home-page/sections-preview/get.svg';
import requestPreviewImg from 'assets/img/home-page/sections-preview/request.svg';
import curatePreviewImg from 'assets/img/home-page/sections-preview/curate.svg';
import comparePreviewImg from 'assets/img/home-page/sections-preview/compare.svg';
import shopPreviewImg from 'assets/img/home-page/sections-preview/shop.svg';

import ambassador1 from 'assets/img/home-page/ambassadors-slider/ambassador-1.png';
import ambassador2 from 'assets/img/home-page/ambassadors-slider/ambassador-2.png';
import ambassador3 from 'assets/img/home-page/ambassadors-slider/ambassador-3.png';
import ambassador4 from 'assets/img/home-page/ambassadors-slider/ambassador-4.png';

import stool1 from 'assets/img/home-page/furniture/stool-1.png';
import stool2 from 'assets/img/home-page/furniture/stool-2.png';
import stool3 from 'assets/img/home-page/furniture/stool-3.png';
import stool4 from 'assets/img/home-page/furniture/stool-4.png';

import table1 from 'assets/img/home-page/furniture/table-1.png';
import table2 from 'assets/img/home-page/furniture/table-2.png';
import table3 from 'assets/img/home-page/furniture/table-3.png';
import table4 from 'assets/img/home-page/furniture/table-4.png';
import table5 from 'assets/img/home-page/furniture/table-5.png';

import sofa1 from 'assets/img/home-page/furniture/sofa-1.png';
import sofa2 from 'assets/img/home-page/furniture/sofa-2.png';
import sofa3 from 'assets/img/home-page/furniture/sofa-3.png';
import sofa4 from 'assets/img/home-page/furniture/sofa-4.png';

import chair1 from 'assets/img/home-page/furniture/chair-1.png';
import chair2 from 'assets/img/home-page/furniture/chair-2.png';
import chair3 from 'assets/img/home-page/furniture/chair-3.png';
import chair4 from 'assets/img/home-page/furniture/chair-4.png';
import chair5 from 'assets/img/home-page/furniture/chair-5.png';
import chair6 from 'assets/img/home-page/furniture/chair-6.png';

export const imagesConfig = {
  ovalShabyCollection: [
    {
      id: 1,
      url: shabyChair,
    },
    {
      id: 2,
      url: shabyTable,
    },
    {
      id: 3,
      url: shabyLamp,
    },
    {
      id: 4,
      url: shabyVase,
    },
  ],
};

export const roomTypesConfig = [
  {
    id: 1,
    type: 'Contemporary',
    imgUrl: roomFarm,
  },
  {
    id: 2,
    type: 'Modern',
    imgUrl: roomModern,
  },
  {
    id: 3,
    type: 'Rustic',
    imgUrl: roomModern,
  },
  {
    id: 4,
    type: 'Farmhouse',
    imgUrl: roomFarm,
  },
];

export const previewsCongif = [
  {
    title: 'Get the Look',
    description: 'Visit our Gallery of Pre-Curated Look Boards',
    imgUrl: getPreviewImg,
    targetId: 'get-the-look',
  },
  {
    title: 'Request the Look',
    description: 'Get Look Board Recommendations for Favorite Images',
    imgUrl: requestPreviewImg,
    targetId: 'request-the-look',
  },
  {
    title: 'Curate the Look',
    description: 'Create Look Boards With Easy Drag & Drop Tools',
    imgUrl: curatePreviewImg,
    targetId: 'curate-the-look',
  },
  {
    title: 'Compare the Look',
    description: 'Compare Similar Items to Find the Best Match',
    imgUrl: comparePreviewImg,
    targetId: 'compare-the-look',
  },
  {
    title: 'Shop the Look',
    description: 'Follow Product Links to Purchase Anywhere Online',
    imgUrl: shopPreviewImg,
    targetId: 'shop-the-look',
  },
];

export const ambassadorsSliderConfig = [
  {
    id: 1,
    imgUrl: ambassador1,
    text: 'So Many Possibilities! Can’t Wait',
    name: 'Janice l. Ambassador',
  },
  {
    id: 2,
    imgUrl: ambassador2,
    text: 'So Many Possibilities! Can’t Wait',
    name: 'Janice l. Ambassador',
  },
  {
    id: 3,
    imgUrl: ambassador3,
    text: 'So Many Possibilities! Can’t Wait',
    name: 'Janice l. Ambassador',
  },
  {
    id: 4,
    imgUrl: ambassador4,
    text: 'So Many Possibilities! Can’t Wait',
    name: 'Janice l. Ambassador',
  },
];

export const visualizeSectionConfig = {
  colors: ['#A0ADB2', '#E2DFDA', '#FFFFFF'],
  lookBoardTabData: [
    {
      id: 1,
      title: 'Look Board 1',
      items: [
        {
          id: 1,
          url: stool2,
        },
        {
          id: 2,
          url: table3,
        },
        {
          id: 3,
          url: sofa3,
        },
        {
          id: 4,
          url: chair5,
        },
      ],
    },
    {
      id: 2,
      title: 'Look Board 2',
      items: [
        {
          id: 1,
          url: stool1,
        },
        {
          id: 2,
          url: table1,
        },
        {
          id: 3,
          url: sofa1,
        },
        {
          id: 4,
          url: chair5,
        },
      ],
    },
    {
      id: 3,
      title: 'Look Board 3',
      items: [
        {
          id: 1,
          url: stool3,
        },
        {
          id: 2,
          url: table2,
        },
        {
          id: 3,
          url: sofa2,
        },
        {
          id: 4,
          url: chair5,
        },
      ],
    },
  ],
  mixMatchData: [
    {
      id: 1,
      title: 'Counter Stools',
      items: [
        {
          id: 1,
          url: stool1,
        },
        {
          id: 2,
          url: stool2,
        },
        {
          id: 3,
          url: stool3,
        },
        {
          id: 4,
          url: stool4,
        },
      ],
    },
    {
      id: 2,
      title: 'Coffee Tables',
      items: [
        {
          id: 1,
          url: table1,
        },
        {
          id: 2,
          url: table2,
        },
        {
          id: 3,
          url: table3,
        },
        {
          id: 4,
          url: table4,
        },
      ],
    },
    {
      id: 3,
      title: 'Sofas',
      items: [
        {
          id: 1,
          url: sofa1,
        },
        {
          id: 2,
          url: sofa2,
        },
        {
          id: 3,
          url: sofa3,
        },
        {
          id: 4,
          url: sofa4,
        },
      ],
    },
    {
      id: 4,
      title: 'Accent Chairs',
      items: [
        {
          id: 1,
          url: chair1,
        },
        {
          id: 2,
          url: chair2,
        },
        {
          id: 3,
          url: chair3,
        },
        {
          id: 4,
          url: chair4,
        },
      ],
    },
  ],
  productStreamData: [
    {
      id: 1,
      title: 'Accent Chairs',
      items: [
        {
          id: 1,
          imgUrl: chair6,
          price: 1260,
        },
        {
          id: 2,
          imgUrl: chair1,
          price: 1480,
        },
        {
          id: 3,
          imgUrl: chair2,
          price: 1696,
        },
        {
          id: 4,
          imgUrl: chair3,
          price: 2260,
        },
        {
          id: 5,
          imgUrl: chair4,
          price: 2460,
        },
      ],
    },
    {
      id: 2,
      title: 'Coffee tables',
      items: [
        {
          id: 1,
          imgUrl: table3,
          price: 1260,
        },
        {
          id: 2,
          imgUrl: table1,
          price: 1480,
        },
        {
          id: 3,
          imgUrl: table2,
          price: 1696,
        },
        {
          id: 4,
          imgUrl: table5,
          price: 2260,
        },
        {
          id: 5,
          imgUrl: table4,
          price: 2460,
        },
      ],
    },
  ],
};
