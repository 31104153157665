import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import mediaTypes from 'constants/mediaTypes';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import CreateImageForm from 'modules/pinMedia/CreateImageForm/CreateImageForm';
import CreateProductForm from 'modules/pinMedia/CreateProductForm/CreateProductForm';
import createImageValidator from 'modules/pinMedia/CreateImageForm/createImageValidator';
import createProductValidator from 'modules/pinMedia/CreateProductForm/createProductValidator';
import SimilarImagesBlock from 'modules/pinMedia/SimilarImagesBlock/SimilarImagesBlock';
import classes from 'modules/pinMedia/SaveImageModal/SaveImageModal.module.scss';

const ParsedMediaModal = ({
  open,
  onClose,
  mediaType,
  mediaList,
  initialFormValues,
  onCreateMedia,
}) => {
  const [selectedMedia, setSelectedMedia] = useState(null);

  useEffect(() => {
    const mediaListAsArr = Object.values(mediaList);
    if (mediaListAsArr.length > 0) {
      setSelectedMedia(mediaListAsArr[0]);
    }
  }, [mediaList]);

  const handleSelectMedia = useCallback(
    (id) => {
      setSelectedMedia(mediaList[id]);
    },
    [mediaList]
  );

  const handleSaveMedia = useCallback(
    (formValues) => {
      onCreateMedia({
        mediaUrl: selectedMedia.url,
        formValues,
      });
    },
    [onCreateMedia, selectedMedia]
  );

  return (
    <BasicModal open={open} onClose={onClose} maxWidth="lg" scroll="body">
      <div className="flex-fill d-flex">
        <div className={`${classes.similarImagesBlock} mr-3`}>
          <SimilarImagesBlock
            similarMediaList={mediaList}
            selectedMedia={selectedMedia}
            onSelectMedia={handleSelectMedia}
          />
        </div>
        <div className={`${classes.imageBlock} mr-3`}>
          <div className={classes.currentImageContainer}>
            <img src={selectedMedia && selectedMedia.url} alt="" />
          </div>
        </div>
        <div className={`${classes.formBlock} pt-2 pr-2`}>
          {mediaType === mediaTypes.product ? (
            <CreateProductForm
              initialValues={initialFormValues}
              validate={createProductValidator}
              onSubmit={handleSaveMedia}
            />
          ) : (
            <CreateImageForm
              validate={createImageValidator}
              onSubmit={handleSaveMedia}
              sourceUrl={selectedMedia && selectedMedia.url}
              mediaType={mediaType}
            />
          )}
        </div>
      </div>
    </BasicModal>
  );
};

ParsedMediaModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mediaType: PropTypes.string.isRequired,
  mediaList: PropTypes.shape({}).isRequired,
  initialFormValues: PropTypes.shape({}).isRequired,
  onCreateMedia: PropTypes.func.isRequired,
};

export default ParsedMediaModal;
