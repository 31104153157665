import React from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import CustomScrollBar from 'components/ui/CustomScrollbar/CustomScrollBar';
import {
  dialogsMock,
  forumsMock,
} from 'modules/layout/Header/Popovers/messagesMock';
import MessageItem from 'modules/layout/Header/MessageItem/MeesageItem';
import Avatar from '@material-ui/core/Avatar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabLabel from 'modules/layout/Header/Popovers/TabLabel/TabLabel';
import { Link } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import TabPanel from 'modules/layout/Header/Popovers/TabPanel';
import classes from './Popovers.module.scss';

const DialogsPopover = ({ open, onClose, anchorEl }) => {
  const [activeTabValue, setActiveTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTabValue(newValue);
  };

  return (
    <BasicModal
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      component={Popover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      className={classes.root}
    >
      <p className="font-title text-lg mb-2">Dialogs</p>
      <Tabs
        className="mb-3"
        value={activeTabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="inherit"
        variant="fullWidth"
      >
        <Tab
          label={
            <TabLabel
              icon="envelope"
              label={`Messages (${dialogsMock.length})`}
              selected={activeTabValue === 0}
            />
          }
        />
        <Tab
          label={
            <TabLabel
              icon="promotion"
              label={`Dialogs (${dialogsMock.length})`}
              selected={activeTabValue === 1}
            />
          }
        />
      </Tabs>
      <p className="text-sm text-gray mb-2">New</p>
      <TabPanel value={activeTabValue} index={0}>
        <CustomScrollBar autoHeightMin={350} autoHeightMax={450}>
          {dialogsMock.map(({ photo, text, date }) => (
            <MessageItem
              key={date}
              text={text}
              date={date}
              thumbnail={<Avatar src={photo} />}
            />
          ))}
        </CustomScrollBar>
        <Link
          to={routesByName.dialogs}
          className={`${classes.dialogLink} mt-2`}
        >
          Go to All Dialogs
        </Link>
      </TabPanel>
      <TabPanel value={activeTabValue} index={1}>
        <CustomScrollBar autoHeightMin={350} autoHeightMax={450}>
          {forumsMock.map(({ photo, text, date }) => (
            <MessageItem
              key={date}
              text={text}
              date={date}
              thumbnail={<Avatar src={photo} />}
            />
          ))}
        </CustomScrollBar>
        <Link to={routesByName.forums} className={`${classes.dialogLink} mt-2`}>
          Go to Forums
        </Link>
      </TabPanel>
    </BasicModal>
  );
};

DialogsPopover.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.object]),
};

DialogsPopover.defaultProps = {
  anchorEl: null,
};

export default DialogsPopover;
