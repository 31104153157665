import { toastr } from 'react-redux-toastr';
import React from 'react';
import SpriteIcon from 'components/ui/SpriteIcon';
import ToastrIconContainer from 'libs/toastr/ToastrIconContainer/ToastrIconContainer';

const successToastr = (title, message, options = {}) => {
  toastr.success(title, message, {
    icon: (
      <ToastrIconContainer type="success">
        <SpriteIcon name="correct" size="sm" />
      </ToastrIconContainer>
    ),
    ...options,
    showCloseButton: true,
  });
};

export default successToastr;
