import React from 'react';
import Button from 'components/ui/Button/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import NavigationIcon from '@material-ui/icons/Navigation';

const ButtonList = () => (
  <div className="row">
    <div className="col-5">
      <h2 className="title">Regular buttons</h2>
      <Button className="mb-3" variant="default" color="default">
        Basic button
      </Button>
      <Button className="mb-3" variant="default">
        Basic primary button
      </Button>
      <Button className="mb-3" variant="default" color="secondary">
        Basic secondary button
      </Button>
      <Button className="mb-3" variant="default" color="grey">
        Basic grey button
      </Button>
      <Button className="mb-3">Contained Primary button</Button>
      <Button className="mb-3" color="secondary">
        Contained secondary button
      </Button>
      <Button className="mb-3" color="grey">
        Contained grey button
      </Button>
      <Button className="mb-3" variant="outlined">
        Outlined Primary button
      </Button>
      <Button className="mb-3" variant="outlined" color="secondary">
        Outlined secondary button
      </Button>
      <Button className="mb-3" variant="outlined" color="grey">
        Outlined grey button
      </Button>
      <Button className="mb-3" disabled>
        Disabled button
      </Button>
      <Button className="mb-3 mr-3" inline>
        {' '}
        Inline Primary button
      </Button>
      <Button className="mb-3" inline variant="default" color="default">
        {' '}
        Inline Default button
      </Button>
    </div>
    <div className="col-4">
      <h2 className="title">Small buttons</h2>
      <Button className="mb-3" size="sm">
        Contained Primary small button
      </Button>
      <Button className="mb-3" variant="outlined" color="secondary" size="sm">
        Outlined secondary small button
      </Button>
      <Button className="mb-3" disabled size="sm">
        Disabled small button
      </Button>
    </div>
    <div className="col-3">
      <h2 className="title">Material FAB buttons</h2>
      <Fab size="small" color="primary" aria-label="add">
        <AddIcon />
      </Fab>
      <Fab variant="extended" size="medium" color="primary" aria-label="add">
        <NavigationIcon className="mr-1" />
        Extended
      </Fab>
      <Fab
        variant="extended"
        size="medium"
        color="primary"
        aria-label="add"
        disabled
      >
        <NavigationIcon className="mr-1" />
        Disabled
      </Fab>
    </div>
  </div>
);

export default ButtonList;
