import React, { useCallback, useState } from 'react';
import { Field, Form } from 'react-final-form';
import ReCAPTCHA from 'react-google-recaptcha';

import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import appConfig from 'constants/appConfig';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import SearchInput from 'components/inputs/SearchInput/SearchInput';

const BasicForm = () => {
  const [reCaptchaToken, setReCaptchaToken] = useState(null);

  const reCaptchaVerify = useCallback((token) => {
    setReCaptchaToken(token);
  }, []);

  const handleFormSubmit = useCallback(
    (formValues) => {
      console.log('Submitted form values:', { ...formValues, reCaptchaToken });
    },
    [reCaptchaToken]
  );

  const validateForm = useCallback(({ email, password }) => {
    const errors = {};

    if (!email) {
      errors.email = ['Required field'];
    }

    if (!password) {
      errors.password = ['Required field'];
    } else if (password.length < 6) {
      errors.password = 'Password should be at least 6 chars';
    }

    return errors;
  }, []);

  const [searchInputValue, setSearchInputValue] = useState('');

  const handleSearchInputChange = useCallback((query) => {
    setSearchInputValue(query);
  }, []);

  const resetSearchQuery = useCallback(() => {
    setSearchInputValue('');
  }, []);

  return (
    <>
      <h2 className="title">Basic form</h2>
      <div className="row">
        <div className="col-sm-9">
          <Form
            onSubmit={handleFormSubmit}
            validate={validateForm}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <Field
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="Type your email"
                  autoComplete="email"
                  required
                  component={TextInputWrapper}
                />
                <Field
                  label="Password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  component={TextInputWrapper}
                />
                <div className="d-flex justify-content-center py-2">
                  <ReCAPTCHA
                    sitekey={appConfig.reCaptchaSiteKey}
                    render="explicit"
                    onChange={reCaptchaVerify}
                  />
                </div>
                <FinalFormSubmitButton disabled={!reCaptchaToken}>
                  Submit
                </FinalFormSubmitButton>
              </form>
            )}
          />
        </div>
        <div className="col-sm-3 pt-4" style={{ backgroundColor: '#e0e0e0' }}>
          <SearchInput
            value={searchInputValue}
            placeholder="Search by keywords"
            onChange={handleSearchInputChange}
            onCrossClick={resetSearchQuery}
          />
        </div>
      </div>
    </>
  );
};

export default BasicForm;
