import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import SelectedImageBlock from 'modules/curateTheLook/createLookBoard/components/SelectedImageBlock/SelectedImageBlock';
import { routesByName } from 'constants/routes';
import {
  syncInspirationImageAction,
  toggleLikeLookBoardAction,
  toggleLikeProductAction,
} from 'modules/getTheLook/store/actions';
import { inspirationImageShape } from 'modules/inspirationImage/propTypes';
import errorToastr from 'libs/toastr/errorToastr';
import { getTheLookTabKeys } from 'modules/getTheLook/constants';
import transformArrayToMap from 'utils/transformArrayToMap';
import NoLookBoardsModal from 'modules/getTheLook/components/NoLookBoardsModal/NoLookBoardsModal';
import { selectImageAction as selectImageCTL } from 'modules/curateTheLook/store/actions';
import { selectImageAction as selectImageRTL } from 'modules/requestTheLook/store/actions';
import LookBoardItem from 'modules/getTheLook/components/LookBoardItem';
import customEvent from 'utils/customEvent';
import {
  UPDATE_SCROLLER_HEIGHT,
  SET_INITIAL_SCROLLER_HEIGHT,
} from 'constants/customEventNames';
import ProductsStream from 'modules/getTheLook/components/ProductsStream';
import classes from 'modules/getTheLook/GetTheLook.module.scss';

const DetailsView = ({
  match: { params },
  currentImage,
  activeTab,
  lookBoards,
  products,
  users,
  searchParams,
  syncInspirationImage,
  toggleLikeLookBoard,
  toggleLikeProduct,
  createLookBoard,
  createRequest,
}) => {
  const { imageId } = params;
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        await syncInspirationImage(imageId);
      } catch (e) {
        errorToastr(e.message);
      }
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTimeout(() => {
      customEvent.trigger(SET_INITIAL_SCROLLER_HEIGHT);
      customEvent.trigger(UPDATE_SCROLLER_HEIGHT);
    }, 0);
  }, [lookBoards, activeTab]);

  const goBack = useCallback(() => {
    history.push(routesByName.getTheLook.index);
  }, [history]);

  const handleCreateLookBoard = useCallback(() => {
    createLookBoard(imageId);
    history.push(routesByName.curateTheLook.index);
  }, [createLookBoard, imageId, history]);

  const handleCreateRequest = useCallback(() => {
    createRequest(imageId);
    history.push(routesByName.requestTheLook.index);
  }, [createRequest, imageId, history]);

  const handleToggleLikeLookBoard = useCallback(
    async (lookBoardId, likeStatus) => {
      try {
        await toggleLikeLookBoard(imageId, lookBoardId, likeStatus);
      } catch (e) {
        errorToastr('Error', e.message);
      }
    },
    [toggleLikeLookBoard, imageId]
  );

  const handleToggleLikeProduct = useCallback(
    async (productId, likeStatus) => {
      try {
        await toggleLikeProduct(imageId, productId, likeStatus);
      } catch (e) {
        errorToastr('Error', e.message);
      }
    },
    [toggleLikeProduct, imageId]
  );

  return (
    <div className="flex-fill d-flex">
      <div className={`${classes.leftPanelWrapper} pb-3`}>
        {currentImage && (
          <SelectedImageBlock
            inspirationImageId={Number.parseInt(imageId, 10)}
            searchParams={searchParams}
            onBack={goBack}
            onOpenPreview={() => {}}
          />
        )}
      </div>
      <div className="flex-fill">
        <div className={classes.mainContainer}>
          {!lookBoards && <div className="text-center">Loading...</div>}
          {lookBoards && lookBoards.length === 0 && (
            <NoLookBoardsModal
              onCreateLookBoard={handleCreateLookBoard}
              onCreateRequest={handleCreateRequest}
            />
          )}
          {lookBoards && lookBoards.length > 0 && (
            <div className="row mb-3">
              {activeTab === getTheLookTabKeys.lookBoardView &&
                lookBoards.map((lookBoard) => (
                  <div key={lookBoard.id} className="col-4 px-1 pb-2">
                    <LookBoardItem
                      lookBoard={lookBoard}
                      user={users[lookBoard.userId]}
                      products={products}
                      onToggleLike={handleToggleLikeLookBoard}
                    />
                  </div>
                ))}
              {activeTab === getTheLookTabKeys.productStream && (
                <ProductsStream
                  products={products}
                  users={users}
                  onToggleLikeProduct={handleToggleLikeProduct}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

DetailsView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      imageId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  currentImage: inspirationImageShape,
  activeTab: PropTypes.string.isRequired,
  searchParams: PropTypes.shape({}).isRequired,
  lookBoards: PropTypes.arrayOf(PropTypes.shape({})),
  products: PropTypes.shape({}),
  users: PropTypes.shape({}),
  syncInspirationImage: PropTypes.func.isRequired,
  toggleLikeLookBoard: PropTypes.func.isRequired,
  toggleLikeProduct: PropTypes.func.isRequired,
  createLookBoard: PropTypes.func.isRequired,
  createRequest: PropTypes.func.isRequired,
};

DetailsView.defaultProps = {
  currentImage: null,
  lookBoards: null,
  products: {},
  users: {},
};

const mapStateToProps = (
  {
    getTheLook: { activeTab, searchParams, lookBoardsData },
    inspirationImage: { library },
  },
  {
    match: {
      params: { imageId },
    },
  }
) => ({
  currentImage: library[imageId],
  activeTab,
  searchParams,
  lookBoards: lookBoardsData[imageId]?.lookBoards,
  products:
    lookBoardsData[imageId] &&
    transformArrayToMap(lookBoardsData[imageId].products),
  users:
    lookBoardsData[imageId] &&
    transformArrayToMap(lookBoardsData[imageId].users),
});

const mapDispatchToProps = {
  syncInspirationImage: syncInspirationImageAction,
  toggleLikeLookBoard: toggleLikeLookBoardAction,
  toggleLikeProduct: toggleLikeProductAction,
  createLookBoard: selectImageCTL,
  createRequest: selectImageRTL,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsView);
