import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import SpriteIcon from 'components/ui/SpriteIcon';
import Button from 'components/ui/Button/Button';
import classes from 'modules/pinMedia/UploadImageModal/UploadImageModal.module.scss';

const UploadImageModal = ({ open, onClose, onAcceptImg, ...rest }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      const img = acceptedFiles[0];
      onAcceptImg({
        file: img,
        url: URL.createObjectURL(img),
      });
    },
    [onAcceptImg]
  );

  const { getRootProps, getInputProps, open: openFileSelect } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
    multiple: false,
    maxSize: 10485760, // 10mb
    noClick: true,
  });

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      classes={{ root: classes.root }}
      {...rest}
    >
      <>
        <div className={classes.dropZone} {...getRootProps()}>
          <input {...getInputProps()} />
          <SpriteIcon
            name="image-upload"
            className={`${classes.uploadIcon} mb-3`}
          />
          <p className={classes.uploadText}>
            Drag and drop the image or click the button to select file
          </p>
        </div>
        <Button
          variant="outlined"
          className={classes.uploadBtn}
          onClick={openFileSelect}
        >
          Select
        </Button>
      </>
    </BasicModal>
  );
};

UploadImageModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAcceptImg: PropTypes.func.isRequired,
};

export default UploadImageModal;
