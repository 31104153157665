import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  loadFirstAction,
  loadMoreAction,
} from 'modules/getTheLook/store/actions';
import { inspirationImageShape } from 'modules/inspirationImage/propTypes';
import errorToastr from 'libs/toastr/errorToastr';
import CustomInfiniteScroll from 'components/CustomInfiniteScroll';
import ImgPreviewModal from 'components/modals/ImgPreviewModal/ImgPreviewModal';
import ListItem from 'modules/getTheLook/components/ListItem';

const ListView = ({
  searchParams,
  imageList,
  loadFirst,
  loadMore,
  loading,
  hasMore,
}) => {
  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [imgPreviewUrl, setImgPreviewUrl] = useState(null);

  useEffect(() => {
    (async () => {
      if (searchParams.offset === 0) {
        try {
          await loadFirst();
        } catch (e) {
          errorToastr('Error', e.message);
        }
      }
    })();
    // eslint-disable-next-line
  }, [searchParams]);

  const handleLoadMore = useCallback(async () => {
    try {
      await loadMore();
    } catch (e) {
      errorToastr('Error', e.message);
    }
  }, [loadMore]);

  const handleImgModalOpen = useCallback((imgUrl) => {
    setImgPreviewUrl(imgUrl);
    setImgModalOpen(true);
  }, []);

  const handleImgModalClose = useCallback(() => {
    setImgModalOpen(false);
  }, []);

  return (
    <>
      <CustomInfiniteScroll
        isLoading={loading}
        hasMore={hasMore}
        loadMore={handleLoadMore}
        initialLoad={false}
        threshold={500}
      >
        {imageList.map((image) => (
          <ListItem
            key={image.id}
            image={image}
            onOpenImgModal={handleImgModalOpen}
          />
        ))}
      </CustomInfiniteScroll>
      <ImgPreviewModal
        open={imgModalOpen}
        onClose={handleImgModalClose}
        url={imgPreviewUrl}
      />
    </>
  );
};

ListView.propTypes = {
  searchParams: PropTypes.shape({
    offset: PropTypes.number.isRequired,
  }).isRequired,
  imageList: PropTypes.arrayOf(inspirationImageShape).isRequired,
  loadFirst: PropTypes.func.isRequired,
  loadMore: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
};

const mapStateToProps = ({
  getTheLook: { searchParams, imageList, loading, hasMore },
  inspirationImage: { library },
}) => ({
  searchParams,
  imageList: imageList.map((id) => library[id]),
  loading,
  hasMore,
});

const mapDispatchToProps = {
  loadFirst: loadFirstAction,
  loadMore: loadMoreAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
