import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from './TabLabel.module.scss';

const TabLabel = ({ label, icon, selected }) => (
  <div className="d-flex align-items-center">
    <div
      className={clsx(
        'd-flex align-items-center justify-content-center mr-1',
        classes.popoverTabIcon,
        { [classes.selected]: selected }
      )}
    >
      <SpriteIcon name={icon} size="sm" />
    </div>
    <p>{label}</p>
  </div>
);

TabLabel.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default TabLabel;
