import createPalette from '@material-ui/core/styles/createPalette';

const palette = createPalette({
  primary: { main: '#87BDC3' },
  secondary: { main: '#D9CAB6' },
  error: { main: '#EB5757' },
  text: {
    primary: '#333333',
    disabled: '#C4C4C4',
  },
  grey: {
    1: '#c4c4c4',
    2: '#f2f2f2',
    3: '#e0e0e0',
    4: '#bdbdbd',
  },
});

export default palette;
