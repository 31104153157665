import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { preferencesShape } from 'modules/account/profileAndPreferences/propTypes';
import EmptyPreferencesView from 'modules/account/profileAndPreferences/preferences/EmptyPreferencesView';
import PreferenceQuiz from 'components/PreferenceQuiz/PreferenceQuiz';
import PreferencesView from 'modules/account/profileAndPreferences/preferences/PreferencesView';
import classes from './Preferences.module.scss';

const PreferencesContainer = ({ preferences }) => {
  const [isEdit, setIsEdit] = useState(false);

  const handleEnableEditMode = useCallback(() => {
    setIsEdit(true);
  }, []);

  const handleCancelEdit = useCallback(() => {
    setIsEdit(false);
  }, []);

  return (
    <div className={classes.section}>
      {isEdit && <PreferenceQuiz onCancelEdit={handleCancelEdit} />}
      {!isEdit && preferences && (
        <PreferencesView onEnableEditMode={handleEnableEditMode} />
      )}
      {!isEdit && !preferences && (
        <EmptyPreferencesView onEnableEditMode={handleEnableEditMode} />
      )}
    </div>
  );
};

PreferencesContainer.propTypes = {
  preferences: preferencesShape,
};

PreferencesContainer.defaultProps = {
  preferences: undefined,
};

const mapStateToProps = ({
  auth: {
    user: { preferences },
  },
}) => ({ preferences });

export default connect(mapStateToProps)(PreferencesContainer);
