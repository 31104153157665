import React from 'react';
import PropTypes from 'prop-types';
import Rating from '@material-ui/lab/Rating';
import classes from 'components/MixMatchThumbnail/MixMatchThumbnail.module.scss';

const MatchRating = ({ name, value, onChange }) => {
  return (
    <div className="d-flex align-items-center justify-content-center mb-3">
      <span className="text-sm font-semi-bold mr-1">Rate the Match</span>
      <Rating
        name={name}
        classes={{
          iconEmpty: classes.ratingIconEmpty,
          iconFilled: classes.ratingIconFilled,
        }}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

MatchRating.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
};

MatchRating.defaultProps = {
  value: null,
};

export default MatchRating;
