import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import ReCAPTCHA from 'react-google-recaptcha';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import {
  requestModalFields,
  requestModalPlaceholders,
} from 'modules/ambassadors/components/RequestModal/constants';
import appConfig from 'constants/appConfig';
import modalImg from 'assets/img/ambassador-page/request-modal.jpg';
import classes from './RequestModal.module.scss';

const RequestModal = ({
  open,
  onClose,
  onSubmit,
  prefilledFormValues,
  validate,
  disableSubmit,
  reCaptchaVerify,
  reCaptchaRef,
}) => (
  <BasicModal
    open={open}
    onClose={onClose}
    fullWidth
    classes={{ root: classes.root, closeBtn: classes.closeBtn }}
    maxWidth="md"
  >
    <div className="d-flex pb-2">
      <div className={classes.about}>
        <h2 className={classes.title}>Request an invitation</h2>
        <p className={classes.description}>
          Ambassadors are decor lovers who help others Get the Look they love
        </p>
        <p className="font-italic font-semi-bold mb-2">
          Qualified Candidates include:
        </p>
        <ul className={classes.list}>
          <li>Decor Lovers</li>
          <li>Skilled Online Shoppers</li>
          <li>Interior Designers & Architects</li>
        </ul>
      </div>
      <div className="flex-fill">
        <img className="w-100" src={modalImg} alt="" />
      </div>
    </div>
    <div className={classes.formContainer}>
      <p className="text-sm mb-2">
        <b className="mr-1">From:</b> {prefilledFormValues.from}
      </p>
      <p className="text-sm mb-2">
        <b className="mr-1">Subject:</b> {prefilledFormValues.subject}
      </p>
      <Form
        initialValues={prefilledFormValues}
        validate={validate}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Field
              required
              name={requestModalFields.message}
              label="Message"
              multiline
              rows={4}
              component={TextInputWrapper}
              placeholder={requestModalPlaceholders.message}
            />
            <div className="d-flex justify-content-center py-2 mb-3">
              <ReCAPTCHA
                ref={reCaptchaRef}
                sitekey={appConfig.reCaptchaSiteKey}
                render="explicit"
                onChange={reCaptchaVerify}
              />
            </div>
            <div className="d-flex justify-content-center">
              <div className="text-center">
                <FinalFormSubmitButton
                  size="lg"
                  inline
                  disabled={disableSubmit}
                >
                  Submit
                </FinalFormSubmitButton>
              </div>
            </div>
          </form>
        )}
      />
    </div>
  </BasicModal>
);

RequestModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  prefilledFormValues: PropTypes.shape({
    from: PropTypes.string,
    subject: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  disableSubmit: PropTypes.bool.isRequired,
  reCaptchaVerify: PropTypes.func.isRequired,
  reCaptchaRef: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default RequestModal;
