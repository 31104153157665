import React, { useRef, useState, useCallback } from 'react';
import { slidesArray } from 'modules/home/components/Ambassadors/Slider/propTypes';
import SliderDots from 'modules/home/components/Ambassadors/Slider/SliderDots';
import ImageSlider from 'modules/home/components/Ambassadors/Slider/ImageSlider';
import SliderArrows from 'modules/home/components/Ambassadors/Slider/SliderArrows';
import classes from './Slider.module.scss';

const SliderContainer = ({ slidesData }) => {
  const sliderRef = useRef(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const handleSlideChange = useCallback((oldIndex, newIndex) => {
    setActiveSlideIndex(newIndex);
  }, []);

  const handleGoToSlide = useCallback((index) => {
    sliderRef.current.slickGoTo(index);
  }, []);

  const handleSlideNext = useCallback(() => {
    sliderRef.current.slickNext();
  }, []);

  const handleSlidePrev = useCallback(() => {
    sliderRef.current.slickPrev();
  }, []);

  const settings = {
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
  };

  return (
    <div className={classes.root}>
      <ImageSlider
        sliderRef={sliderRef}
        items={slidesData}
        onChange={handleSlideChange}
        onSlideClick={handleGoToSlide}
        settings={settings}
        activeSlideIndex={activeSlideIndex}
      />
      <SliderArrows
        onSlidePrev={handleSlidePrev}
        onSlideNext={handleSlideNext}
      />
      <SliderDots
        items={slidesData}
        activeSlideIndex={activeSlideIndex}
        onDotClick={handleGoToSlide}
      />
    </div>
  );
};

SliderContainer.propTypes = {
  slidesData: slidesArray.isRequired,
};

export default SliderContainer;
