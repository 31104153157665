import React from 'react';
import { Popover } from '@material-ui/core';
import BasicModal from 'components/modals/BasicModal/BasicModal';

const CanvasTitlePopover = (props) => (
  <BasicModal
    component={Popover}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  >
    <p className="font-semi-bold mb-1">Title Info Text:</p>
    <p className="text-xs mb-3">
      Your Title will be used to find your Look Board on Pin the Look and in
      search engines, so make sure that it includes keywords that best describe
      your Look Board. Your title can be up to 60 characters. Try to put the
      most important works first and make sure it&apos;s a natural title, and
      not one that uses the same keywords multiple times
    </p>
    <p className="font-semi-bold mb-1">Examples:</p>
    <p className="text-xs mb-3">
      - Modern Living Room Furniture with Green Sofa <br />- Coastal White
      Kitchen Ideas with Gold Accents
    </p>
    <p className="font-semi-bold mb-1">Default Text:</p>
    <p className="text-xs">
      If you do not edit the text, by default it will say “GET THE LOOK”
    </p>
  </BasicModal>
);

export default CanvasTitlePopover;
