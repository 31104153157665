import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import SpriteIcon from 'components/ui/SpriteIcon';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const SelectInput = ({
  className,
  label,
  options,
  required,
  helperText,
  error,
  ...rest
}) => (
  <FormControl
    className={clsx('w-100 mb-3', className)}
    required={required}
    error={error}
  >
    <InputLabel>{label}</InputLabel>
    <Select
      IconComponent={() => (
        <SpriteIcon name="arrow-down" size="sm" className="secondary-color" />
      )}
      {...rest}
    >
      {options.map(({ value, label: optionLabel }) => (
        <MenuItem key={value} value={value}>
          {optionLabel}
        </MenuItem>
      ))}
    </Select>
    {helperText && <FormHelperText>{helperText}</FormHelperText>}
  </FormControl>
);

SelectInput.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  className: PropTypes.string,
  required: PropTypes.bool,
  helperText: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  error: PropTypes.bool,
};

SelectInput.defaultProps = {
  className: '',
  required: false,
  helperText: null,
  error: false,
};

export default SelectInput;
