import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import descriptionOptionShape from 'components/PreferenceQuiz/propTypes/descriptionOptionShape';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from 'components/PreferenceQuiz/DescriptionCard/DescriptionCard.module.scss';

const DescriptionCard = ({
  option: { value, imgUrl, title, description, icon: iconName },
  currentValue,
  onSelect,
  className,
}) => {
  const selected = value === currentValue;
  const handleSelect = useCallback(() => {
    onSelect(value);
  }, [onSelect, value]);

  return (
    <div
      className={clsx('h-100 pb-2', classes.root, className, {
        [classes.selected]: selected,
      })}
      onClick={handleSelect}
    >
      <div className={`${classes.imgContainer} mb-2`}>
        <img src={imgUrl} alt={title} />
      </div>
      <div className="d-flex align-items-center px-2 mb-1">
        <SpriteIcon
          name={iconName}
          size="lg"
          className={clsx('mr-2', classes.icon)}
        />
        <span className="font-title text-uppercase">{title}</span>
      </div>
      <p className={`${classes.description} px-2`}>{description}</p>
    </div>
  );
};

DescriptionCard.propTypes = {
  option: descriptionOptionShape.isRequired,
  onSelect: PropTypes.func.isRequired,
  currentValue: PropTypes.string,
  className: PropTypes.string,
};

DescriptionCard.defaultProps = {
  currentValue: undefined,
  className: '',
};

export default DescriptionCard;
