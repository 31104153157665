import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import classes from './Tab.module.scss';

const Tab = ({ name, title, isActive, onToggle }) => {
  const handleToggleTab = useCallback(() => {
    onToggle(name);
  }, [name, onToggle]);

  return (
    <div
      onClick={handleToggleTab}
      className={clsx(classes.root, { [classes.active]: isActive })}
    >
      {title}
    </div>
  );
};

Tab.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default Tab;
