import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import Button from 'components/ui/Button/Button';

const ConfirmModal = ({ open, onClose, title }) => {
  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleConfirm = useCallback(() => {
    onClose(true);
  }, [onClose]);

  return (
    <BasicModal open={open} onClose={handleCancel} fullWidth>
      <p
        className="font-title text-lg text-uppercase text-center mb-3"
        style={{ lineHeight: 1.4 }}
      >
        {title}
      </p>
      <div className="d-flex justify-content-center mb-1">
        <Button
          type="button"
          inline
          size="sm"
          variant="outlined"
          className="mr-2"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button type="button" inline size="sm" onClick={handleConfirm}>
          Confirm
        </Button>
      </div>
    </BasicModal>
  );
};

ConfirmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.node.isRequired,
};

export default ConfirmModal;
