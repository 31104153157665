import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InspirationImage from 'components/imageThumbnails/InspirationImage/InspirationImage';
import { getInspirationImgUrl } from 'modules/app/store/selectors';
import buildResourceUrl from 'utils/buildResourceUrl';
import classes from './SimilarImageItem.module.scss';

const SimilarImageItem = ({
  id,
  userId,
  hash,
  onSelectImage,
  inspirationImageUrl,
}) => {
  const imgUrl = useMemo(
    () => buildResourceUrl(inspirationImageUrl.medium, userId, hash),
    [inspirationImageUrl, userId, hash]
  );

  const handleSelectImage = useCallback(() => {
    onSelectImage(id);
  }, [onSelectImage, id]);

  return (
    <InspirationImage
      id={id}
      imgUrl={imgUrl}
      hoverOverlay={
        <div className="h-100 flex-center">
          <p className={classes.hoverLabel} onClick={handleSelectImage}>
            Click to select inspiration image
          </p>
        </div>
      }
    />
  );
};

SimilarImageItem.propTypes = {
  id: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  hash: PropTypes.string.isRequired,
  onSelectImage: PropTypes.func.isRequired,
  inspirationImageUrl: PropTypes.shape({
    medium: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ app: { config } }) => ({
  inspirationImageUrl: getInspirationImgUrl(config),
});

export default connect(mapStateToProps)(SimilarImageItem);
