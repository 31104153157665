import React from 'react';
import PropTypes from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

const CustomRadioGroup = ({ value, onChange, controls }) => (
  <RadioGroup row className="mb-2" value={value} onChange={onChange}>
    {controls.map(({ value: controlValue, label }) => (
      <FormControlLabel
        key={controlValue}
        value={controlValue}
        label={label}
        control={<Radio />}
        classes={{ label: controlValue === value && 'font-semi-bold' }}
      />
    ))}
  </RadioGroup>
);

CustomRadioGroup.propTypes = {
  controls: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CustomRadioGroup;
