export const imageSourceKeys = {
  all: 'all',
  my: 'my',
  liked: 'liked',
};

export const imageTypeKeys = {
  all: 'all',
  room: 'room',
  single: 'single',
};

export const imageSortKeys = {
  newest: 'newest',
  rated: 'rated',
};
