import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import SpriteIcon from 'components/ui/SpriteIcon';
import Button from 'components/ui/Button/Button';
import BannerButton from 'modules/home/components/Banner/BannerButton/BannerButton';
import RequestModal from 'modules/home/components/Banner/RequestModal/RequestModal';
import DiscoverModal from 'modules/home/components/Banner/DiscoverModal';
import bannerBg from 'assets/img/home-page/main-banner.jpg';
import classes from './Banner.module.scss';

const Banner = () => {
  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const [discoverModalOpen, setDiscoverModalOpen] = useState(false);

  const handleOpenRequestModal = useCallback(() => {
    setRequestModalOpen(true);
  }, []);

  const handleCloseRequestModal = useCallback(() => {
    setRequestModalOpen(false);
  }, []);

  const handleOpenDiscoverModal = useCallback(() => {
    setDiscoverModalOpen(true);
  }, []);

  const handleCloseDiscoverModal = useCallback(() => {
    setDiscoverModalOpen(false);
  }, []);

  return (
    <>
      <section className={classes.root}>
        <Link to={routesByName.howItworks} className={classes.fixedLink}>
          <Button className={classes.fixedBtn}>
            How it Works
            <SpriteIcon name="play-icon" size="md" className="ml-2" />
          </Button>
        </Link>
        <img src={bannerBg} alt="" />
        <div className={classes.box}>
          <h1 className={classes.titleLg}>
            Getting the Look you Love for your Home
          </h1>
          <h2 className={classes.titleMd}>Just Got Easy</h2>
          <BannerButton
            onClick={handleOpenRequestModal}
            title="Request the Look"
          />
          <BannerButton
            onClick={handleOpenDiscoverModal}
            title="Discover Your Look"
          />
          <div className="text-lg text-center mt-5 primary-color">
            It’s fast, free & beautiful
          </div>
        </div>
      </section>
      <RequestModal open={requestModalOpen} onClose={handleCloseRequestModal} />
      <DiscoverModal
        open={discoverModalOpen}
        onClose={handleCloseDiscoverModal}
      />
    </>
  );
};

export default Banner;
