import {
  GTL_SET_ACTIVE_TAB,
  GTL_SET_IMAGES,
  GTL_UPDATE_HAS_MORE_STATUS,
  GTL_UPDATE_IMAGES,
  GTL_UPDATE_LOADING_STATUS,
  GTL_UPDATE_LOOK_BOARDS_DATA,
  GTL_UPDATE_SEARCH_PARAMS,
} from 'modules/getTheLook/store/constants';
import inspirationImageService from 'modules/inspirationImage/inspirationImageService';
import { updateIILibraryAction } from 'modules/inspirationImage/store/actions';
import lookBoardService from 'modules/lookBoard/lookBoardService';
import productService from 'modules/product/productService';

export const changeActiveTabAction = (tab) => (dispatch) => {
  dispatch({ type: GTL_SET_ACTIVE_TAB, payload: tab });
};

export const updateSearchParamsAction = (params) => (dispatch) => {
  dispatch({ type: GTL_UPDATE_SEARCH_PARAMS, payload: params });
};

export const updateLookBoardsDataAction = (imageIds) => async (dispatch) => {
  const promiseArr = imageIds.map((imageId) =>
    inspirationImageService.getLookBoardDetails(imageId)
  );
  const result = await Promise.all(promiseArr);
  const lookBoardsData = imageIds.reduce(
    (accum, item, index) => ({ ...accum, [item]: result[index].result }),
    {}
  );

  dispatch({ type: GTL_UPDATE_LOOK_BOARDS_DATA, payload: lookBoardsData });
};

export const loadFirstAction = () => async (dispatch, getState) => {
  const {
    getTheLook: { searchParams, limit },
  } = getState();

  dispatch({ type: GTL_UPDATE_LOADING_STATUS, payload: true });

  const list = await inspirationImageService.getImages(searchParams);
  const imageIds = Object.keys(list);

  dispatch(updateIILibraryAction(list));
  dispatch({ type: GTL_SET_IMAGES, payload: imageIds });
  await dispatch(updateLookBoardsDataAction(imageIds));

  if (imageIds.length > 0) {
    dispatch(
      updateSearchParamsAction({
        offset: searchParams.offset + imageIds.length,
      })
    );
  }

  dispatch({
    type: GTL_UPDATE_HAS_MORE_STATUS,
    payload: imageIds.length === limit,
  });
  dispatch({ type: GTL_UPDATE_LOADING_STATUS, payload: false });
};

export const loadMoreAction = () => async (dispatch, getState) => {
  const {
    getTheLook: { searchParams, limit },
  } = getState();

  dispatch({ type: GTL_UPDATE_LOADING_STATUS, payload: true });

  const list = await inspirationImageService.getImages(searchParams);
  const imageIds = Object.keys(list);

  dispatch(updateIILibraryAction(list));
  dispatch({ type: GTL_UPDATE_IMAGES, payload: imageIds });
  await dispatch(updateLookBoardsDataAction(imageIds));

  if (imageIds.length > 0) {
    dispatch(
      updateSearchParamsAction({
        offset: searchParams.offset + imageIds.length,
      })
    );
  }

  dispatch({
    type: GTL_UPDATE_HAS_MORE_STATUS,
    payload: imageIds.length === limit,
  });
  dispatch({ type: GTL_UPDATE_LOADING_STATUS, payload: false });
};

export const toggleLikeLookBoardAction = (
  imageId,
  lookBoardId,
  isLiked
) => async (dispatch, getState) => {
  const {
    getTheLook: { lookBoardsData },
  } = getState();

  await lookBoardService.toggleLike(lookBoardId, isLiked);

  const currentImageCopy = { ...lookBoardsData[imageId] };
  const currentLookBoard = currentImageCopy.lookBoards.find(
    ({ id }) => id === lookBoardId
  );
  currentLookBoard.isLiked = isLiked;

  dispatch({
    type: GTL_UPDATE_LOOK_BOARDS_DATA,
    payload: { [currentImageCopy.id]: currentImageCopy },
  });
};

export const toggleLikeProductAction = (imageId, productId, isLiked) => async (
  dispatch,
  getState
) => {
  const {
    getTheLook: { lookBoardsData },
  } = getState();

  await productService.toggleLike(productId, isLiked);

  const currentImageCopy = { ...lookBoardsData[imageId] };
  const currentProduct = currentImageCopy.products.find(
    ({ id }) => id === productId
  );
  currentProduct.isLiked = isLiked;

  dispatch({
    type: GTL_UPDATE_LOOK_BOARDS_DATA,
    payload: { [currentImageCopy.id]: currentImageCopy },
  });
};

export const syncInspirationImageAction = (imageId) => async (dispatch) => {
  const { result: image } = await inspirationImageService.getImageById(imageId);
  dispatch(updateIILibraryAction({ [image.id]: image }));
  const {
    result: imageDetails,
  } = await inspirationImageService.getLookBoardDetails(imageId);
  dispatch({
    type: GTL_UPDATE_LOOK_BOARDS_DATA,
    payload: { [imageId]: imageDetails },
  });
};
