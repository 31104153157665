import {
  RTL_SET_IMAGES,
  RTL_UPDATE_IMAGES,
  RTL_UPDATE_REQUEST_DATA,
  RTL_UPDATE_PRIORITY_ITEM,
  RTL_ADD_PRIORITY_ITEM,
  RTL_UPDATE_II_SEARCH_PARAMS,
  RTL_RESET_REQUEST_DATA,
  RTL_RESET_REQUEST_FORM,
  RTL_RESET_STATE,
} from 'modules/requestTheLook/store/constants';
import {
  imageSourceKeys,
  imageTypeKeys,
} from 'constants/inspirationImageSearchParams';
import { defaultPriorityItem } from 'modules/requestTheLook/requestDetails/requestForm/constants';

const initialImageSearchParams = {
  source: imageSourceKeys.all,
  type: imageTypeKeys.room,
  search: '',
  offset: 0,
};

const initialRequestFormData = {
  title: '',
  items: [{ ...defaultPriorityItem }],
};

const initialRequestData = {
  inspirationImageId: null,
  requestType: '',
  ...initialRequestFormData,
};

const initialState = {
  inspirationImageSearchParams: initialImageSearchParams,
  imageList: [],
  requestData: initialRequestData,
};

const requestThelookReducer = (
  state = { ...initialState },
  { type, payload }
) => {
  switch (type) {
    case RTL_SET_IMAGES: {
      return {
        ...state,
        imageList: payload,
      };
    }
    case RTL_UPDATE_IMAGES: {
      return {
        ...state,
        imageList: [...state.imageList, ...payload],
      };
    }
    case RTL_UPDATE_REQUEST_DATA: {
      return {
        ...state,
        requestData: {
          ...state.requestData,
          ...payload,
        },
      };
    }
    case RTL_UPDATE_PRIORITY_ITEM: {
      const { index, values } = payload;
      return {
        ...state,
        requestData: {
          ...state.requestData,
          items: state.requestData.items.map((item, itemIdx) =>
            index === itemIdx ? { ...values } : item
          ),
        },
      };
    }
    case RTL_ADD_PRIORITY_ITEM: {
      return {
        ...state,
        requestData: {
          ...state.requestData,
          items: [...state.requestData.items, defaultPriorityItem],
        },
      };
    }
    case RTL_RESET_REQUEST_DATA: {
      return {
        ...state,
        requestData: { ...initialRequestData },
      };
    }
    case RTL_RESET_REQUEST_FORM: {
      return {
        ...state,
        requestData: {
          ...state.requestData,
          ...initialRequestFormData,
        },
      };
    }
    case RTL_UPDATE_II_SEARCH_PARAMS: {
      return {
        ...state,
        inspirationImageSearchParams: {
          ...state.inspirationImageSearchParams,
          ...payload,
        },
      };
    }
    case RTL_RESET_STATE: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export default requestThelookReducer;
