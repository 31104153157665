import React from 'react';
import PropTypes from 'prop-types';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import classes from './ImgPreviewModal.module.scss';

const ImgPreviewModal = ({ open, onClose, url }) => (
  <BasicModal
    open={open}
    onClose={onClose}
    classes={{ root: classes.root }}
    maxWidth="md"
  >
    <img src={url} alt="" />
  </BasicModal>
);

ImgPreviewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  url: PropTypes.string,
};

ImgPreviewModal.defaultProps = {
  url: undefined,
};

export default ImgPreviewModal;
