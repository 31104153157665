import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { getSubStyleImgUrl } from 'modules/app/store/selectors';
import { urlPlaceholders } from 'constants/urlPlaceholders';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from './SubStyleCard.module.scss';

const SubStyleCard = ({
  id,
  name,
  media: { hash },
  subStyleImgUrl,
  onLike,
  selected,
}) => {
  const handleLike = useCallback(() => {
    onLike(id);
  }, [onLike, id]);

  return (
    <div className={classes.root}>
      <img
        src={subStyleImgUrl.replace(urlPlaceholders.hash, hash)}
        alt={name}
      />
      <IconButton
        className={clsx(classes.likeBtn, { [classes.selected]: selected })}
        onClick={handleLike}
      >
        <SpriteIcon name="like-filled" size="sm" />
      </IconButton>
    </div>
  );
};

SubStyleCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  media: PropTypes.shape({
    hash: PropTypes.string.isRequired,
  }).isRequired,
  subStyleImgUrl: PropTypes.string.isRequired,
  onLike: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ app: { config } }) => ({
  subStyleImgUrl: getSubStyleImgUrl(config),
});

export default connect(mapStateToProps)(SubStyleCard);
