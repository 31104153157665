import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import stylesOptionShape from 'components/PreferenceQuiz/propTypes/stylesOptionShape';
import StylesCard from 'components/PreferenceQuiz/StylesCard/StylesCard';
import Button from 'components/ui/Button/Button';
import classes from 'components/PreferenceQuiz/steps/steps.module.scss';
import BasicModal from 'components/modals/BasicModal/BasicModal';

const StylesStep = ({
  isLoading,
  currentValue,
  options,
  onCancelEdit,
  onSaveStyles,
}) => {
  const [inputValue, setInputValue] = useState([]);
  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [imgPreviewUrl, setImgPreviewUrl] = useState(null);

  useEffect(() => {
    if (Array.isArray(currentValue)) {
      setInputValue(currentValue);
    }
  }, [currentValue]);

  const handleChange = useCallback(
    (optionValue) => {
      let valueCopy = [...inputValue];
      const isOptionExist = inputValue.includes(optionValue);

      if (isOptionExist) {
        valueCopy = valueCopy.filter((item) => item !== optionValue);
      } else if (valueCopy.length < 10) {
        valueCopy.push(optionValue);
      }
      setInputValue(valueCopy);
    },
    [inputValue]
  );

  const handleSaveStyles = useCallback(() => {
    onSaveStyles(inputValue);
  }, [onSaveStyles, inputValue]);

  const handleOpenImgModal = useCallback((imgUrl) => {
    setImgPreviewUrl(imgUrl);
    setImgModalOpen(true);
  }, []);

  const handleImgModalClose = useCallback(() => {
    setImgModalOpen(false);
  }, []);

  return (
    <>
      <div className={clsx('row', classes.stylesContainer)}>
        {options.map((option) => (
          <div className="col-4 mb-3" key={option.id}>
            <StylesCard
              option={option}
              currentValue={inputValue}
              onSelect={handleChange}
              onOpenPreviewModal={handleOpenImgModal}
            />
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-center pt-3">
        <Button
          inline
          className="mr-2"
          onClick={handleSaveStyles}
          disabled={isLoading}
        >
          Next
        </Button>
        <Button
          inline
          variant="outlined"
          onClick={onCancelEdit}
          disabled={isLoading}
        >
          Cancel
        </Button>
      </div>
      <BasicModal
        onClose={handleImgModalClose}
        open={imgModalOpen}
        classes={{ root: classes.imgPreviewModal }}
        maxWidth="md"
      >
        <img src={imgPreviewUrl} alt="" />
      </BasicModal>
    </>
  );
};

StylesStep.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  currentValue: PropTypes.arrayOf(PropTypes.number),
  options: PropTypes.arrayOf(stylesOptionShape).isRequired,
  onCancelEdit: PropTypes.func.isRequired,
  onSaveStyles: PropTypes.func.isRequired,
};

StylesStep.defaultProps = {
  currentValue: undefined,
};

export default StylesStep;
