export const codeModalFields = {
  code: 'code',
};

export const errorMessages = {
  required: 'This field is required',
  codeLength: 'Access code, should be 6 characters',
};

export default {
  codeModalFields,
  errorMessages,
};
