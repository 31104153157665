import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import Button from 'components/ui/Button/Button';
import SquareModal from 'components/modals/SquareModal/SquareModal';
import DiscoverContent from 'modules/home/components/DiscoverContent/DiscoverContent';
import PresentationCard from 'modules/home/components/PresentationCard/PresentationCard';
import modernRoom1Img from 'assets/img/home-page/detail-modal/modern-room-1.png';
import modernRoom2Img from 'assets/img/home-page/detail-modal/modern-room-2.png';
import midCenturyImg from 'assets/img/home-page/detail-modal/mid-century.png';
import modernRoomItemsImg from 'assets/img/home-page/detail-modal/modern-room-items.png';
import classes from './DetailModal.module.scss';

const DetailModal = ({ open, onClose }) => {
  const settings = useMemo(
    () => ({
      title: 'Get the Look Your Way',
      description: 'Get the Look & Request the Look',
    }),
    []
  );

  return (
    <SquareModal open={open} onClose={onClose} scroll="body" {...settings}>
      <h2 className={classes.title}>Get the Look</h2>
      <DiscoverContent />
      <div className="mt-5">
        <h2 className={classes.title}>Request the Look</h2>
        <div className={classes.content}>
          <div className="d-flex mb-3">
            <div className={classes.cardWrapper}>
              <PresentationCard
                title="Free Request"
                description="Quickly find the look of room and product images you love!"
              >
                <div className="text-center">
                  <img src={modernRoom1Img} alt="" />
                  <div className="flex-center mt-2">
                    <h4 className={classes.titleSm}>
                      Look Board Recommendation
                    </h4>
                  </div>
                  <div className={classes.shadow}>
                    <img src={modernRoomItemsImg} alt="" />
                  </div>
                </div>
              </PresentationCard>
            </div>
            <div className={classes.cardWrapper}>
              <PresentationCard
                title="Upgraded request"
                description="Get professional help finding items in the exact size, color and price range needed"
              >
                <div className="text-center">
                  <img src={modernRoom2Img} alt="" />
                  <div className="flex-center mt-2">
                    <h4 className={classes.titleSm}>
                      Look Board Recommendation
                    </h4>
                  </div>
                  <img src={midCenturyImg} alt="" />
                </div>
              </PresentationCard>
            </div>
          </div>
        </div>
        <div className="flex-center">
          <Link to={routesByName.requestTheLook.index}>
            <Button size="lg">Request the Look</Button>
          </Link>
        </div>
      </div>
    </SquareModal>
  );
};

DetailModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DetailModal;
