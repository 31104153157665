import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { routesByName } from 'constants/routes';
import { logoutAction } from 'modules/auth/store/actions';
import errorToastr from 'libs/toastr/errorToastr';
import ConfirmModal from 'components/modals/ConfirmModal';
import classes from './HeaderMenu.module.scss';

const HeaderMenu = ({ open, onClose, anchorEl, authenticated, logout }) => {
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  const handleLogoutModalOpen = useCallback(() => {
    setLogoutModalOpen(true);
  }, []);

  const handleLogout = useCallback(
    async (confirm) => {
      if (confirm) {
        try {
          await logout();
          onClose();
          setLogoutModalOpen(false);
        } catch (e) {
          errorToastr('Error', e.generalError);
        }
      } else {
        setLogoutModalOpen(false);
      }
    },
    [logout, onClose]
  );

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        getContentAnchorEl={null}
        classes={{ list: classes.menuList }}
        PopoverClasses={{ paper: classes.menuPaper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={onClose}>
          <Link to={routesByName.about}>About Us</Link>
        </MenuItem>
        <MenuItem onClick={onClose}>
          <Link to={routesByName.contact}>Contact Us</Link>
        </MenuItem>
        <MenuItem onClick={onClose}>
          <Link to={routesByName.faqs}>FAQs</Link>
        </MenuItem>
        {authenticated && (
          <MenuItem onClick={handleLogoutModalOpen}>Logout</MenuItem>
        )}
      </Menu>
      <ConfirmModal
        open={logoutModalOpen}
        onClose={handleLogout}
        title={
          <>
            Are you sure you want to <br />
            <span className="primary-color">logout</span> from account?
          </>
        }
      />
    </>
  );
};

HeaderMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.object]),
};

HeaderMenu.defaultProps = {
  anchorEl: null,
};

const mapStateToProps = ({ auth }) => ({ authenticated: Boolean(auth.user) });

const mapDispatchToProps = {
  logout: logoutAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenu);
