import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SelectStylesPopover from 'components/modals/SelectStylesPopover/SelectStylesPopover';
import ColorPicker from 'components/colorSelectors/ColorPicker/ColorPicker';

const SelectColorPopover = ({ currentColor, onApply, colors, ...rest }) => {
  const [selectedColorId, setSelectedColorId] = useState(currentColor);

  const handleColorChange = useCallback((id) => {
    setSelectedColorId(id);
  }, []);

  const handleApplySelection = useCallback(() => {
    onApply(selectedColorId);
  }, [selectedColorId, onApply]);

  return (
    <SelectStylesPopover onApplyStyles={handleApplySelection} {...rest}>
      <h4 className="mb-3">Background Color</h4>
      <ColorPicker
        colorList={Object.values(colors)}
        selectedColor={selectedColorId}
        onChange={handleColorChange}
      />
    </SelectStylesPopover>
  );
};

SelectColorPopover.propTypes = {
  currentColor: PropTypes.number.isRequired,
  onApply: PropTypes.func.isRequired,
  colors: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({
  app: {
    enums: { colors },
  },
}) => ({ colors });

export default connect(mapStateToProps)(SelectColorPopover);
