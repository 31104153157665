import React from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import ColorPoint from 'components/colorSelectors/ColorPoint/ColorPoint';
import classes from 'components/colorSelectors/ColorPicker/ColorPicker.module.scss';

const ColorPicker = ({ colorList, selectedColor, onChange }) => (
  <div className={clsx('d-flex flex-wrap', classes.root)}>
    {colorList.map(({ id, name, code }) => (
      <ColorPoint
        key={id}
        id={id}
        title={name}
        interactive
        selected={id === selectedColor}
        className={classes.colorPoint}
        onChange={onChange}
        color={code}
      />
    ))}
  </div>
);

ColorPicker.propTypes = {
  colorList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      code: PropTypes.string,
    })
  ).isRequired,
  selectedColor: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

ColorPicker.defaultProps = {
  selectedColor: null,
};

export default ColorPicker;
