import React from 'react';
import * as PropTypes from 'prop-types';
import BasicAvatar from 'components/ui/BasicAvatar';
import classes from './ContactThumbnail.module.scss';

const ContactThumbnail = ({
  user: { firstName, lastName, avatarUrl, date },
}) => {
  return (
    <div className="d-inline-flex align-items-center">
      <BasicAvatar
        className={classes.avatar}
        lastName={firstName}
        firstName={lastName}
        src={avatarUrl}
      />
      <div className="d-flex flex-column">
        <span
          className={`${classes.fullName}`}
        >{`${firstName} ${lastName}`}</span>
        <span className={classes.date}>{date}</span>
      </div>
    </div>
  );
};

ContactThumbnail.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    avatarUrl: PropTypes.string,
    date: PropTypes.string,
  }).isRequired,
};

export default ContactThumbnail;
