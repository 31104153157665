import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useCallbackRef from 'hooks/useCallbackRef';
import {
  changeStepAction,
  selectImageAction,
  setImagesAction,
  unselectImageAction,
  updateIISearchParamsAction,
  updateImagesAction,
} from 'modules/curateTheLook/store/actions';
import {
  createLookBoardStepKeys,
  infoMenuConfig,
} from 'modules/curateTheLook/constants';
import errorToastr from 'libs/toastr/errorToastr';
import { inspirationImageShape } from 'modules/inspirationImage/propTypes';
import inspirationImageService from 'modules/inspirationImage/inspirationImageService';
import ImageItem from 'modules/curateTheLook/createLookBoard/components/ImageItem/ImageItem';
import SideBar from 'modules/curateTheLook/createLookBoard/components/SideBar';
import ImagesSearchParamsBlock from 'modules/curateTheLook/createLookBoard/components/ImagesSearchParamsBlock/ImagesSearchParamsBlock';
import ScrollableDatabase from 'modules/curateTheLook/createLookBoard/components/ScrollableDatabase';
import SelectedImageBlock from 'modules/curateTheLook/createLookBoard/components/SelectedImageBlock/SelectedImageBlock';
import classes from 'modules/curateTheLook/createLookBoard/CreateLookBoard.module.scss';

// TODO: Get limit value from application config
const limit = 10;

const LeftPanel = ({
  currentStep,
  imageList,
  searchParams,
  selectedImage,
  setImages,
  updateImages,
  changeStep,
  selectImage,
  unselectImage,
  updateIISearchParams,
  onOpenPreview,
}) => {
  const [scrollBarNode, scrollBarRef] = useCallbackRef();
  const [scrollPosition, setScrollPosition] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [
    initialScrollableDatabaseHeight,
    setInitialScrollableDatabaseHeight,
  ] = useState(0);

  const isFirstStepSkipped = useMemo(
    () => !selectedImage && currentStep !== createLookBoardStepKeys.selectImage,
    [selectedImage, currentStep]
  );

  const loadFirst = useCallback(async () => {
    setLoading(true);

    try {
      const list = await inspirationImageService.getImages(searchParams);
      const imageArrLength = Object.keys(list).length;

      setImages(list);
      if (imageArrLength > 0) {
        updateIISearchParams({ offset: searchParams.offset + imageArrLength });
      }

      if (scrollBarNode) {
        scrollBarNode.scrollToTop();
      }

      setHasMore(imageArrLength === limit);
      setLoading(false);
    } catch (e) {
      errorToastr('Error loadFirst', e.generalError);
    }
  }, [updateIISearchParams, setImages, searchParams, scrollBarNode]);

  useEffect(() => {
    (async () => {
      if (searchParams.offset === 0 && scrollBarNode) {
        await loadFirst();
      }
    })();
    // eslint-disable-next-line
  }, [searchParams, scrollBarNode]);

  useEffect(() => {
    if (!isFirstStepSkipped && !selectedImage && scrollBarNode) {
      scrollBarNode.scrollTop(scrollPosition);
    }
    // eslint-disable-next-line
  }, [selectedImage, scrollBarNode]);

  const loadMore = useCallback(async () => {
    setLoading(true);

    try {
      const list = await inspirationImageService.getImages(searchParams);
      const imageArrLength = Object.keys(list).length;

      updateImages(list);
      updateIISearchParams({ offset: searchParams.offset + imageArrLength });

      setHasMore(imageArrLength === limit);
      setLoading(false);
    } catch (e) {
      errorToastr('Error', e.generalError);
    }
  }, [updateIISearchParams, updateImages, searchParams]);

  const handleUpdateSearchParams = useCallback(
    (params) => {
      updateIISearchParams({
        ...params,
        offset: 0,
      });

      setHasMore(false);
    },
    [updateIISearchParams]
  );

  const handleSelectImage = useCallback(
    (imgId) => {
      if (scrollBarNode) {
        const currentScroll = scrollBarNode.getScrollTop();
        setScrollPosition(currentScroll);
      }
      selectImage(imgId);
    },
    [selectImage, scrollBarNode]
  );

  return (
    <div className="d-flex">
      <SideBar
        currentSource={searchParams.source}
        onUpdateSearchParams={handleUpdateSearchParams}
        unselectImage={unselectImage}
        infoMenuConfig={infoMenuConfig}
      />
      <div className={classes.sidePanel}>
        <ImagesSearchParamsBlock
          currentSource={searchParams.source}
          currentImgType={searchParams.type}
          searchQuery={searchParams.search}
          isFirstStepSkipped={isFirstStepSkipped}
          selectedImage={selectedImage}
          onChangeStep={changeStep}
          onUpdateSearchParams={handleUpdateSearchParams}
        />
        {!isFirstStepSkipped && !selectedImage && (
          <ScrollableDatabase
            initialHeight={initialScrollableDatabaseHeight}
            setInitialHeight={setInitialScrollableDatabaseHeight}
            scrollBarRef={scrollBarRef}
            loading={loading}
            hasMore={hasMore}
            loadMore={loadMore}
          >
            {imageList.map(({ id, url, isLiked, media: { userId, hash } }) => (
              <ImageItem
                key={id}
                id={id}
                url={url}
                userId={userId}
                hash={hash}
                isLiked={isLiked}
                onOpenPreview={onOpenPreview}
                onSelectImage={handleSelectImage}
              />
            ))}
          </ScrollableDatabase>
        )}
        {selectedImage && (
          <SelectedImageBlock
            inspirationImageId={selectedImage}
            searchParams={searchParams}
            onBack={unselectImage}
            onOpenPreview={onOpenPreview}
          />
        )}
      </div>
    </div>
  );
};

LeftPanel.propTypes = {
  currentStep: PropTypes.string.isRequired,
  imageList: PropTypes.arrayOf(inspirationImageShape).isRequired,
  selectedImage: PropTypes.number,
  searchParams: PropTypes.shape({
    source: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    search: PropTypes.string,
    offset: PropTypes.number.isRequired,
  }).isRequired,
  setImages: PropTypes.func.isRequired,
  updateImages: PropTypes.func.isRequired,
  changeStep: PropTypes.func.isRequired,
  selectImage: PropTypes.func.isRequired,
  unselectImage: PropTypes.func.isRequired,
  updateIISearchParams: PropTypes.func.isRequired,
  onOpenPreview: PropTypes.func.isRequired,
};

LeftPanel.defaultProps = {
  selectedImage: null,
};

const mapSateToProps = ({
  curateTheLook: {
    currentStep,
    imageList,
    inspirationImageSearchParams,
    lookBoardData: { inspirationImageId },
  },
  inspirationImage: { library },
}) => ({
  currentStep,
  imageList: imageList.map((id) => library[id]),
  selectedImage: inspirationImageId,
  searchParams: inspirationImageSearchParams,
});

const mapDispatchToProps = {
  setImages: setImagesAction,
  updateImages: updateImagesAction,
  changeStep: changeStepAction,
  selectImage: selectImageAction,
  unselectImage: unselectImageAction,
  updateIISearchParams: updateIISearchParamsAction,
};

export default connect(mapSateToProps, mapDispatchToProps)(LeftPanel);
