export const supportFormTypes = {
  contact: 'contact',
  support: 'support',
};

export const supportFormFields = {
  type: 'type',
  name: 'name',
  email: 'email',
  subject: 'subject',
  message: 'message',
};

export const errorMessages = {
  required: 'This field is required',
};
