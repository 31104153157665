import {
  requestFormFields,
  errorMessages,
} from 'modules/requestTheLook/requestDetails/requestForm/constants';

const requestFormValidator = ({ [requestFormFields.title]: title }) => {
  const errors = {};

  if (!title) {
    errors.title = errorMessages.required;
  }

  return errors;
};

export default requestFormValidator;
