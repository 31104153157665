import React from 'react';
import backgroundImage from 'assets/img/home-page/visualize-bg.png';
import { visualizeSectionConfig } from 'modules/home/constants';
import Tabs from 'modules/home/components/VisualizeIdeas/Tabs/Tabs';
import MixMatchTab from 'modules/home/components/VisualizeIdeas/MixMatchTab';
import LookBoardViewTab from 'modules/home/components/VisualizeIdeas/LookBoardViewTab';
import ProductStreamTab from 'modules/home/components/VisualizeIdeas/ProductStreamTab';
import classes from './VisualizeIdeas.module.scss';

const VisualizeIdeas = () => {
  const styles = {
    backgroundImage: `url(${backgroundImage})`,
  };

  const {
    colors,
    lookBoardTabData,
    mixMatchData,
    productStreamData,
  } = visualizeSectionConfig;

  // TODO Change Tabs implementation
  return (
    <section className={classes.root}>
      <div className={classes.image} style={styles}>
        <div className={classes.imageContent}>
          <div className={classes.imageTitle}>Get the Look</div>
          <ul className={classes.list}>
            <li>
              <b>Items:</b> Chair, Lamp, Sofa, Table
            </li>
            <li>
              <b>Price point:</b> Mid Range
            </li>
            <li className="d-flex align-items-center">
              <b className="mr-1">Colors:</b>
              {colors.map((backgroundColor) => (
                <div
                  key={backgroundColor}
                  style={{ backgroundColor }}
                  className={classes.color}
                />
              ))}
            </li>
          </ul>
        </div>
      </div>
      <div className="flex-center">
        <h2 className={classes.title}>
          Visualize Ideas <br /> Your Way
        </h2>
      </div>
      <div className={classes.tabsWrapper}>
        <Tabs selected={1}>
          <MixMatchTab
            title="Mix & Match"
            icon="shuffle-icon"
            items={mixMatchData}
          />
          <LookBoardViewTab
            title="Look Board View"
            icon="dashboard"
            items={lookBoardTabData}
          />
          <ProductStreamTab
            title="Product Stream View"
            icon="living-room"
            items={productStreamData}
          />
        </Tabs>
      </div>
    </section>
  );
};

export default VisualizeIdeas;
