import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import {
  navLinksArrayOf,
  infoMenuShape,
  infoMenuItemsArrayOf,
} from 'modules/layout/SideNav/propTypes';
import SpriteIcon from 'components/ui/SpriteIcon';
import InfoMenu from 'modules/layout/SideNav/InfoMenu/InfoMenu';
import classes from './SideNav.module.scss';

const SideNav = ({
  navLinks,
  isAmbassador,
  ambassadorNavLinks,
  infoMenuConfig,
  ambassadorInfoMenuItems,
}) => {
  const [infoMenuOpen, setInfoMenuOpen] = useState(false);

  const handleOpenInfoMenu = useCallback(() => {
    setInfoMenuOpen(true);
  }, []);

  const handleCloseInfoMenu = useCallback(() => {
    setInfoMenuOpen(false);
  }, []);

  return (
    <div className={classes.root}>
      {infoMenuConfig && (
        <div
          className={clsx(classes.navLink, {
            [classes.active]: infoMenuOpen,
          })}
          title="Help"
          onClick={handleOpenInfoMenu}
        >
          <SpriteIcon name="info" />
        </div>
      )}
      <div>
        {isAmbassador &&
          ambassadorNavLinks &&
          ambassadorNavLinks.map(({ route, title, miniTitle, icon }) => (
            <NavLink
              key={route}
              to={route}
              className={clsx(classes.navLink, classes.ambassadorNavLink)}
              activeClassName={clsx({ [classes.active]: !infoMenuOpen })}
              title={title}
              onClick={handleCloseInfoMenu}
            >
              {miniTitle && (
                <div className={classes.miniTitle}>{miniTitle}</div>
              )}
              <SpriteIcon name={icon} size="lg" />
            </NavLink>
          ))}
      </div>
      {navLinks.map(({ route, title, icon }) => (
        <NavLink
          key={route}
          to={route}
          className={classes.navLink}
          activeClassName={clsx({ [classes.active]: !infoMenuOpen })}
          title={title}
          onClick={handleCloseInfoMenu}
        >
          <SpriteIcon name={icon} size="lg" />
        </NavLink>
      ))}
      {infoMenuConfig && (
        <InfoMenu
          isAmbassador={isAmbassador}
          config={infoMenuConfig}
          ambassadorConfig={ambassadorInfoMenuItems}
          isOpen={infoMenuOpen}
          onClose={handleCloseInfoMenu}
        />
      )}
    </div>
  );
};

SideNav.propTypes = {
  navLinks: navLinksArrayOf.isRequired,
  ambassadorNavLinks: navLinksArrayOf,
  isAmbassador: PropTypes.bool,
  infoMenuConfig: infoMenuShape,
  ambassadorInfoMenuItems: infoMenuItemsArrayOf,
};

SideNav.defaultProps = {
  ambassadorNavLinks: null,
  isAmbassador: false,
  infoMenuConfig: null,
  ambassadorInfoMenuItems: null,
};

export default SideNav;
