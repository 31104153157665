export const signInFormFields = {
  email: 'email',
  password: 'password',
};

export const errorMessages = {
  required: 'This field is required',
};

export default {
  signInFormFields,
  errorMessages,
};
