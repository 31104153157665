import React from 'react';
import * as PropTypes from 'prop-types';
import classes from 'components/PreferenceQuiz/LinearProgress/LinearProgress.module.scss';

const LinearProgress = ({ className, progress }) => (
  <div className={className}>
    <div className={classes.track}>
      <div
        className={classes.progressTrack}
        style={{ width: `${progress}%` }}
      />
    </div>
  </div>
);

LinearProgress.propTypes = {
  progress: PropTypes.number.isRequired,
  className: PropTypes.string,
};

LinearProgress.defaultProps = {
  className: undefined,
};

export default LinearProgress;
