export const localStorageKeys = {
  appVersion: 'appVersion',
  applicationConfig: 'applicationConfig',
  checksum: 'checksum',
  enums: 'enums',
};

export default {
  localStorageKeys,
};
