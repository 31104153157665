import {
  CTL_RESET_CANVAS,
  CTL_RESET_STATE,
  CTL_SET_FORM_VALUES,
  CTL_SET_IMAGES,
  CTL_SET_LOOK_BOARD_STATUS,
  CTL_SET_PRODUCTS,
  CTL_SET_SELECTED_TEMPLATE,
  CTL_SET_STEP,
  CTL_UPDATE_DND_STATE,
  CTL_UPDATE_II_SEARCH_PARAMS,
  CTL_UPDATE_IMAGES,
  CTL_UPDATE_LOOK_BOARD_DATA,
  CTL_UPDATE_PRODUCT_SEARCH_PARAMS,
  CTL_UPDATE_PRODUCTS,
} from 'modules/curateTheLook/store/constants';
import { updateProductsLibraryAction } from 'modules/product/store/actions';
import {
  createLookBoardStepKeys,
  lookBoardStatusKeys,
  lookBoardTemplatesConfig,
} from 'modules/curateTheLook/constants';
import customEvent from 'utils/customEvent';
import {
  SET_INITIAL_SCROLLER_HEIGHT,
  UPDATE_SCROLLER_HEIGHT,
} from 'constants/customEventNames';
import { updateIILibraryAction } from 'modules/inspirationImage/store/actions';
import lookBoardService from 'modules/lookBoard/lookBoardService';
import { imageTypeKeys } from 'constants/inspirationImageSearchParams';

export const buildDroppableColumnsAction = (count, startNumber = 0) => async (
  dispatch,
  getState
) => {
  const {
    curateTheLook: { dndState, productList },
  } = getState();
  const arrayFromColumnCount = Array.from(
    Array(count),
    (_, i) => i + startNumber + 1
  );

  const cols = arrayFromColumnCount.reduce((accum, currItem) => {
    const columnId = `item-${currItem}`;
    /* eslint-disable no-param-reassign */
    accum[columnId] = { id: columnId, product: null };

    return accum;
  }, {});

  // let prevColumns = {};
  let updatedState;
  if (startNumber > 0) {
    const prevColumns = { ...dndState.dynamicColumns };
    updatedState = {
      dynamicColumns: {
        ...prevColumns,
        ...cols,
      },
    };
  } else {
    updatedState = {
      productList: {
        ...dndState.productList,
        productIds: [...productList],
      },
      dynamicColumns: {
        ...cols,
      },
    };
  }

  await dispatch({
    type: CTL_UPDATE_DND_STATE,
    payload: updatedState,
  });

  customEvent.trigger(UPDATE_SCROLLER_HEIGHT);
};

export const setImagesAction = (list) => (dispatch) => {
  const imageIds = Object.keys(list);
  dispatch(updateIILibraryAction(list));
  dispatch({
    type: CTL_SET_IMAGES,
    payload: imageIds,
  });
};

export const updateImagesAction = (list) => (dispatch) => {
  const imageIds = Object.keys(list);
  dispatch(updateIILibraryAction(list));
  dispatch({
    type: CTL_UPDATE_IMAGES,
    payload: imageIds,
  });
};

export const setProductsAction = (list) => (dispatch) => {
  const productIds = Object.keys(list);
  dispatch(updateProductsLibraryAction(list));
  dispatch({
    type: CTL_SET_PRODUCTS,
    payload: productIds,
  });
};

export const updateProductsAction = (list) => (dispatch) => {
  const productIds = Object.keys(list);
  dispatch(updateProductsLibraryAction(list));
  dispatch({
    type: CTL_UPDATE_PRODUCTS,
    payload: productIds,
  });
};

export const handleDragEndAction = ({ source, destination, draggableId }) => (
  dispatch,
  getState
) => {
  const {
    curateTheLook: { dndState },
    product: { library },
  } = getState();

  if (!destination) {
    return;
  }

  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return;
  }

  const sourceColumn =
    dndState[source.droppableId] || dndState.dynamicColumns[source.droppableId];

  const destColumn = dndState.dynamicColumns[destination.droppableId];
  const sourceColumnCopy = { ...sourceColumn };
  const destColumnCopy = { ...destColumn };
  const productListCopy = { ...dndState.productList };

  if (sourceColumn.id === 'productList') {
    sourceColumnCopy.productIds.splice(source.index, 1);
  } else {
    sourceColumnCopy.product = null;
    if (destColumn.product) {
      productListCopy.productIds.unshift(destColumn.product.id);
    }
  }

  destColumnCopy.product = { ...library[draggableId] };

  let newDndState;

  if (sourceColumn.id === 'productList') {
    newDndState = {
      [sourceColumnCopy.id]: sourceColumnCopy,
      dynamicColumns: {
        ...dndState.dynamicColumns,
        [destColumnCopy.id]: destColumnCopy,
      },
    };
  } else {
    newDndState = {
      productList: productListCopy,
      dynamicColumns: {
        ...dndState.dynamicColumns,
        [sourceColumnCopy.id]: sourceColumnCopy,
        [destColumnCopy.id]: destColumnCopy,
      },
    };
  }

  dispatch({
    type: CTL_UPDATE_DND_STATE,
    payload: newDndState,
  });
};

export const removeItemFromCanvasAction = (columnId) => (
  dispatch,
  getState
) => {
  const {
    curateTheLook: { dndState },
  } = getState();
  const sourceColumnCopy = { ...dndState.dynamicColumns[columnId] };
  const movedProductId = sourceColumnCopy.product.id;
  const productListCopy = { ...dndState.productList };

  sourceColumnCopy.product = null;
  productListCopy.productIds = [movedProductId, ...productListCopy.productIds];

  dispatch({
    type: CTL_UPDATE_DND_STATE,
    payload: {
      productList: productListCopy,
      dynamicColumns: {
        ...dndState.dynamicColumns,
        [columnId]: sourceColumnCopy,
      },
    },
  });
};

export const updateLookBoardDataAction = (payload) => (dispatch) => {
  dispatch({ type: CTL_UPDATE_LOOK_BOARD_DATA, payload });
};

export const changeTemplateAction = (template) => (dispatch) => {
  dispatch({ type: CTL_SET_SELECTED_TEMPLATE, payload: template });
  if (template) {
    dispatch(buildDroppableColumnsAction(template.columnCount));
    dispatch(updateLookBoardDataAction({ columns: template.columnCount }));
  }
  customEvent.trigger(SET_INITIAL_SCROLLER_HEIGHT);
};

export const changeStepAction = (step) => (dispatch) => {
  const defaultTemplate = Object.values(
    lookBoardTemplatesConfig
  ).find(({ isDefault }) => Boolean(isDefault));

  const currentTemplate =
    step === createLookBoardStepKeys.addProducts ? defaultTemplate : null;
  dispatch({ type: CTL_SET_STEP, payload: step });
  dispatch(changeTemplateAction(currentTemplate));
};

export const selectImageAction = (imgId) => (dispatch) => {
  dispatch(updateLookBoardDataAction({ inspirationImageId: imgId }));
  dispatch(changeStepAction(createLookBoardStepKeys.addProducts));
};

export const unselectImageAction = () => (dispatch) => {
  dispatch(
    updateLookBoardDataAction({
      inspirationImageId: null,
      columns: null,
    })
  );
  dispatch(changeStepAction(createLookBoardStepKeys.selectImage));
};

export const resetCanvasAction = () => (dispatch) => {
  dispatch({ type: CTL_RESET_CANVAS });
  customEvent.trigger(SET_INITIAL_SCROLLER_HEIGHT);
};

export const updateIISearchParamsAction = (params) => (dispatch) => {
  dispatch({ type: CTL_UPDATE_II_SEARCH_PARAMS, payload: params });
};

export const updateProductSearchParamsAction = (params) => (dispatch) => {
  dispatch({ type: CTL_UPDATE_PRODUCT_SEARCH_PARAMS, payload: params });
};

export const saveLookBoardDetailsFormValuesAction = (values) => (dispatch) => {
  dispatch({ type: CTL_SET_FORM_VALUES, payload: values });
};

export const changeLookBoardStatusAction = (value) => (dispatch) => {
  dispatch({ type: CTL_SET_LOOK_BOARD_STATUS, payload: value });
};

export const submitLookBoardAction = () => async (dispatch, getState) => {
  const {
    curateTheLook: {
      lookBoardData,
      lookBoardDetailsFormValues: {
        title,
        type,
        description,
        styleId,
        roomTypeId,
        itemClassId,
      },
      lookBoardStatus,
    },
  } = getState();

  const {
    result: { id: lookBoardId },
  } = await lookBoardService.createLookBoard(lookBoardData);
  const optionalParam =
    type === imageTypeKeys.room ? { roomTypeId } : { itemClassId };
  const lookBoardParams = {
    title,
    description,
    public: Number(Boolean(lookBoardStatus === lookBoardStatusKeys.public)),
    styleId,
    ...optionalParam,
  };

  await lookBoardService.submitLookBoard(lookBoardId, lookBoardParams);
};

export const resetCurateStateAction = () => (dispatch) => {
  dispatch({ type: CTL_RESET_STATE });
};
