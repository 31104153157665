import palette from 'materialUi/palette';

const MuiFab = {
  root: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: '14px',
    fontWeight: 'normal',
    textTransform: 'none',
    lineHeight: '150%',
    backgroundColor: '#fff',
    boxShadow: 'none',
    color: palette.primary.main,
    transition: 'all 150ms ease-in',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  primary: {
    backgroundColor: '#fff',
    color: palette.primary.main,
    '&:hover': {
      backgroundColor: palette.primary.main,
      color: '#fff',
    },
  },
};

export default MuiFab;
