import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';
import { handleDragEndAction } from 'modules/curateTheLook/store/actions';
import ImgPreviewModal from 'components/modals/ImgPreviewModal/ImgPreviewModal';
import LeftPanel from 'modules/curateTheLook/createLookBoard/LeftPanel';
import RightPanel from 'modules/curateTheLook/createLookBoard/RightPanel';
import MainArea from 'modules/curateTheLook/createLookBoard/MainArea';

const CreateLookBoardContainer = ({ handleDragEnd }) => {
  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [imgPreviewUrl, setImgPreviewUrl] = useState(null);

  const handleImgModalOpen = useCallback((imgUrl) => {
    setImgPreviewUrl(imgUrl);
    setImgModalOpen(true);
  }, []);

  const handleImgModalClose = useCallback(() => {
    setImgModalOpen(false);
  }, []);

  return (
    <div className="flex-fill d-flex">
      <LeftPanel onOpenPreview={handleImgModalOpen} />
      <DragDropContext onDragEnd={handleDragEnd}>
        <MainArea onOpenPreview={handleImgModalOpen} />
        <RightPanel />
      </DragDropContext>
      <ImgPreviewModal
        open={imgModalOpen}
        onClose={handleImgModalClose}
        url={imgPreviewUrl}
      />
    </div>
  );
};

CreateLookBoardContainer.propTypes = {
  handleDragEnd: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  handleDragEnd: handleDragEndAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateLookBoardContainer);
