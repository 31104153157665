import {
  imageSourceKeys,
  imageTypeKeys,
} from 'constants/inspirationImageSearchParams';
import { productTypeKeys } from 'modules/product/constants';
import itemOne from 'assets/img/chair-1.png';
import itemTwo from 'assets/img/lamp-1.png';
import itemThree from 'assets/img/chair-2.png';

export const imageTypeOptions = [
  {
    value: imageSourceKeys.my,
    label: 'My Added Images',
  },
  {
    value: imageSourceKeys.liked,
    label: 'My Saved Images',
  },
];

export const productTypeOptions = [
  {
    value: productTypeKeys.all,
    label: 'All Products',
  },
  {
    value: productTypeKeys.my,
    label: 'My Saved Products',
  },
  {
    value: productTypeKeys.liked,
    label: 'Me Liked Products',
  },
];

export const stepTwoTabKeys = {
  templates: 'templates',
  products: 'products',
};

export const stepTwoColumnViewKeys = {
  single: 'single',
  double: 'double',
};

export const createLookBoardStepKeys = {
  selectImage: 'selectImage',
  addProducts: 'addProducts',
};

export const lookBoardStatusKeys = {
  public: 'public',
  private: 'private',
};

export const lookBoardTemplatesConfig = {
  1: {
    id: 1,
    columnCount: 1,
    title: 'One Column Template',
    subTitle: '1 Column (1-4 Items)',
    imageList: [
      {
        id: 1,
        src: itemOne,
      },
    ],
  },
  2: {
    id: 2,
    columnCount: 2,
    title: 'Two Column Template',
    subTitle: '2 Column (2-8 Items)',
    isDefault: true,
    imageList: [
      {
        id: 1,
        src: itemOne,
      },
      {
        id: 2,
        src: itemTwo,
      },
    ],
  },
  3: {
    id: 3,
    columnCount: 3,
    title: 'Three Column Template',
    subTitle: '3 Column (3-12 Items)',
    imageList: [
      {
        id: 1,
        src: itemOne,
      },
      {
        id: 2,
        src: itemTwo,
      },
      {
        id: 3,
        src: itemThree,
      },
    ],
  },
};

export const imageSearchRadioOptions = [
  { value: imageTypeKeys.room, label: 'Room Images' },
  { value: imageTypeKeys.single, label: 'Single Items' },
];

export const columnViewRadioOptions = [
  { value: stepTwoColumnViewKeys.double, label: '2 Column View' },
  { value: stepTwoColumnViewKeys.single, label: '1 Column View' },
];

export const infoMenuConfig = {
  title: 'Curate the Look',
  description: 'Curate the Look Section Guide',
  items: [
    {
      id: 1,
      title: 'Inspiration Images Database',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut ?',
      icon: 'living-room',
    },
    {
      id: 2,
      title: 'My Added Images',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor consectetur adipiscing incididunt ut ?',
      icon: 'added-image',
    },
  ],
};

export const lookBoardDetailsFormFields = {
  title: 'title',
  type: 'type',
  roomTypeId: 'roomTypeId',
  itemClassId: 'itemClassId',
  styleId: 'styleId',
  description: 'description',
};

export const lookBoardStatusRadioOptions = [
  { value: lookBoardStatusKeys.public, label: 'Public' },
  { value: lookBoardStatusKeys.private, label: 'Keep Private' },
];

export const errorMessages = {
  required: 'This field is required',
};
