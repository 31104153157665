import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import classes from './OverlayLabel.module.scss';

const OverlayLabel = ({ text, labelClasses }) => (
  <div className={clsx('flex-center', classes.root, labelClasses.root)}>
    <p className={labelClasses.label}>{text}</p>
    <div className={classes.topRightBorder} />
    <div className={classes.bottomLeftBorder} />
  </div>
);

OverlayLabel.propTypes = {
  text: PropTypes.node.isRequired,
  labelClasses: PropTypes.shape({
    root: PropTypes.string,
    label: PropTypes.string,
  }),
};

OverlayLabel.defaultProps = {
  labelClasses: {},
};

export default OverlayLabel;
