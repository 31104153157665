import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  imageTypeOptions,
  imageTypeValuesMap,
  createImageFieldKeys,
  ownershipValuesMap,
  permissionsValuesMap,
} from 'modules/pinMedia/constants';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import SelectInputWrapper from 'components/finalFormWrappers/SelectInputWrapper';
import RadioInputWrapper from 'components/finalFormWrappers/RadioInputWrapper';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import { getEnumAsSelectOptions } from 'modules/app/store/selectors';
import SpriteIcon from 'components/ui/SpriteIcon';
import OwnershipInfoPopup from 'modules/pinMedia/OwnershipInfoPopup/OwnershipInfoPopup';
import classes from 'modules/pinMedia/CreateImageForm/CreateImageForm.module.scss';

const initialFormValues = {
  [createImageFieldKeys.ownership]: ownershipValuesMap.thirdPartyOwned,
  [createImageFieldKeys.permissions]: permissionsValuesMap.open,
};

const CreateImageForm = ({
  validate,
  onSubmit,
  sourceUrl,
  roomTypes,
  itemClasses,
}) => {
  const [formValues, setFormValues] = useState({
    ...initialFormValues,
    sourceUrl,
  });
  const [infoPopupOpen, setInfoPopupOpen] = useState(false);

  useEffect(() => {
    setFormValues((prevState) => ({ ...prevState, sourceUrl }));
  }, [sourceUrl]);

  const handleOpenInfoPopup = useCallback((event) => {
    event.preventDefault();
    setInfoPopupOpen(true);
  }, []);

  const handleCloseInfoPopup = useCallback(() => {
    setInfoPopupOpen(false);
  }, []);

  return (
    <>
      <Form
        initialValues={formValues}
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, values }) => (
          <form
            className=" h-100 d-flex flex-column"
            noValidate
            onSubmit={handleSubmit}
          >
            <div className="flex-fill">
              <Field
                label="Title"
                required
                name={createImageFieldKeys.title}
                component={TextInputWrapper}
              />
              {sourceUrl && (
                <Field
                  label="Source URL"
                  disabled
                  name={createImageFieldKeys.sourceUrl}
                  component={TextInputWrapper}
                  InputProps={{
                    classes: { disabled: classes.disabledInput },
                  }}
                />
              )}
              <Field
                name={createImageFieldKeys.type}
                label="Image Type"
                required
                component={SelectInputWrapper}
                options={imageTypeOptions}
              />
              {values.type === imageTypeValuesMap.room && (
                <Field
                  name={createImageFieldKeys.roomType}
                  label="Room Type"
                  required
                  component={SelectInputWrapper}
                  options={roomTypes}
                />
              )}
              {values.type === imageTypeValuesMap.single && (
                <Field
                  name={createImageFieldKeys.itemClassId}
                  label="Item Class"
                  required
                  component={SelectInputWrapper}
                  options={itemClasses}
                />
              )}
              <Field
                label="Description (optional)"
                name={createImageFieldKeys.description}
                multiline
                rows={5}
                maxLength={160}
                component={TextInputWrapper}
              />
              <FormControl component="fieldset" className="mb-3">
                <RadioGroup>
                  <FormControlLabel
                    label="I found this image online"
                    classes={{
                      label: clsx({
                        'font-semi-bold':
                          values.ownership ===
                          ownershipValuesMap.thirdPartyOwned,
                      }),
                    }}
                    control={
                      <Field
                        name={createImageFieldKeys.ownership}
                        type="radio"
                        value={ownershipValuesMap.thirdPartyOwned}
                        component={RadioInputWrapper}
                      />
                    }
                  />
                  <FormControlLabel
                    classes={{
                      label: clsx({
                        'font-semi-bold':
                          values.ownership === ownershipValuesMap.selfOwned,
                      }),
                    }}
                    control={
                      <Field
                        name={createImageFieldKeys.ownership}
                        type="radio"
                        value={ownershipValuesMap.selfOwned}
                        component={RadioInputWrapper}
                      />
                    }
                    label={
                      <div className="d-flex align-items-center">
                        <span className="mr-1">I own this image</span>
                        <SpriteIcon
                          name="info"
                          size="sm"
                          className="secondary-color"
                          onClick={handleOpenInfoPopup}
                        />
                      </div>
                    }
                  />
                </RadioGroup>
              </FormControl>
              {values.ownership === ownershipValuesMap.selfOwned && (
                <FormControl component="fieldset">
                  <FormLabel>
                    <p className={classes.permissionsLabel}>Image Usage</p>
                    <p className={classes.permissionsDescription}>
                      As the image owner, you can choose to include it in our
                      Inspiration Image database or you can use it exclusively
                      for your Look Boards. It’s your choice!
                    </p>
                  </FormLabel>
                  <RadioGroup>
                    <FormControlLabel
                      label={
                        <span className="text-sm">
                          <b>Open:</b> Include in Image Database
                        </span>
                      }
                      control={
                        <Field
                          name={createImageFieldKeys.permissions}
                          type="radio"
                          value={permissionsValuesMap.open}
                          component={RadioInputWrapper}
                        />
                      }
                    />
                    <FormControlLabel
                      control={
                        <Field
                          name={createImageFieldKeys.permissions}
                          type="radio"
                          value={permissionsValuesMap.exclusive}
                          component={RadioInputWrapper}
                        />
                      }
                      label={
                        <span className="text-sm">
                          <b>Exclusive:</b> Do not Include in Image Database
                        </span>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </div>
            <div className="d-flex justify-content-center">
              <div className={classes.submitBtnContainer}>
                <FinalFormSubmitButton color="secondary" size="sm">
                  Save
                </FinalFormSubmitButton>
              </div>
            </div>
          </form>
        )}
      />
      <OwnershipInfoPopup open={infoPopupOpen} onClose={handleCloseInfoPopup} />
    </>
  );
};

CreateImageForm.propTypes = {
  validate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  sourceUrl: PropTypes.string,
  roomTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  itemClasses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

CreateImageForm.defaultProps = {
  sourceUrl: undefined,
};

const mapStateToProps = ({
  app: {
    enums: { roomTypes, itemClasses },
  },
}) => ({
  roomTypes: getEnumAsSelectOptions(roomTypes),
  itemClasses: getEnumAsSelectOptions(itemClasses),
});

export default connect(mapStateToProps)(CreateImageForm);
