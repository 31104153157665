import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import SpriteIcon from 'components/ui/SpriteIcon';
import CustomTextInputWrapper from 'components/finalFormWrappers/CustomTextInputWrapper';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import pinMediaValidator from 'modules/pinMedia/PinMediaModal/pinMediaValidator';
import classes from './PinMediaModal.module.scss';

const PinMediaModal = ({ open, onClose, onParseUrl, ...rest }) => (
  <BasicModal open={open} onClose={onClose} {...rest}>
    <div className={classes.wrapper}>
      <h2 className={classes.title}>Pin an Image</h2>
      <p className={`${classes.description} mb-4`}>
        You can Pin an Image by copying and pasting a Website Page URL or an
        Image URL into the field below
      </p>
      <h3 className={classes.subtitle}>Enter Website Page URL or Image URL</h3>
      <div className="d-flex align-items-center mb-2">
        <div className={`${classes.infoIcon} mr-1`}>
          <SpriteIcon name="info" size="sm" />
        </div>
        <i className={classes.infoText}>
          Copy and Paste a Website Page URL to capture all page images or a
          specific Image URL
        </i>
      </div>
      <Form
        onSubmit={onParseUrl}
        validate={pinMediaValidator}
        render={({ handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Field
              required
              name="url"
              type="url"
              pattern="(http:|https:)+[^\s]+[\w]"
              component={CustomTextInputWrapper}
            />
            <FinalFormSubmitButton
              variant="outlined"
              className={classes.actionBtn}
            >
              Next
            </FinalFormSubmitButton>
          </form>
        )}
      />
    </div>
  </BasicModal>
);

PinMediaModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onParseUrl: PropTypes.func.isRequired,
};

export default PinMediaModal;
