import React from 'react';
import PropTypes from 'prop-types';
import classes from './BannerButton.module.scss';

const BannerButton = ({ title, onClick }) => (
  <div className={classes.root}>
    <div className={classes.title}>{title}</div>
    <button className={classes.btn} onClick={onClick} type="button">
      Go
    </button>
  </div>
);

BannerButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default BannerButton;
