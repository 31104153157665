import PropTypes from 'prop-types';

export const userShape = PropTypes.shape({
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  avatar: PropTypes.shape({
    userId: PropTypes.number.isRequired,
    hash: PropTypes.string.isRequired,
  }),
  email: PropTypes.string.isRequired,
});

export const preferencesShape = PropTypes.shape({});
