import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import roomTypesOptionShape from 'components/PreferenceQuiz/propTypes/roomTypesOptionShape';
import RoomTypeOption from 'components/PreferenceQuiz/RoomTypeOption/RoomTypeOption';
import classes from 'components/PreferenceQuiz/steps/steps.module.scss';
import Button from 'components/ui/Button/Button';

const RoomTypeStep = ({
  isLoading,
  currentValue,
  options,
  onCancelEdit,
  onSaveRoomTypes,
}) => {
  const [inputValue, setInputValue] = useState([]);

  useEffect(() => {
    if (Array.isArray(currentValue)) {
      setInputValue(currentValue);
    }
  }, [currentValue]);

  const handleChange = useCallback(
    (optionValue) => {
      let valueCopy = [...inputValue];
      const isOptionExist = inputValue.includes(optionValue);

      if (isOptionExist) {
        valueCopy = valueCopy.filter((item) => item !== optionValue);
      } else if (valueCopy.length < 5) {
        valueCopy.push(optionValue);
      }

      setInputValue(valueCopy);
    },
    [inputValue]
  );

  const handleSaveRoomTypes = useCallback(() => {
    onSaveRoomTypes(inputValue);
  }, [onSaveRoomTypes, inputValue]);

  return (
    <>
      <div className={clsx('row', classes.roomTypeContainer)}>
        {options.map((option) => (
          <div
            className="col-3 mb-5 d-flex justify-content-center"
            key={option.id}
          >
            <RoomTypeOption
              option={option}
              currentValue={inputValue}
              onSelect={handleChange}
            />
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-center pt-3">
        <Button
          inline
          className="mr-2"
          onClick={handleSaveRoomTypes}
          disabled={isLoading}
        >
          Next
        </Button>
        <Button
          inline
          variant="outlined"
          onClick={onCancelEdit}
          disabled={isLoading}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

RoomTypeStep.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  currentValue: PropTypes.arrayOf(PropTypes.number),
  options: PropTypes.arrayOf(roomTypesOptionShape).isRequired,
  onCancelEdit: PropTypes.func.isRequired,
  onSaveRoomTypes: PropTypes.func.isRequired,
};

RoomTypeStep.defaultProps = {
  currentValue: undefined,
};

export default RoomTypeStep;
