import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import ImageLink from 'components/imageThumbnails/ImageLink';
import classes from './ImageDetails.module.scss';

const ImageDetails = ({ imgUrl, url, title, editRoute }) => (
  <div className={classes.root}>
    <div className="position-relative">
      <img className="w-100" src={imgUrl} alt="" />
      <div className={classes.info}>
        <div className="font-semi-bold text-sm">Title</div>
        <div className={classes.title}>{title}</div>
        {url && (
          <div className="text-ellipsis">
            <ImageLink url={url} />
          </div>
        )}
      </div>
      {editRoute && (
        <Link className={classes.edit} to={editRoute}>
          <IconButton variant="inverted-grey" color="secondary" size="sm">
            <SpriteIcon name="edit" size="sm" />
          </IconButton>
        </Link>
      )}
    </div>
  </div>
);

ImageDetails.propTypes = {
  imgUrl: PropTypes.string.isRequired,
  url: PropTypes.string,
  title: PropTypes.string.isRequired,
  editRoute: PropTypes.string,
};

ImageDetails.defaultProps = {
  url: null,
  editRoute: null,
};

export default ImageDetails;
