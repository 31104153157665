import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import { getEnumAsSelectOptions } from 'modules/app/store/selectors';
import { connect } from 'react-redux';
import { debounce, filter } from 'lodash-es';
import { toggleUnderConstructionModalAction } from 'modules/layout/store/actions';
import { priorityItemShape } from 'modules/requestTheLook/requestDetails/requestForm/propTypes';
import {
  requestFormLabels,
  requestFormFields,
} from 'modules/requestTheLook/requestDetails/requestForm/constants';
import {
  updateRequestDataAction,
  addPriorityItemAction,
  resetRequestFormAction,
} from 'modules/requestTheLook/store/actions';
import requestFormValidator from 'modules/requestTheLook/requestDetails/requestForm/requestFormValidator';
import ConfirmModal from 'components/modals/ConfirmModal';
import RequestFormComponent from 'modules/requestTheLook/requestDetails/requestForm/RequestFormComponent';
import classes from './RequestForm.module.scss';

const limit = 6; // priority items limit

const RequestFormContainer = ({
  requestType,
  requestTitle,
  updateRequestData,
  addPriorityItem,
  resetRequestForm,
  requestPriorityItems,
  priorityItemsList,
  toggleUnderConstructionModal,
}) => {
  const history = useHistory();

  const [resetModalOpen, setResetModalOpen] = useState(false);

  const addAnotherItemEnable = useMemo(
    () => requestPriorityItems.length < limit,
    [requestPriorityItems]
  );

  const optionsList = useMemo(() => {
    return filter(priorityItemsList, (option) => {
      return filter(requestPriorityItems, { value: option.value }).length === 0;
    });
  }, [priorityItemsList, requestPriorityItems]);

  const handleUnderConstructionModalOpen = useCallback(() => {
    toggleUnderConstructionModal(true);
  }, [toggleUnderConstructionModal]);

  const handleAddAnotherItem = useCallback(() => {
    if (addAnotherItemEnable) {
      addPriorityItem();
    }
  }, [addPriorityItem, addAnotherItemEnable]);

  const handleResetModalOpen = useCallback(() => {
    setResetModalOpen(true);
  }, []);

  const handleResetForm = useCallback(
    (confirm) => {
      if (confirm) {
        resetRequestForm();
      }
      setResetModalOpen(false);
    },
    [resetRequestForm]
  );

  const initialFormValues = useMemo(
    () => ({
      [requestFormFields.title]: requestTitle,
    }),
    [requestTitle]
  );

  const onSubmit = useCallback(() => {
    history.push(routesByName.requestTheLook.submit);
  }, [history]);

  const onTitleChange = useCallback(
    ({ values: { title } }) => {
      const value = title ?? '';
      if (requestTitle === value) return;
      updateRequestData({ title: value });
    },
    [requestTitle, updateRequestData]
  );

  const debouncedOnTitleChange = debounce(onTitleChange, 500);

  return (
    <div className={classes.root}>
      <h2
        className={`${classes.title} d-flex align-items-center justify-content-between mb-5`}
      >
        Get The Look: {requestFormLabels[requestType]}
        <button
          onClick={handleResetModalOpen}
          className={`${classes.underlineBtn} primary-color text-lg`}
          type="button"
        >
          Reset
        </button>
      </h2>
      <div className="text-lg font-semi-bold mb-5">More Specifications</div>
      <RequestFormComponent
        initialValues={initialFormValues}
        onSubmit={onSubmit}
        onTitleChange={debouncedOnTitleChange}
        validateForm={requestFormValidator}
        handleAddAnotherItem={handleAddAnotherItem}
        showAddAnotherItem={addAnotherItemEnable}
        optionsList={optionsList}
        requestPriorityItems={requestPriorityItems}
        handleUnderConstructionModalOpen={handleUnderConstructionModalOpen}
        requestType={requestType}
      />
      <ConfirmModal
        open={resetModalOpen}
        onClose={handleResetForm}
        title={
          <>
            Are you sure you want to <br />
            <span className="primary-color">reset</span> form?
          </>
        }
      />
    </div>
  );
};

RequestFormContainer.propTypes = {
  requestType: PropTypes.string.isRequired,
  requestTitle: PropTypes.string.isRequired,
  updateRequestData: PropTypes.func.isRequired,
  addPriorityItem: PropTypes.func.isRequired,
  resetRequestForm: PropTypes.func.isRequired,
  requestPriorityItems: PropTypes.arrayOf(priorityItemShape).isRequired,
  priorityItemsList: PropTypes.arrayOf(priorityItemShape).isRequired,
  toggleUnderConstructionModal: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  app: {
    enums: { itemClasses },
  },
  requestTheLook: {
    requestData: { requestType, title, items },
  },
}) => ({
  requestType,
  requestTitle: title,
  priorityItemsList: getEnumAsSelectOptions(itemClasses),
  requestPriorityItems: items,
});

const mapDispatchToProps = {
  updateRequestData: updateRequestDataAction,
  addPriorityItem: addPriorityItemAction,
  resetRequestForm: resetRequestFormAction,
  toggleUnderConstructionModal: toggleUnderConstructionModalAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestFormContainer);
