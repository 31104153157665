import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import ItemThumbnail from 'components/lookBoardThumbnails/ItemThumbnail/ItemThumbnail';
import SpriteIcon from 'components/ui/SpriteIcon';
import IconButton from 'components/ui/IconButton/IconButton';
import classes from 'components/lookBoardThumbnails/LookBoard/LookBoard.module.scss';

const LookBoardPreview = ({
  className,
  title,
  colorId,
  isLiked,
  products,
  showStaticOverlay,
  staticOverlay,
  hoverOverlay,
  colors,
}) => {
  const containerRef = useRef(null);
  const [imgHeight, setImgHeight] = useState('50%');
  const [containerHeight, setContainerHeight] = useState(null);
  const [productList, setProductList] = useState([]);
  const [hover, setHover] = useState(false);

  const hiddenItemsCount = useMemo(() => products.length - productList.length, [
    products,
    productList,
  ]);

  useEffect(() => {
    const refWidth = containerRef.current.clientWidth;
    const itemHeight =
      products.length > 1 ? Math.round(refWidth / 2) : refWidth;

    setContainerHeight(refWidth);
    setImgHeight(itemHeight);
  }, [products]);

  useEffect(() => {
    const list = products.slice(0, 4);
    switch (list.length) {
      case 2: {
        list.splice(1, 0, { id: uuidv4() }, { id: uuidv4() });
        break;
      }
      case 3: {
        list.push({ id: uuidv4() });
        break;
      }
      default: {
        break;
      }
    }
    setProductList(list);
  }, [products]);

  const handleHover = useCallback(() => {
    setHover(true);
  }, []);

  const handleBlur = useCallback(() => {
    setHover(false);
  }, []);

  return (
    <div
      ref={containerRef}
      className={clsx(classes.root, className)}
      onMouseEnter={handleHover}
      onMouseLeave={handleBlur}
    >
      <div
        className={classes.title}
        style={{ backgroundColor: colors[colorId].code }}
      >
        {title}
      </div>
      <div
        style={{ minHeight: containerHeight }}
        className={clsx(classes.imgContainer)}
      >
        {productList.map(({ id: productId, media }) =>
          media ? (
            <ItemThumbnail
              key={productId}
              src={media}
              className={products.length > 1 ? classes.double : classes.single}
              height={imgHeight}
            />
          ) : (
            <div
              key={productId}
              className={clsx(classes.placeholderWrapper, classes.double)}
              style={{ height: imgHeight }}
            >
              <div className={classes.placeholder}>
                <SpriteIcon name="logo" className={classes.placeholderIcon} />
              </div>
            </div>
          )
        )}
      </div>
      <div className={classes.bottomControls}>
        {hiddenItemsCount > 0 && (
          <IconButton
            variant="inverted-grey"
            color="secondary"
            size="sm"
            className="mr-1"
          >
            +{hiddenItemsCount}
          </IconButton>
        )}
        <IconButton
          variant="inverted-grey"
          color="secondary"
          size="sm"
          className="mr-1"
        >
          <SpriteIcon name={isLiked ? 'like-filled' : 'like'} size="sm" />
        </IconButton>
      </div>
      {showStaticOverlay && (
        <div className={classes.staticOverlay}>{staticOverlay}</div>
      )}
      <div className={clsx(classes.overlay, { [classes.hidden]: !hover })}>
        {hoverOverlay}
      </div>
    </div>
  );
};

LookBoardPreview.propTypes = {
  title: PropTypes.string.isRequired,
  colorId: PropTypes.number.isRequired,
  isLiked: PropTypes.number.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  className: PropTypes.string,
  hoverOverlay: PropTypes.node.isRequired,
  showStaticOverlay: PropTypes.bool,
  staticOverlay: PropTypes.node,
  colors: PropTypes.shape({
    code: PropTypes.string,
  }).isRequired,
};

LookBoardPreview.defaultProps = {
  className: '',
  showStaticOverlay: false,
  staticOverlay: null,
};

const mapStateToProps = ({
  app: {
    enums: { colors },
  },
}) => ({ colors });

export default connect(mapStateToProps)(LookBoardPreview);
