import React from 'react';
import * as PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import Button from 'components/ui/Button/Button';
import classes from './SelectStylesPopover.module.scss';

const SelectStylesPopover = ({
  open,
  anchorEl,
  onClose,
  onApplyStyles,
  children,
}) => {
  return (
    <Popover
      classes={{ paper: classes.root }}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {children}
      <div className="text-center pt-2">
        <Button variant="outlined" inline onClick={onApplyStyles}>
          Apply
        </Button>
      </div>
    </Popover>
  );
};

SelectStylesPopover.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onApplyStyles: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.object]),
};

SelectStylesPopover.defaultProps = {
  anchorEl: null,
};

export default SelectStylesPopover;
