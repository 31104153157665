import React from 'react';
import * as PropTypes from 'prop-types';
import InputSkeleton from 'components/inputs/InputSkeleton/InputSkeleton';
import classes from './TextAreaInput.module.scss';

const TextAreaInput = ({ rows, value, onChange, className, ...rest }) => {
  return (
    <InputSkeleton
      input={<textarea rows={rows} />}
      value={value}
      onChange={onChange}
      inputClasses={{
        inputWrapper: className,
        input: classes.input,
      }}
      {...rest}
    />
  );
};

TextAreaInput.propTypes = {
  rows: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

TextAreaInput.defaultProps = {
  rows: 5,
  value: undefined,
  onChange: undefined,
  className: '',
};

export default TextAreaInput;
