import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Field, Form } from 'react-final-form';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { accountTypes, signUpFormFields } from 'modules/auth/signUp/constants';
import { userShape } from 'modules/account/profileAndPreferences/propTypes';
import RadioInputWrapper from 'components/finalFormWrappers/RadioInputWrapper';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import SelectInputWrapper from 'components/finalFormWrappers/SelectInputWrapper';
import ProfilePhoto from 'components/photoUploaders/ProfilePhoto';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import Button from 'components/ui/Button/Button';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import TextInput from 'components/inputs/TextInput/TextInput';
import ConfirmModal from 'components/modals/ConfirmModal';
import { changePasswordFormFields } from 'modules/account/constants';
import PasswordInputWrapper from 'components/finalFormWrappers/PasswordInputWrapper';
import classes from './Profile.module.scss';

const ProfileComponent = ({
  isEdit,
  isEditPassword,
  onEnableEditMode,
  onEnableEditPasswordMode,
  profileValues,
  user,
  avatarUrl,
  countriesOptions,
  languagesOptions,
  onChangeProfile,
  onChangePassword,
  onCancelEditProfile,
  onCancelEditPassword,
  onSelectNewImage,
  onDeactivateUser,
}) => {
  const [deactivateAccountModalOpen, setDeactivateAccountModalOpen] = useState(
    false
  );

  const handleDeactivateAccountModalOpen = useCallback(() => {
    setDeactivateAccountModalOpen(true);
  }, []);

  const handleDeactivateAccount = useCallback(
    (confirm) => {
      if (confirm) {
        onDeactivateUser();
      }
      setDeactivateAccountModalOpen(false);
    },
    [onDeactivateUser]
  );

  return (
    <>
      <section className={classes.section}>
        {!isEdit && (
          <IconButton
            className={classes.editBtn}
            color="secondary"
            title="Edit Profile"
            onClick={onEnableEditMode}
          >
            <SpriteIcon name="edit" size="sm" />
          </IconButton>
        )}
        <p className={classes.title}>Profile</p>
        <Form
          initialValuesEqual={(prevVal, currVal) => prevVal === currVal}
          initialValues={profileValues}
          onSubmit={onChangeProfile}
          render={({ values, handleSubmit }) => (
            <form noValidate onSubmit={handleSubmit}>
              <div className="d-flex">
                <div className="flex-fill">
                  <FormControl component="fieldset" disabled={!isEdit}>
                    <RadioGroup>
                      <div className="d-flex mb-3">
                        <FormControlLabel
                          label="Personal"
                          classes={{
                            disabled: classes.disabledInput,
                            root: 'mr-5',
                            label: clsx({
                              'font-semi-bold':
                                values.type === accountTypes.personal,
                            }),
                          }}
                          control={
                            <Field
                              name={signUpFormFields.type}
                              type="radio"
                              value={accountTypes.personal}
                              component={RadioInputWrapper}
                            />
                          }
                        />
                        <FormControlLabel
                          label="Business"
                          classes={{
                            label: clsx({
                              'font-semi-bold':
                                values.type === accountTypes.business,
                            }),
                            disabled: classes.disabledInput,
                          }}
                          control={
                            <Field
                              name={signUpFormFields.type}
                              type="radio"
                              value={accountTypes.business}
                              component={RadioInputWrapper}
                            />
                          }
                        />
                      </div>
                    </RadioGroup>
                  </FormControl>
                  <div className="d-flex flex-wrap justify-content-between">
                    <div className={classes.halfWidthContainer}>
                      <Field
                        disabled={!isEdit}
                        required
                        label="First Name"
                        name={signUpFormFields.firstName}
                        component={TextInputWrapper}
                        InputProps={{
                          classes: { disabled: classes.disabledInput },
                        }}
                      />
                    </div>
                    <div className={classes.halfWidthContainer}>
                      <Field
                        disabled={!isEdit}
                        required
                        label="Last Name"
                        name={signUpFormFields.lastName}
                        component={TextInputWrapper}
                        InputProps={{
                          classes: { disabled: classes.disabledInput },
                        }}
                      />
                    </div>

                    {values.type === accountTypes.business && (
                      <>
                        <div className={classes.halfWidthContainer}>
                          <Field
                            disabled={!isEdit}
                            required
                            label="Company Name"
                            name={signUpFormFields.businessName}
                            component={TextInputWrapper}
                            InputProps={{
                              classes: { disabled: classes.disabledInput },
                            }}
                          />
                        </div>
                        <div className={classes.halfWidthContainer}>
                          <Field
                            disabled={!isEdit}
                            required
                            label="Business Type"
                            name={signUpFormFields.businessType}
                            component={TextInputWrapper}
                            InputProps={{
                              classes: { disabled: classes.disabledInput },
                            }}
                          />
                        </div>
                        <div className={classes.halfWidthContainer}>
                          <Field
                            disabled={!isEdit}
                            label="Website Url (Optional)"
                            name={signUpFormFields.businessUrl}
                            component={TextInputWrapper}
                            InputProps={{
                              classes: { disabled: classes.disabledInput },
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <Field
                    disabled={!isEdit}
                    label="Profile Description (Optional)"
                    name={signUpFormFields.description}
                    multiline
                    rows={4}
                    component={TextInputWrapper}
                    InputProps={{
                      classes: { disabled: classes.disabledInput },
                    }}
                  />
                </div>
                <div className={classes.avatarContainer}>
                  <ProfilePhoto
                    firstName={user.firstName}
                    lastName={user.lastName}
                    src={avatarUrl}
                    editMode={isEdit}
                    onSelectNewImage={onSelectNewImage}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className={classes.oneThirdWidthContainer}>
                  <Field
                    disabled={!isEdit}
                    required
                    label="Country"
                    name={signUpFormFields.countryId}
                    component={SelectInputWrapper}
                    options={countriesOptions}
                    classes={{ disabled: classes.disabledInput }}
                  />
                </div>
                <div className={classes.oneThirdWidthContainer}>
                  <Field
                    disabled={!isEdit}
                    required
                    label="Language"
                    name={signUpFormFields.languageId}
                    component={SelectInputWrapper}
                    options={languagesOptions}
                    classes={{ disabled: classes.disabledInput }}
                  />
                </div>
                <div className={classes.oneThirdWidthContainer}>
                  <Field
                    disabled={!isEdit}
                    label="Zip Code (Optional)"
                    name={signUpFormFields.zipCode}
                    component={TextInputWrapper}
                    InputProps={{
                      classes: { disabled: classes.disabledInput },
                    }}
                  />
                </div>
              </div>
              {isEdit && (
                <>
                  <FinalFormSubmitButton inline className="mr-2">
                    Save Changes
                  </FinalFormSubmitButton>
                  <Button
                    type="button"
                    inline
                    variant="outlined"
                    onClick={onCancelEditProfile}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </form>
          )}
        />
      </section>
      <section className={classes.section}>
        {!isEditPassword && (
          <IconButton
            className={classes.editBtn}
            color="secondary"
            title="Change Password"
            onClick={onEnableEditPasswordMode}
          >
            <SpriteIcon name="edit" size="sm" />
          </IconButton>
        )}
        <p className={clsx(classes.title, 'mb-5')}>Email & Password</p>
        <div className={classes.oneThirdWidthContainer}>
          <TextInput
            disabled
            label="Email"
            value={user.email}
            InputProps={{
              classes: { disabled: classes.disabledInput },
            }}
          />
        </div>
        {isEditPassword ? (
          <Form
            onSubmit={onChangePassword}
            render={({ handleSubmit }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className="d-flex align-items-center justify-content-between">
                  <div className={classes.oneThirdWidthContainer}>
                    <Field
                      required
                      label="Current Password"
                      name={changePasswordFormFields.currentPassword}
                      component={PasswordInputWrapper}
                    />
                  </div>
                  <div className={classes.oneThirdWidthContainer}>
                    <Field
                      required
                      label="New Password"
                      name={changePasswordFormFields.newPassword}
                      component={PasswordInputWrapper}
                    />
                  </div>
                  <div className={classes.oneThirdWidthContainer}>
                    <Field
                      required
                      label="Confirm Password"
                      name={changePasswordFormFields.confirmNewPassword}
                      component={PasswordInputWrapper}
                    />
                  </div>
                </div>
                <FinalFormSubmitButton inline className="mr-2">
                  Change Password
                </FinalFormSubmitButton>
                <Button
                  type="button"
                  inline
                  variant="outlined"
                  onClick={onCancelEditPassword}
                >
                  Cancel
                </Button>
              </form>
            )}
          />
        ) : (
          <div className={classes.oneThirdWidthContainer}>
            <TextInput
              disabled
              label="Password"
              type="password"
              value="************"
              InputProps={{
                classes: { disabled: classes.disabledInput },
              }}
            />
          </div>
        )}
      </section>
      <section className={classes.section}>
        <p className={clsx(classes.title, 'mb-4')}>Membership type: General</p>
        <Button inline>Become an Ambassador</Button>
      </section>
      <section className={classes.section}>
        <div className="d-flex align-items-center justify-content-between mb-2">
          <p className={classes.title}>Deactivate Account</p>
          <Button
            type="button"
            variant="default"
            color="default"
            inline
            className={classes.deactivateBtn}
            onClick={handleDeactivateAccountModalOpen}
          >
            Deactivate Account
          </Button>
        </div>
        <p className={classes.deactivateDescription}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitatio
        </p>
      </section>
      <ConfirmModal
        open={deactivateAccountModalOpen}
        onClose={handleDeactivateAccount}
        title={
          <>
            Are you sure you want to <br />
            <span className="primary-color">deactivate</span> account?
          </>
        }
      />
    </>
  );
};

ProfileComponent.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  isEditPassword: PropTypes.bool.isRequired,
  onEnableEditMode: PropTypes.func.isRequired,
  onEnableEditPasswordMode: PropTypes.func.isRequired,
  user: userShape.isRequired,
  countriesOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  languagesOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  profileValues: PropTypes.shape({}).isRequired,
  onChangeProfile: PropTypes.func.isRequired,
  onChangePassword: PropTypes.func.isRequired,
  onCancelEditProfile: PropTypes.func.isRequired,
  onCancelEditPassword: PropTypes.func.isRequired,
  onSelectNewImage: PropTypes.func.isRequired,
  onDeactivateUser: PropTypes.func.isRequired,
  avatarUrl: PropTypes.string,
};

ProfileComponent.defaultProps = {
  avatarUrl: null,
};

export default ProfileComponent;
