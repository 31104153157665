import {
  CTL_RESET_CANVAS,
  CTL_RESET_STATE,
  CTL_SET_FORM_VALUES,
  CTL_SET_IMAGES,
  CTL_SET_LOOK_BOARD_STATUS,
  CTL_SET_PRODUCTS,
  CTL_SET_SELECTED_TEMPLATE,
  CTL_SET_STEP,
  CTL_UPDATE_DND_STATE,
  CTL_UPDATE_II_SEARCH_PARAMS,
  CTL_UPDATE_IMAGES,
  CTL_UPDATE_LOOK_BOARD_DATA,
  CTL_UPDATE_PRODUCT_SEARCH_PARAMS,
  CTL_UPDATE_PRODUCTS,
} from 'modules/curateTheLook/store/constants';
import {
  createLookBoardStepKeys,
  lookBoardStatusKeys,
} from 'modules/curateTheLook/constants';
import {
  imageSourceKeys,
  imageTypeKeys,
} from 'constants/inspirationImageSearchParams';
import { productTypeKeys } from 'modules/product/constants';
// import { AUTH_LOGOUT } from 'modules/auth/store/constants';

const initialImageSearchParams = {
  source: imageSourceKeys.all,
  type: imageTypeKeys.room,
  search: '',
  offset: 0,
};

const initialProductSearchParams = {
  type: productTypeKeys.all,
  search: '',
  offset: 0,
};

const dndInitialState = {
  productList: {
    id: 'productList',
    productIds: [],
  },
  dynamicColumns: {},
};

const initialLookBoardData = {
  title: 'Get The Look',
  colorId: 2,
  products: [],
  columns: null,
};

const initialState = {
  currentStep: createLookBoardStepKeys.selectImage,
  inspirationImageSearchParams: initialImageSearchParams,
  imageList: [],
  productSearchParams: initialProductSearchParams,
  productList: [],
  selectedTemplate: null,
  dndState: dndInitialState,
  lookBoardData: initialLookBoardData,
  lookBoardDetailsFormValues: null,
  lookBoardStatus: lookBoardStatusKeys.public,
};

const curateTheLookReducer = (
  state = { ...initialState },
  { type, payload }
) => {
  switch (type) {
    case CTL_UPDATE_DND_STATE: {
      return {
        ...state,
        dndState: {
          ...state.dndState,
          ...payload,
        },
      };
    }
    case CTL_SET_IMAGES: {
      return {
        ...state,
        imageList: payload,
      };
    }
    case CTL_UPDATE_IMAGES: {
      return {
        ...state,
        imageList: [...state.imageList, ...payload],
      };
    }
    case CTL_SET_PRODUCTS: {
      return {
        ...state,
        productList: payload,
        dndState: {
          ...state.dndState,
          productList: {
            ...state.dndState.productList,
            productIds: payload,
          },
        },
      };
    }
    case CTL_UPDATE_PRODUCTS: {
      return {
        ...state,
        productList: [...state.productList, ...payload],
        dndState: {
          ...state.dndState,
          productList: {
            ...state.dndState.productList,
            productIds: [...state.dndState.productList.productIds, ...payload],
          },
        },
      };
    }
    case CTL_SET_STEP: {
      return {
        ...state,
        currentStep: payload,
      };
    }
    case CTL_SET_SELECTED_TEMPLATE: {
      return {
        ...state,
        selectedTemplate: payload,
      };
    }
    case CTL_UPDATE_LOOK_BOARD_DATA: {
      return {
        ...state,
        lookBoardData: {
          ...state.lookBoardData,
          ...payload,
        },
      };
    }
    case CTL_RESET_CANVAS: {
      return {
        ...state,
        lookBoardData: {
          ...state.lookBoardData,
          ...initialLookBoardData,
        },
      };
    }
    case CTL_UPDATE_II_SEARCH_PARAMS: {
      return {
        ...state,
        inspirationImageSearchParams: {
          ...state.inspirationImageSearchParams,
          ...payload,
        },
      };
    }
    case CTL_UPDATE_PRODUCT_SEARCH_PARAMS: {
      return {
        ...state,
        productSearchParams: {
          ...state.productSearchParams,
          ...payload,
        },
      };
    }
    case CTL_SET_FORM_VALUES: {
      return {
        ...state,
        lookBoardDetailsFormValues: payload,
      };
    }
    case CTL_SET_LOOK_BOARD_STATUS: {
      return {
        ...state,
        lookBoardStatus: payload,
      };
    }
    case CTL_RESET_STATE: {
      return { ...initialState };
    }
    // case AUTH_LOGOUT: {
    //   return { ...initialState };
    // }
    default:
      return state;
  }
};

export default curateTheLookReducer;
