import React from 'react';
import Banner from 'modules/home/components/Banner/Banner';
import GetTheLookYouLove from 'modules/home/components/GetTheLookYouLove/GetTheLookYouLove';
import HowItWorks from 'modules/home/components/HowItWorks/HowItWorks';
import Ambassadors from 'modules/home/components/Ambassadors/Ambassadors';
import VisualizeIdeas from 'modules/home/components/VisualizeIdeas/VisualizeIdeas';

const HomeContainer = () => (
  <>
    <Banner />
    <GetTheLookYouLove />
    <VisualizeIdeas />
    <HowItWorks />
    <Ambassadors />
  </>
);

export default HomeContainer;
