const colorsConfig = {
  1: {
    id: 1,
    color: '#999A95',
  },
  2: {
    id: 2,
    color: '#B79759',
  },
  3: {
    id: 3,
    color: '#DC4E41',
  },
  4: {
    id: 4,
    color: '#BDCAA3',
  },
  5: {
    id: 5,
    color: '#FBB17B',
  },
  6: {
    id: 6,
    color: '#FFBABA',
  },
  7: {
    id: 7,
    color: '#72B2B7',
  },
  8: {
    id: 8,
    color: '#B3B3B3',
  },
  9: {
    id: 9,
    color: '#EEE2DA',
  },
  10: {
    id: 10,
    color: '#FF3F3F',
  },
  11: {
    id: 11,
    color: '#1CADFF',
  },
  12: {
    id: 12,
    color: '#81E8FF',
  },
  13: {
    id: 13,
    color: '#FFACAC',
  },
  14: {
    id: 14,
    color: '#B7ADA4',
  },
  15: {
    id: 15,
    color: '#FF3F3F',
  },
};

export default colorsConfig;
