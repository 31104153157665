import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import BasicAvatar from 'components/ui/BasicAvatar';
import classes from 'modules/layout/Header/Header.module.scss';

const HeaderThumbnail = ({ firstName, lastName, src }) => {
  const fullName = useMemo(() => `${firstName} ${lastName}`, [
    firstName,
    lastName,
  ]);

  return (
    <div className="d-flex align-items-center" style={{ width: 180 }}>
      <BasicAvatar firstName={firstName} lastName={lastName} src={src} />
      <p className={`${classes.fullName} ml-1 text-ellipsis`}>{fullName}</p>
    </div>
  );
};

HeaderThumbnail.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  src: PropTypes.string,
};

HeaderThumbnail.defaultProps = {
  src: undefined,
};

export default HeaderThumbnail;
