import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CreateLookBoardCanvas from 'modules/curateTheLook/createLookBoard/components/CreateLookBoardCanvas/CreateLookBoardCanvas';
import { inspirationImageShape } from 'modules/inspirationImage/propTypes';
import { getInspirationImgUrl } from 'modules/app/store/selectors';
import buildResourceUrl from 'utils/buildResourceUrl';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from 'modules/curateTheLook/createLookBoard/CreateLookBoard.module.scss';

const MainArea = ({ inspirationImageUrl, selectedImage, onOpenPreview }) => {
  const imgUrl = useMemo(
    () =>
      selectedImage &&
      buildResourceUrl(
        inspirationImageUrl.medium,
        selectedImage.media.userId,
        selectedImage.media.hash
      ),
    [inspirationImageUrl, selectedImage]
  );

  const handleOpenPreview = useCallback(() => {
    onOpenPreview(imgUrl);
  }, [onOpenPreview, imgUrl]);

  return (
    <div className={classes.mainAreaWrapper}>
      <div>
        <div className="d-flex justify-content-between mb-2">
          <div>
            <p
              className="font-title text-capitalize mb-1"
              style={{ fontSize: 24 }}
            >
              Create Look Board
            </p>
            <div className={classes.processDescription}>
              <p className="primary-color font-semi-bold">How it works:</p>
              <p>
                <b>Step 1.</b> Select a Look Board Inspiration image{' '}
                <b className="primary-color">(or skip)</b>
              </p>
              <p>
                <b>Step 2.</b> Select a Look Board Template and Curate with
                Products
              </p>
              <p>
                <b>Step 3.</b> Add Look Board details on the next page
              </p>
            </div>
          </div>
          {selectedImage && (
            <div>
              <div className={classes.imgPreview}>
                <IconButton
                  className={classes.imgPreviewThumbZoom}
                  color="secondary"
                  size="xs"
                  onClick={handleOpenPreview}
                >
                  <SpriteIcon name="loupe" size="xs" />
                </IconButton>
                <img
                  alt=""
                  src={buildResourceUrl(
                    inspirationImageUrl.thumb,
                    selectedImage.media.userId,
                    selectedImage.media.hash
                  )}
                />
              </div>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-between mb-2">
          <div>
            <p className="font-title d-flex align-items-center">
              <span className="mr-1">Look Board Curation Canvas</span>
              <IconButton variant="inverted-white" color="secondary" size="xs">
                <SpriteIcon name="info" size="sm" />
              </IconButton>
            </p>
          </div>
        </div>
        <CreateLookBoardCanvas />
      </div>
    </div>
  );
};

MainArea.propTypes = {
  inspirationImageUrl: PropTypes.shape({
    thumb: PropTypes.string.isRequired,
    medium: PropTypes.string.isRequired,
  }).isRequired,
  selectedTemplate: PropTypes.shape({
    columnCount: PropTypes.number.isRequired,
  }),
  selectedImage: inspirationImageShape,
  onOpenPreview: PropTypes.func.isRequired,
};

MainArea.defaultProps = {
  selectedImage: null,
  selectedTemplate: null,
};

const mapStateToProps = ({
  app: { config },
  curateTheLook: {
    lookBoardData: { inspirationImageId },
  },
  inspirationImage: { library },
}) => ({
  inspirationImageUrl: getInspirationImgUrl(config),
  selectedImage: library[inspirationImageId],
});

export default connect(mapStateToProps)(MainArea);
